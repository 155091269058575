import {Stack, Tooltip} from '@mui/material';
import React, {FC} from 'react';
import {CloseIcon} from '../images/CloseIcon';

type Props = {
  onClick: () => void;
};
export const DeleteRuleButton: FC<Props> = ({onClick}) => {
  return (
    <Tooltip title="ルールを削除">
      <Stack onClick={onClick}>
        <CloseIcon
          style={{
            color: '#767C95',
            width: '20px',
            cursor: 'pointer',
          }}
        />
      </Stack>
    </Tooltip>
  );
};

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://xdexy8cofl.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "flikRevenuecat",
            "endpoint": "https://wkr7978od2.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "flikSbpaymentCancel",
            "endpoint": "https://bry4jryqh5.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "flikSbpaymentError",
            "endpoint": "https://nncoe9n3qh.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "flikSbpaymentPageContext",
            "endpoint": "https://w9mkqfexg3.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "flikSbpaymentSuccess",
            "endpoint": "https://1rxs9hrgy7.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nalxp4hrjzecllsv63ixgq25sm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vm7d325gbbdvnbyvhxi6lio4ci",
    "aws_cognito_identity_pool_id": "ap-northeast-1:43ebde39-aeb1-4169-874c-1e85133640bc",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_AwT6Lwrbb",
    "aws_user_pools_web_client_id": "3j9co6mreujkje2mqe4kd39an8",
    "oauth": {
        "domain": "flikd9c30cf3-d9c30cf3-production.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "flik://",
        "redirectSignOut": "flik://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "flik9c644637d6e3406d8ee0aca562240a3393129-production",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;

import {
  Stack,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Typography,
  Box,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Storage} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {SimpleStats} from '../components/SimpleStats';
import {ImageIcon} from '../images/ImageIcon';
import {LocationIcon} from '../images/LocationIcon';
import {OutlinedCheckCircleIcon} from '../images/OutlinedCheckCircleIcon';
import {PlusIcon} from '../images/PlusIcon';
import {useProperties} from '../queries/property';
import {isNonNull} from '../utils/isNonNull';

const ItemContainer = styled('ul')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
  padding: 0,
}));

type PropertyInfo = {
  id: string;
  name: string;
  address: string;
  thumbnail: string;
  stock: number;
  sold: number;
};

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {firstSignIn: boolean};
  const [open, setOpen] = useState(false);
  const {data} = useProperties();
  const [properties, setProperties] = useState<PropertyInfo[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (state?.firstSignIn) {
      setOpen(true);
    }
    const updateProperties = async () => {
      if (!data) return;
      const items: PropertyInfo[] = [];
      for (const property of data.items) {
        if (!property) continue;
        const rooms = property.rooms?.items;
        const item: PropertyInfo = {
          id: property.id,
          name: property.name,
          address: property.address,
          thumbnail: property.thumbnail
            ? await Storage.get(property.thumbnail, {
                level: 'private',
              })
            : '',
          stock: rooms
            ? rooms.filter(isNonNull).filter(item => !item.soldDate).length
            : 0,
          sold: rooms
            ? rooms.filter(isNonNull).filter(item => !!item.soldDate).length
            : 0,
        };
        items.push(item);
      }
      setProperties(items);
    };
    updateProperties();
  }, [state, data]);

  return (
    <>
      <SimpleStats />
      <Stack direction="row" margin="48px 0 24px" spacing={4}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '36px',
          }}>
          物件一覧
        </Typography>
        <Button
          variant="outlined"
          startIcon={<PlusIcon fontSize="small" strokeColor="#26B9C0" />}
          size="small"
          onClick={() => navigate('/addProperty')}>
          新規追加
        </Button>
      </Stack>
      {properties ? (
        <ItemContainer>
          {Object.values(properties)
            .filter(isNonNull)
            .map(item => (
              <ImageListItem
                key={item.id}
                sx={{
                  width: '254px',
                  height: '190px !important',
                  cursor: 'pointer',
                  transition: 'box-shadow ease-in .2s',
                  '&:hover': {
                    boxShadow: 2,
                  },
                  backgroundColor: 'flikGray.-25',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  borderRadius: '12px',
                }}
                onClick={() => navigate(`/property/${item.id}`)}>
                {item.thumbnail ? (
                  <Box
                    component="img"
                    sx={{
                      borderRadius: '12px',
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    src={item.thumbnail}></Box>
                ) : (
                  <ImageIcon
                    sx={{
                      width: '60px !important',
                      height: '44px !important',
                      fill: 'transparent !important',
                    }}
                  />
                )}
                <ImageListItemBar
                  sx={{
                    borderRadius: '0px 0px 12px 12px',
                    '& .MuiImageListItemBar-titleWrap': {padding: '8px 12px'},
                  }}
                  position="bottom"
                  title={
                    <Stack alignItems="unset" spacing={0.5}>
                      <Typography
                        noWrap
                        color="white"
                        fontSize="16px"
                        fontWeight="600">
                        {item.name}
                      </Typography>
                      <Stack direction="row" spacing={0.5}>
                        <LocationIcon />
                        <Typography
                          color="white"
                          fontSize="12px"
                          fontWeight="400"
                          noWrap>
                          {item.address}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          color="white"
                          fontSize="12px"
                          fontWeight="400"
                          noWrap>
                          所有数：{item.stock}
                        </Typography>
                        <Typography
                          color="white"
                          fontSize="12px"
                          fontWeight="400"
                          noWrap>
                          売却済：{item.sold}
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
              </ImageListItem>
            ))}
        </ItemContainer>
      ) : (
        <Typography>物件がまだ登録されていません。</Typography>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <BaseBox
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'unset',
            boxShadow: 'none',
          }}>
          <OutlinedCheckCircleIcon />
          <Typography
            id="modal-modal-description"
            align="center"
            sx={{
              fontWeight: '500',
              fontSize: 20,
              lineHeight: 1.5,
              m: '24px 0 40px',
            }}>
            アカウント登録が完了しました
          </Typography>
          <Button
            variant="contained"
            sx={{
              display: 'flex',
              m: 'auto',
            }}
            onClick={handleClose}>
            はじめる
          </Button>
        </BaseBox>
      </Modal>
    </>
  );
};

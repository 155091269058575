import {Stack, Grid, Typography, Modal, CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {Dispatch, SetStateAction} from 'react';
import {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {Expenses} from '../API';
import {BaseBox as Box} from '../components/BaseBox';
import {Button} from '../components/Button';
import {useDeleteExpensesMutation} from '../queries/expenses';
import {convertDateToString} from '../utils/convertDateToString';
import {convertExpensesCategoryToString} from '../utils/convertExpensesCategoryToString';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export type PropsForEdit = {
  openDeleteModal: boolean;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  expense: Expenses | null;
};

const StyledText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '20.8px',
}));

export const DeleteExpenseModal: React.VFC<PropsForEdit> = (
  props: PropsForEdit,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: deleteExpenses, isLoading} = useDeleteExpensesMutation();
  const handleClose = () => {
    props.setOpenDeleteModal(false);
  };
  const onDelete = useCallback(async () => {
    if (!props.expense) {
      navigate('/error');
      return;
    }
    deleteExpenses(
      {
        input: {
          id: props.expense?.id,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          props.setOpenDeleteModal(false);
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [deleteExpenses, navigate, props, queryClient]);

  return (
    <Modal
      open={props.openDeleteModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          color: 'white',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '464px',
          bgcolor: 'background.paper',
          padding: '40px',
          boxShadow: 'none',
        }}>
        <Stack height="100%">
          <Typography
            textAlign="center"
            sx={{fontSize: '20px', fontWeight: '500', LineHeight: '26px'}}>
            削除
          </Typography>
          <Typography my="24px">
            以下の雑費を削除します。削除すると復元はできません。
          </Typography>
          <Grid container direction="row" mb="20px">
            <Grid item container xs={6}>
              <Stack>
                <Grid item container alignItems="center" mb="8px">
                  <StyledText>発生日</StyledText>
                </Grid>
                <Typography>
                  {props.expense?.issueDate &&
                    convertDateToString(
                      toISOStringWithTimezone(
                        new Date(props.expense.issueDate),
                      ),
                      'yyyy/M/d',
                    )}
                </Typography>
              </Stack>
            </Grid>
            <Grid item container xs={6}>
              <Stack>
                <Grid item container alignItems="center" mb="8px">
                  <StyledText>金額</StyledText>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid item container xs="auto">
                    <Typography>
                      {props.expense?.price.toLocaleString()}円
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Stack mb="20px">
            <StyledText mb="8px">利用先名称</StyledText>
            <Typography>{props.expense?.company}</Typography>
          </Stack>
          <Stack mb="20px">
            <StyledText mb="8px">用途</StyledText>
            <Typography>
              {convertExpensesCategoryToString(props.expense?.category)}
            </Typography>
          </Stack>
          <Stack mb="40px">
            <StyledText mb="8px">メモ</StyledText>
            <Typography>{props.expense?.note}</Typography>
          </Stack>
        </Stack>
        <Button variant="outlined" onClick={handleClose} sx={{mr: '20px'}}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="warning"
          disabled={isLoading}
          startIcon={
            isLoading ? (
              <CircularProgress
                sx={{
                  color: 'white',
                  width: '20px  !important',
                  height: '20px  !important',
                }}
              />
            ) : null
          }
          onClick={onDelete}>
          {isLoading ? '削除中' : ' 削除する'}
        </Button>
      </Box>
    </Modal>
  );
};

import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation} from 'react-query';
import {useQuery} from 'react-query';
import {
  CreatePropertyFileMutation,
  CreatePropertyFileMutationVariables,
  DeletePropertyFileMutation,
  DeletePropertyFileMutationVariables,
  ListFilesByUserFilesIdQuery,
  ListFilesByUserFilesIdQueryVariables,
} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {createPropertyFile, deletePropertyFile} from '../graphql/mutations';
import {listFilesByUserFilesId} from '../graphql/queries';

export const usePropertyFiles = () => {
  const user = useOAuthUser();
  return useQuery(['propertyFiles', user?.attributes.sub], async () => {
    if (!user) {
      return undefined;
    }
    const variables: ListFilesByUserFilesIdQueryVariables = {
      userFilesId: user.attributes.sub,
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listFilesByUserFilesId, variables),
    )) as GraphQLResult<ListFilesByUserFilesIdQuery>;
    return result?.data?.listFilesByUserFilesId;
  });
};

export const useCreatePropertyFileMutation = () => {
  return useMutation(async (variables: CreatePropertyFileMutationVariables) => {
    const propertyFile = (await API.graphql(
      graphqlOperation(createPropertyFile, variables),
    )) as GraphQLResult<CreatePropertyFileMutation>;
    return propertyFile.data?.createPropertyFile;
  });
};

export const useDeletePropertyFileMutation = () => {
  return useMutation(async (variables: DeletePropertyFileMutationVariables) => {
    const propertyFile = (await API.graphql(
      graphqlOperation(deletePropertyFile, variables),
    )) as GraphQLResult<DeletePropertyFileMutation>;
    return propertyFile.data?.deletePropertyFile;
  });
};

import {
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BaseBox as Box} from './BaseBox';
import {
  ConsultingSheetInput,
  CreateConsultingSheetInput,
  PdfCategory,
  User,
} from '../API';
import {ArrowBackIcon} from '../images/ArrowBackIcon';
import {ArrowForwardIcon} from '../images/ArrowForwardIcon';
import {DownloadIcon} from '../images/DownloadIcon';
import {FileIcon} from '../images/FileIcon';
import {useDownloadPdf} from '../queries/download';
import {chunkItems} from '../utils/chunkItems';

type Props = {
  selectSheetIndex: number;
  consultingData: ConsultingSheetInput[];
  userInfo: User;
  setSelectSheetIndex: (index: number) => void;
};
type SelectData = {data: CreateConsultingSheetInput; index: number};

export const ConsultingSheetList = ({
  selectSheetIndex,
  consultingData,
  userInfo,
  setSelectSheetIndex,
}: Props) => {
  const navigate = useNavigate();
  const {mutate: downloadPdf, isLoading} = useDownloadPdf();
  const [dataChunk, setDataChunk] = useState<CreateConsultingSheetInput[][]>([
    [],
  ]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;

  const handleChangeConsultingSheet = useCallback(
    (selectData: SelectData) => {
      setSelectSheetIndex(selectData.index + 20 * (page - 1));
    },
    [page, setSelectSheetIndex],
  );
  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  useEffect(() => {
    const chunks = chunkItems(consultingData, rowsPerPage);
    setDataChunk(chunks);
  }, [consultingData]);
  const checkIsSelected = useCallback(
    (index: number) => {
      return index + 20 * (page - 1) === selectSheetIndex;
    },
    [page, selectSheetIndex],
  );

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '420px',
        height: '100%',
        maxHeight: 'calc(100vh - 152px)',
        padding: '20px',
        position: 'fixed',
      }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize="16px" fontWeight={500}>
          コンサルティングシート一覧
        </Typography>
        <Button
          variant="outlined"
          size="small"
          startIcon={
            isLoading ? (
              <CircularProgress
                sx={{width: '20px  !important', height: '20px  !important'}}
              />
            ) : (
              <DownloadIcon fontSize="small" strokeColor="#31CDD3" />
            )
          }
          onClick={async () => {
            downloadPdf(
              {
                category: PdfCategory.CONSULTINGSHEET,
                fileName: `${consultingData[0].currentDate}コンサルティングシート`,
                userName: `${userInfo.firstName} ${userInfo.lastName}`,
                consultingData: consultingData,
              },
              {
                onError: () => {
                  navigate('/error');
                  return;
                },
              },
            );
          }}>
          {isLoading ? 'ダウンロード中' : 'ダウンロード'}
        </Button>
      </Stack>
      <Typography
        textAlign="right"
        fontSize="14px"
        color="flikGray.+15"
        margin="16px 0 4px">{`※${consultingData[0].currentDate}1日更新`}</Typography>
      <List sx={{height: 'calc(100% - 116px)', overflow: 'scroll'}}>
        {dataChunk[page - 1] &&
          dataChunk[page - 1].map((data, index) => {
            return (
              <ListItemButton
                key={index}
                selected={checkIsSelected(index)}
                onClick={() => {
                  handleChangeConsultingSheet({data, index});
                }}
                sx={{borderBottom: '1px solid #E9EAEE'}}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <FileIcon
                    strokeColor={checkIsSelected(index) ? '#25A7AC' : '#181C32'}
                    sx={{
                      width: '20px !important',
                      height: '20px !important',
                      path: {
                        fill: 'transparent',
                      },
                      color: checkIsSelected(index)
                        ? 'flickPrimary.main'
                        : 'black',
                    }}
                  />
                  <ListItemText
                    primary={`${data.propertyName}_${data.roomName}`}
                    primaryTypographyProps={{
                      color: checkIsSelected(index)
                        ? 'flikPrimary.+10'
                        : 'black',
                      fontWeight: checkIsSelected(index) ? 500 : 300,
                      fontSize: 14,
                    }}
                  />
                </Stack>
              </ListItemButton>
            );
          })}
      </List>
      <Pagination
        count={Math.ceil(consultingData.length / rowsPerPage)}
        renderItem={item => (
          <PaginationItem
            sx={{
              backgroundColor: '#E9EAEE',
              '&:hover': {
                backgroundColor: 'flickPrimary.main',
              },
              '&.Mui-selected': {color: 'white'},
            }}
            components={{
              previous: ArrowBackIcon,
              next: ArrowForwardIcon,
            }}
            {...item}
          />
        )}
        color="primary"
        shape="rounded"
        sx={{ul: {justifyContent: 'center'}, marginTop: '16px'}}
        onChange={handleChangePage}
      />
    </Box>
  );
};

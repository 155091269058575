import {SvgIconProps, SvgIcon} from '@material-ui/core';
import React from 'react';

export const EditIcon = (props: SvgIconProps) => {
  const StrokeColor = props.color === 'primary' ? '#31CDD3' : '#181C32';
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.33334 26.6667H10.6667L24.6667 12.6667C25.3739 11.9594 25.7712 11.0002 25.7712 10C25.7712 8.9998 25.3739 8.04057 24.6667 7.33333C23.9594 6.62609 23.0002 6.22876 22 6.22876C20.9998 6.22876 20.0406 6.62609 19.3333 7.33333L5.33334 21.3333V26.6667Z"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8.66663L23.3333 14"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import {Stack, Typography} from '@mui/material';
import React, {ComponentProps, VFC} from 'react';

type Props = {
  isRequired?: boolean;
} & ComponentProps<typeof Stack>;

export const LabelTitle: VFC<Props> = ({isRequired = false, ...props}) => {
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      width="210px"
      {...props}>
      <Typography>{props.children}</Typography>
      {isRequired && (
        <Typography
          sx={{
            color: 'warning.main',
            fontSize: '12px',
            wordBreak: 'keep-all',
          }}>
          必須
        </Typography>
      )}
    </Stack>
  );
};

import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import React, {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
  VFC,
} from 'react';
import {TextField} from './TextField';

type CustomProps = Omit<
  ComponentProps<typeof MuiDatePicker>,
  'renderInput' | 'onChange'
> & {
  value: Date | null;
  inputFormat: string;
  helperText?: string;
  error?: boolean;
  onChangeValue: (v: Date | null) => void;
  onErrorChange?: (e: boolean) => void;
  minDate?: Date;
  disableDate?: (date: Date) => boolean;
};

export const DatePicker: VFC<CustomProps> = ({
  value,
  views = ['year', 'month', 'day'],
  inputFormat,
  helperText = '日付を正しく入力してください',
  error = false,
  onChangeValue,
  onErrorChange,
  minDate,
  disableDate,
}) => {
  const [isNotValid, setIsNotValid] = useState<boolean>(false);
  const checkError = useCallback(() => {
    onErrorChange && onErrorChange(isNotValid);
  }, [onErrorChange, isNotValid]);
  const mask = useMemo(() => {
    switch (inputFormat) {
      case 'yyyy/MM/dd':
        return '____/__/__';
      case 'yyyy/MM':
        return '____/__';
      case 'yyyy':
        return '____';
      default:
        return '';
    }
  }, [inputFormat]);
  const placeholder = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    switch (inputFormat) {
      case 'yyyy/MM/dd':
      default:
        return `${year}/04/01`;
      case 'yyyy/MM':
        return `${year}/04`;
      case 'yyyy':
        return `${year}`;
    }
  }, [inputFormat]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiDatePicker
        mask={mask}
        minDate={minDate}
        shouldDisableDate={disableDate}
        views={views}
        value={value}
        inputFormat={inputFormat}
        onChange={(newValue: Date | null) => {
          if (!newValue) {
            return;
          }
          const date = new Date(newValue.toDateString());
          onChangeValue(date);
          if (date && error) {
            const isNotValid = isNaN(date.getDate());
            setIsNotValid(isNotValid);
            onErrorChange && onErrorChange(isNotValid);
          }
        }}
        onError={reason => setIsNotValid(!!reason)}
        openTo="year"
        renderInput={({inputProps, ...params}) => (
          <TextField
            {...params}
            size="small"
            error={error}
            onBlur={checkError}
            helperText={error && helperText}
            inputProps={{
              ...inputProps,
              placeholder: placeholder,
            }}
            sx={{
              '& .MuiIconButton-root': {
                marginRight: 0,
              },
            }}
          />
        )}
        PaperProps={{
          sx: {
            '& .Mui-selected:focus': {
              backgroundColor: 'flikPrimary.main',
            },
            '& .Mui-selected:hover': {
              backgroundColor: 'flikPrimary.main',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

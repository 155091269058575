import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const UploadIcon = ({
  strokeColor,
  ...props
}: SvgIconProps & {
  strokeColor?: string;
}) => {
  const color = strokeColor ? strokeColor : '#181C32';
  return (
    <SvgIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.6667 3V6.33333C11.6667 6.55435 11.7545 6.76631 11.9107 6.92259C12.067 7.07887 12.279 7.16667 12.5 7.16667H15.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 18H5.83332C5.3913 18 4.96737 17.8244 4.65481 17.5118C4.34225 17.1993 4.16666 16.7754 4.16666 16.3333V4.66667C4.16666 4.22464 4.34225 3.80072 4.65481 3.48816C4.96737 3.17559 5.3913 3 5.83332 3H11.6667L15.8333 7.16667V16.3333C15.8333 16.7754 15.6577 17.1993 15.3452 17.5118C15.0326 17.8244 14.6087 18 14.1667 18Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.66699V14.667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 12.167L10 9.66699L12.5 12.167"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

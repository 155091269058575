import {Box, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React from 'react';

type Props = {width?: string};

export const EarlyRepaymentInfoHead = ({width = '250px'}: Props) => {
  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{
        width: '100%',
        backgroundColor: 'flikPrimary.-40',
        border: '1px solid white',
      }}>
      <Box sx={{width: '250px'}} />
      <Box sx={{width: width}}>
        <StyledTypography>繰上返済時期</StyledTypography>
      </Box>
      <Box sx={{width: '230px'}}>
        <StyledTypography>繰上返済金額</StyledTypography>
      </Box>
    </Stack>
  );
};

const StyledTypography = styled(Typography)({
  fontWeight: 500,
  padding: '4px 12px',
  boxSizing: 'border-box',
});

import {Box, Stack, Typography} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import React, {useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {BoxForAuth} from '../components/BoxForAuth';
import {Button} from '../components/Button';
import {LabelTitle} from '../components/LabelTitle';
import {Logo} from '../components/Logo';
import {TextField} from '../components/TextField';

type FormInput = {
  email: string;
};

export const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm<FormInput>({mode: 'onBlur'});
  const [sendError, setSendError] = useState(false);
  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.forgotPassword(data.email)
      .then(_data => {
        navigate('/resetPassword', {state: {email: data.email}});
      })
      .catch(err => {
        Sentry.captureException(err);
        setSendError(true);
      });
  };
  const disabled = useMemo(
    () => !isDirty || !!errors.email?.type,
    [errors.email?.type, isDirty],
  );

  return (
    <BoxForAuth>
      <Logo />
      <Box
        sx={{
          width: '756px',
          borderRadius: '12px',
          bgcolor: 'flikGray.-30',
          padding: '40px',
          mt: '24px',
        }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}>
          <Typography
            textAlign="center"
            sx={{
              fontStyle: 'normal',
              fontSize: '20px',
              fontWeight: '700',
              LineHeight: '1.3',
            }}>
            パスワードを忘れた方
          </Typography>
          <Typography textAlign="center">
            アカウント作成時に登録されたメールアドレスを入力してください。
            <br />
            パスワード再設定ページへのメールをお送りします。
          </Typography>
          {sendError ? (
            <Typography color="warning.main" fontSize="16px" textAlign="center">
              ご入力いただいた情報に該当するユーザーが見つかりませんでした。
              <br />
              もう一度、入力されたメールアドレスをご確認ください。
            </Typography>
          ) : null}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{width: '100%', maxWidth: '600px'}}>
            <LabelTitle>メールアドレス</LabelTitle>
            <TextField
              fullWidth
              placeholder="test@prop-dock.com"
              error={!!errors.email}
              {...register('email', {required: true})}
              helperText={
                errors.email ? 'メールアドレスを入力してください' : ''
              }
            />
          </Stack>
          <Button
            variant="contained"
            disabled={disabled}
            onClick={handleSubmit(onSubmit)}
            sx={{width: '270px !important'}}>
            送信する
          </Button>
        </Stack>
      </Box>
    </BoxForAuth>
  );
};

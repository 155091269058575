import 'react-native-get-random-values';
import {Box, Modal, ModalProps, Stack, styled, Typography} from '@mui/material';
import React, {useCallback, useMemo, useState, VFC} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {Cell} from './Cell';
import {DatePicker} from './DatePicker';
import {IconButton} from './IconButton';
import {LabelTitle} from './LabelTitle';
import {TextField} from './TextField';
import {CustomCostItem, CustomCostItemInput} from '../API';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {DeleteIcon} from '../images/DeleteIcon';
import {PlusIcon} from '../images/PlusIcon';
import {useResident, useUpdateResidentMutation} from '../queries/resident';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

type Props = Omit<ModalProps, 'onClose' | 'children'> & {
  onClose: () => void;
  residentId: string;
};
type CustomMoveOutCostItemsType = {
  key: CustomCostItemInput['key'];
  money: number;
  id: string;
};

export const MoveOutResidentModal: VFC<Props> = ({
  onClose,
  residentId,
  ...props
}) => {
  const navigate = useNavigate();
  const {data: resident} = useResident(residentId);
  const queryClient = useQueryClient();
  const {mutate: updateResident} = useUpdateResidentMutation();
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isDateError, setIsDateError] = useState(false);
  const {
    register,
    formState: {errors},
    getValues,
    handleSubmit,
    reset,
    control,
  } = useForm<{
    customMoveOutCostItems: CustomMoveOutCostItemsType[];
    renovationCost: number;
    keyExchangeCost: number;
  }>({
    mode: 'onBlur',
    defaultValues: {
      customMoveOutCostItems: [],
      renovationCost: 0,
      keyExchangeCost: 0,
    },
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'customMoveOutCostItems',
  });

  const currentCustomMoveOutItems = useMemo(() => {
    if (!resident) return null;
    return resident.customMoveOutCostItems;
  }, [resident]);
  const moveOutYearAndMonth = useMemo(
    () =>
      endDate
        ? {
            year: endDate.getFullYear().toString(),
            month: (endDate.getMonth() + 1).toString(),
          }
        : null,
    [endDate],
  );
  const onAddCustomItem = useCallback(
    () => append({key: '', money: 0, id: uuidv4()}),
    [append],
  );
  const handleClose = useCallback(() => {
    reset();
    endDate && setEndDate(null);
    isDateError && setIsDateError(false);
    onClose();
  }, [endDate, isDateError, onClose, reset]);
  const onUpdate = useCallback(async () => {
    if (!endDate || !moveOutYearAndMonth || isDateError) {
      !endDate && setIsDateError(true);
      return;
    }

    const frequencyAndDateValue = {
      frequency: 'once',
      ...moveOutYearAndMonth,
    };
    const customMoveOutCostItems =
      getValues('customMoveOutCostItems').length > 0
        ? getValues('customMoveOutCostItems').map(item => {
            return {
              key: item.key,
              value: [
                {
                  money: item.money,
                  ...frequencyAndDateValue,
                },
              ],
            };
          })
        : [];
    updateResident(
      {
        input: {
          id: residentId,
          renovationCost: [
            {
              money: getValues('renovationCost'),
              ...frequencyAndDateValue,
            },
          ],
          keyExchangeCost: [
            {
              money: getValues('keyExchangeCost'),
              ...frequencyAndDateValue,
            },
          ],
          endDate: toISOStringWithTimezone(endDate),
          customMoveOutCostItems:
            currentCustomMoveOutItems && currentCustomMoveOutItems.length > 0
              ? currentCustomMoveOutItems.concat(
                  customMoveOutCostItems as CustomCostItem[],
                )
              : customMoveOutCostItems,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          handleClose();
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [
    endDate,
    getValues,
    handleClose,
    isDateError,
    moveOutYearAndMonth,
    navigate,
    queryClient,
    residentId,
    updateResident,
    currentCustomMoveOutItems,
  ]);

  return (
    <Modal onClose={handleClose} {...props}>
      <BaseBox
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'min-content',
          boxShadow: 'none',
          maxHeight: 'calc(100vh - 80px)',
          overflow: 'scroll',
        }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: 20,
            lineHeight: 1.3,
          }}
          align="center">
          退去
        </Typography>
        <Typography sx={{lineHeight: 1.5, m: '24px 0'}} textAlign="center">
          現在の契約者の退去処理を行います。
          <br />
          退去時に発生する費用を入力してください。
        </Typography>

        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle isRequired>退去日</LabelTitle>
          </Cell>
          <StyledCell>
            <DatePicker
              inputFormat={'yyyy/MM/dd'}
              value={endDate}
              error={isDateError}
              onChangeValue={setEndDate}
              onErrorChange={setIsDateError}
            />
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle isRequired>
              内装・
              <br />
              リフォーム費用
            </LabelTitle>
          </Cell>
          <StyledCell>
            <TextField
              {...register('renovationCost', {required: true})}
              type="number"
              helperText={errors.renovationCost && '入力してください'}
              error={!!errors.renovationCost}
              size="small"
            />
            <Typography>円</Typography>
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle isRequired>鍵交換費用</LabelTitle>
          </Cell>
          <StyledCell>
            <TextField
              {...register('keyExchangeCost', {required: true})}
              type="number"
              helperText={errors.keyExchangeCost && '入力してください'}
              error={!!errors.keyExchangeCost}
              size="small"
            />
            <Typography>円</Typography>
          </StyledCell>
        </Stack>
        {fields.map((field, index) => (
          <Stack key={field.id} direction="row" spacing={0}>
            <Cell color="primary-40">
              <TextField
                {...register(`customMoveOutCostItems.${index}.key`, {
                  required: true,
                })}
                size="small"
                placeholder="項目名"
                helperText={
                  errors.customMoveOutCostItems?.[index]?.key &&
                  '入力してください'
                }
                error={!!errors.customMoveOutCostItems?.[index]?.key}
              />
            </Cell>
            <StyledCell sx={{justifyContent: 'space-between'}}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  {...register(`customMoveOutCostItems.${index}.money`, {
                    required: true,
                  })}
                  type="number"
                  helperText={
                    errors.customMoveOutCostItems?.[index]?.money &&
                    '入力してください'
                  }
                  error={!!errors.customMoveOutCostItems?.[index]?.money}
                  size="small"
                />
                <Typography>円</Typography>
              </Stack>
              <IconButton tooltip="項目を削除" onClick={() => remove(index)}>
                <DeleteIcon />
              </IconButton>
            </StyledCell>
          </Stack>
        ))}
        <Box sx={{margin: '16px 0 24px'}}>
          <Button
            size="small"
            startIcon={<PlusIcon strokeColor="#31CDD3" />}
            onClick={onAddCustomItem}
            sx={{margin: '16px 0 24px'}}>
            項目を追加
          </Button>
        </Box>

        <Stack direction="row" spacing={3} justifyContent="end">
          <Button variant="outlined" onClick={handleClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleSubmit(onUpdate)}>
            退去する
          </Button>
        </Stack>
      </BaseBox>
    </Modal>
  );
};

const StyledCell = styled(Cell)({
  width: '320px',
});

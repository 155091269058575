import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const ImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="131"
      height="98"
      viewBox="0 0 131 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M115.1 96.4H16C8.3 96.4 2 90.1 2 82.4V16C2 8.3 8.3 2 16 2H115.1C122.8 2 129.1 8.3 129.1 16V82.4C129.1 90.1 122.8 96.4 115.1 96.4Z"
        stroke="#A1A5B6"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9 35.9001C32.2078 35.9001 35.7 32.4079 35.7 28.1C35.7 23.7922 32.2078 20.3 27.9 20.3C23.5922 20.3 20.1 23.7922 20.1 28.1C20.1 32.4079 23.5922 35.9001 27.9 35.9001Z"
        stroke="#A1A5B6"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.5 75.5L91.6 38.6C89.1 36.1 85 36.1 82.5 38.6L52.8 68.9L40.5 56.6C38 54.1 33.9 54.1 31.4 56.6L2.29999 85.7"
        stroke="#A1A5B6"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

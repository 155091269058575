import {TableCell, TableRow, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {VFC} from 'react';

export type Props = {
  month: string;
  repaymentAmount: number;
  interest: number;
  principal: number;
  remainingPrincipal: number;
};

export const RepaymentResultTableBody: VFC<Props> = ({
  month,
  repaymentAmount,
  interest,
  principal,
  remainingPrincipal,
}) => {
  return (
    <TableRow
      sx={{
        backgroundColor: 'white',
        '&:nth-of-type(even)': {
          backgroundColor: 'flikGray.-30',
        },
      }}>
      <StyledTableCell component="th" scope="row" align="center">
        <StyledTypography>{month}</StyledTypography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <StyledTypography>
          {Math.round(repaymentAmount).toLocaleString()}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <StyledTypography>
          {Math.round(interest).toLocaleString()}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <StyledTypography>
          {Math.round(principal).toLocaleString()}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <StyledTypography>
          {Math.round(remainingPrincipal).toLocaleString()}
        </StyledTypography>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)`
  padding: 12px 16px;
`;
const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

import {Box, Grid, MenuItem, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React, {useCallback, useEffect, VFC} from 'react';
import {FieldError, useFieldArray, useForm} from 'react-hook-form';
import {AddItemButton} from './AddItemButton';
import {DeleteRuleButton} from './DeleteRuleButton';
import {IconButton} from './IconButton';
import {MoneyInfo} from './MoneyInfo';
import {MoneyInfoTableHead} from './MoneyInfoTableHead';
import {Select} from './Select';
import {MAX_INPUT_LENGTH, TextField} from './TextField';
import {MoneyInfoInput} from '../API';
import {Cell} from '../components/Cell';
import {LabelTitle} from '../components/LabelTitle';
import {DeleteIcon} from '../images/DeleteIcon';
import {RoomCostInfoType} from '../screens/AddRoom';
import {frequencyList, monthList, yearsList} from '../utils/selectOptions';

type Props = {
  costInfo: RoomCostInfoType | null;
  onCostInfoChange: (value: RoomCostInfoType) => void;
  onErrorChange: (error: boolean) => void;
  isSubmitted: boolean;
};
export const initialValue: MoneyInfoInput = {
  money: 0,
  frequency: 'everyMonth',
  year: new Date().getFullYear().toString(),
  month: '1',
};
type MoneyInfoError = {
  money?: FieldError | undefined;
  frequency?: FieldError | undefined;
  year?: FieldError | undefined;
  month?: FieldError | undefined;
};

export const RoomCostInfo: VFC<Props> = ({
  costInfo,
  onCostInfoChange,
  onErrorChange,
  isSubmitted,
}) => {
  const formatCosts = useCallback((costs: Array<MoneyInfoInput | null>) => {
    return costs.map(c => {
      return {
        money: c?.money ?? 0,
        frequency: c?.frequency ?? 'everyMonth',
        year: c?.year ?? new Date().getFullYear().toString(),
        month: c?.month ?? '1',
      };
    });
  }, []);
  const {
    register,
    formState: {errors},
    handleSubmit,
    setValue,
    getValues,
    control,
  } = useForm<RoomCostInfoType>({
    mode: 'onBlur',
  });
  const {
    fields: purchaseCostsFields,
    append: purchaseCostsAppend,
    remove: purchaseCostsRemove,
  } = useFieldArray({
    control,
    name: 'purchaseCosts',
  });
  const {
    fields: propertyTaxFields,
    append: propertyTaxAppend,
    remove: propertyTaxRemove,
  } = useFieldArray({
    control,
    name: 'propertyTax',
  });
  const {
    fields: realEstateAcquisitionTaxFields,
    append: realEstateAcquisitionTaxAppend,
    remove: realEstateAcquisitionTaxRemove,
  } = useFieldArray({
    control,
    name: 'realEstateAcquisitionTax',
  });
  const {
    fields: insuranceFeeFields,
    append: insuranceFeeAppend,
    remove: insuranceFeeRemove,
  } = useFieldArray({
    control,
    name: 'insuranceFee',
  });
  const {
    fields: repairReserveFundFields,
    append: repairReserveFundAppend,
    remove: repairReserveFundRemove,
  } = useFieldArray({
    control,
    name: 'repairReserveFund',
  });
  const {
    fields: managementFeeFields,
    append: managementFeeAppend,
    remove: managementFeeRemove,
  } = useFieldArray({
    control,
    name: 'managementFee',
  });
  const {
    fields: managementCommissionFields,
    append: managementCommissionAppend,
    remove: managementCommissionRemove,
  } = useFieldArray({
    control,
    name: 'managementCommission',
  });
  const {
    fields: customCostItemsFields,
    append: customCostItemsAppend,
    remove: customCostItemsRemove,
    update: customCostItemsUpdate,
  } = useFieldArray({
    control,
    name: 'customCostItems',
  });

  useEffect(() => {
    setValue(
      'purchaseCosts',
      costInfo && costInfo.purchaseCosts
        ? formatCosts(costInfo.purchaseCosts)
        : [{...initialValue}],
    );
    setValue(
      'propertyTax',
      costInfo && costInfo.propertyTax
        ? formatCosts(costInfo.propertyTax)
        : [{...initialValue}],
    );
    setValue(
      'realEstateAcquisitionTax',
      costInfo && costInfo.realEstateAcquisitionTax
        ? formatCosts(costInfo.realEstateAcquisitionTax)
        : [{...initialValue}],
    );
    setValue(
      'insuranceFee',
      costInfo && costInfo.insuranceFee
        ? formatCosts(costInfo.insuranceFee)
        : [{...initialValue}],
    );
    setValue(
      'repairReserveFund',
      costInfo && costInfo.repairReserveFund
        ? formatCosts(costInfo.repairReserveFund)
        : [{...initialValue}],
    );
    setValue(
      'managementFee',
      costInfo && costInfo.managementFee
        ? formatCosts(costInfo.managementFee)
        : [{...initialValue}],
    );
    setValue(
      'managementCommission',
      costInfo && costInfo.managementCommission
        ? formatCosts(costInfo.managementCommission)
        : [{...initialValue}],
    );
    costInfo &&
      costInfo.customCostItems &&
      setValue('customCostItems', [...costInfo.customCostItems]);
  }, [costInfo, formatCosts, setValue]);
  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(
        () => {
          onErrorChange(false);
          onCostInfoChange({
            purchaseCosts: getValues('purchaseCosts'),
            propertyTax: getValues('propertyTax'),
            insuranceFee: getValues('insuranceFee'),
            realEstateAcquisitionTax: getValues('realEstateAcquisitionTax'),
            repairReserveFund: getValues('repairReserveFund'),
            managementFee: getValues('managementFee'),
            managementCommission: getValues('managementCommission'),
            customCostItems: getValues('customCostItems'),
          });
        },
        () => onErrorChange(true),
      )();
    }
  }, [getValues, handleSubmit, isSubmitted, onCostInfoChange, onErrorChange]);

  return (
    <>
      <Typography sx={{fontWeight: 500, mb: '8px'}}>費用</Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <MoneyInfoTableHead />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>購入諸費用</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {purchaseCostsFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="purchaseCosts"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.purchaseCosts)
                        ? errors.purchaseCosts
                        : undefined
                    }
                    onRemove={purchaseCostsRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() => purchaseCostsAppend({...initialValue})}>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>固定資産税・都市計画税</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {propertyTaxFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="propertyTax"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.propertyTax)
                        ? errors.propertyTax
                        : undefined
                    }
                    onRemove={propertyTaxRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() => propertyTaxAppend({...initialValue})}>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>不動産取得税</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {realEstateAcquisitionTaxFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="realEstateAcquisitionTax"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.realEstateAcquisitionTax)
                        ? errors.realEstateAcquisitionTax
                        : undefined
                    }
                    onRemove={realEstateAcquisitionTaxRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() =>
                    realEstateAcquisitionTaxAppend({...initialValue})
                  }>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>地震火災保険料</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {insuranceFeeFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="insuranceFee"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.insuranceFee)
                        ? errors.insuranceFee
                        : undefined
                    }
                    onRemove={insuranceFeeRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() => insuranceFeeAppend({...initialValue})}>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>修繕積立金</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {repairReserveFundFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="repairReserveFund"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.repairReserveFund)
                        ? errors.repairReserveFund
                        : undefined
                    }
                    onRemove={repairReserveFundRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() => repairReserveFundAppend({...initialValue})}>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>管理費</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {managementFeeFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="managementFee"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.managementFee)
                        ? errors.managementFee
                        : undefined
                    }
                    onRemove={managementFeeRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() => managementFeeAppend({...initialValue})}>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>管理委託手数料</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack spacing={1} mb="8px">
                {managementCommissionFields.map((field, index) => (
                  <MoneyInfo
                    key={field.id}
                    register={register}
                    name="managementCommission"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.managementCommission)
                        ? errors.managementCommission
                        : undefined
                    }
                    onRemove={managementCommissionRemove}
                  />
                ))}
                <AddItemButton
                  onAdd={() => managementCommissionAppend({...initialValue})}>
                  繰り返しルールを追加
                </AddItemButton>
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        {customCostItemsFields.map((field, index) => (
          <Grid item xs={12} key={index}>
            <Stack key={field.id} direction="row" spacing={0}>
              <Cell color="primary-40">
                <TextField
                  {...register(`customCostItems.${index}.key`, {
                    required: true,
                    maxLength: MAX_INPUT_LENGTH,
                  })}
                  size="small"
                  placeholder="項目名"
                  helperText={
                    Array.isArray(errors.customCostItems) &&
                    !!errors.customCostItems[index] &&
                    Object.keys(errors.customCostItems[index]).some(
                      key => key === 'key',
                    ) &&
                    '入力してください'
                  }
                  error={
                    Array.isArray(errors.customCostItems) &&
                    !!errors.customCostItems[index] &&
                    Object.keys(errors.customCostItems[index]).some(
                      key => key === 'key',
                    )
                  }
                />
              </Cell>
              <StyledCell sx={{justifyContent: 'space-between'}}>
                <Stack spacing={1} mb="8px">
                  {field.value &&
                    field.value.map((value, i) => (
                      <Stack
                        key={`${field.id}-${i}`}
                        direction="row"
                        spacing={3}
                        alignItems="center">
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}>
                          <TextField
                            {...register(
                              `customCostItems.${index}.value.${i}.money`,
                              {
                                required: true,
                              },
                            )}
                            type="number"
                            sx={{width: '180px'}}
                            size="small"
                            // TODO: https://geekbox.atlassian.net/browse/FLIK-116
                            helperText={
                              Array.isArray(errors.customCostItems) &&
                              !!errors.customCostItems[index] &&
                              Object.keys(errors.customCostItems[index]).some(
                                key => key === 'value',
                              ) &&
                              !!(
                                errors.customCostItems[index] as {
                                  value: MoneyInfoError[];
                                }
                              ).value[i] &&
                              '入力してください'
                            }
                            error={
                              Array.isArray(errors.customCostItems) &&
                              !!errors.customCostItems[index] &&
                              Object.keys(errors.customCostItems[index]).some(
                                key => key === 'value',
                              ) &&
                              !!(
                                errors.customCostItems[index] as {
                                  value: MoneyInfoError[];
                                }
                              ).value[i]
                            }
                          />
                          <Typography>円</Typography>
                        </Stack>
                        <Select
                          {...register(
                            `customCostItems.${index}.value.${i}.frequency`,
                          )}
                          defaultValue={value?.frequency}
                          sx={{width: '130px'}}>
                          {frequencyList.map((s, index) => (
                            <MenuItem value={s.value} key={index}>
                              {s.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}>
                            <Select
                              {...register(
                                `customCostItems.${index}.value.${i}.year`,
                              )}
                              defaultValue={value?.year}
                              sx={{width: '100px'}}>
                              {yearsList.map((s, index) => (
                                <MenuItem value={s.value} key={index}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography>年</Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}>
                            <Select
                              {...register(
                                `customCostItems.${index}.value.${i}.month`,
                              )}
                              defaultValue={value?.month}
                              sx={{width: '100px'}}>
                              {monthList.map((s, index) => (
                                <MenuItem value={s.value} key={index}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography>月</Typography>
                          </Stack>
                        </Stack>
                        {i !== 0 && (
                          <DeleteRuleButton
                            onClick={() => {
                              const customCostItems =
                                getValues('customCostItems') ?? [];
                              customCostItems[index]?.value?.splice(i, 1);
                              customCostItemsUpdate(
                                index,
                                customCostItems[index],
                              );
                            }}
                          />
                        )}
                      </Stack>
                    ))}
                  <AddItemButton
                    onAdd={() => {
                      const customCostItems =
                        getValues('customCostItems') ?? [];
                      customCostItems[index]?.value?.push({...initialValue});
                      customCostItemsUpdate(index, customCostItems[index]);
                    }}>
                    繰り返しルールを追加
                  </AddItemButton>
                </Stack>
                <IconButton
                  tooltip="削除"
                  onClick={() => customCostItemsRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </StyledCell>
            </Stack>
          </Grid>
        ))}
        <Grid item>
          <Box sx={{margin: '16px 0 24px'}}>
            <AddItemButton
              onAdd={() =>
                customCostItemsAppend({key: '', value: [{...initialValue}]})
              }>
              費用を追加
            </AddItemButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const StyledCell = styled(Cell)({
  width: '100%',
});

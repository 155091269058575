import 'react-native-get-random-values';
import {CircularProgress, Grid, Stack, Typography} from '@mui/material';
import React, {useState, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {CreateRoomInput} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {RoomBasicInfo} from '../components/RoomBasicInfo';
import {RoomCostInfo} from '../components/RoomCostInfo';
import {RoomEarlyRepaymentInfo} from '../components/RoomEarlyRepaymentInfo';
import {Snackbar} from '../components/Snackbar';
import {useCreateRoomMutation} from '../queries/room';

export type RoomBasicInfoType = Pick<
  CreateRoomInput,
  | 'name'
  | 'layout'
  | 'condition'
  | 'deliveryDate'
  | 'salesCompany'
  | 'sellingPrice'
  | 'occupiedArea'
  | 'totalArea'
  | 'bankName'
  | 'loanAmount'
  | 'loanYears'
  | 'interestRate'
  | 'repaymentMethod'
  | 'repaymentDate'
  | 'customItems'
>;
export type RoomCostInfoType = Pick<
  CreateRoomInput,
  | 'purchaseCosts'
  | 'propertyTax'
  | 'realEstateAcquisitionTax'
  | 'insuranceFee'
  | 'repairReserveFund'
  | 'managementFee'
  | 'customCostItems'
  | 'managementCommission'
>;
export type RoomEarlyRepaymentInfoType = Pick<
  CreateRoomInput,
  'earlyRepaymentInfo'
>;

export const AddRoomScreen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {propertyId} = useParams();
  const {mutate: createRoom, isLoading} = useCreateRoomMutation();
  const [isBasicInfoError, setIsBasicInfoError] = useState(false);
  const [isCostInfoError, setIsCostInfoError] = useState(false);
  const [isEarlyRepaymentInfoError, setIsEarlyRepaymentInfoError] =
    useState(false);
  const [basicInfo, setBasicInfo] = useState<RoomBasicInfoType | null>(null);
  const [costInfo, setCostInfo] = useState<RoomCostInfoType | null>(null);
  const [earlyRepaymentInfo, setEarlyRepaymentInfo] =
    useState<RoomEarlyRepaymentInfoType | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSaveButtonDown, setIsSaveButtonDown] = useState(false);
  const user = useOAuthUser();

  const {handleSubmit} = useForm();
  const onRegister = useCallback(async () => {
    setIsSaveButtonDown(false);
    if (!user) {
      navigate('/error');
      return;
    }
    if (
      isBasicInfoError ||
      isCostInfoError ||
      isEarlyRepaymentInfoError ||
      !basicInfo ||
      !costInfo ||
      !earlyRepaymentInfo
    ) {
      setOpenSnackbar(true);
      return;
    }
    const roomId = uuidv4();
    createRoom(
      {
        input: {
          id: roomId,
          ownerId: user.attributes.sub,
          name: basicInfo.name,
          layout: basicInfo.layout,
          condition: basicInfo.condition,
          deliveryDate: basicInfo.deliveryDate,
          salesCompany: basicInfo.salesCompany,
          sellingPrice: basicInfo.sellingPrice,
          occupiedArea: basicInfo.occupiedArea,
          totalArea: basicInfo.totalArea,
          bankName: basicInfo.bankName,
          loanYears: basicInfo.loanYears,
          loanAmount: basicInfo.loanAmount,
          interestRate: basicInfo.interestRate ? basicInfo.interestRate : null,
          repaymentMethod: basicInfo.repaymentMethod,
          repaymentDate: basicInfo.repaymentDate || null,
          customItems: basicInfo.customItems,
          purchaseCosts: costInfo.purchaseCosts,
          propertyTax: costInfo.propertyTax,
          realEstateAcquisitionTax: costInfo.realEstateAcquisitionTax,
          insuranceFee: costInfo.insuranceFee,
          repairReserveFund: costInfo.repairReserveFund,
          managementFee: costInfo.managementFee,
          managementCommission: costInfo.managementCommission,
          customCostItems: costInfo.customCostItems,
          earlyRepaymentInfo: earlyRepaymentInfo.earlyRepaymentInfo,
          propertyRoomsId: propertyId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          navigate(`/property/${propertyId}/${roomId}`, {
            state: {operationState: 'add-success'},
          });
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [
    basicInfo,
    costInfo,
    createRoom,
    earlyRepaymentInfo,
    isBasicInfoError,
    isCostInfoError,
    isEarlyRepaymentInfoError,
    navigate,
    propertyId,
    queryClient,
    user,
  ]);

  return (
    <>
      <Typography
        sx={{fontSize: '24px', margin: '0 0 24px 0', fontWeight: '700'}}>
        部屋情報の追加
      </Typography>
      <BaseBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RoomBasicInfo
              onBasicInfoChange={setBasicInfo}
              onErrorChange={setIsBasicInfoError}
              isSubmitted={isSaveButtonDown}
            />
          </Grid>
          <Grid item xs={12}>
            <RoomCostInfo
              costInfo={costInfo}
              onCostInfoChange={setCostInfo}
              onErrorChange={setIsCostInfoError}
              isSubmitted={isSaveButtonDown}
            />
          </Grid>
          <Grid item xs={12}>
            <RoomEarlyRepaymentInfo
              onEarlyRepaymentInfoChange={setEarlyRepaymentInfo}
              onErrorChange={setIsEarlyRepaymentInfoError}
              isSubmitted={isSaveButtonDown}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button variant="outlined" onClick={() => navigate(-1)}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        width: '20px  !important',
                        height: '20px  !important',
                      }}
                    />
                  ) : null
                }
                onMouseDown={() => setIsSaveButtonDown(true)}
                onClick={handleSubmit(onRegister)}>
                {isLoading ? '保存中' : '保存する'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </BaseBox>
      <Snackbar
        severity="error"
        message="入力内容を確認してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreatePropertyMutation,
  CreatePropertyMutationVariables,
  DeletePropertyMutation,
  DeletePropertyMutationVariables,
  GetPropertyQuery,
  ListPropertiesByUserPropertiesIdQuery,
  ListPropertiesByUserPropertiesIdQueryVariables,
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables,
} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {
  createProperty,
  deleteProperty,
  updateProperty,
} from '../graphql/mutations';
import {
  getProperty,
  listPropertiesByUserPropertiesId,
} from '../graphql/queries';

export const useProperties = () => {
  const user = useOAuthUser();
  return useQuery(['properties', user?.attributes.sub], async () => {
    if (!user) {
      return undefined;
    }
    const variables: ListPropertiesByUserPropertiesIdQueryVariables = {
      userPropertiesId: user.attributes.sub,
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listPropertiesByUserPropertiesId, variables),
    )) as GraphQLResult<ListPropertiesByUserPropertiesIdQuery>;
    return result?.data?.listPropertiesByUserPropertiesId;
  });
};

export const useProperty = (propertyId?: string) => {
  return useQuery(
    ['property', propertyId],
    async () => {
      if (!propertyId) return;
      const result = (await API.graphql(
        graphqlOperation(getProperty, {id: propertyId}),
      )) as GraphQLResult<GetPropertyQuery>;
      return result.data?.getProperty;
    },
    {
      enabled: !!propertyId,
    },
  );
};

export const useCreatePropertyMutation = () => {
  return useMutation(async (variables: CreatePropertyMutationVariables) => {
    const property = (await API.graphql(
      graphqlOperation(createProperty, variables),
    )) as GraphQLResult<CreatePropertyMutation>;
    return property.data?.createProperty;
  });
};

export const useUpdatePropertyMutation = () => {
  return useMutation(async (variables: UpdatePropertyMutationVariables) => {
    const property = (await API.graphql(
      graphqlOperation(updateProperty, variables),
    )) as GraphQLResult<UpdatePropertyMutation>;
    return property.data?.updateProperty;
  });
};

export const useDeletePropertyMutation = () => {
  return useMutation(async (variables: DeletePropertyMutationVariables) => {
    const property = (await API.graphql(
      graphqlOperation(deleteProperty, variables),
    )) as GraphQLResult<DeletePropertyMutation>;
    return property.data?.deleteProperty;
  });
};

import {GraphQLResult} from '@aws-amplify/api-graphql';
import * as Sentry from '@sentry/react';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {CreatePdfInput} from './../API';
import {CreatePdfQuery} from '../API';
import {createPdf} from '../graphql/queries';
import {downloadPdf} from '../utils/downloadPdf';

export const useDownloadPdf = () => {
  const navigate = useNavigate();
  return useMutation(async (data: Omit<CreatePdfInput, 'identityId'>) => {
    const currentCredentials = await Auth.currentCredentials();
    const identityId = currentCredentials.identityId;
    const input = {
      identityId: identityId,
      ...data,
    };
    const result = (await API.graphql(
      graphqlOperation(createPdf, {input}),
    )) as GraphQLResult<CreatePdfQuery>;
    const body = result?.data?.createPdf?.body;
    if (result?.data?.createPdf?.statusCode !== 200 || !body) {
      Sentry.captureException(body);
      return navigate('/error');
    }
    await downloadPdf(body, encodeURIComponent(input.fileName));
  });
};

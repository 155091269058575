import {Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '../components/Button';
import {OutlinedCheckCircleIcon} from '../images/OutlinedCheckCircleIcon';

export const PaymentSuccessScreen = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}>
      <OutlinedCheckCircleIcon width={100} height={100} />
      <Typography fontSize={'20px'} fontWeight={600}>
        プランの変更が完了しました
      </Typography>
      <Typography fontSize={'16px'} textAlign="center">
        ご登録いただいているメールアドレスへ
        <br />
        プラン変更完了の案内メールを送信いたしましたので、
        <br />
        ご確認をお願いいたします。
      </Typography>
      <Button variant="contained" onClick={() => navigate('/')}>
        トップページ
      </Button>
    </Stack>
  );
};

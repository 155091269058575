import {Stack, Typography, styled} from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts';
import {BaseBox} from '../components/BaseBox';

type UnitType = 'amount' | 'percentage';

export type CalculationResult = {
  loanAmount: number;
  totalRepaymentAmount: number;
  totalRepaymentAmountIncludeEarlyRepayment: number;
  totalInterest: number;
  totalInterestIncludeEarlyRepayment: number;
  percentageInterest: number;
  percentageInterestIncludeEarlyRepayment: number;
  averageRepaymentAmount: number;
  averageRepaymentAmountIncludeEarlyRepayment: number;
  totalEarlyRepaymentAmount: number;
};

export type BarChartProps = {
  title: string;
  unit?: UnitType;
  beforePrepayment: number;
  afterPrepayment: number;
  totalEarlyRepaymentAmount: number;
};

export type Props = {
  unit?: UnitType;
  beforePrepayment: number;
  afterPrepayment: number;
};

export type CustomLabelProps = {
  width?: number;
  labelTop: string;
  labelBottom: string | null;
};

const CustomBarChart = ({
  title,
  unit = 'amount',
  beforePrepayment,
  afterPrepayment,
  totalEarlyRepaymentAmount,
}: BarChartProps) => {
  const calucDifference = ({
    unit,
    beforePrepayment,
    afterPrepayment,
  }: Props) => {
    const digit = 1000;
    return unit === 'amount'
      ? Math.round(Math.abs(beforePrepayment - afterPrepayment))
      : Math.round(Math.abs(beforePrepayment - afterPrepayment) * digit) /
          digit;
  };
  const difference = calucDifference({
    unit,
    beforePrepayment,
    afterPrepayment,
  });
  const data = [
    {
      beforePrepayment: beforePrepayment,
      afterPrepayment: afterPrepayment,
      labelBforePrepayment:
        unit === 'amount'
          ? `${beforePrepayment.toLocaleString()}円`
          : `${beforePrepayment}％`,
      labelAfterPrepayment:
        unit === 'amount'
          ? `${afterPrepayment.toLocaleString()}円`
          : `${afterPrepayment}％`,
    },
  ];
  const CustomLabelList = ({
    width = 0,
    labelTop,
    labelBottom,
  }: CustomLabelProps) => (
    <g>
      <Typography
        x={36 + width / 2}
        y={90}
        dy={-10}
        textAnchor="start"
        fill="#36D4F7"
        component="text"
        fontSize="13px">
        {labelTop}
      </Typography>
      <Typography
        x={36 + width / 2}
        y={90}
        dy={5}
        textAnchor="start"
        fill="#767c95"
        component="text"
        fontSize="13px">
        {labelBottom}
      </Typography>
    </g>
  );
  return (
    <>
      <HeadLine>{title}</HeadLine>
      <ResponsiveContainer height={'100%'} width={'100%'}>
        <BarChart
          width={500}
          height={300}
          layout="vertical"
          data={data}
          margin={{
            right: 130,
            bottom: 5,
          }}
          key={Math.random()}>
          <XAxis dataKey="beforePrepayment" type="number" hide />
          <YAxis type="category" hide />
          <Bar dataKey="beforePrepayment" fill="#FFA714" barSize={36}>
            <LabelList
              dataKey="labelBforePrepayment"
              position="right"
              fill="#FFA714"
              fontSize="13px"
            />
          </Bar>
          {totalEarlyRepaymentAmount > 0 ? (
            <Bar dataKey="afterPrepayment" fill="#36D4F7" barSize={36}>
              <LabelList
                dataKey="labelAfterPrepayment"
                position="right"
                fill="#36D4F7"
                fontSize="13px"
                content={
                  <CustomLabelList
                    labelTop={
                      unit === 'amount'
                        ? `${afterPrepayment.toLocaleString()}円`
                        : `${afterPrepayment}％`
                    }
                    labelBottom={
                      difference !== 0
                        ? unit === 'amount'
                          ? `（${difference.toLocaleString()}）`
                          : `（${difference}）`
                        : null
                    }
                  />
                }
              />
            </Bar>
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export const RepaymentBarChart = ({
  loanAmount,
  totalRepaymentAmount,
  totalRepaymentAmountIncludeEarlyRepayment,
  totalInterest,
  totalInterestIncludeEarlyRepayment,
  percentageInterest,
  percentageInterestIncludeEarlyRepayment,
  averageRepaymentAmount,
  averageRepaymentAmountIncludeEarlyRepayment,
  totalEarlyRepaymentAmount,
}: CalculationResult) => {
  return (
    <>
      <Stack direction="row" justifyContent="center" spacing={4} mb="24px">
        <LegendTitle color={'#FFA714'}>繰り上げ返済前</LegendTitle>
        <LegendTitle color={'#36D4F7'}>繰り上げ返済後</LegendTitle>
      </Stack>
      <Stack direction="column" spacing={2.5}>
        <Stack direction="row" spacing={2.5}>
          <StyledBox>
            <CustomBarChart
              title="借入額合計"
              beforePrepayment={loanAmount}
              afterPrepayment={loanAmount}
              totalEarlyRepaymentAmount={totalEarlyRepaymentAmount}
            />
          </StyledBox>
          <StyledBox>
            <CustomBarChart
              title="返済額合計"
              beforePrepayment={totalRepaymentAmount}
              afterPrepayment={totalRepaymentAmountIncludeEarlyRepayment}
              totalEarlyRepaymentAmount={totalEarlyRepaymentAmount}
            />
          </StyledBox>
        </Stack>
        <Stack direction="row" spacing={2.5}>
          <StyledBox>
            <CustomBarChart
              title="利息合計"
              beforePrepayment={totalInterest}
              afterPrepayment={totalInterestIncludeEarlyRepayment}
              totalEarlyRepaymentAmount={totalEarlyRepaymentAmount}
            />
          </StyledBox>
          <StyledBox>
            <CustomBarChart
              title="利息割合"
              unit="percentage"
              beforePrepayment={percentageInterest}
              afterPrepayment={percentageInterestIncludeEarlyRepayment}
              totalEarlyRepaymentAmount={totalEarlyRepaymentAmount}
            />
          </StyledBox>
        </Stack>
        <Stack direction="row" spacing={2.5}>
          <StyledBox maxWidth="236px">
            <CustomBarChart
              title="月々返済分（平均）"
              beforePrepayment={averageRepaymentAmount}
              afterPrepayment={averageRepaymentAmountIncludeEarlyRepayment}
              totalEarlyRepaymentAmount={totalEarlyRepaymentAmount}
            />
          </StyledBox>
        </Stack>
      </Stack>
    </>
  );
};

const LegendTitle = styled(Typography)(({color}) => ({
  '&::before': {
    content: '"■"',
    width: '16px',
    height: '16px',
    marginRight: '8px',
    color: color,
  },
  '&.MuiTypography-root': {
    color: '#181C32',
    fontSize: '14px',
  },
})) as typeof Typography;

const HeadLine = styled(Typography)(() => ({
  fontWeight: 600,
  lineHeight: 1.3,
  fontSize: '14px',
  textAlign: 'center',
}));

const StyledBox = styled(BaseBox)({
  width: '100%',
  minWidth: '236px',
  height: '156px',
  padding: '20px',
  boxShadow: 'none',
  border: '1px solid #DADCE3',
});

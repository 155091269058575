import {MenuItem, Stack, Typography} from '@mui/material';
import React, {VFC} from 'react';
import {
  FieldError,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form';
import {DeleteRuleButton} from './DeleteRuleButton';
import {Select} from './Select';
import {TextField} from './TextField';
import {MoneyInfoInput} from '../API';
import {frequencyList} from '../utils/selectOptions';
import {monthList, yearsList} from '../utils/selectOptions';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  index: number;
  name: string;
  defaultValue: MoneyInfoInput;
  errors:
    | (
        | FieldError
        | {
            money?: FieldError | undefined;
            frequency?: FieldError | undefined;
            year?: FieldError | undefined;
            month?: FieldError | undefined;
          }
      )[]
    | undefined;
  onRemove: UseFieldArrayRemove;
};

export const MoneyInfo: VFC<Props> = ({
  register,
  index,
  name,
  errors,
  onRemove,
  defaultValue,
}) => {
  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <TextField
          {...register(`${name}.${index}.money`, {
            required: true,
          })}
          type="number"
          sx={{width: '180px'}}
          size="small"
          // TODO: https://geekbox.atlassian.net/browse/FLIK-116
          helperText={errors && !!errors[index] && '入力してください'}
          error={errors && !!errors[index]}
        />
        <Typography>円</Typography>
      </Stack>
      {/* TODO: https://geekbox.atlassian.net/browse/FLIK-121 */}
      <Select
        {...register(`${name}.${index}.frequency`)}
        defaultValue={defaultValue.frequency}
        sx={{width: '130px'}}>
        {frequencyList.map((s, index) => (
          <MenuItem value={s.value} key={index}>
            {s.name}
          </MenuItem>
        ))}
      </Select>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Select
            {...register(`${name}.${index}.year`)}
            defaultValue={defaultValue.year}
            sx={{width: '100px'}}>
            {yearsList.map((s, index) => (
              <MenuItem value={s.value} key={index}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <Typography>年</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Select
            {...register(`${name}.${index}.month`)}
            defaultValue={defaultValue.month}
            sx={{width: '100px'}}>
            {monthList.map((s, index) => (
              <MenuItem value={s.value} key={index}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <Typography>月</Typography>
        </Stack>
      </Stack>
      {index !== 0 && <DeleteRuleButton onClick={() => onRemove(index)} />}
    </Stack>
  );
};

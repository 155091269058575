/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      disabled
      plan
      subscriptionId
      subscriptionStartDate
      subscriptionEndDate
      subscriptionType
      files {
        items {
          id
          name
          category
          propertyName
          roomName
          userFilesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      expenses {
        items {
          id
          issueDate
          price
          category
          note
          company
          userExpensesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      properties {
        items {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          orderType
          trackingId
          user {
            id
            firstName
            lastName
            email
            disabled
            plan
            subscriptionId
            subscriptionStartDate
            subscriptionEndDate
            subscriptionType
            files {
              nextToken
            }
            expenses {
              nextToken
            }
            properties {
              nextToken
            }
            orders {
              nextToken
            }
            fcmTokens {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          userOrdersId
          owner
        }
        nextToken
      }
      fcmTokens {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        disabled
        plan
        subscriptionId
        subscriptionStartDate
        subscriptionEndDate
        subscriptionType
        files {
          items {
            id
            name
            category
            propertyName
            roomName
            userFilesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        expenses {
          items {
            id
            issueDate
            price
            category
            note
            company
            userExpensesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        properties {
          items {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            orderType
            trackingId
            user {
              id
              firstName
              lastName
              email
              disabled
              plan
              subscriptionId
              subscriptionStartDate
              subscriptionEndDate
              subscriptionType
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            userOrdersId
            owner
          }
          nextToken
        }
        fcmTokens {
          items {
            id
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPropertyFile = /* GraphQL */ `
  query GetPropertyFile($id: ID!) {
    getPropertyFile(id: $id) {
      id
      name
      category
      propertyName
      roomName
      userFilesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPropertyFiles = /* GraphQL */ `
  query ListPropertyFiles(
    $filter: ModelPropertyFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        propertyName
        roomName
        userFilesId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listFilesByUserFilesId = /* GraphQL */ `
  query ListFilesByUserFilesId(
    $userFilesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilesByUserFilesId(
      userFilesId: $userFilesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        propertyName
        roomName
        userFilesId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExpenses = /* GraphQL */ `
  query GetExpenses($id: ID!) {
    getExpenses(id: $id) {
      id
      issueDate
      price
      category
      note
      company
      userExpensesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        issueDate
        price
        category
        note
        company
        userExpensesId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listExpensesByUserExpensesId = /* GraphQL */ `
  query ListExpensesByUserExpensesId(
    $userExpensesId: ID!
    $issueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpensesByUserExpensesId(
      userExpensesId: $userExpensesId
      issueDate: $issueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        issueDate
        price
        category
        note
        company
        userExpensesId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      name
      thumbnail
      address
      station
      minutes
      structure
      stories
      units
      years
      buildingManagement
      rentalManagement
      customItems {
        key
        value
      }
      rooms {
        items {
          id
          ownerId
          name
          layout
          condition
          deliveryDate
          soldDate
          salesCompany
          sellingPrice
          occupiedArea
          totalArea
          bankName
          loanYears
          loanAmount
          interestRate
          repaymentMethod
          repaymentDate
          customItems {
            key
            value
          }
          purchaseCosts {
            money
            frequency
            year
            month
          }
          propertyTax {
            money
            frequency
            year
            month
          }
          realEstateAcquisitionTax {
            money
            frequency
            year
            month
          }
          insuranceFee {
            money
            frequency
            year
            month
          }
          repairReserveFund {
            money
            frequency
            year
            month
          }
          managementFee {
            money
            frequency
            year
            month
          }
          managementCommission {
            money
            frequency
            year
            month
          }
          customCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          residents {
            items {
              id
              ownerId
              name
              startDate
              endDate
              createdAt
              updatedAt
              roomResidentsId
              owner
            }
            nextToken
          }
          property {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          earlyRepaymentInfo {
            year
            month
            repaymentAmount
          }
          createdAt
          updatedAt
          propertyRoomsId
          owner
        }
        nextToken
      }
      userPropertiesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        thumbnail
        address
        station
        minutes
        structure
        stories
        units
        years
        buildingManagement
        rentalManagement
        customItems {
          key
          value
        }
        rooms {
          items {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          nextToken
        }
        userPropertiesId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listPropertiesByUserPropertiesId = /* GraphQL */ `
  query ListPropertiesByUserPropertiesId(
    $userPropertiesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertiesByUserPropertiesId(
      userPropertiesId: $userPropertiesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        thumbnail
        address
        station
        minutes
        structure
        stories
        units
        years
        buildingManagement
        rentalManagement
        customItems {
          key
          value
        }
        rooms {
          items {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          nextToken
        }
        userPropertiesId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      ownerId
      name
      layout
      condition
      deliveryDate
      soldDate
      salesCompany
      sellingPrice
      occupiedArea
      totalArea
      bankName
      loanYears
      loanAmount
      interestRate
      repaymentMethod
      repaymentDate
      customItems {
        key
        value
      }
      purchaseCosts {
        money
        frequency
        year
        month
      }
      propertyTax {
        money
        frequency
        year
        month
      }
      realEstateAcquisitionTax {
        money
        frequency
        year
        month
      }
      insuranceFee {
        money
        frequency
        year
        month
      }
      repairReserveFund {
        money
        frequency
        year
        month
      }
      managementFee {
        money
        frequency
        year
        month
      }
      managementCommission {
        money
        frequency
        year
        month
      }
      customCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      residents {
        items {
          id
          ownerId
          name
          startDate
          endDate
          customItems {
            key
            value
          }
          keyMoney {
            money
            frequency
            year
            month
          }
          firstIncome {
            money
            frequency
            year
            month
          }
          rent {
            money
            frequency
            year
            month
          }
          customMoveInCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          renovationCost {
            money
            frequency
            year
            month
          }
          keyExchangeCost {
            money
            frequency
            year
            month
          }
          customMoveOutCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          advertisingRates {
            money
            frequency
            year
            month
          }
          room {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          createdAt
          updatedAt
          roomResidentsId
          owner
        }
        nextToken
      }
      property {
        id
        name
        thumbnail
        address
        station
        minutes
        structure
        stories
        units
        years
        buildingManagement
        rentalManagement
        customItems {
          key
          value
        }
        rooms {
          items {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          nextToken
        }
        userPropertiesId
        createdAt
        updatedAt
        owner
      }
      earlyRepaymentInfo {
        year
        month
        repaymentAmount
      }
      createdAt
      updatedAt
      propertyRoomsId
      owner
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        name
        layout
        condition
        deliveryDate
        soldDate
        salesCompany
        sellingPrice
        occupiedArea
        totalArea
        bankName
        loanYears
        loanAmount
        interestRate
        repaymentMethod
        repaymentDate
        customItems {
          key
          value
        }
        purchaseCosts {
          money
          frequency
          year
          month
        }
        propertyTax {
          money
          frequency
          year
          month
        }
        realEstateAcquisitionTax {
          money
          frequency
          year
          month
        }
        insuranceFee {
          money
          frequency
          year
          month
        }
        repairReserveFund {
          money
          frequency
          year
          month
        }
        managementFee {
          money
          frequency
          year
          month
        }
        managementCommission {
          money
          frequency
          year
          month
        }
        customCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        residents {
          items {
            id
            ownerId
            name
            startDate
            endDate
            customItems {
              key
              value
            }
            keyMoney {
              money
              frequency
              year
              month
            }
            firstIncome {
              money
              frequency
              year
              month
            }
            rent {
              money
              frequency
              year
              month
            }
            customMoveInCostItems {
              key
            }
            renovationCost {
              money
              frequency
              year
              month
            }
            keyExchangeCost {
              money
              frequency
              year
              month
            }
            customMoveOutCostItems {
              key
            }
            advertisingRates {
              money
              frequency
              year
              month
            }
            room {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            createdAt
            updatedAt
            roomResidentsId
            owner
          }
          nextToken
        }
        property {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        earlyRepaymentInfo {
          year
          month
          repaymentAmount
        }
        createdAt
        updatedAt
        propertyRoomsId
        owner
      }
      nextToken
    }
  }
`;
export const listRoomsByOwnerId = /* GraphQL */ `
  query ListRoomsByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomsByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        name
        layout
        condition
        deliveryDate
        soldDate
        salesCompany
        sellingPrice
        occupiedArea
        totalArea
        bankName
        loanYears
        loanAmount
        interestRate
        repaymentMethod
        repaymentDate
        customItems {
          key
          value
        }
        purchaseCosts {
          money
          frequency
          year
          month
        }
        propertyTax {
          money
          frequency
          year
          month
        }
        realEstateAcquisitionTax {
          money
          frequency
          year
          month
        }
        insuranceFee {
          money
          frequency
          year
          month
        }
        repairReserveFund {
          money
          frequency
          year
          month
        }
        managementFee {
          money
          frequency
          year
          month
        }
        managementCommission {
          money
          frequency
          year
          month
        }
        customCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        residents {
          items {
            id
            ownerId
            name
            startDate
            endDate
            customItems {
              key
              value
            }
            keyMoney {
              money
              frequency
              year
              month
            }
            firstIncome {
              money
              frequency
              year
              month
            }
            rent {
              money
              frequency
              year
              month
            }
            customMoveInCostItems {
              key
            }
            renovationCost {
              money
              frequency
              year
              month
            }
            keyExchangeCost {
              money
              frequency
              year
              month
            }
            customMoveOutCostItems {
              key
            }
            advertisingRates {
              money
              frequency
              year
              month
            }
            room {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            createdAt
            updatedAt
            roomResidentsId
            owner
          }
          nextToken
        }
        property {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        earlyRepaymentInfo {
          year
          month
          repaymentAmount
        }
        createdAt
        updatedAt
        propertyRoomsId
        owner
      }
      nextToken
    }
  }
`;
export const getResident = /* GraphQL */ `
  query GetResident($id: ID!) {
    getResident(id: $id) {
      id
      ownerId
      name
      startDate
      endDate
      customItems {
        key
        value
      }
      keyMoney {
        money
        frequency
        year
        month
      }
      firstIncome {
        money
        frequency
        year
        month
      }
      rent {
        money
        frequency
        year
        month
      }
      customMoveInCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      renovationCost {
        money
        frequency
        year
        month
      }
      keyExchangeCost {
        money
        frequency
        year
        month
      }
      customMoveOutCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      advertisingRates {
        money
        frequency
        year
        month
      }
      room {
        id
        ownerId
        name
        layout
        condition
        deliveryDate
        soldDate
        salesCompany
        sellingPrice
        occupiedArea
        totalArea
        bankName
        loanYears
        loanAmount
        interestRate
        repaymentMethod
        repaymentDate
        customItems {
          key
          value
        }
        purchaseCosts {
          money
          frequency
          year
          month
        }
        propertyTax {
          money
          frequency
          year
          month
        }
        realEstateAcquisitionTax {
          money
          frequency
          year
          month
        }
        insuranceFee {
          money
          frequency
          year
          month
        }
        repairReserveFund {
          money
          frequency
          year
          month
        }
        managementFee {
          money
          frequency
          year
          month
        }
        managementCommission {
          money
          frequency
          year
          month
        }
        customCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        residents {
          items {
            id
            ownerId
            name
            startDate
            endDate
            customItems {
              key
              value
            }
            keyMoney {
              money
              frequency
              year
              month
            }
            firstIncome {
              money
              frequency
              year
              month
            }
            rent {
              money
              frequency
              year
              month
            }
            customMoveInCostItems {
              key
            }
            renovationCost {
              money
              frequency
              year
              month
            }
            keyExchangeCost {
              money
              frequency
              year
              month
            }
            customMoveOutCostItems {
              key
            }
            advertisingRates {
              money
              frequency
              year
              month
            }
            room {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            createdAt
            updatedAt
            roomResidentsId
            owner
          }
          nextToken
        }
        property {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        earlyRepaymentInfo {
          year
          month
          repaymentAmount
        }
        createdAt
        updatedAt
        propertyRoomsId
        owner
      }
      createdAt
      updatedAt
      roomResidentsId
      owner
    }
  }
`;
export const listResidents = /* GraphQL */ `
  query ListResidents(
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        name
        startDate
        endDate
        customItems {
          key
          value
        }
        keyMoney {
          money
          frequency
          year
          month
        }
        firstIncome {
          money
          frequency
          year
          month
        }
        rent {
          money
          frequency
          year
          month
        }
        customMoveInCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        renovationCost {
          money
          frequency
          year
          month
        }
        keyExchangeCost {
          money
          frequency
          year
          month
        }
        customMoveOutCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        advertisingRates {
          money
          frequency
          year
          month
        }
        room {
          id
          ownerId
          name
          layout
          condition
          deliveryDate
          soldDate
          salesCompany
          sellingPrice
          occupiedArea
          totalArea
          bankName
          loanYears
          loanAmount
          interestRate
          repaymentMethod
          repaymentDate
          customItems {
            key
            value
          }
          purchaseCosts {
            money
            frequency
            year
            month
          }
          propertyTax {
            money
            frequency
            year
            month
          }
          realEstateAcquisitionTax {
            money
            frequency
            year
            month
          }
          insuranceFee {
            money
            frequency
            year
            month
          }
          repairReserveFund {
            money
            frequency
            year
            month
          }
          managementFee {
            money
            frequency
            year
            month
          }
          managementCommission {
            money
            frequency
            year
            month
          }
          customCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          residents {
            items {
              id
              ownerId
              name
              startDate
              endDate
              createdAt
              updatedAt
              roomResidentsId
              owner
            }
            nextToken
          }
          property {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          earlyRepaymentInfo {
            year
            month
            repaymentAmount
          }
          createdAt
          updatedAt
          propertyRoomsId
          owner
        }
        createdAt
        updatedAt
        roomResidentsId
        owner
      }
      nextToken
    }
  }
`;
export const listResidentsByOwnerId = /* GraphQL */ `
  query ListResidentsByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidentsByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        name
        startDate
        endDate
        customItems {
          key
          value
        }
        keyMoney {
          money
          frequency
          year
          month
        }
        firstIncome {
          money
          frequency
          year
          month
        }
        rent {
          money
          frequency
          year
          month
        }
        customMoveInCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        renovationCost {
          money
          frequency
          year
          month
        }
        keyExchangeCost {
          money
          frequency
          year
          month
        }
        customMoveOutCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        advertisingRates {
          money
          frequency
          year
          month
        }
        room {
          id
          ownerId
          name
          layout
          condition
          deliveryDate
          soldDate
          salesCompany
          sellingPrice
          occupiedArea
          totalArea
          bankName
          loanYears
          loanAmount
          interestRate
          repaymentMethod
          repaymentDate
          customItems {
            key
            value
          }
          purchaseCosts {
            money
            frequency
            year
            month
          }
          propertyTax {
            money
            frequency
            year
            month
          }
          realEstateAcquisitionTax {
            money
            frequency
            year
            month
          }
          insuranceFee {
            money
            frequency
            year
            month
          }
          repairReserveFund {
            money
            frequency
            year
            month
          }
          managementFee {
            money
            frequency
            year
            month
          }
          managementCommission {
            money
            frequency
            year
            month
          }
          customCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          residents {
            items {
              id
              ownerId
              name
              startDate
              endDate
              createdAt
              updatedAt
              roomResidentsId
              owner
            }
            nextToken
          }
          property {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          earlyRepaymentInfo {
            year
            month
            repaymentAmount
          }
          createdAt
          updatedAt
          propertyRoomsId
          owner
        }
        createdAt
        updatedAt
        roomResidentsId
        owner
      }
      nextToken
    }
  }
`;
export const getConsultingSheet = /* GraphQL */ `
  query GetConsultingSheet($id: ID!) {
    getConsultingSheet(id: $id) {
      id
      ownerId
      propertyName
      roomName
      address
      station
      occupiedArea
      stories
      units
      years
      buildingManagement
      rentalManagement
      bankName
      loanAmount
      remainingPrincipal
      currentDate
      interestRate
      principal
      interest
      deliveryDate
      lastRepaymentYear
      remainingRepaymentYear
      totalPrincipalPerYear
      reducedRepayment
      yearOfTermReduction
      condition
      roomIncome
      repayment
      managementFee
      repairReserveFund
      otherRoomCost
      managementCommission
      totalIncome
      totalCost
      totalIncomeAndExpenses
      createdAt
      updatedAt
    }
  }
`;
export const listConsultingSheets = /* GraphQL */ `
  query ListConsultingSheets(
    $filter: ModelConsultingSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultingSheets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        propertyName
        roomName
        address
        station
        occupiedArea
        stories
        units
        years
        buildingManagement
        rentalManagement
        bankName
        loanAmount
        remainingPrincipal
        currentDate
        interestRate
        principal
        interest
        deliveryDate
        lastRepaymentYear
        remainingRepaymentYear
        totalPrincipalPerYear
        reducedRepayment
        yearOfTermReduction
        condition
        roomIncome
        repayment
        managementFee
        repairReserveFund
        otherRoomCost
        managementCommission
        totalIncome
        totalCost
        totalIncomeAndExpenses
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listConsultingSheetsByOwnerId = /* GraphQL */ `
  query ListConsultingSheetsByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConsultingSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultingSheetsByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        propertyName
        roomName
        address
        station
        occupiedArea
        stories
        units
        years
        buildingManagement
        rentalManagement
        bankName
        loanAmount
        remainingPrincipal
        currentDate
        interestRate
        principal
        interest
        deliveryDate
        lastRepaymentYear
        remainingRepaymentYear
        totalPrincipalPerYear
        reducedRepayment
        yearOfTermReduction
        condition
        roomIncome
        repayment
        managementFee
        repairReserveFund
        otherRoomCost
        managementCommission
        totalIncome
        totalCost
        totalIncomeAndExpenses
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      orderType
      trackingId
      user {
        id
        firstName
        lastName
        email
        disabled
        plan
        subscriptionId
        subscriptionStartDate
        subscriptionEndDate
        subscriptionType
        files {
          items {
            id
            name
            category
            propertyName
            roomName
            userFilesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        expenses {
          items {
            id
            issueDate
            price
            category
            note
            company
            userExpensesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        properties {
          items {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            orderType
            trackingId
            user {
              id
              firstName
              lastName
              email
              disabled
              plan
              subscriptionId
              subscriptionStartDate
              subscriptionEndDate
              subscriptionType
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            userOrdersId
            owner
          }
          nextToken
        }
        fcmTokens {
          items {
            id
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      userOrdersId
      owner
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderType
        trackingId
        user {
          id
          firstName
          lastName
          email
          disabled
          plan
          subscriptionId
          subscriptionStartDate
          subscriptionEndDate
          subscriptionType
          files {
            items {
              id
              name
              category
              propertyName
              roomName
              userFilesId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          expenses {
            items {
              id
              issueDate
              price
              category
              note
              company
              userExpensesId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          properties {
            items {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          orders {
            items {
              id
              orderType
              trackingId
              createdAt
              updatedAt
              userOrdersId
              owner
            }
            nextToken
          }
          fcmTokens {
            items {
              id
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        userOrdersId
        owner
      }
      nextToken
    }
  }
`;
export const getFcmToken = /* GraphQL */ `
  query GetFcmToken($id: ID!) {
    getFcmToken(id: $id) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listFcmTokens = /* GraphQL */ `
  query ListFcmTokens(
    $filter: ModelFcmTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFcmTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createPdf = /* GraphQL */ `
  query CreatePdf($input: CreatePdfInput!) {
    createPdf(input: $input) {
      statusCode
      body
    }
  }
`;

import {SvgIconProps, SvgIcon} from '@material-ui/core';
import React from 'react';

export const AccountIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8 16C28.8 19.3947 27.4514 22.6505 25.051 25.0509C22.6505 27.4514 19.3948 28.7999 16 28.7999C12.6052 28.7999 9.34951 27.4514 6.94905 25.0509C4.54858 22.6505 3.20001 19.3947 3.20001 16C3.20001 12.6052 4.54858 9.34945 6.94905 6.94898C9.34951 4.54852 12.6052 3.19995 16 3.19995C19.3948 3.19995 22.6505 4.54852 25.051 6.94898C27.4514 9.34945 28.8 12.6052 28.8 16ZM19.2 11.2C19.2 12.0486 18.8629 12.8626 18.2628 13.4627C17.6626 14.0628 16.8487 14.4 16 14.4C15.1513 14.4 14.3374 14.0628 13.7373 13.4627C13.1372 12.8626 12.8 12.0486 12.8 11.2C12.8 10.3513 13.1372 9.53733 13.7373 8.93721C14.3374 8.33709 15.1513 7.99995 16 7.99995C16.8487 7.99995 17.6626 8.33709 18.2628 8.93721C18.8629 9.53733 19.2 10.3513 19.2 11.2ZM16 17.6C14.4682 17.5996 12.9685 18.0391 11.6791 18.8662C10.3897 19.6933 9.36488 20.8731 8.72641 22.2656C9.62669 23.3129 10.7428 24.1532 11.9983 24.7287C13.2538 25.3043 14.6189 25.6015 16 25.5999C17.3811 25.6015 18.7462 25.3043 20.0017 24.7287C21.2572 24.1532 22.3733 23.3129 23.2736 22.2656C22.6351 20.8731 21.6103 19.6933 20.3209 18.8662C19.0316 18.0391 17.5318 17.5996 16 17.6Z"
      />
    </SvgIcon>
  );
};

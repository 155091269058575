import {PlatformType} from '../API';

export const convertPlatformTypeToString = (
  platformType: PlatformType | undefined,
) => {
  switch (platformType) {
    case PlatformType.WEB:
      return 'Web';
    case PlatformType.IOS:
      return 'iOS';
    case PlatformType.ANDROID:
      return 'Android';
    default:
      return '';
  }
};

import {Box, Grid, Stack, Typography} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Storage} from 'aws-amplify';
import GoogleMapReact from 'google-map-react';
import React, {useCallback, useEffect, useState} from 'react';
import Geocode from 'react-geocode';
import {useQueryClient} from 'react-query';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {AddPropertySuccessfulModal} from '../components/AddPropertySuccessfulModal';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {Cell} from '../components/Cell';
import {DeletePropertyModal} from '../components/DeletePropertyModal';
import {LabelTitle} from '../components/LabelTitle';
import {Navigation} from '../components/Navigation';
import {PropertyImage} from '../components/PropertyImage';
import {Snackbar} from '../components/Snackbar';
import {DeleteIcon} from '../images/DeleteIcon';
import {EditIcon} from '../images/EditIcon';
import {useDeletePropertyMutation, useProperty} from '../queries/property';
import {useDeleteResidentMutation} from '../queries/resident';
import {useDeleteRoomMutation} from '../queries/room';
import {OperationState, replaceState} from '../utils/OperationState';
import {convertDateToString} from '../utils/convertDateToString';
import {isNonNull} from '../utils/isNonNull';
import {ToDisplayStructureName} from '../utils/toDisplayStructureName';

export const PropertyManagementScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const state = location.state as OperationState;
  const {propertyId} = useParams();
  const {data: property} = useProperty(propertyId);
  const [thumbnail, setThumbnail] = useState('');
  const [mapCenter, setMapCenter] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 36,
    lng: 140,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [maps, setMaps] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [map, setMap] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDeletePropertyModal, setOpenDeletePropertyModal] = useState(false);
  const {mutate: deleteResident, isLoading: isLoadingDeleteResident} =
    useDeleteResidentMutation();
  const {mutate: deleteRoom, isLoading: isLoadingDeleteRoom} =
    useDeleteRoomMutation();
  const {mutate: deleteProperty, isLoading: isLoadingDeleteProperty} =
    useDeletePropertyMutation();

  useEffect(() => {
    const fetchThumbnail = async () => {
      setThumbnail(
        property?.thumbnail
          ? await Storage.get(property.thumbnail, {
              level: 'private',
            })
          : '',
      );
    };
    fetchThumbnail();
  }, [property?.thumbnail]);

  useEffect(() => {
    if (!property?.address) {
      return;
    }
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '');
    Geocode.fromAddress(property?.address).then(
      response => {
        setMapCenter(response.results[0].geometry.location);
      },
      _err => {
        return;
      },
    );
    if (state) {
      switch (state.operationState) {
        case 'add-success':
          setOpen(true);
          replaceState();
          return;
        case 'edit-success':
          setOpenSnackbar(true);
          replaceState();
          return;
        default:
          return;
      }
    }
  }, [navigate, property?.address, state]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!maps || !map) return;
    new maps.Marker({
      map,
      position: mapCenter,
    });
  }, [mapCenter, maps, map]);

  const handleApiLoaded = useCallback(({map, maps}) => {
    setMaps(maps);
    setMap(map);
  }, []);

  const handleDeleteProperty = useCallback(() => {
    setOpenDeletePropertyModal(true);
  }, [setOpenDeletePropertyModal]);

  const onPropertyDelete = useCallback(async () => {
    if (!property || !property.rooms) {
      navigate('/error');
      return;
    }

    await Promise.all(
      property.rooms.items.map(async room => {
        if (!room || !room.residents) {
          return;
        }
        return room.residents.items.map(async resident => {
          if (!resident) {
            return;
          }
          await deleteResident({
            input: {
              id: resident.id,
            },
          });
        });
      }),
    ).catch(err => {
      Sentry.captureException(err);
      navigate('/error');
      return;
    });

    await Promise.all(
      property.rooms.items.map(async room => {
        if (!room) {
          return;
        }
        await deleteRoom({
          input: {
            id: room.id,
          },
        });
      }),
    ).catch(err => {
      Sentry.captureException(err);
      navigate('/error');
      return;
    });

    deleteProperty(
      {
        input: {
          id: property.id,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          setOpenDeletePropertyModal(false);
          navigate('/');
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [
    deleteProperty,
    deleteResident,
    deleteRoom,
    navigate,
    property,
    queryClient,
  ]);

  return property ? (
    <>
      <Box sx={{display: 'flex'}}>
        {property.rooms?.items && <Navigation />}
        <Box sx={{flexGrow: 1}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginBottom: '24px',
              //  maxWidth:　画面幅 - サイドメニュー幅 - 左右padding
              maxWidth: 'calc(100vw - 240px - 64px)',
              width: '100%',
            }}
            spacing={3}>
            <Typography
              noWrap
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: 1.3,
              }}>
              {property.name}
            </Typography>
            <Stack spacing={2} direction="row" sx={{flexShrink: 0}}>
              <Button
                variant="outlined"
                size="small"
                startIcon={<EditIcon color="primary" />}
                onClick={() => navigate('edit')}>
                編集
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="warning"
                startIcon={<DeleteIcon color="error" />}
                onClick={handleDeleteProperty}>
                削除
              </Button>
            </Stack>
          </Stack>
          <BaseBox>
            <Grid spacing={3} direction="row" container>
              <Grid item>
                <PropertyImage imageUrl={thumbnail} />
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>物件名称</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>{property.name}</Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>所在地</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Stack>
                      <Typography>{property.address}</Typography>
                      <Box sx={{width: '420px', height: '168px'}}>
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key:
                              process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
                          }}
                          center={mapCenter}
                          zoom={15}
                          onGoogleApiLoaded={handleApiLoaded}
                        />
                      </Box>
                    </Stack>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>最寄駅</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>{property.station}</Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>駅徒歩</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>{property.minutes}分</Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>構造</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>
                      {property.structure &&
                        ToDisplayStructureName(property.structure)}
                    </Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>階建</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>
                      {property.stories && `${property.stories}階建`}
                    </Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>総戸数</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>
                      {property.units && `${property.units}戸`}
                    </Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>築年月</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>
                      {property.years &&
                        convertDateToString(property.years, 'yyyy/M')}
                    </Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>建物管理会社名</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>{property.buildingManagement}</Typography>
                  </Cell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>賃貸管理会社名</LabelTitle>
                  </Cell>
                  <Cell width={450}>
                    <Typography>{property.rentalManagement}</Typography>
                  </Cell>
                </Stack>
                {property.customItems &&
                  property.customItems.filter(isNonNull).map((item, index) => (
                    <Stack key={index} direction="row" spacing={0}>
                      <Cell color="primary-40">
                        <LabelTitle>{item.key}</LabelTitle>
                      </Cell>
                      <Cell width={450}>
                        <Typography>{item.value}</Typography>
                      </Cell>
                    </Stack>
                  ))}
              </Grid>
            </Grid>
          </BaseBox>
        </Box>
      </Box>
      <AddPropertySuccessfulModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      />
      <Snackbar
        message="物件情報を更新しました"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
      {openDeletePropertyModal ? (
        <DeletePropertyModal
          openDeletePropertyModal={openDeletePropertyModal}
          setOpenDeletePropertyModal={setOpenDeletePropertyModal}
          onPropertyDelete={onPropertyDelete}
          propertyName={property.name}
          isLoading={
            isLoadingDeleteResident ||
            isLoadingDeleteRoom ||
            isLoadingDeleteProperty
          }
        />
      ) : null}
    </>
  ) : null;
};

import {Modal, ModalProps, Stack, Typography} from '@mui/material';
import React, {useCallback, VFC} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {useDeleteResidentMutation} from '../queries/resident';

type Props = Omit<ModalProps, 'onClose' | 'children'> & {
  onClose: () => void;
  residentId: string;
};

export const DeleteResidentModal: VFC<Props> = ({
  onClose,
  residentId,
  ...props
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: deleteResident} = useDeleteResidentMutation();
  const onDelete = useCallback(async () => {
    deleteResident(
      {
        input: {
          id: residentId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          onClose();
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [deleteResident, navigate, onClose, queryClient, residentId]);
  return (
    <Modal onClose={onClose} {...props}>
      <BaseBox
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '464px',
          boxShadow: 'none',
        }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: 20,
            lineHeight: 1.3,
          }}
          align="center">
          削除
        </Typography>
        <Typography sx={{lineHeight: 1.5, m: '24px 0 40px'}}>
          <Typography sx={{fontWeight: 600}} component="span">
            現在の契約者情報
          </Typography>
          を削除します。削除すると、現在の契約者情報（基本情報、収入、支出）がすべて削除されます。
          削除後のデータは復元できませんのでご注意ください。
        </Typography>
        <Stack direction="row" spacing={3}>
          <Button variant="outlined" onClick={onClose}>
            キャンセル
          </Button>
          <Button variant="contained" color="warning" onClick={onDelete}>
            削除する
          </Button>
        </Stack>
      </BaseBox>
    </Modal>
  );
};

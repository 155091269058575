import {Box} from '@mui/material';
import React, {FC} from 'react';
import {Button} from './Button';
import {PlusIcon} from '../images/PlusIcon';

type Props = {
  onAdd: () => void;
};

export const AddItemButton: FC<Props> = ({onAdd, ...props}) => {
  return (
    <Box {...props}>
      <Button
        size="small"
        startIcon={<PlusIcon strokeColor="#31CDD3" />}
        onClick={onAdd}>
        {props.children}
      </Button>
    </Box>
  );
};

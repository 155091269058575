import {PropertyFileCategory} from '../API';

export const convertStringToPropertyFileCategory = (category: string) => {
  switch (category) {
    case '損害保険':
      return PropertyFileCategory.INSURANCE;
    case '賃貸管理':
      return PropertyFileCategory.LEASING;
    case '銀行・ローン':
      return PropertyFileCategory.LOAN;
    case 'リフォーム':
      return PropertyFileCategory.REPAIR;
    case '売買契約':
      return PropertyFileCategory.SALES;
    case '確定申告・税務':
      return PropertyFileCategory.TAX;
    case 'その他':
      return PropertyFileCategory.OTHER;
    default:
      return PropertyFileCategory.OTHER;
  }
};

import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateExpensesMutation,
  CreateExpensesMutationVariables,
  DeleteExpensesMutation,
  DeleteExpensesMutationVariables,
  ListExpensesByUserExpensesIdQuery,
  ListExpensesByUserExpensesIdQueryVariables,
  UpdateExpensesMutation,
  UpdateExpensesMutationVariables,
} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {
  createExpenses,
  deleteExpenses,
  updateExpenses,
} from '../graphql/mutations';
import {listExpensesByUserExpensesId} from '../graphql/queries';

export const useExpenses = (startDate?: string, endDate?: string) => {
  const user = useOAuthUser();
  return useQuery(
    ['expenses', user?.attributes.sub, startDate, endDate],
    async () => {
      if (!user) {
        return undefined;
      }
      const variables: ListExpensesByUserExpensesIdQueryVariables = {
        userExpensesId: user.attributes.sub,
        issueDate:
          startDate && endDate
            ? {
                between: [startDate, endDate],
              }
            : undefined,
        limit: 10000,
      };
      const result = (await API.graphql(
        graphqlOperation(listExpensesByUserExpensesId, variables),
      )) as GraphQLResult<ListExpensesByUserExpensesIdQuery>;
      return result?.data?.listExpensesByUserExpensesId;
    },
  );
};

export const useCreateExpensesMutation = () => {
  return useMutation(async (variables: CreateExpensesMutationVariables) => {
    const expenses = (await API.graphql(
      graphqlOperation(createExpenses, variables),
    )) as GraphQLResult<CreateExpensesMutation>;
    return expenses.data?.createExpenses;
  });
};

export const useUpdateExpensesMutation = () => {
  return useMutation(async (variables: UpdateExpensesMutationVariables) => {
    const expenses = (await API.graphql(
      graphqlOperation(updateExpenses, variables),
    )) as GraphQLResult<UpdateExpensesMutation>;
    return expenses.data?.updateExpenses;
  });
};

export const useDeleteExpensesMutation = () => {
  return useMutation(async (variables: DeleteExpensesMutationVariables) => {
    const expenses = (await API.graphql(
      graphqlOperation(deleteExpenses, variables),
    )) as GraphQLResult<DeleteExpensesMutation>;
    return expenses.data?.deleteExpenses;
  });
};

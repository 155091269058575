import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateResidentMutation,
  CreateResidentMutationVariables,
  DeleteResidentMutation,
  DeleteResidentMutationVariables,
  GetResidentQuery,
  ListResidentsByOwnerIdQuery,
  ListResidentsByOwnerIdQueryVariables,
  ModelResidentFilterInput,
  UpdateResidentMutation,
  UpdateResidentMutationVariables,
} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {
  createResident,
  deleteResident,
  updateResident,
} from '../graphql/mutations';
import {getResident, listResidentsByOwnerId} from '../graphql/queries';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export const useResident = (residentId?: string) => {
  return useQuery(
    ['resident', residentId],
    async () => {
      if (!residentId) return;
      const result = (await API.graphql(
        graphqlOperation(getResident, {id: residentId}),
      )) as GraphQLResult<GetResidentQuery>;
      return result.data?.getResident;
    },
    {
      enabled: !!residentId,
    },
  );
};

export const useResidents = (filter?: ModelResidentFilterInput | null) => {
  const user = useOAuthUser();
  return useQuery(['residents', user?.attributes.sub], async () => {
    if (!user) {
      return undefined;
    }
    const variables: ListResidentsByOwnerIdQueryVariables = {
      ownerId: user.attributes.sub,
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listResidentsByOwnerId, variables),
    )) as GraphQLResult<ListResidentsByOwnerIdQuery>;
    return result?.data?.listResidentsByOwnerId;
  });
};

export const useResidentsByYear = (year: number) => {
  const date = new Date(year.toString());
  const nextYearDate = new Date(date);
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
  return useResidents({
    or: [
      {
        endDate: {
          ge: toISOStringWithTimezone(date),
        },
        startDate: {
          lt: toISOStringWithTimezone(nextYearDate),
        },
      },
      {
        endDate: {
          attributeExists: false,
        },
        startDate: {
          lt: toISOStringWithTimezone(nextYearDate),
        },
      },
    ],
  });
};

export const useResidentsByMonth = (year: number, month: number) => {
  const date = new Date(year.toString() + '-' + ('00' + month).slice(-2));
  const nextMonthDate = new Date(date);
  nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
  return useResidents({
    or: [
      {
        endDate: {
          ge: toISOStringWithTimezone(date),
        },
        startDate: {
          lt: toISOStringWithTimezone(nextMonthDate),
        },
      },
      {
        endDate: {
          attributeExists: false,
        },
        startDate: {
          lt: toISOStringWithTimezone(nextMonthDate),
        },
      },
    ],
  });
};

export const useCreateResidentMutation = () => {
  return useMutation(async (variables: CreateResidentMutationVariables) => {
    const resident = (await API.graphql(
      graphqlOperation(createResident, variables),
    )) as GraphQLResult<CreateResidentMutation>;
    return resident.data?.createResident;
  });
};

export const useUpdateResidentMutation = () => {
  return useMutation(async (variables: UpdateResidentMutationVariables) => {
    const resident = (await API.graphql(
      graphqlOperation(updateResident, variables),
    )) as GraphQLResult<UpdateResidentMutation>;
    return resident.data?.updateResident;
  });
};

export const useDeleteResidentMutation = () => {
  return useMutation(async (variables: DeleteResidentMutationVariables) => {
    const resident = (await API.graphql(
      graphqlOperation(deleteResident, variables),
    )) as GraphQLResult<DeleteResidentMutation>;
    return resident.data?.deleteResident;
  });
};

import {
  Paper,
  Modal,
  Stack,
  Typography,
  styled,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Storage} from 'aws-amplify';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {useDropzone} from 'react-dropzone';
import {useForm, FormProvider} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {BaseBox as Box} from './BaseBox';
import {Button} from './Button';
import {CustomUploadFile} from './CustomUploadFile';
import {useOAuthUserState} from '../AuthProvider';
import {useCreatePropertyFileMutation} from '../queries/propertyFile';
import {convertStringToPropertyFileCategory} from '../utils/convertStringToPropertyFileCategory';

const styledPaper = styled(Paper)(() => ({
  boxShadow: 'unset',
}));

export type PropsForUpload = {
  openUploadModal: boolean;
  setOpenUploadModal: Dispatch<SetStateAction<boolean>>;
};

export type UploadFileInfo = {
  name: string | null;
  size: number;
  category: string | null;
  property: string | null;
  room: string | null;
  data: File;
  error: boolean;
};

export const UploadFileModal: React.VFC<PropsForUpload> = ({
  openUploadModal,
  setOpenUploadModal,
}: PropsForUpload) => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const userId = userState.user?.attributes.sub ?? '';
  const queryClient = useQueryClient();
  const {mutate: createPropertyFile} = useCreatePropertyFileMutation();
  const [isExistSameFile, setIsExistSameFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<UploadFileInfo[]>([]);
  const methods = useForm<UploadFileInfo[]>({
    reValidateMode: 'onBlur',
  });
  const accept = 'application/pdf';
  const onDrop = (acceptedFiles: File[]) => {
    if (
      uploadFiles.some(uploadFile => {
        return acceptedFiles.some((acceptedFile: File) => {
          return uploadFile.name === acceptedFile.name;
        });
      })
    ) {
      return;
    }
    acceptedFiles.forEach((file: File) => {
      setUploadFiles(prev => [
        ...prev,
        {
          name: file.name,
          size: file.size,
          category: null,
          property: null,
          room: null,
          data: file,
          error: false,
        },
      ]);
    });
  };
  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept,
  });

  useEffect(() => {
    uploadFiles
      .map(uploadFile => {
        return uploadFile.error;
      })
      .includes(true)
      ? setIsExistSameFile(true)
      : setIsExistSameFile(false);
  }, [uploadFiles]);

  const handleClose = useCallback(() => {
    setOpenUploadModal(false);
  }, [setOpenUploadModal]);

  const handleUpdate = useCallback((info: UploadFileInfo) => {
    setUploadFiles(uploadFiles =>
      uploadFiles.map(uploadFile =>
        uploadFile.name === info.name
          ? {
              name: info.name,
              size: info.size,
              category: info.category,
              property: info.property,
              room: info.room,
              data: info.data,
              error: info.error,
            }
          : uploadFile,
      ),
    );
  }, []);

  const handleDelete = useCallback(
    (info: UploadFileInfo) => {
      setUploadFiles(uploadFiles.filter(file => file.name !== info.name));
    },
    [uploadFiles],
  );

  const onUpload = useCallback(async () => {
    setIsLoading(true);
    await Promise.all(
      uploadFiles.map(async uploadFile => {
        if (!uploadFile.name || !uploadFile.size || !uploadFile.category) {
          navigate('/error');
          return;
        }
        if (isExistSameFile) {
          navigate('/error');
          return;
        }
        const encodedFileName = encodeURIComponent(uploadFile.name);
        const uploadFileCategory = convertStringToPropertyFileCategory(
          uploadFile.category,
        );
        await Storage.put('documents/' + encodedFileName, uploadFile.data, {
          level: 'private',
          contentType: accept,
        }).catch(err => {
          Sentry.captureException(err);
          navigate('/error');
          return;
        });
        createPropertyFile(
          {
            input: {
              name: encodedFileName,
              category: uploadFileCategory,
              propertyName: uploadFile.property ?? '',
              roomName: uploadFile.room ?? '',
              userFilesId: userId,
            },
          },
          {
            onError: () => {
              navigate('/error');
              return;
            },
          },
        );
      }),
    ).finally(() => {
      queryClient.invalidateQueries();
      setIsLoading(false);
      handleClose();
    });
  }, [
    uploadFiles,
    isExistSameFile,
    createPropertyFile,
    userId,
    navigate,
    queryClient,
    handleClose,
  ]);

  return (
    <FormProvider {...methods}>
      <Modal
        open={openUploadModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}>
        <Stack>
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '1130px',
              padding: '40px',
              boxShadow: 'none',
            }}>
            <div {...getRootProps()}>
              <Box
                sx={{
                  width: '1048px',
                  border: '3px dashed #A1A5B6',
                  bgcolor: 'flikGray.-30',
                  boxShadow: 'none',
                }}>
                <input {...getInputProps()} />
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center">
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '500',
                      lineHeight: '32.4px',
                    }}>
                    アップロードするファイルを
                    <br />
                    ここにドラッグ&ドロップ
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '300',
                      lineHeight: '18.2px',
                      my: '12px',
                    }}>
                    または
                  </Typography>
                  <Button variant="contained">ファイルを選択</Button>
                </Stack>
              </Box>
            </div>
            <TableContainer
              component={styledPaper}
              sx={{mt: '24px', mb: '40px'}}>
              <Table aria-label="simple table">
                <TableHead sx={{backgroundColor: 'flikPrimary.-40'}}>
                  <TableRow>
                    <TableCell>ファイル名</TableCell>
                    <TableCell>サイズ</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1.25}>
                        <StyledText>カテゴリー</StyledText>
                        <StyledRequired>必須</StyledRequired>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1.25}>
                        <StyledText>物件名</StyledText>
                      </Stack>
                    </TableCell>
                    <TableCell>号室</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {uploadFiles.map((uploadFile, index) => (
                  <CustomUploadFile
                    key={index}
                    uploadFile={uploadFile}
                    fileIndex={index}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                  />
                ))}
              </Table>
            </TableContainer>
            <Stack direction="row" spacing={3} justifyContent="end">
              <Button variant="outlined" onClick={handleClose}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        width: '20px  !important',
                        height: '20px  !important',
                      }}
                    />
                  ) : null
                }
                onClick={methods.handleSubmit(onUpload)}>
                {isLoading ? 'アップロード中' : 'アップロード'}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Modal>
    </FormProvider>
  );
};

const StyledText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: 1.3,
}));

const StyledRequired = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: 1.3,
  color: 'red',
}));

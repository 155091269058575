import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const ArrowRightIcon = ({...props}: SvgIconProps & {}) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      sx={{fill: 'none', ...props.sx}}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 8L20 16L12 24"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

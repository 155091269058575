import {MenuItem, Stack, Typography} from '@mui/material';
import React, {VFC} from 'react';
import {
  FieldError,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form';
import {IconButton} from './IconButton';
import {Select} from './Select';
import {MAX_INPUT_LENGTH, TextField} from './TextField';
import {EarlyRepaymentInfoInput} from '../API';
import {DeleteIcon} from '../images/DeleteIcon';
import {monthList, yearsList} from '../utils/selectOptions';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  index: number;
  name: string;
  defaultValue: EarlyRepaymentInfoInput;
  errors:
    | (
        | FieldError
        | {
            year?: FieldError | undefined;
            month?: FieldError | undefined;
            repaymentAmount?: FieldError | undefined;
          }
      )[]
    | undefined;
  onRemove: UseFieldArrayRemove;
};

export const EarlyRepaymentInfo: VFC<Props> = ({
  register,
  index,
  name,
  onRemove,
  defaultValue,
  errors,
}) => {
  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Stack direction="row" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Select
            {...register(`${name}.${index}.year`)}
            defaultValue={defaultValue.year}
            sx={{width: '100px'}}>
            {yearsList.map((s, index) => (
              <MenuItem value={s.value} key={index}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{marginLeft: '4px'}}>年</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Select
            {...register(`${name}.${index}.month`)}
            defaultValue={defaultValue.month}
            sx={{width: '80px'}}>
            {monthList.map((s, index) => (
              <MenuItem value={s.value} key={index}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{marginLeft: '4px'}}>月</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <TextField
          type="number"
          defaultValue={defaultValue.repaymentAmount}
          {...register(`${name}.${index}.repaymentAmount`, {
            required: true,
            maxLength: MAX_INPUT_LENGTH,
          })}
          size="small"
          // TODO: https://geekbox.atlassian.net/browse/FLIK-116
          helperText={errors && !!errors[index] && '入力してください'}
          error={errors && !!errors[index]}
          sx={{width: '180px'}}
        />
        <Typography sx={{marginLeft: '4px'}}>円</Typography>
      </Stack>
      <IconButton tooltip="削除" onClick={() => onRemove(index)}>
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
};

import {SvgIconProps, SvgIcon} from '@material-ui/core';
import React from 'react';

export const ChartBarIcon = (props: SvgIconProps) => {
  const StrokeColor = props.color === 'primary' ? '#31CDD3' : '#181C32';
  return (
    <SvgIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_4809_5247)">
        <path
          d="M6.66667 10.5H3.33333C2.8731 10.5 2.5 10.8731 2.5 11.3333V16.3333C2.5 16.7936 2.8731 17.1667 3.33333 17.1667H6.66667C7.1269 17.1667 7.5 16.7936 7.5 16.3333V11.3333C7.5 10.8731 7.1269 10.5 6.66667 10.5Z"
          stroke={StrokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 7.1665H8.33333C7.8731 7.1665 7.5 7.5396 7.5 7.99984V16.3332C7.5 16.7934 7.8731 17.1665 8.33333 17.1665H11.6667C12.1269 17.1665 12.5 16.7934 12.5 16.3332V7.99984C12.5 7.5396 12.1269 7.1665 11.6667 7.1665Z"
          stroke={StrokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 3.8335H13.3333C12.8731 3.8335 12.5 4.20659 12.5 4.66683V16.3335C12.5 16.7937 12.8731 17.1668 13.3333 17.1668H16.6667C17.1269 17.1668 17.5 16.7937 17.5 16.3335V4.66683C17.5 4.20659 17.1269 3.8335 16.6667 3.8335Z"
          stroke={StrokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33334 17.1665H15"
          stroke={StrokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4809_5247">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      disabled
      plan
      subscriptionId
      subscriptionStartDate
      subscriptionEndDate
      subscriptionType
      files {
        items {
          id
          name
          category
          propertyName
          roomName
          userFilesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      expenses {
        items {
          id
          issueDate
          price
          category
          note
          company
          userExpensesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      properties {
        items {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          orderType
          trackingId
          user {
            id
            firstName
            lastName
            email
            disabled
            plan
            subscriptionId
            subscriptionStartDate
            subscriptionEndDate
            subscriptionType
            files {
              nextToken
            }
            expenses {
              nextToken
            }
            properties {
              nextToken
            }
            orders {
              nextToken
            }
            fcmTokens {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          userOrdersId
          owner
        }
        nextToken
      }
      fcmTokens {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      disabled
      plan
      subscriptionId
      subscriptionStartDate
      subscriptionEndDate
      subscriptionType
      files {
        items {
          id
          name
          category
          propertyName
          roomName
          userFilesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      expenses {
        items {
          id
          issueDate
          price
          category
          note
          company
          userExpensesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      properties {
        items {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          orderType
          trackingId
          user {
            id
            firstName
            lastName
            email
            disabled
            plan
            subscriptionId
            subscriptionStartDate
            subscriptionEndDate
            subscriptionType
            files {
              nextToken
            }
            expenses {
              nextToken
            }
            properties {
              nextToken
            }
            orders {
              nextToken
            }
            fcmTokens {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          userOrdersId
          owner
        }
        nextToken
      }
      fcmTokens {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      disabled
      plan
      subscriptionId
      subscriptionStartDate
      subscriptionEndDate
      subscriptionType
      files {
        items {
          id
          name
          category
          propertyName
          roomName
          userFilesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      expenses {
        items {
          id
          issueDate
          price
          category
          note
          company
          userExpensesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      properties {
        items {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      orders {
        items {
          id
          orderType
          trackingId
          user {
            id
            firstName
            lastName
            email
            disabled
            plan
            subscriptionId
            subscriptionStartDate
            subscriptionEndDate
            subscriptionType
            files {
              nextToken
            }
            expenses {
              nextToken
            }
            properties {
              nextToken
            }
            orders {
              nextToken
            }
            fcmTokens {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          userOrdersId
          owner
        }
        nextToken
      }
      fcmTokens {
        items {
          id
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPropertyFile = /* GraphQL */ `
  mutation CreatePropertyFile(
    $input: CreatePropertyFileInput!
    $condition: ModelPropertyFileConditionInput
  ) {
    createPropertyFile(input: $input, condition: $condition) {
      id
      name
      category
      propertyName
      roomName
      userFilesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePropertyFile = /* GraphQL */ `
  mutation UpdatePropertyFile(
    $input: UpdatePropertyFileInput!
    $condition: ModelPropertyFileConditionInput
  ) {
    updatePropertyFile(input: $input, condition: $condition) {
      id
      name
      category
      propertyName
      roomName
      userFilesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePropertyFile = /* GraphQL */ `
  mutation DeletePropertyFile(
    $input: DeletePropertyFileInput!
    $condition: ModelPropertyFileConditionInput
  ) {
    deletePropertyFile(input: $input, condition: $condition) {
      id
      name
      category
      propertyName
      roomName
      userFilesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExpenses = /* GraphQL */ `
  mutation CreateExpenses(
    $input: CreateExpensesInput!
    $condition: ModelExpensesConditionInput
  ) {
    createExpenses(input: $input, condition: $condition) {
      id
      issueDate
      price
      category
      note
      company
      userExpensesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExpenses = /* GraphQL */ `
  mutation UpdateExpenses(
    $input: UpdateExpensesInput!
    $condition: ModelExpensesConditionInput
  ) {
    updateExpenses(input: $input, condition: $condition) {
      id
      issueDate
      price
      category
      note
      company
      userExpensesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExpenses = /* GraphQL */ `
  mutation DeleteExpenses(
    $input: DeleteExpensesInput!
    $condition: ModelExpensesConditionInput
  ) {
    deleteExpenses(input: $input, condition: $condition) {
      id
      issueDate
      price
      category
      note
      company
      userExpensesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      name
      thumbnail
      address
      station
      minutes
      structure
      stories
      units
      years
      buildingManagement
      rentalManagement
      customItems {
        key
        value
      }
      rooms {
        items {
          id
          ownerId
          name
          layout
          condition
          deliveryDate
          soldDate
          salesCompany
          sellingPrice
          occupiedArea
          totalArea
          bankName
          loanYears
          loanAmount
          interestRate
          repaymentMethod
          repaymentDate
          customItems {
            key
            value
          }
          purchaseCosts {
            money
            frequency
            year
            month
          }
          propertyTax {
            money
            frequency
            year
            month
          }
          realEstateAcquisitionTax {
            money
            frequency
            year
            month
          }
          insuranceFee {
            money
            frequency
            year
            month
          }
          repairReserveFund {
            money
            frequency
            year
            month
          }
          managementFee {
            money
            frequency
            year
            month
          }
          managementCommission {
            money
            frequency
            year
            month
          }
          customCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          residents {
            items {
              id
              ownerId
              name
              startDate
              endDate
              createdAt
              updatedAt
              roomResidentsId
              owner
            }
            nextToken
          }
          property {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          earlyRepaymentInfo {
            year
            month
            repaymentAmount
          }
          createdAt
          updatedAt
          propertyRoomsId
          owner
        }
        nextToken
      }
      userPropertiesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      name
      thumbnail
      address
      station
      minutes
      structure
      stories
      units
      years
      buildingManagement
      rentalManagement
      customItems {
        key
        value
      }
      rooms {
        items {
          id
          ownerId
          name
          layout
          condition
          deliveryDate
          soldDate
          salesCompany
          sellingPrice
          occupiedArea
          totalArea
          bankName
          loanYears
          loanAmount
          interestRate
          repaymentMethod
          repaymentDate
          customItems {
            key
            value
          }
          purchaseCosts {
            money
            frequency
            year
            month
          }
          propertyTax {
            money
            frequency
            year
            month
          }
          realEstateAcquisitionTax {
            money
            frequency
            year
            month
          }
          insuranceFee {
            money
            frequency
            year
            month
          }
          repairReserveFund {
            money
            frequency
            year
            month
          }
          managementFee {
            money
            frequency
            year
            month
          }
          managementCommission {
            money
            frequency
            year
            month
          }
          customCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          residents {
            items {
              id
              ownerId
              name
              startDate
              endDate
              createdAt
              updatedAt
              roomResidentsId
              owner
            }
            nextToken
          }
          property {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          earlyRepaymentInfo {
            year
            month
            repaymentAmount
          }
          createdAt
          updatedAt
          propertyRoomsId
          owner
        }
        nextToken
      }
      userPropertiesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      name
      thumbnail
      address
      station
      minutes
      structure
      stories
      units
      years
      buildingManagement
      rentalManagement
      customItems {
        key
        value
      }
      rooms {
        items {
          id
          ownerId
          name
          layout
          condition
          deliveryDate
          soldDate
          salesCompany
          sellingPrice
          occupiedArea
          totalArea
          bankName
          loanYears
          loanAmount
          interestRate
          repaymentMethod
          repaymentDate
          customItems {
            key
            value
          }
          purchaseCosts {
            money
            frequency
            year
            month
          }
          propertyTax {
            money
            frequency
            year
            month
          }
          realEstateAcquisitionTax {
            money
            frequency
            year
            month
          }
          insuranceFee {
            money
            frequency
            year
            month
          }
          repairReserveFund {
            money
            frequency
            year
            month
          }
          managementFee {
            money
            frequency
            year
            month
          }
          managementCommission {
            money
            frequency
            year
            month
          }
          customCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          residents {
            items {
              id
              ownerId
              name
              startDate
              endDate
              createdAt
              updatedAt
              roomResidentsId
              owner
            }
            nextToken
          }
          property {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          earlyRepaymentInfo {
            year
            month
            repaymentAmount
          }
          createdAt
          updatedAt
          propertyRoomsId
          owner
        }
        nextToken
      }
      userPropertiesId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      ownerId
      name
      layout
      condition
      deliveryDate
      soldDate
      salesCompany
      sellingPrice
      occupiedArea
      totalArea
      bankName
      loanYears
      loanAmount
      interestRate
      repaymentMethod
      repaymentDate
      customItems {
        key
        value
      }
      purchaseCosts {
        money
        frequency
        year
        month
      }
      propertyTax {
        money
        frequency
        year
        month
      }
      realEstateAcquisitionTax {
        money
        frequency
        year
        month
      }
      insuranceFee {
        money
        frequency
        year
        month
      }
      repairReserveFund {
        money
        frequency
        year
        month
      }
      managementFee {
        money
        frequency
        year
        month
      }
      managementCommission {
        money
        frequency
        year
        month
      }
      customCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      residents {
        items {
          id
          ownerId
          name
          startDate
          endDate
          customItems {
            key
            value
          }
          keyMoney {
            money
            frequency
            year
            month
          }
          firstIncome {
            money
            frequency
            year
            month
          }
          rent {
            money
            frequency
            year
            month
          }
          customMoveInCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          renovationCost {
            money
            frequency
            year
            month
          }
          keyExchangeCost {
            money
            frequency
            year
            month
          }
          customMoveOutCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          advertisingRates {
            money
            frequency
            year
            month
          }
          room {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          createdAt
          updatedAt
          roomResidentsId
          owner
        }
        nextToken
      }
      property {
        id
        name
        thumbnail
        address
        station
        minutes
        structure
        stories
        units
        years
        buildingManagement
        rentalManagement
        customItems {
          key
          value
        }
        rooms {
          items {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          nextToken
        }
        userPropertiesId
        createdAt
        updatedAt
        owner
      }
      earlyRepaymentInfo {
        year
        month
        repaymentAmount
      }
      createdAt
      updatedAt
      propertyRoomsId
      owner
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      ownerId
      name
      layout
      condition
      deliveryDate
      soldDate
      salesCompany
      sellingPrice
      occupiedArea
      totalArea
      bankName
      loanYears
      loanAmount
      interestRate
      repaymentMethod
      repaymentDate
      customItems {
        key
        value
      }
      purchaseCosts {
        money
        frequency
        year
        month
      }
      propertyTax {
        money
        frequency
        year
        month
      }
      realEstateAcquisitionTax {
        money
        frequency
        year
        month
      }
      insuranceFee {
        money
        frequency
        year
        month
      }
      repairReserveFund {
        money
        frequency
        year
        month
      }
      managementFee {
        money
        frequency
        year
        month
      }
      managementCommission {
        money
        frequency
        year
        month
      }
      customCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      residents {
        items {
          id
          ownerId
          name
          startDate
          endDate
          customItems {
            key
            value
          }
          keyMoney {
            money
            frequency
            year
            month
          }
          firstIncome {
            money
            frequency
            year
            month
          }
          rent {
            money
            frequency
            year
            month
          }
          customMoveInCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          renovationCost {
            money
            frequency
            year
            month
          }
          keyExchangeCost {
            money
            frequency
            year
            month
          }
          customMoveOutCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          advertisingRates {
            money
            frequency
            year
            month
          }
          room {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          createdAt
          updatedAt
          roomResidentsId
          owner
        }
        nextToken
      }
      property {
        id
        name
        thumbnail
        address
        station
        minutes
        structure
        stories
        units
        years
        buildingManagement
        rentalManagement
        customItems {
          key
          value
        }
        rooms {
          items {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          nextToken
        }
        userPropertiesId
        createdAt
        updatedAt
        owner
      }
      earlyRepaymentInfo {
        year
        month
        repaymentAmount
      }
      createdAt
      updatedAt
      propertyRoomsId
      owner
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      ownerId
      name
      layout
      condition
      deliveryDate
      soldDate
      salesCompany
      sellingPrice
      occupiedArea
      totalArea
      bankName
      loanYears
      loanAmount
      interestRate
      repaymentMethod
      repaymentDate
      customItems {
        key
        value
      }
      purchaseCosts {
        money
        frequency
        year
        month
      }
      propertyTax {
        money
        frequency
        year
        month
      }
      realEstateAcquisitionTax {
        money
        frequency
        year
        month
      }
      insuranceFee {
        money
        frequency
        year
        month
      }
      repairReserveFund {
        money
        frequency
        year
        month
      }
      managementFee {
        money
        frequency
        year
        month
      }
      managementCommission {
        money
        frequency
        year
        month
      }
      customCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      residents {
        items {
          id
          ownerId
          name
          startDate
          endDate
          customItems {
            key
            value
          }
          keyMoney {
            money
            frequency
            year
            month
          }
          firstIncome {
            money
            frequency
            year
            month
          }
          rent {
            money
            frequency
            year
            month
          }
          customMoveInCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          renovationCost {
            money
            frequency
            year
            month
          }
          keyExchangeCost {
            money
            frequency
            year
            month
          }
          customMoveOutCostItems {
            key
            value {
              money
              frequency
              year
              month
            }
          }
          advertisingRates {
            money
            frequency
            year
            month
          }
          room {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          createdAt
          updatedAt
          roomResidentsId
          owner
        }
        nextToken
      }
      property {
        id
        name
        thumbnail
        address
        station
        minutes
        structure
        stories
        units
        years
        buildingManagement
        rentalManagement
        customItems {
          key
          value
        }
        rooms {
          items {
            id
            ownerId
            name
            layout
            condition
            deliveryDate
            soldDate
            salesCompany
            sellingPrice
            occupiedArea
            totalArea
            bankName
            loanYears
            loanAmount
            interestRate
            repaymentMethod
            repaymentDate
            customItems {
              key
              value
            }
            purchaseCosts {
              money
              frequency
              year
              month
            }
            propertyTax {
              money
              frequency
              year
              month
            }
            realEstateAcquisitionTax {
              money
              frequency
              year
              month
            }
            insuranceFee {
              money
              frequency
              year
              month
            }
            repairReserveFund {
              money
              frequency
              year
              month
            }
            managementFee {
              money
              frequency
              year
              month
            }
            managementCommission {
              money
              frequency
              year
              month
            }
            customCostItems {
              key
            }
            residents {
              nextToken
            }
            property {
              id
              name
              thumbnail
              address
              station
              minutes
              structure
              stories
              units
              years
              buildingManagement
              rentalManagement
              userPropertiesId
              createdAt
              updatedAt
              owner
            }
            earlyRepaymentInfo {
              year
              month
              repaymentAmount
            }
            createdAt
            updatedAt
            propertyRoomsId
            owner
          }
          nextToken
        }
        userPropertiesId
        createdAt
        updatedAt
        owner
      }
      earlyRepaymentInfo {
        year
        month
        repaymentAmount
      }
      createdAt
      updatedAt
      propertyRoomsId
      owner
    }
  }
`;
export const createResident = /* GraphQL */ `
  mutation CreateResident(
    $input: CreateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    createResident(input: $input, condition: $condition) {
      id
      ownerId
      name
      startDate
      endDate
      customItems {
        key
        value
      }
      keyMoney {
        money
        frequency
        year
        month
      }
      firstIncome {
        money
        frequency
        year
        month
      }
      rent {
        money
        frequency
        year
        month
      }
      customMoveInCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      renovationCost {
        money
        frequency
        year
        month
      }
      keyExchangeCost {
        money
        frequency
        year
        month
      }
      customMoveOutCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      advertisingRates {
        money
        frequency
        year
        month
      }
      room {
        id
        ownerId
        name
        layout
        condition
        deliveryDate
        soldDate
        salesCompany
        sellingPrice
        occupiedArea
        totalArea
        bankName
        loanYears
        loanAmount
        interestRate
        repaymentMethod
        repaymentDate
        customItems {
          key
          value
        }
        purchaseCosts {
          money
          frequency
          year
          month
        }
        propertyTax {
          money
          frequency
          year
          month
        }
        realEstateAcquisitionTax {
          money
          frequency
          year
          month
        }
        insuranceFee {
          money
          frequency
          year
          month
        }
        repairReserveFund {
          money
          frequency
          year
          month
        }
        managementFee {
          money
          frequency
          year
          month
        }
        managementCommission {
          money
          frequency
          year
          month
        }
        customCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        residents {
          items {
            id
            ownerId
            name
            startDate
            endDate
            customItems {
              key
              value
            }
            keyMoney {
              money
              frequency
              year
              month
            }
            firstIncome {
              money
              frequency
              year
              month
            }
            rent {
              money
              frequency
              year
              month
            }
            customMoveInCostItems {
              key
            }
            renovationCost {
              money
              frequency
              year
              month
            }
            keyExchangeCost {
              money
              frequency
              year
              month
            }
            customMoveOutCostItems {
              key
            }
            advertisingRates {
              money
              frequency
              year
              month
            }
            room {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            createdAt
            updatedAt
            roomResidentsId
            owner
          }
          nextToken
        }
        property {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        earlyRepaymentInfo {
          year
          month
          repaymentAmount
        }
        createdAt
        updatedAt
        propertyRoomsId
        owner
      }
      createdAt
      updatedAt
      roomResidentsId
      owner
    }
  }
`;
export const updateResident = /* GraphQL */ `
  mutation UpdateResident(
    $input: UpdateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    updateResident(input: $input, condition: $condition) {
      id
      ownerId
      name
      startDate
      endDate
      customItems {
        key
        value
      }
      keyMoney {
        money
        frequency
        year
        month
      }
      firstIncome {
        money
        frequency
        year
        month
      }
      rent {
        money
        frequency
        year
        month
      }
      customMoveInCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      renovationCost {
        money
        frequency
        year
        month
      }
      keyExchangeCost {
        money
        frequency
        year
        month
      }
      customMoveOutCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      advertisingRates {
        money
        frequency
        year
        month
      }
      room {
        id
        ownerId
        name
        layout
        condition
        deliveryDate
        soldDate
        salesCompany
        sellingPrice
        occupiedArea
        totalArea
        bankName
        loanYears
        loanAmount
        interestRate
        repaymentMethod
        repaymentDate
        customItems {
          key
          value
        }
        purchaseCosts {
          money
          frequency
          year
          month
        }
        propertyTax {
          money
          frequency
          year
          month
        }
        realEstateAcquisitionTax {
          money
          frequency
          year
          month
        }
        insuranceFee {
          money
          frequency
          year
          month
        }
        repairReserveFund {
          money
          frequency
          year
          month
        }
        managementFee {
          money
          frequency
          year
          month
        }
        managementCommission {
          money
          frequency
          year
          month
        }
        customCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        residents {
          items {
            id
            ownerId
            name
            startDate
            endDate
            customItems {
              key
              value
            }
            keyMoney {
              money
              frequency
              year
              month
            }
            firstIncome {
              money
              frequency
              year
              month
            }
            rent {
              money
              frequency
              year
              month
            }
            customMoveInCostItems {
              key
            }
            renovationCost {
              money
              frequency
              year
              month
            }
            keyExchangeCost {
              money
              frequency
              year
              month
            }
            customMoveOutCostItems {
              key
            }
            advertisingRates {
              money
              frequency
              year
              month
            }
            room {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            createdAt
            updatedAt
            roomResidentsId
            owner
          }
          nextToken
        }
        property {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        earlyRepaymentInfo {
          year
          month
          repaymentAmount
        }
        createdAt
        updatedAt
        propertyRoomsId
        owner
      }
      createdAt
      updatedAt
      roomResidentsId
      owner
    }
  }
`;
export const deleteResident = /* GraphQL */ `
  mutation DeleteResident(
    $input: DeleteResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    deleteResident(input: $input, condition: $condition) {
      id
      ownerId
      name
      startDate
      endDate
      customItems {
        key
        value
      }
      keyMoney {
        money
        frequency
        year
        month
      }
      firstIncome {
        money
        frequency
        year
        month
      }
      rent {
        money
        frequency
        year
        month
      }
      customMoveInCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      renovationCost {
        money
        frequency
        year
        month
      }
      keyExchangeCost {
        money
        frequency
        year
        month
      }
      customMoveOutCostItems {
        key
        value {
          money
          frequency
          year
          month
        }
      }
      advertisingRates {
        money
        frequency
        year
        month
      }
      room {
        id
        ownerId
        name
        layout
        condition
        deliveryDate
        soldDate
        salesCompany
        sellingPrice
        occupiedArea
        totalArea
        bankName
        loanYears
        loanAmount
        interestRate
        repaymentMethod
        repaymentDate
        customItems {
          key
          value
        }
        purchaseCosts {
          money
          frequency
          year
          month
        }
        propertyTax {
          money
          frequency
          year
          month
        }
        realEstateAcquisitionTax {
          money
          frequency
          year
          month
        }
        insuranceFee {
          money
          frequency
          year
          month
        }
        repairReserveFund {
          money
          frequency
          year
          month
        }
        managementFee {
          money
          frequency
          year
          month
        }
        managementCommission {
          money
          frequency
          year
          month
        }
        customCostItems {
          key
          value {
            money
            frequency
            year
            month
          }
        }
        residents {
          items {
            id
            ownerId
            name
            startDate
            endDate
            customItems {
              key
              value
            }
            keyMoney {
              money
              frequency
              year
              month
            }
            firstIncome {
              money
              frequency
              year
              month
            }
            rent {
              money
              frequency
              year
              month
            }
            customMoveInCostItems {
              key
            }
            renovationCost {
              money
              frequency
              year
              month
            }
            keyExchangeCost {
              money
              frequency
              year
              month
            }
            customMoveOutCostItems {
              key
            }
            advertisingRates {
              money
              frequency
              year
              month
            }
            room {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            createdAt
            updatedAt
            roomResidentsId
            owner
          }
          nextToken
        }
        property {
          id
          name
          thumbnail
          address
          station
          minutes
          structure
          stories
          units
          years
          buildingManagement
          rentalManagement
          customItems {
            key
            value
          }
          rooms {
            items {
              id
              ownerId
              name
              layout
              condition
              deliveryDate
              soldDate
              salesCompany
              sellingPrice
              occupiedArea
              totalArea
              bankName
              loanYears
              loanAmount
              interestRate
              repaymentMethod
              repaymentDate
              createdAt
              updatedAt
              propertyRoomsId
              owner
            }
            nextToken
          }
          userPropertiesId
          createdAt
          updatedAt
          owner
        }
        earlyRepaymentInfo {
          year
          month
          repaymentAmount
        }
        createdAt
        updatedAt
        propertyRoomsId
        owner
      }
      createdAt
      updatedAt
      roomResidentsId
      owner
    }
  }
`;
export const createConsultingSheet = /* GraphQL */ `
  mutation CreateConsultingSheet(
    $input: CreateConsultingSheetInput!
    $condition: ModelConsultingSheetConditionInput
  ) {
    createConsultingSheet(input: $input, condition: $condition) {
      id
      ownerId
      propertyName
      roomName
      address
      station
      occupiedArea
      stories
      units
      years
      buildingManagement
      rentalManagement
      bankName
      loanAmount
      remainingPrincipal
      currentDate
      interestRate
      principal
      interest
      deliveryDate
      lastRepaymentYear
      remainingRepaymentYear
      totalPrincipalPerYear
      reducedRepayment
      yearOfTermReduction
      condition
      roomIncome
      repayment
      managementFee
      repairReserveFund
      otherRoomCost
      managementCommission
      totalIncome
      totalCost
      totalIncomeAndExpenses
      createdAt
      updatedAt
    }
  }
`;
export const updateConsultingSheet = /* GraphQL */ `
  mutation UpdateConsultingSheet(
    $input: UpdateConsultingSheetInput!
    $condition: ModelConsultingSheetConditionInput
  ) {
    updateConsultingSheet(input: $input, condition: $condition) {
      id
      ownerId
      propertyName
      roomName
      address
      station
      occupiedArea
      stories
      units
      years
      buildingManagement
      rentalManagement
      bankName
      loanAmount
      remainingPrincipal
      currentDate
      interestRate
      principal
      interest
      deliveryDate
      lastRepaymentYear
      remainingRepaymentYear
      totalPrincipalPerYear
      reducedRepayment
      yearOfTermReduction
      condition
      roomIncome
      repayment
      managementFee
      repairReserveFund
      otherRoomCost
      managementCommission
      totalIncome
      totalCost
      totalIncomeAndExpenses
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsultingSheet = /* GraphQL */ `
  mutation DeleteConsultingSheet(
    $input: DeleteConsultingSheetInput!
    $condition: ModelConsultingSheetConditionInput
  ) {
    deleteConsultingSheet(input: $input, condition: $condition) {
      id
      ownerId
      propertyName
      roomName
      address
      station
      occupiedArea
      stories
      units
      years
      buildingManagement
      rentalManagement
      bankName
      loanAmount
      remainingPrincipal
      currentDate
      interestRate
      principal
      interest
      deliveryDate
      lastRepaymentYear
      remainingRepaymentYear
      totalPrincipalPerYear
      reducedRepayment
      yearOfTermReduction
      condition
      roomIncome
      repayment
      managementFee
      repairReserveFund
      otherRoomCost
      managementCommission
      totalIncome
      totalCost
      totalIncomeAndExpenses
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      orderType
      trackingId
      user {
        id
        firstName
        lastName
        email
        disabled
        plan
        subscriptionId
        subscriptionStartDate
        subscriptionEndDate
        subscriptionType
        files {
          items {
            id
            name
            category
            propertyName
            roomName
            userFilesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        expenses {
          items {
            id
            issueDate
            price
            category
            note
            company
            userExpensesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        properties {
          items {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            orderType
            trackingId
            user {
              id
              firstName
              lastName
              email
              disabled
              plan
              subscriptionId
              subscriptionStartDate
              subscriptionEndDate
              subscriptionType
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            userOrdersId
            owner
          }
          nextToken
        }
        fcmTokens {
          items {
            id
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      userOrdersId
      owner
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      orderType
      trackingId
      user {
        id
        firstName
        lastName
        email
        disabled
        plan
        subscriptionId
        subscriptionStartDate
        subscriptionEndDate
        subscriptionType
        files {
          items {
            id
            name
            category
            propertyName
            roomName
            userFilesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        expenses {
          items {
            id
            issueDate
            price
            category
            note
            company
            userExpensesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        properties {
          items {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            orderType
            trackingId
            user {
              id
              firstName
              lastName
              email
              disabled
              plan
              subscriptionId
              subscriptionStartDate
              subscriptionEndDate
              subscriptionType
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            userOrdersId
            owner
          }
          nextToken
        }
        fcmTokens {
          items {
            id
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      userOrdersId
      owner
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      orderType
      trackingId
      user {
        id
        firstName
        lastName
        email
        disabled
        plan
        subscriptionId
        subscriptionStartDate
        subscriptionEndDate
        subscriptionType
        files {
          items {
            id
            name
            category
            propertyName
            roomName
            userFilesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        expenses {
          items {
            id
            issueDate
            price
            category
            note
            company
            userExpensesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        properties {
          items {
            id
            name
            thumbnail
            address
            station
            minutes
            structure
            stories
            units
            years
            buildingManagement
            rentalManagement
            customItems {
              key
              value
            }
            rooms {
              nextToken
            }
            userPropertiesId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        orders {
          items {
            id
            orderType
            trackingId
            user {
              id
              firstName
              lastName
              email
              disabled
              plan
              subscriptionId
              subscriptionStartDate
              subscriptionEndDate
              subscriptionType
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            userOrdersId
            owner
          }
          nextToken
        }
        fcmTokens {
          items {
            id
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      userOrdersId
      owner
    }
  }
`;
export const createFcmToken = /* GraphQL */ `
  mutation CreateFcmToken(
    $input: CreateFcmTokenInput!
    $condition: ModelFcmTokenConditionInput
  ) {
    createFcmToken(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateFcmToken = /* GraphQL */ `
  mutation UpdateFcmToken(
    $input: UpdateFcmTokenInput!
    $condition: ModelFcmTokenConditionInput
  ) {
    updateFcmToken(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteFcmToken = /* GraphQL */ `
  mutation DeleteFcmToken(
    $input: DeleteFcmTokenInput!
    $condition: ModelFcmTokenConditionInput
  ) {
    deleteFcmToken(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
    }
  }
`;

import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const ArrowDownIcon = ({...props}: SvgIconProps & {}) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      sx={{fill: 'none', ...props.sx}}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="6 9 12 15 18 9" />
    </SvgIcon>
  );
};

import {Stack, Typography} from '@mui/material';
import React from 'react';
import {Logo} from '../components/Logo';
import AppIcon from '../images/AppIcon.png';
import AppStoreBadge from '../images/AppStoreBadge.svg';
import GooglePlayBadge from '../images/GooglePlayBadge.png';

export const GuidanceToAppScreen = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      px={2.5}
      sx={{
        height: '100vh',
        background: 'linear-gradient(3.8deg, #FBFFE4 6.23%, #D9F6F7 72.45%);',
      }}
      spacing={5}>
      <Stack
        alignItems="center"
        spacing={2.5}
        sx={{
          svg: {
            width: 180,
          },
        }}>
        <Logo />
        <img
          alt="app icon"
          src={AppIcon}
          width="120"
          height="120"
          style={{
            borderRadius: 26.6,
            boxShadow: ' 0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
          }}
        />
      </Stack>
      <Stack spacing={3}>
        <Typography
          sx={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>
          アプリから不動産管理ができるようになりました！
        </Typography>
        <Typography sx={{fontSize: '14px', lineHeight: 1.8}}>
          モバイルでご利用いただく場合は、こちらからアプリをダウンロードしてください。
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <a
            href="https://apps.apple.com/jp/app/id6478487444"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              width: '100%',
              maxWidth: '180px',
            }}>
            <img
              alt="AppStoreBadge"
              src={AppStoreBadge}
              style={{
                width: '100%',
                padding: '0 28px 0 0',
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.freescorp.prop_dock"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              width: ' 100%',
              maxWidth: '180px',
            }}>
            <img
              alt="GooglePlayBadge"
              src={GooglePlayBadge}
              style={{
                width: '100%',
              }}
            />
          </a>
        </Stack>
      </Stack>
    </Stack>
  );
};

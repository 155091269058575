import {Stack, Typography, styled} from '@mui/material';
import React, {VFC} from 'react';
import {Cell} from './Cell';
import {EarlyRepaymentInfo} from '../API';

export const EarlyRepaymentList: VFC<
  {info: EarlyRepaymentInfo | null} & {index: number}
> = ({info, index}) => {
  return (
    <Stack direction="row">
      <Cell color="primary-40">{`繰り上げ返済${index + 1}`}</Cell>
      <StyledCell>
        <Typography width={215}>
          {info?.year}年{info?.month}月
        </Typography>
        <Typography width={200}>
          {info?.repaymentAmount.toLocaleString()}円
        </Typography>
      </StyledCell>
    </Stack>
  );
};

const StyledCell = styled(Cell)(() => ({
  width: '100%',
}));

import {Box, Stack, Typography, styled} from '@mui/material';
import React, {useEffect} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {Button} from './Button';
import {Cell} from './Cell';
import {EarlyRepaymentInfo} from './EarlyRepaymentInfo';
import {EarlyRepaymentInfoHead} from './EarlyRepaymentInfoHead';
import {PlusIcon} from '../images/PlusIcon';
import {RoomEarlyRepaymentInfoType} from '../screens/AddRoom';
import {initialValue} from '../screens/RepaymentInfo';

type Props = {
  earlyRepaymentInfo?: RoomEarlyRepaymentInfoType;
  onEarlyRepaymentInfoChange: (info: RoomEarlyRepaymentInfoType) => void;
  onErrorChange: (error: boolean) => void;
  isSubmitted: boolean;
};

export const RoomEarlyRepaymentInfo = ({
  earlyRepaymentInfo,
  onErrorChange,
  onEarlyRepaymentInfoChange,
  isSubmitted,
}: Props) => {
  const {
    register,
    setValue,
    formState: {errors},
    handleSubmit,
    getValues,
    control,
  } = useForm<RoomEarlyRepaymentInfoType>({
    mode: 'onBlur',
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'earlyRepaymentInfo',
  });
  useEffect(() => {
    if (earlyRepaymentInfo?.earlyRepaymentInfo) {
      setValue('earlyRepaymentInfo', earlyRepaymentInfo.earlyRepaymentInfo);
    }
  }, [earlyRepaymentInfo, setValue]);
  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(
        () => {
          onErrorChange(false);
          onEarlyRepaymentInfoChange({
            earlyRepaymentInfo: getValues('earlyRepaymentInfo'),
          });
        },
        () => onErrorChange(true),
      )();
    }
  }, [
    getValues,
    handleSubmit,
    isSubmitted,
    onEarlyRepaymentInfoChange,
    onErrorChange,
  ]);
  return (
    <>
      <Typography sx={{fontWeight: 500, mb: '8px'}}>
        繰り上げ返済設定
      </Typography>
      {fields.length > 0 && (
        <>
          <Stack direction="column">
            <EarlyRepaymentInfoHead />
            {fields.map((field, index) => (
              <Stack key={field.id} direction="row" spacing={0}>
                <Cell color="primary-40">{`繰り上げ返済${index + 1}`}</Cell>
                <StyledCell>
                  <EarlyRepaymentInfo
                    key={field.id}
                    register={register}
                    name="earlyRepaymentInfo"
                    index={index}
                    defaultValue={field}
                    errors={
                      Array.isArray(errors.earlyRepaymentInfo)
                        ? errors.earlyRepaymentInfo
                        : undefined
                    }
                    onRemove={remove}
                  />
                </StyledCell>
              </Stack>
            ))}
          </Stack>
        </>
      )}
      <Box sx={{margin: '16px 0 0'}}>
        <Button
          size="small"
          startIcon={<PlusIcon strokeColor="#31CDD3" />}
          onClick={() => append({...initialValue})}>
          繰り上げ返済情報を追加
        </Button>
      </Box>
    </>
  );
};

const StyledCell = styled(Cell)(() => ({
  width: '100%',
}));

import {Stack, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ConsultingSheetInput, Plan, User} from '../API';
import {Button} from '../components/Button';
import {ConsultingSheet} from '../components/ConsultingSheet';
import {useConsultingSheets} from '../queries/consultingSheet';

export const ConsultingInfoScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state as User;
  const {data: consultingSheets} = useConsultingSheets();
  const isPro = useMemo(() => user?.plan === Plan.PRO, [user?.plan]);

  return (
    <>
      {user?.id && isPro ? (
        consultingSheets && consultingSheets.items.length > 0 ? (
          <ConsultingSheet
            consultingData={consultingSheets.items as ConsultingSheetInput[]}
            userInfo={user}
          />
        ) : (
          <Stack
            py="40px"
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1.25}>
            <Typography fontSize="18px" fontWeight={600} textAlign="center">
              コンサルティングシートを準備中です
            </Typography>
            <Typography textAlign="center">
              準備ができ次第、こちらに表示されます
            </Typography>
          </Stack>
        )
      ) : (
        <Stack
          py="40px"
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}>
          <Typography fontSize="18px" fontWeight={600} textAlign="center">
            PROプランを契約すると、
            <br />
            毎月の運用状況やプロによる運用アドバイスが記載された
            <br />
            コンサルティングシートをお送りします
          </Typography>
          <Button
            variant="contained"
            sx={{width: '100%', minWidth: '200px'}}
            onClick={() => navigate('/plan')}>
            プロプランを確認する
          </Button>
        </Stack>
      )}
    </>
  );
};

import {StructureType} from '../API';

export const ToDisplayStructureName = (type: StructureType) => {
  switch (type) {
    case StructureType.W:
      return '木造（W造）';
    case StructureType.LIGHTS:
      return '軽量鉄骨造（S造）';
    case StructureType.WEIGHTS:
      return '重量鉄骨造（S造）';
    case StructureType.RC:
      return '鉄筋コンクリート造（RC造）';
    case StructureType.SRC:
      return '鉄骨鉄筋コンクリート造（SRC造）';
    case StructureType.AL:
      return 'アルミ造（AL造）';
    case StructureType.CFT:
      return 'コンクリート充填鋼管構造（CFT造）';
    case StructureType.CB:
      return 'コンクリートブロック造（CB造）';
    default:
      return '';
  }
};

import {Modal, Stack, Typography} from '@mui/material';
import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {BaseBox as Box} from './BaseBox';
import {Button} from './Button';

export type PropsForErrorSale = {
  openErrorSaleModal: boolean;
  setOpenErrorSaleModal: Dispatch<SetStateAction<boolean>>;
};

export const ErrorSaleRoomModal: React.VFC<PropsForErrorSale> = ({
  openErrorSaleModal,
  setOpenErrorSaleModal,
}: PropsForErrorSale) => {
  const handleClose = useCallback(() => {
    setOpenErrorSaleModal(false);
  }, [setOpenErrorSaleModal]);
  return (
    <Modal
      open={openErrorSaleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '463px',
          padding: '40px',
          boxShadow: 'none',
        }}>
        <Typography
          textAlign="center"
          mb="24px"
          sx={{fontSize: '20px', fontWeight: '500', LineHeight: '26px'}}>
          先に現在の入居者の退去処理を
          <br />
          行ってください
        </Typography>
        <Typography
          sx={{fontSize: '16px', fontWeight: '300', LineHeight: '24px'}}>
          退去を行った後、物件を売却していただけます。退去は契約者情報から行うことができます。
        </Typography>
        <Stack direction="row" justifyContent="center" mt="40px">
          <Button variant="contained" onClick={handleClose}>
            閉じる
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

import {Grid, Stack, styled, Typography} from '@mui/material';
import React, {VFC} from 'react';
import {Cell} from './Cell';
import {LabelTitle} from './LabelTitle';
import {MoneyInfoTableHead} from './MoneyInfoTableHead';
import {MoneyList} from './MoneyList';
import {useResident} from '../queries/resident';
import {convertDateToString} from '../utils/convertDateToString';
import {isNonNull} from '../utils/isNonNull';

type CustomProps = {
  residentId: string;
};

export const PastResidentInfo: VFC<CustomProps> = ({residentId}) => {
  const {data: resident} = useResident(residentId);

  return resident ? (
    <Grid container spacing={3} sx={{pl: '14px', mb: '20px'}}>
      <Grid item xs={12}>
        <Typography sx={{fontWeight: 500, m: '8px 0'}}>基本情報</Typography>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>入居開始日</LabelTitle>
          </Cell>
          <Cell>
            {resident && convertDateToString(resident.startDate, 'yyyy/M/d')}
          </Cell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>入居者氏名</LabelTitle>
          </Cell>
          <Cell>{resident?.name}</Cell>
        </Stack>
        {resident.customItems?.filter(isNonNull).map((item, index) => (
          <Grid item xs={12} key={index}>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>{item.key}</LabelTitle>
              </Cell>
              <Cell>
                <Typography>{item.value}</Typography>
              </Cell>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{fontWeight: 500, m: '8px 0'}}>収入</Typography>
        <MoneyInfoTableHead />
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>礼金</LabelTitle>
          </Cell>
          <StyledCell>
            <Stack direction="column" spacing={1}>
              {resident?.keyMoney.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>初月賃料</LabelTitle>
          </Cell>
          <StyledCell>
            <Stack direction="column" spacing={1}>
              {resident.firstIncome?.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>月額賃料</LabelTitle>
          </Cell>
          <StyledCell>
            <Stack direction="column" spacing={1}>
              {resident.firstIncome?.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCell>
        </Stack>
        {resident.customMoveInCostItems
          ?.filter(isNonNull)
          .map((item, index) => (
            <Grid item xs={12} key={index}>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle>{item.key}</LabelTitle>
                </Cell>
                <StyledCell>
                  <Stack direction="column" spacing={1}>
                    {item.value?.map((c, index) => (
                      <MoneyList key={index} cost={c} />
                    ))}
                  </Stack>
                </StyledCell>
              </Stack>
            </Grid>
          ))}
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{fontWeight: 500, m: '8px 0'}}>費用</Typography>
        <MoneyInfoTableHead />
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>広告料</LabelTitle>
          </Cell>
          <StyledCell>
            <Stack direction="column" spacing={1}>
              {resident?.advertisingRates?.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>内装・ リフォーム費用</LabelTitle>
          </Cell>
          <StyledCell>
            {resident.renovationCost?.map((c, index) => (
              <MoneyList key={index} cost={c} />
            ))}
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>鍵交換費用</LabelTitle>
          </Cell>
          <StyledCell>
            {resident.keyExchangeCost?.map((c, index) => (
              <MoneyList key={index} cost={c} />
            ))}
          </StyledCell>
        </Stack>
        {resident.customMoveOutCostItems
          ?.filter(isNonNull)
          .map((item, index) => (
            <Grid item xs={12} key={index}>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle>{item.key}</LabelTitle>
                </Cell>
                <StyledCell>
                  <Stack direction="column" spacing={1}>
                    {item.value?.map((c, index) => (
                      <MoneyList key={index} cost={c} />
                    ))}
                  </Stack>
                </StyledCell>
              </Stack>
            </Grid>
          ))}
      </Grid>
    </Grid>
  ) : null;
};

const StyledCell = styled(Cell)({
  width: ' 100%',
});

import {
  FormControl,
  FormHelperText,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {FormEvent, useState} from 'react';

export const MAX_INPUT_LENGTH = 200;
type CustomProps = {
  maxLength?: number;
} & TextFieldProps;

export const TextField = React.forwardRef<HTMLDivElement, CustomProps>(
  function TextField({error, maxLength = MAX_INPUT_LENGTH, ...props}, ref) {
    const [overLength, setOverLength] = useState(0);
    const checkLength = (e: FormEvent) => {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }
      e.target?.value
        ? setOverLength(e.target.value.length - maxLength)
        : setOverLength(0);
    };

    return (
      <FormControl fullWidth>
        <StyledTextField
          ref={ref}
          error={error || overLength > 0}
          onInput={checkLength}
          {...props}
        />
        {overLength > 0 && (
          <StyledFormHelperText>
            {overLength}文字オーバーしています
          </StyledFormHelperText>
        )}
      </FormControl>
    );
  },
);

const StyledTextField = styled(MuiTextField)(({theme}) => ({
  width: '100%',

  '& .MuiInputBase-root': {
    bgcolor: 'white',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px',
    lineHeight: '1.3',
  },
  '& .MuiInputBase-sizeSmall': {
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
    padding: '8px 12px !important',
  },
  '& .MuiOutlinedInput-root': {
    padding: '0',
    '& fieldset': {
      borderColor: theme.palette.flikGray['-20'],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.flikGray['-20'],
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.flikPrimary.main,
      borderWidth: '1px',
    },
    '&.Mui-error fieldset': {
      borderColor: theme.palette.warning.main,
    },
  },
})) as typeof MuiTextField;

const StyledFormHelperText = styled(FormHelperText)(({theme}) => ({
  color: theme.palette.warning.main,
})) as typeof FormHelperText;

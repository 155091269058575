import {styled, Typography, TypographyProps} from '@mui/material';
import React from 'react';

export const TypographyForTermsAndConditions: React.FC<
  TypographyProps
> = props => {
  return <StyledTypography component="span" {...props} />;
};

const StyledTypography = styled(Typography)(() => ({
  '&.MuiTypography-h1': {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '31px',
    paddingBottom: '24px',
  },
  '&.MuiTypography-h2': {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '23.4px',
    paddingBottom: '12px',
  },
  '&.MuiTypography-body1': {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    paddingBottom: '24px',
  },
})) as typeof Typography;

import {Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '../components/Button';
import {ExclamationCircleIcon} from '../images/ExclamationCircleIcon';

export const PaymentFailedScreen = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}>
      <ExclamationCircleIcon
        sx={{
          fill: 'transparent !important',
          stroke: '#DA0000',
          width: '100px !important',
          height: '100px !important',
        }}
      />
      <Typography fontSize={'20px'} fontWeight={600}>
        決済処理に失敗しました
      </Typography>
      {/* TODO: エラー内容の表示 */}
      <Typography fontSize={'16px'} textAlign="center">
        プランを変更する場合は、再度ご契約内容からお手続きを行ってください。
      </Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button variant="outlined" onClick={() => navigate('/')}>
          トップページ
        </Button>
        <Button variant="contained" onClick={() => navigate('/profile')}>
          ご契約内容
        </Button>
      </Stack>
    </Stack>
  );
};

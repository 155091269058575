import {Stack, Typography, Grid, Link} from '@mui/material';
import {styled} from '@mui/system';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Plan, PlatformType} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {Cell} from '../components/Cell';
import {LabelTitle} from '../components/LabelTitle';
import usePayment from '../hooks/usePayment';
import {EditIcon} from '../images/EditIcon';
import {useUser} from '../queries/user';
import {convertDateToString} from '../utils/convertDateToString';
import {convertPlatformTypeToString} from '../utils/convertPlatformTypeToString';

export const ProfileScreen = () => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const email = userState.user?.attributes.email ?? '';
  const {data} = useUser(sub);
  const userName =
    data?.firstName && data?.lastName
      ? `${data?.lastName} ${data?.firstName}`
      : '';
  const isPro = useMemo(() => data?.plan === Plan.PRO, [data?.plan]);
  const isMobileSubscription = useMemo(
    () =>
      data?.subscriptionType === PlatformType.ANDROID ||
      data?.subscriptionType === PlatformType.IOS,
    [data?.subscriptionType],
  );
  const onChangeProfile = () => {
    navigate('/changeProfile');
  };
  const nextPaymentDate = useMemo(() => {
    if (
      data?.subscriptionStartDate &&
      data.subscriptionType === PlatformType.WEB
    ) {
      const today = new Date();
      today.setMonth(today.getMonth() + 1);
      return `${today.getFullYear()}年${today.getMonth() + 1}月1日`;
    } else if (PlatformType.ANDROID === data?.subscriptionType) {
      return 'Google Play Storeでご確認ください';
    } else if (PlatformType.IOS === data?.subscriptionType) {
      return 'App Storeでご確認ください';
    }
    return '';
  }, [data?.subscriptionStartDate, data?.subscriptionType]);
  const {updatePaymentMethod} = usePayment();

  return (
    <Stack alignItems="center">
      <Grid container spacing={6} justifyContent="center" sx={{width: '900px'}}>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Title>アカウント</Title>
              <Button
                variant="outlined"
                startIcon={<EditIcon color="primary" />}
                size="small"
                onClick={onChangeProfile}>
                編集
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <BaseBox sx={{wordBreak: 'break-all'}}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                  mb: '12px',
                  lineHeight: '1.3',
                }}>
                {userName}さん
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  mb: '12px',
                  lineHeight: '1.3',
                }}>
                {email}
              </Typography>
              <Link
                href="/changePassword"
                underline="hover"
                color="flikPrimary.main"
                fontSize="14px">
                パスワードを変更する
              </Link>
            </BaseBox>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Title>ご契約内容</Title>
          </Grid>
          <Grid item xs={12}>
            <BaseBox sx={{wordBreak: 'break-all'}}>
              {isPro ? (
                <Stack>
                  <Stack direction="row">
                    <Cell color="primary-40">
                      <LabelTitle>ご契約中のプラン</LabelTitle>
                    </Cell>
                    <Cell
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Typography>プロプラン（2,580円/月）</Typography>
                      {!isMobileSubscription ? (
                        <Link
                          href="/plan"
                          underline="hover"
                          color="flikPrimary.main"
                          fontSize="14px">
                          プランを変更する
                        </Link>
                      ) : null}
                    </Cell>
                  </Stack>
                  <Stack direction="row">
                    <Cell color="primary-40">
                      <LabelTitle>契約開始日</LabelTitle>
                    </Cell>
                    <Cell sx={{width: '100%'}}>
                      <Typography>
                        {data?.subscriptionStartDate &&
                          convertDateToString(
                            data?.subscriptionStartDate,
                            'yyyy/M/d',
                          )}
                      </Typography>
                    </Cell>
                  </Stack>
                  <Stack direction="row">
                    <Cell color="primary-40">
                      <LabelTitle>課金形態</LabelTitle>
                    </Cell>
                    <Cell sx={{width: '100%'}}>
                      <Typography>
                        {data?.subscriptionType &&
                          convertPlatformTypeToString(data?.subscriptionType)}
                      </Typography>
                    </Cell>
                  </Stack>
                  <Stack direction="row">
                    <Cell color="primary-40">
                      <LabelTitle>次回お支払日</LabelTitle>
                    </Cell>
                    <Cell sx={{width: '100%'}}>
                      <Typography>{nextPaymentDate}</Typography>
                    </Cell>
                  </Stack>
                  {isMobileSubscription ? (
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: 14,
                        color: 'error.main',
                      }}>
                      現在ご利用中のプランはモバイルアプリでご契約されています。
                      プラン変更はモバイルアプリにて行ってください。
                    </Typography>
                  ) : null}
                  <Link
                    mt={2}
                    onClick={() => {
                      updatePaymentMethod(sub);
                    }}
                    underline="hover"
                    color="flikPrimary.main"
                    fontSize="14px">
                    お支払い方法の変更
                  </Link>
                </Stack>
              ) : (
                <>
                  <Stack direction="row" mb={1}>
                    <Cell color="primary-40">
                      <LabelTitle>ご契約中のプラン</LabelTitle>
                    </Cell>
                    <Cell sx={{width: '100%'}}>ライトプラン（無料）</Cell>
                  </Stack>
                  <Link
                    href="/plan"
                    underline="hover"
                    color="flikPrimary.main"
                    fontSize="14px">
                    アップグレードする
                  </Link>
                </>
              )}
            </BaseBox>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Title>退会</Title>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              退会は
              <Link href="/leave" underline="hover" color="flikPrimary.main">
                こちら
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
` as typeof Typography;

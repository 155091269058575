import {Box, styled} from '@mui/material';
import React, {FC} from 'react';
import {ImageIcon} from '../images/ImageIcon';

type Props = {
  imageUrl: string;
};

export const PropertyImage: FC<Props> = ({imageUrl}) => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        width: '320px',
        height: '240px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'flikGray.-20',
        marginBottom: '12px',
      }}>
      {imageUrl ? (
        <StyledImg src={imageUrl} alt="Property Thumbnail" loading="lazy" />
      ) : (
        <ImageIcon
          sx={{
            width: '60px !important',
            height: '44px !important',
            fill: 'transparent !important',
          }}
        />
      )}
    </Box>
  );
};

const StyledImg = styled('img')`
  border-radius: 12px;
  width: 320px;
  height: 240px;
  object-fit: cover;
`;

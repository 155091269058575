import {SvgIconProps, SvgIcon} from '@material-ui/core';
import React from 'react';

export const DeleteIcon = (props: SvgIconProps) => {
  const StrokeColor =
    props.color === 'primary'
      ? '#31CDD3'
      : props.color === 'error'
      ? '#DA0000'
      : '#181C32';
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.33334 9.33337H26.6667"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 14.6666V22.6666"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 14.6666V22.6666"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66666 9.33337L7.99999 25.3334C7.99999 26.0406 8.28094 26.7189 8.78104 27.219C9.28114 27.7191 9.95941 28 10.6667 28H21.3333C22.0406 28 22.7188 27.7191 23.2189 27.219C23.719 26.7189 24 26.0406 24 25.3334L25.3333 9.33337"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.33333V5.33333C12 4.97971 12.1405 4.64057 12.3905 4.39052C12.6406 4.14048 12.9797 4 13.3333 4H18.6667C19.0203 4 19.3594 4.14048 19.6095 4.39052C19.8595 4.64057 20 4.97971 20 5.33333V9.33333"
        stroke={StrokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import {MoneyInfo} from '../API';

export const getCostFromMoneyInfoByYear = (
  info: MoneyInfo | null,
  targetYear: number,
  end: string | null | undefined,
) => {
  const nowDate = new Date();
  const nowDateMonth = nowDate.getMonth() + 1;
  if (!info || nowDate.getFullYear() < targetYear) return 0;
  if (targetYear < Number(info.year)) {
    return 0;
  } else if (targetYear === Number(info.year)) {
    if (nowDateMonth < Number(info.month)) {
      return 0;
    }
    if (!end) {
      if (info.frequency === 'once') {
        return info.money;
      } else {
        if (targetYear === nowDate.getFullYear()) {
          return info.money * (nowDateMonth - Number(info.month) + 1);
        } else {
          return info.money * (12 - Number(info.month) + 1);
        }
      }
    } else {
      const endDate = new Date(end);
      const endDateMonth = endDate.getMonth() + 1;
      if (targetYear > endDate.getFullYear()) {
        return 0;
      } else if (targetYear === endDate.getFullYear()) {
        if (targetYear === nowDate.getFullYear()) {
          if (info.frequency === 'once') {
            return info.money;
          } else {
            if (Number(info.month) === nowDateMonth) {
              return info.money;
            } else {
              if (nowDateMonth > endDateMonth) {
                return (endDateMonth - Number(info.month) + 1) * info.money;
              } else {
                return (nowDateMonth - Number(info.month) + 1) * info.money;
              }
            }
          }
        } else {
          if (info.frequency === 'once') {
            return info.money;
          } else {
            return (endDateMonth - Number(info.month) + 1) * info.money;
          }
        }
      } else {
        if (targetYear === nowDate.getFullYear()) {
          if (info.frequency === 'once') {
            return info.money;
          } else {
            return info.money * nowDateMonth;
          }
        } else {
          if (info.frequency === 'once') {
            return info.money;
          } else {
            return info.money * (12 - Number(info.month) + 1);
          }
        }
      }
    }
  } else {
    if (info.frequency === 'once') {
      return 0;
    } else {
      if (!end) {
        if (targetYear === nowDate.getFullYear()) {
          return info.money * nowDateMonth;
        } else {
          return info.money * 12;
        }
      } else {
        const endDate = new Date(end);
        const endDateMonth = endDate.getMonth() + 1;
        if (targetYear > endDate.getFullYear()) {
          return 0;
        } else if (targetYear === endDate.getFullYear()) {
          if (targetYear === nowDate.getFullYear()) {
            if (nowDateMonth > endDateMonth) {
              return endDateMonth * info.money;
            } else {
              return nowDateMonth * info.money;
            }
          } else {
            return endDateMonth * info.money;
          }
        } else {
          if (targetYear === nowDate.getFullYear()) {
            return info.money * nowDateMonth;
          } else {
            return info.money * 12;
          }
        }
      }
    }
  }
};

export const getCostFromMoneyInfoByMonth = (
  info: MoneyInfo | null,
  targetYear: number,
  targetMonth: number,
  end: string | null | undefined,
) => {
  const nowDate = new Date();
  const nowDateMonth = nowDate.getMonth() + 1;
  if (
    !info ||
    nowDate.getFullYear() < targetYear ||
    (nowDate.getFullYear() === targetYear && nowDateMonth < targetMonth)
  ) {
    return 0;
  }
  if (targetYear < Number(info.year)) {
    return 0;
  } else if (targetYear === Number(info.year)) {
    if (Number(info.month) > targetMonth) {
      return 0;
    } else if (Number(info.month) === targetMonth) {
      return info.money;
    } else {
      if (info.frequency === 'once') {
        return 0;
      } else {
        if (!end) {
          return info.money;
        } else {
          const endDate = new Date(end);
          const endDateMonth = endDate.getMonth() + 1;
          if (targetYear > endDate.getFullYear()) {
            return 0;
          } else if (targetYear === endDate.getFullYear()) {
            if (targetMonth > endDateMonth) {
              return 0;
            } else {
              return info.money;
            }
          } else {
            return info.money;
          }
        }
      }
    }
  } else {
    if (info.frequency === 'once') {
      return 0;
    } else {
      if (!end) {
        if (targetYear === nowDate.getFullYear()) {
          return info.money;
        }
        return info.money;
      } else {
        const endDate = new Date(end);
        const endDateMonth = endDate.getMonth() + 1;
        if (targetYear > endDate.getFullYear()) {
          return 0;
        } else if (targetYear === endDate.getFullYear()) {
          if (targetMonth > endDateMonth) {
            return 0;
          } else {
            return info.money;
          }
        } else {
          return info.money;
        }
      }
    }
  }
};

import {ExpensesCategory} from '../API';

export const convertExpensesCategoryToString = (
  category: ExpensesCategory | undefined,
) => {
  switch (category) {
    case ExpensesCategory.TRAVELING:
      return '旅費交通費';
    case ExpensesCategory.COMMUNICATION:
      return '通信費';
    case ExpensesCategory.LIBRARY:
      return '図書費';
    case ExpensesCategory.PARTICIPATION:
      return '参加費';
    case ExpensesCategory.ENTERTAINMENT:
      return '交際接待費';
    case ExpensesCategory.OTHER:
      return 'その他';
    default:
      return '';
  }
};

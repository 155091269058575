export const convertDateToString = (date: string, format: string) => {
  const source = new Date(date);
  const year = source.getFullYear();
  const month = source.getMonth() + 1;
  const day = source.getDate();
  switch (format) {
    case 'yyyy/M':
      return year + '年' + month + '月';
    case 'yyyy/M/d':
      return year + '年' + month + '月' + day + '日';
    case 'M/d':
      return month + '月' + day + '日';
    default:
      break;
  }
};

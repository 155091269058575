import React from 'react';

export const LocationIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1528_4214)">
        <path
          d="M8 9.51148C9.10457 9.51148 10 8.61604 10 7.51147C10 6.40691 9.10457 5.51147 8 5.51147C6.89543 5.51147 6 6.40691 6 7.51147C6 8.61604 6.89543 9.51148 8 9.51148Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00002 2.17798C6.58553 2.17798 5.22898 2.73988 4.22878 3.74008C3.22859 4.74027 2.66669 6.09682 2.66669 7.51131C2.66669 8.77265 2.93469 9.59798 3.66669 10.5113L8.00002 15.5113L12.3334 10.5113C13.0654 9.59798 13.3334 8.77265 13.3334 7.51131C13.3334 6.09682 12.7715 4.74027 11.7713 3.74008C10.7711 2.73988 9.41451 2.17798 8.00002 2.17798V2.17798Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1528_4214">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.844727)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import {Stack, Typography, Grid} from '@mui/material';
import {styled} from '@mui/system';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useOAuthUserState} from '../AuthProvider';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {LabelTitle} from '../components/LabelTitle';
import {TextField} from '../components/TextField';
import {useIsMobile} from '../hooks/useIsMobile';
import {useUser} from '../queries/user';

type ContactFormInput = {
  firstName: string;
  lastName: string;
  email: string;
  content: string;
};

export const ContactScreen = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<ContactFormInput>({
    mode: 'onBlur',
  });
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const email = userState.user?.attributes.email ?? '';
  const {data} = useUser(sub);
  const [confirm, setConfirm] = useState<'edit' | 'confirm' | 'thanks'>('edit');
  useEffect(() => {
    if (data) {
      setValue('firstName', data?.firstName ?? '');
      setValue('lastName', data?.lastName ?? '');
      setValue('email', email);
    }
  }, [data, email, setValue]);
  const onSubmit = useCallback(() => {
    axios
      .post('https://api.freescorp.com/contact', {
        ToAddresses: 'contact@freescorp.com',
        Subject: 'Prop Dock からお問い合わせがありました',
        Message: `お名前: ${getValues('lastName')} ${getValues(
          'firstName',
        )}\nメールアドレス: ${getValues('email')}\n\n${getValues('content')}`,
      })
      .then(() => {
        setConfirm('thanks');
      })
      .catch(err => {
        Sentry.captureException(err);
        navigate('/error');
      });
  }, [getValues, navigate]);

  return (
    <Stack margin="auto" spacing={3} maxWidth={900}>
      <Title>お問い合わせ</Title>
      <BaseBox sx={{wordBreak: 'break-all'}}>
        {confirm === 'edit' && (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{marginBottom: '20px'}}>
              <Grid item xs={isMobile ? 12 : 3}>
                <LabelTitle isRequired>お名前</LabelTitle>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                xs={isMobile ? 12 : 9}
                direction="row">
                <Grid item xs={6}>
                  <TextField
                    {...register('lastName', {required: true})}
                    helperText={'lastName' in errors && '入力してください'}
                    error={'lastName' in errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('firstName', {required: true})}
                    helperText={'firstName' in errors && '入力してください'}
                    error={'firstName' in errors}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                direction="row"
                alignItems="center">
                <Grid item xs={isMobile ? 12 : 3}>
                  <LabelTitle isRequired>メールアドレス</LabelTitle>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  xs={isMobile ? 12 : 9}
                  direction="row">
                  <TextField
                    {...register('email', {
                      required: true,
                      pattern:
                        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,
                    })}
                    helperText={
                      'email' in errors &&
                      'メールアドレスを正しく入力してください'
                    }
                    error={'email' in errors}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={isMobile ? 12 : 3}>
                  <LabelTitle isRequired>ご相談内容・ご要望</LabelTitle>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  xs={isMobile ? 12 : 9}
                  direction="row">
                  <TextField
                    {...register('content', {required: true})}
                    multiline
                    minRows={5}
                    helperText={'content' in errors && '入力してください'}
                    error={'content' in errors}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              sx={{display: 'flex', m: '24px 0 0 auto'}}
              onClick={handleSubmit(() => setConfirm('confirm'))}>
              確認する
            </Button>
          </>
        )}
        {confirm === 'confirm' && (
          <>
            <Stack spacing={3}>
              <Stack direction="row" spacing={1}>
                <StyledLabelTitle>お名前</StyledLabelTitle>
                <Typography>
                  {getValues('lastName')} {getValues('firstName')}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <StyledLabelTitle>メールアドレス</StyledLabelTitle>
                <Typography>{getValues('email')}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="start">
                <StyledLabelTitle>ご相談内容・ご要望</StyledLabelTitle>
                <Typography sx={{whiteSpace: 'pre-wrap'}}>
                  {getValues('content')}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              justifyContent="end"
              direction="row"
              spacing={3}
              sx={{mt: '24px'}}>
              <Button variant="outlined" onClick={() => setConfirm('edit')}>
                キャンセル
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                送信する
              </Button>
            </Stack>
          </>
        )}
        {confirm === 'thanks' && (
          <Stack spacing={3}>
            <Typography sx={{fontSize: '20px', fontWeight: 'bold'}}>
              お問い合わせ、ありがとうございます
            </Typography>
            <Typography>
              担当者より折り返しご連絡させていただきます。
              <br />
              なお、3営業日を過ぎても返答がない場合はメールの受信がうまく行えていない可能性がありますので、
              <br />
              お手数ですが改めてお問い合わせください。
            </Typography>
            <Button variant="contained" onClick={() => navigate('/')}>
              TOP
            </Button>
          </Stack>
        )}
      </BaseBox>
    </Stack>
  );
};

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
` as typeof Typography;

const StyledLabelTitle = styled(LabelTitle)`
  width: 200px;
  flex-shrink: 0;
`;

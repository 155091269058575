import {Container} from '@mui/material';
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {RepaymentInfoScreen} from './/RepaymentInfo';
import {AddPropertyScreen} from './AddProperty';
import {AddResidentScreen} from './AddResident';
import {AddRoomScreen} from './AddRoom';
import {AdminScreen} from './Admin';
import {BalanceScreen} from './Balance';
import {ChangePasswordScreen} from './ChangePassword';
import {ChangeProfileScreen} from './ChangeProfile';
import {CompleteResetPasswordScreen} from './CompleteResetPassword';
import {ConsultingInfoScreen} from './ConsultingInfo';
import {ContactScreen} from './Contact';
import {EditPropertyScreen} from './EditProperty';
import {EditResidentScreen} from './EditResident';
import {EditRoomInfoScreen} from './EditRoomInfo';
import {ErrorScreen} from './Error';
import {ExpensesScreen} from './Expenses';
import {FilesScreen} from './Files';
import {ForgotPasswordScreen} from './ForgotPassword';
import {GuidanceToAppScreen} from './GuidanceToAppScreen';
import {HelpScreen} from './Help';
import {Home} from './Home';
import {LawScreen} from './Law';
import {LeaveScreen} from './Leave';
import {NotFoundErrorScreen} from './NotFoundError';
import {PaymentCancelScreen} from './PaymentCancel';
import {PaymentFailedScreen} from './PaymentFailed';
import {PaymentSuccessScreen} from './PaymentSuccess';
import {PlanScreen} from './Plan';
import {PrivacyScreen} from './Privacy';
import {ProfileScreen} from './Profile';
import {PropertyManagementScreen} from './PropertyManagement';
import {RepaymentResultScreen} from './RepaymentResult';
import {ResetPasswordScreen} from './ResetPassword';
import {RoomDetailScreen} from './RoomDetail';
import {SignInScreen} from './SignIn';
import {SignUpScreen} from './SignUp';
import {SignUpVerificationScreen} from './SignUpVerification';
import {TermsScreen} from './Terms';
import {useOAuthUserState} from '../AuthProvider';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {useIsMobile} from '../hooks/useIsMobile';

export type RootStackParamList = {
  ResetPasswordScreen: {email: string};
};

export const Screens = () => {
  const isMobile = useIsMobile();
  const userState = useOAuthUserState();
  if (userState.state === 'loading') {
    return null;
  }
  const DefaultScreens = (
    <>
      <Route path="/privacy" element={<PrivacyScreen />} />
      <Route path="/terms" element={<TermsScreen />} />
      <Route path="/law" element={<LawScreen />} />
      <Route path="help" element={<HelpScreen />} />
      <Route path="/contact" element={<ContactScreen />} />
      <Route path="/error" element={<ErrorScreen />} />
      <Route path="/paymentSuccess" element={<PaymentSuccessScreen />} />
      <Route path="/paymentCancel" element={<PaymentCancelScreen />} />
      <Route path="/paymentFailed" element={<PaymentFailedScreen />} />
      <Route path="*" element={<NotFoundErrorScreen />} />
    </>
  );
  return (
    <BrowserRouter>
      {isMobile ? (
        window.location.pathname === '/help' ? (
          <HelpScreen />
        ) : window.location.pathname === '/privacy' ? (
          <PrivacyScreen />
        ) : window.location.pathname === '/terms' ? (
          <TermsScreen />
        ) : window.location.pathname === '/law' ? (
          <LawScreen />
        ) : window.location.pathname === '/contact' ? (
          <ContactScreen />
        ) : (
          <GuidanceToAppScreen />
        )
      ) : userState.user ? (
        <>
          <Header />
          <Container
            sx={{
              padding: '112px 36px 36px !important',
              minHeight: 'calc(100vh - 22px)',
            }}>
            <Routes>
              <Route index element={<Home />} />
              {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
              <Route path="/signUpVerification" element={<></>} />
              <Route path="/admin/*" element={<AdminScreen />} />
              <Route path="/changeProfile" element={<ChangeProfileScreen />} />
              <Route
                path="/changePassword"
                element={<ChangePasswordScreen />}
              />
              <Route path="/expenses" element={<ExpensesScreen />} />
              <Route path="/files" element={<FilesScreen />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/plan" element={<PlanScreen />} />
              <Route path="/balance" element={<BalanceScreen />} />
              <Route
                path="/consultingInfo"
                element={<ConsultingInfoScreen />}
              />
              <Route path="/leave" element={<LeaveScreen />} />
              <Route path="/signIn" element={<SignInScreen />} />
              <Route path="/addProperty" element={<AddPropertyScreen />} />
              <Route
                path="/property/:propertyId"
                element={<PropertyManagementScreen />}
              />
              <Route
                path="/property/:propertyId/edit"
                element={<EditPropertyScreen />}
              />
              <Route
                path="/property/:propertyId/:roomId"
                element={<RoomDetailScreen />}
              />
              <Route
                path="/property/:propertyId/:roomId/edit"
                element={<EditRoomInfoScreen />}
              />
              <Route
                path="/property/:propertyId/addRoom"
                element={<AddRoomScreen />}
              />
              <Route
                path="/property/:propertyId/:roomId/addResident"
                element={<AddResidentScreen />}
              />
              <Route
                path="/property/:propertyId/:roomId/editResident"
                element={<EditResidentScreen />}
              />
              <Route
                path="/repaymentInfo/:roomId/:propertyId"
                element={<RepaymentInfoScreen />}
              />
              <Route
                path="/repaymentResult/:roomId/:propertyId"
                element={<RepaymentResultScreen />}
              />
              {DefaultScreens}
            </Routes>
          </Container>
          <Footer />
        </>
      ) : (
        <Routes>
          <Route index element={<SignInScreen />} />
          <Route path="/signIn" element={<SignInScreen />} />
          <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
          <Route path="/resetPassword" element={<ResetPasswordScreen />} />
          <Route
            path="/completeResetPassword"
            element={<CompleteResetPasswordScreen />}
          />
          <Route path="/signUp" element={<SignUpScreen />} />
          <Route
            path="/signUpVerification"
            element={<SignUpVerificationScreen />}
          />
          {DefaultScreens}
        </Routes>
      )}
    </BrowserRouter>
  );
};

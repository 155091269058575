import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const DownloadIcon = ({
  strokeColor,
  ...props
}: SvgIconProps & {
  strokeColor?: string;
}) => {
  const color = strokeColor ? strokeColor : '#181C32';
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.83334 14.667V16.3337C3.83334 16.7757 4.00894 17.1996 4.3215 17.5122C4.63406 17.8247 5.05798 18.0003 5.50001 18.0003H15.5C15.942 18.0003 16.366 17.8247 16.6785 17.5122C16.9911 17.1996 17.1667 16.7757 17.1667 16.3337V14.667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33334 9.66699L10.5 13.8337L14.6667 9.66699"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.83301V13.833"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const OutlinedCheckCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{
        width: '100px !important',
        height: '100px !important',
        display: 'flex !important',
        margin: 'auto',
      }}>
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="#31CDD3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12 16L14.6667 18.6667L20 13.3334"
        stroke="#31CDD3"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

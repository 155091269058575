import {Box, BoxProps} from '@mui/material';
import React, {FC} from 'react';

export const BoxForAuth: FC<BoxProps> = ({children}) => {
  return (
    <Box
      sx={{
        height: '100vh',
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#FFFFFF',
      }}>
      {children}
    </Box>
  );
};

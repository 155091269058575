import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {styled} from '@mui/system';
import React, {useCallback, useEffect, useState, VFC, useMemo} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {Button} from './Button';
import {Cell} from './Cell';
import {DatePicker} from './DatePicker';
import {IconButton} from './IconButton';
import {LabelTitle} from './LabelTitle';
import {Select} from './Select';
import {MAX_INPUT_LENGTH, TextField} from './TextField';
import {CustomItemInput} from '../API';
import {DeleteIcon} from '../images/DeleteIcon';
import {PlusIcon} from '../images/PlusIcon';
import {RoomBasicInfoType} from '../screens/AddRoom';
import {convertStringToRepaymentOrNull} from '../utils/convertStringToRepaymentOrNull';
import {
  conditionList,
  layoutList,
  repaymentMethodList,
} from '../utils/selectOptions';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

type Props = {
  basicInfo?: RoomBasicInfoType;
  onBasicInfoChange: (info: RoomBasicInfoType) => void;
  onErrorChange: (error: boolean) => void;
  isSubmitted: boolean;
};

export const RoomBasicInfo: VFC<Props> = ({
  basicInfo,
  onErrorChange,
  onBasicInfoChange,
  isSubmitted,
}) => {
  const {
    register,
    setValue,
    formState: {errors},
    handleSubmit,
    getValues,
    control,
  } = useForm<
    Omit<RoomBasicInfoType, 'customItems'> & {customItems: CustomItemInput[]}
  >({
    mode: 'onBlur',
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'customItems',
  });
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [repaymentDate, setRepaymentDate] = useState<Date | null>(null);

  const isNotValidDeliveryDate = useMemo(
    () => !deliveryDate || isNaN(deliveryDate.getDate()),
    [deliveryDate],
  );
  const isNotValidRepaymentDate = useMemo(
    () => !!repaymentDate && isNaN(repaymentDate.getDate()),
    [repaymentDate],
  );
  useEffect(() => {
    if (basicInfo) {
      setValue('name', basicInfo.name);
      setValue('layout', basicInfo.layout);
      setValue('salesCompany', basicInfo.salesCompany);
      setValue('sellingPrice', basicInfo.sellingPrice);
      setValue('occupiedArea', basicInfo.occupiedArea);
      setValue('bankName', basicInfo.bankName);
      setValue('loanAmount', basicInfo.loanAmount);
      setValue('loanYears', basicInfo.loanYears);
      setValue(
        'interestRate',
        basicInfo.interestRate ? basicInfo.interestRate : null,
      );
      setValue('repaymentMethod', basicInfo.repaymentMethod);
      const deliveryDate = new Date(basicInfo.deliveryDate);
      setDeliveryDate(deliveryDate);
      const repaymentDate = basicInfo.repaymentDate
        ? new Date(basicInfo.repaymentDate)
        : null;
      setRepaymentDate(repaymentDate);
      basicInfo.customItems &&
        setValue('customItems', [
          ...(basicInfo.customItems as CustomItemInput[]),
        ]);
    }
  }, [basicInfo, setValue]);
  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(
        () => {
          onErrorChange(isNotValidDeliveryDate);
          if (!!deliveryDate && !isNotValidDeliveryDate) {
            onBasicInfoChange({
              ...getValues(),
              repaymentMethod: convertStringToRepaymentOrNull(
                getValues('repaymentMethod') ?? '',
              ),
              deliveryDate: toISOStringWithTimezone(deliveryDate),
              repaymentDate:
                !isNotValidRepaymentDate && repaymentDate
                  ? toISOStringWithTimezone(repaymentDate)
                  : null,
            });
          }
        },
        () => onErrorChange(true),
      )();
    }
  }, [
    getValues,
    handleSubmit,
    isNotValidDeliveryDate,
    isSubmitted,
    onBasicInfoChange,
    onErrorChange,
    deliveryDate,
    repaymentDate,
    isNotValidRepaymentDate,
  ]);
  const onChangeDeliveryDate = useCallback((years: Date | null) => {
    setDeliveryDate(years);
  }, []);
  const onChangeRepaymentDate = useCallback((years: Date | null) => {
    setRepaymentDate(years);
  }, []);
  return (
    <>
      <Typography sx={{fontWeight: 500, mb: '8px'}}>基本情報</Typography>
      <Grid container spacing={0} direction="row">
        <Grid item xs={6}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>号室</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                {...register('name', {
                  required: true,
                  maxLength: MAX_INPUT_LENGTH,
                })}
                helperText={
                  errors.name?.type === 'required' && '号室を入力してください'
                }
                error={errors.name?.type === 'required'}
                size="small"
              />
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>間取り</LabelTitle>
            </Cell>
            <StyledCell>
              <FormControl fullWidth>
                <Select
                  defaultValue={basicInfo ? basicInfo.layout : ''}
                  displayEmpty
                  fullWidth
                  {...register('layout', {
                    required: true,
                  })}
                  error={errors.layout?.type === 'required'}>
                  <MenuItem value="">選択してください</MenuItem>
                  {layoutList.map((s, index) => (
                    <MenuItem value={s.value} key={index}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.layout?.type === 'required' && (
                  <FormHelperText sx={{color: 'warning.main'}}>
                    間取りを選択してください
                  </FormHelperText>
                )}
              </FormControl>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>部屋の状況</LabelTitle>
            </Cell>
            <StyledCell>
              <FormControl fullWidth>
                <Select
                  defaultValue={
                    basicInfo
                      ? conditionList.find(
                          list => list.value === basicInfo.condition,
                        )?.value
                      : ''
                  }
                  displayEmpty
                  {...register('condition', {
                    required: true,
                  })}
                  error={errors.condition?.type === 'required'}>
                  <MenuItem value="">選択してください</MenuItem>
                  {conditionList.map((s, index) => (
                    <MenuItem value={s.value} key={index}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.condition?.type === 'required' && (
                  <FormHelperText sx={{color: 'warning.main'}}>
                    部屋の状況を選択してください
                  </FormHelperText>
                )}
              </FormControl>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>引渡し日・決算日</LabelTitle>
            </Cell>
            <StyledCell>
              <DatePicker
                views={['year', 'month', 'day']}
                inputFormat={'yyyy/MM/dd'}
                value={deliveryDate}
                error={isNotValidDeliveryDate}
                onChangeValue={onChangeDeliveryDate}
                onErrorChange={onErrorChange}
              />
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>専有面積</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                {...register('occupiedArea', {
                  required: true,
                  maxLength: MAX_INPUT_LENGTH,
                })}
                helperText={
                  errors.occupiedArea?.type === 'required' &&
                  '専有面積を入力してください'
                }
                error={errors.occupiedArea?.type === 'required'}
                size="small"
              />
              <Typography sx={{marginLeft: '4px', flexShrink: 0}}>
                m²
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>土地面積合計</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                {...register('totalArea', {
                  maxLength: MAX_INPUT_LENGTH,
                })}
                size="small"
              />
              <Typography sx={{marginLeft: '4px', flexShrink: 0}}>
                m²
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>購入会社名</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                {...register('salesCompany', {
                  maxLength: MAX_INPUT_LENGTH,
                })}
                size="small"
              />
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>銀行名（ローン会社名）</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                {...register('bankName', {
                  maxLength: MAX_INPUT_LENGTH,
                })}
                size="small"
              />
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>融資年数</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                type="number"
                {...register('loanYears', {
                  required: true,
                  maxLength: MAX_INPUT_LENGTH,
                  max: 50,
                })}
                helperText={
                  (errors.loanYears?.type === 'required' &&
                    '融資年数を入力してください') ||
                  (errors.loanYears?.type === 'max' &&
                    '融資年数は最大50年までです')
                }
                error={
                  errors.loanYears?.type === 'required' ||
                  errors.loanYears?.type === 'max'
                }
                size="small"
              />
              <Typography sx={{marginLeft: '4px'}}>年</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>融資金額</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                type="number"
                {...register('loanAmount', {
                  required: true,
                  maxLength: MAX_INPUT_LENGTH,
                })}
                helperText={
                  errors.loanAmount?.type === 'required' &&
                  '融資金額を入力してください'
                }
                error={errors.loanAmount?.type === 'required'}
                size="small"
              />
              <Typography sx={{marginLeft: '4px'}}>円</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>金利</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                type="number"
                {...register('interestRate', {
                  maxLength: MAX_INPUT_LENGTH,
                })}
                size="small"
              />
              <Typography sx={{marginLeft: '4px'}}>％</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>返済方法</LabelTitle>
            </Cell>
            <StyledCell>
              <FormControl fullWidth>
                <Select
                  defaultValue={
                    basicInfo?.repaymentMethod ? basicInfo.repaymentMethod : ''
                  }
                  displayEmpty
                  {...register('repaymentMethod')}>
                  <MenuItem value="">選択する</MenuItem>
                  {repaymentMethodList.map((s, index) => (
                    <MenuItem value={s.value} key={index}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>返済開始日</LabelTitle>
            </Cell>
            <StyledCell>
              <DatePicker
                views={['year', 'month', 'day']}
                inputFormat={'yyyy/MM/dd'}
                value={repaymentDate}
                onChangeValue={onChangeRepaymentDate}
              />
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle isRequired>売買価格</LabelTitle>
            </Cell>
            <StyledCell>
              <TextField
                type="number"
                {...register('sellingPrice', {
                  required: true,
                  maxLength: MAX_INPUT_LENGTH,
                })}
                helperText={
                  errors.sellingPrice?.type === 'required' &&
                  '売買価格を入力してください'
                }
                error={errors.sellingPrice?.type === 'required'}
                size="small"
              />
              <Typography sx={{marginLeft: '4px'}}>円</Typography>
            </StyledCell>
          </Stack>
        </Grid>
        <Stack direction="column" sx={{width: '100%'}}>
          {fields.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={0}>
              <Cell color="primary-40">
                <TextField
                  {...register(`customItems.${index}.key`, {
                    required: true,
                    maxLength: MAX_INPUT_LENGTH,
                  })}
                  placeholder="項目名"
                  size="small"
                  helperText={
                    errors.customItems?.[index]?.key?.type === 'required' &&
                    '入力してください'
                  }
                  error={!!errors.customItems?.[index]?.key}
                />
              </Cell>
              <Cell sx={{width: '100%'}}>
                <TextField
                  {...register(`customItems.${index}.value`, {
                    required: true,
                  })}
                  placeholder="項目内容"
                  size="small"
                  multiline
                  fullWidth
                  helperText={
                    errors.customItems?.[index]?.value && '入力してください'
                  }
                  error={!!errors.customItems?.[index]?.value}
                />
                <IconButton tooltip="削除" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Cell>
            </Stack>
          ))}
          <Box sx={{margin: '16px 0 24px'}}>
            <Button
              size="small"
              startIcon={<PlusIcon strokeColor="#31CDD3" />}
              onClick={() => append({key: '', value: ''})}>
              項目を追加
            </Button>
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

const StyledCell = styled(Cell)({
  width: '100%',
  minHeight: '80px',
});

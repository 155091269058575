import {Paper, styled} from '@mui/material';

export const SoldLabel = styled(Paper)(() => ({
  backgroundColor: '#767C95',
  borderRadius: '6px',
  boxShadow: 'none',
  fontSize: '14px',
  fontWeight: '500',
  padding: '4px',
  textAlign: 'center',
  color: '#FFFFFF',
  lineHeight: 1.3,
}));

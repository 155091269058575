import {Visibility, VisibilityOff} from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  Modal,
  Link,
  Box,
  CircularProgress,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {BoxForAuth} from '../components/BoxForAuth';
import {Button} from '../components/Button';
import {Logo} from '../components/Logo';
import {TextFieldForAuth} from '../components/TextFieldForAuth';

type FormInput = {
  email: string;
  password: string;
};

export const SignInScreen = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>();
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<FormInput> = data => {
    setIsLoading(true);
    Auth.signIn({
      username: data.email,
      password: data.password,
    })
      .then(() => {
        navigate('/');
        setIsLoading(false);
      })
      .catch(err => {
        Sentry.captureException(err);
        setAuthError(true);
        setIsLoading(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const location = useLocation();
  const state = location.state as {leave: boolean};
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (state?.leave) {
      setOpen(true);
    }
  }, [state]);

  return (
    <>
      <BoxForAuth>
        <Box
          sx={{
            bgcolor: 'flikGray.-30',
            width: '480px',
            borderRadius: '12px',
            padding: '40px',
            boxSizing: 'border-box',
            mb: '24px',
          }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} alignItems="center" justifyContent="center">
              <Box mb={2}>
                <Logo />
              </Box>
              {authError ? (
                <Typography color="warning.main" fontSize="14px" pb="8px">
                  メールアドレス、またはパスワードが正しくありません。
                  <br />
                  再度正しく入力してください。
                </Typography>
              ) : null}
              <TextFieldForAuth
                error={!!errors.email}
                fullWidth
                label="メールアドレス"
                variant="outlined"
                type="email"
                {...register('email', {required: true})}
                helperText={
                  errors.email ? 'メールアドレスを入力してください' : ''
                }
              />
              <Stack sx={{width: '100%'}} spacing={1}>
                <TextFieldForAuth
                  fullWidth
                  error={!!errors.password}
                  label="パスワード"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password', {required: true})}
                  helperText={
                    errors.password ? 'パスワードを入力してください' : ''
                  }
                />
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                  <Link
                    underline="hover"
                    color="flikPrimary.main"
                    onClick={() => {
                      return navigate('/forgotPassword');
                    }}
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      cursor: 'pointer',
                    }}>
                    パスワードをお忘れですか？
                  </Link>
                </Box>
              </Stack>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                sx={{width: '100% !important'}}>
                {isLoading ? (
                  <CircularProgress
                    sx={{
                      color: 'white',
                      width: '20px  !important',
                      height: '20px  !important',
                    }}
                  />
                ) : (
                  'ログイン'
                )}
              </Button>
              <Link
                underline="hover"
                color="flikPrimary.main"
                onClick={() => {
                  return navigate('/signUp');
                }}
                sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer',
                }}>
                はじめてご利用の方（アカウント作成）
              </Link>
            </Stack>
          </form>
        </Box>
        <Link
          href="https://www.freescorp.com/prop-dock"
          underline="hover"
          color="flikGray.+15"
          target="_blank">
          Prop Dock について
        </Link>
      </BoxForAuth>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Stack
          alignItems={'center'}
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100wh',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
          <CheckCircleOutlineIcon
            sx={{color: 'flikPrimary.main', fontSize: '75px'}}
          />
          <Typography
            textAlign={'center'}
            sx={{fontSize: '20px', fontWeight: '600', mb: '24px'}}>
            退会処理が完了しました
          </Typography>
          <Typography
            textAlign={'center'}
            sx={{fontSize: '16px', fontWeight: '300', mb: '40px'}}>
            Prop Dockをご利用いただき、ありがとうございました。
            <br />
            退会完了のメールをお送りしましたので、ご確認ください。
            <br />
            またのご利用を、心よりお待ちしております。
          </Typography>
          <Button
            variant="contained"
            sx={{
              width: '270px !important',
            }}
            onClick={handleClose}>
            閉じる
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

import {Button as MuiButton, ButtonProps} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {VFC} from 'react';

export const Button: VFC<ButtonProps> = props => {
  // WA: ButtonBaseProps not accepting a component property
  // https://github.com/mui/material-ui/issues/15827
  return (
    <StyledButton
      component={props.type === 'submit' ? 'button' : 'span'}
      {...props}
    />
  );
};

const StyledButton = styled(MuiButton)(({theme}) => ({
  fontSize: '16px',
  fontWeight: '700',
  borderRadius: '6px',
  lineHeight: '1.3',

  '&.MuiButton-text': {
    padding: '8px !important',
    margin: '-8px',
    '&:hover': {
      backgroundColor: theme.palette.flikPrimary['-50'],
    },
  },
  '&.MuiButton-sizeMedium': {
    width: '180px',
    padding: '12px',
  },
  '&.MuiButton-sizeSmall': {
    padding: '6px 12px',
    fontWeight: '500',
    fontSize: '14px',
  },
  '&.MuiButton-contained': {
    color: 'white',
    boxShadow: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.flikPrimary['+10'],
    },
  },
  '&.MuiButton-containedWarning': {
    '&:hover': {
      backgroundColor: '#c71f1f',
    },
  },
})) as typeof MuiButton;

import {Box, Stack} from '@mui/material';
import React from 'react';
import {TypographyForTermsAndConditions as Typography} from '../components/TypographyForTermsAndConditions';
import {UlForTermsAndConditions as Ul} from '../components/UlForTermsAndConditions';
import {useIsMobile} from '../hooks/useIsMobile';

export const PrivacyScreen = () => {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      padding="40px 0">
      <Box
        width={isMobile ? '100%' : '900px'}
        padding={isMobile ? '0 20px' : '0'}>
        <Stack>
          <Typography variant="h1">プライバシーポリシー</Typography>
          <Typography variant="body1">
            フリー株式会社（以下、「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおり、プライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
          </Typography>
          <Typography variant="h2">
            第１条（収集する利用者情報及び収集方法について）
          </Typography>
          <Typography variant="body1">
            本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。収集した利用者情報は、第三者の設置するサーバーに転送され、目的の範囲内で使用されることとなります。
            <br />
            本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                ユーザーからご提供いただく情報
                <br />
                本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
                <br /> （１） 氏名、メールアドレス等サインインに関する情報
                <br /> （２）
                クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報
                <br />
                （３） ユーザーの肖像を含む静止画情報
                <br />
                （４）入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
                <br />
                ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
                <br />
                （１） 当該外部サービスでユーザーが利用する ID
                <br /> （２）
                その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                ユーザーが本サービスを利用するにあたって、当社が収集する情報
                <br />
                当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
                <br /> （１） リファラ
                <br /> （２） IP アドレス
                <br />
                （３） サーバーアクセスログに関する情報
                <br /> （４） Cookie、ADID、IDFA その他の識別子
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                ユーザーが本サービスを利用するにあたって、当社がユーザーの個別同意に基づいて収集する情報
                <br />
                当社は、ユーザーが第３条１に定める方法により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。
                <br />
                （１） 位置情報
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第２条（利用目的について）</Typography>
          <Typography variant="body1">
            本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
            <br />
            （１）
            本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
            <br />
            （２） ユーザーのトラフィック測定及び行動測定のため
            <br /> （３） 広告の配信、表示及び効果測定のため
            <br /> （４） 本サービスに関するご案内、お問い合わせ等への対応のため
            <br />
            （５）
            本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
            <br />
            （６） 本サービスに関する規約等の変更などを通知するため
            <br /> （７） 本サービスの開発及び改善のため
            <br /> （８） 本サービスに登録された不動産に関する情報の提供のため
            <br />
            （９）
            個人を特定できないようにデータ加工した上で、本サービスの利用に関するマーケティング資料を作成、利用、開示、提供するため
          </Typography>
          <Typography variant="h2">
            第３条（通知・公表または同意取得の方法、利用中止要請の方法について）
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。
                <br />
                （１）位置情報
                <br />
                ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">
            第４条（外部送信、第三者提供、情報収集モジュールの有無について）
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                本サービスでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。
                <br />
                （１）提携先 株式会社 cozmic
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第５条（第三者提供について）</Typography>
          <Typography variant="body1">
            当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
            <br />
            （１）
            当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
            <br />
            （２）
            合併その他の事由による事業の承継に伴って個人情報が提供される場合
            <br />
            （３） 第 4
            条の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
            <br />
            （４）
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
            <br />
            （５）
            その他、個人情報の保護に関する法律（以下、「個人情報保護法」といいます。）その他の法令で認められる場合{' '}
          </Typography>
          <Typography variant="h2">第６条（個人情報の開示）</Typography>
          <Typography variant="body1">
            当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。
          </Typography>
          <Typography variant="h2">
            第７条（個人情報の訂正及び利用停止等について）
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、第７条１および第７条２の規定は適用されません。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第８条（日本国外における使用）</Typography>
          <Typography variant="body1">
            日本国外において本アプリを使用する利用者の、本アプリに登録された利用者
            情報は、日本にあるサーバに転送され、日本国内において処理されます。
            <br />
            利用者は、利用者情報の取得、利用、提供等については日本の法令のみが適用されることを確認し、これに同意するものとします。
          </Typography>
          <Typography variant="h2">
            第９条（サービスの終了と情報の取扱い）
          </Typography>
          <Typography variant="body1">
            当社は、利用者が本アプリを削除（退会）した場合、利用者は本アプリ及び本サービスの利用を終了したものとみなします。
            当社は、前項の場合、利用者より取得した利用者情報を、本サービス上のサーバから適切な管理の下、定期的なメンテナンス時に廃棄します。
          </Typography>
          <Typography variant="h2">
            第１０条（お問い合わせ窓口について）
          </Typography>
          <Typography variant="body1">
            ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
          </Typography>
          <Stack direction="column" alignItems="center" mt="12px" mb="24px">
            <Typography variant="body1" mb="12px">
              記
            </Typography>
            <Typography variant="subtitle1">
              名称：フリー株式会社
              <br /> 住所：大阪市西区京町堀1-8-31 安田会館ビル 205
              <br />
              個人情報取扱責任者：北川 純也 <br />
              メールアドレス：contact@freescorp.com
            </Typography>
          </Stack>
          <Typography variant="h2">
            第１１条（プライバシーポリシーの変更手続について）
          </Typography>
          <Typography variant="body1">
            当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知、またはご登録メールアドレスへの送信によりユーザーに通知します。
          </Typography>
          <Typography variant="body1" alignSelf="end">
            以上
          </Typography>
          <Typography variant="body1">
            【２０２３年２月１１日制定】
            <br />
            【２０２４年９月１２日改訂】
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

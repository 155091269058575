import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const BuildingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      sx={{fill: 'none', ...props.sx}}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10 21H14M19 21V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V21H19ZM19 21H21H19ZM19 21H14H19ZM5 21H3H5ZM5 21H10H5ZM9 7H10H9ZM9 11H10H9ZM14 7H15H14ZM14 11H15H14ZM10 21V16C10 15.7348 10.1054 15.4804 10.2929 15.2929C10.4804 15.1054 10.7348 15 11 15H13C13.2652 15 13.5196 15.1054 13.7071 15.2929C13.8946 15.4804 14 15.7348 14 16V21H10Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};

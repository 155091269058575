import {SvgIconProps, SvgIcon} from '@material-ui/core';
import React from 'react';

export const ImageSquareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5 9.4C5 8.23305 5.46357 7.11389 6.28873 6.28873C7.11389 5.46357 8.23305 5 9.4 5H22.6C23.767 5 24.8861 5.46357 25.7113 6.28873C26.5364 7.11389 27 8.23305 27 9.4V22.6C27 23.767 26.5364 24.8861 25.7113 25.7113C24.8861 26.5364 23.767 27 22.6 27H9.4C8.23305 27 7.11389 26.5364 6.28873 25.7113C5.46357 24.8861 5 23.767 5 22.6V9.4Z"
        stroke="#31CDD3"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke="#31CDD3"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5918 16.7175L9 27H22.6496C23.8034 27 24.91 26.5533 25.7258 25.7583C26.5417 24.9632 27 23.8849 27 22.7605V22.6147C27 22.1038 26.8031 21.9076 26.4488 21.5293L21.915 16.711C21.7037 16.4863 21.4467 16.3071 21.1603 16.1847C20.874 16.0623 20.5646 15.9994 20.2519 16C19.9392 16.0006 19.6301 16.0648 19.3442 16.1883C19.0584 16.3118 18.8021 16.4921 18.5918 16.7175V16.7175Z"
        stroke="#31CDD3"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import {
  Stack,
  Grid,
  Typography,
  Modal,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {Dispatch, SetStateAction, useState, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ExpenseFormInput} from './CreateExpenseModal';
import {Snackbar} from './Snackbar';
import {Expenses} from '../API';
import {BaseBox as Box} from '../components/BaseBox';
import {Button} from '../components/Button';
import {DatePicker} from '../components/DatePicker';
import {TextField} from '../components/TextField';
import {useUpdateExpensesMutation} from '../queries/expenses';
import {categoryList} from '../screens/Expenses';
import {convertStringToExpensesCategory} from '../utils/convertStringToExpensesCategory';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export type PropsForEdit = {
  openEditModal: boolean;
  setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  expense: Expenses | null;
};
type FormInput = ExpenseFormInput & {id: string};

const StyledText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '20.8px',
}));

const StyledRequired = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '300',
  lineHeight: '18.2px',
  color: 'red',
}));

export const EditExpenseModal: React.VFC<PropsForEdit> = props => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: updateExpenses, isLoading} = useUpdateExpensesMutation();
  const [issueDate, setIssueDate] = useState<Date | null>(
    new Date(props.expense?.issueDate ?? ''),
  );
  const [isIssueDateError, setIsIssueDateError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'onBlur',
    defaultValues: {
      id: props.expense?.id ?? '',
      price: props.expense?.price,
      category: props.expense?.category,
      note: props.expense?.note ?? '',
      company: props.expense?.company ?? '',
    },
  });
  const handleClose = useCallback(() => {
    props.setOpenEditModal(false);
  }, [props]);
  const checkIssueDateError = useCallback(() => {
    !issueDate && setIsIssueDateError(true);
  }, [issueDate]);
  const onUpdate: SubmitHandler<FormInput> = useCallback(
    async data => {
      if (!props.expense) {
        navigate('/error');
        return;
      }
      checkIssueDateError();
      if (!issueDate || isIssueDateError) {
        setOpenSnackbar(true);
        return;
      }
      updateExpenses(
        {
          input: {
            id: props.expense.id,
            issueDate: toISOStringWithTimezone(issueDate),
            price: data?.price,
            category: convertStringToExpensesCategory(data?.category),
            note: data?.note,
            company: data?.company,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            handleClose();
          },
          onError: () => {
            navigate('/error');
            return;
          },
        },
      );
    },
    [
      checkIssueDateError,
      handleClose,
      isIssueDateError,
      issueDate,
      navigate,
      props.expense,
      queryClient,
      updateExpenses,
    ],
  );

  return (
    <>
      <Modal
        open={props.openEditModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            color: 'white',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '464px',
            bgcolor: 'background.paper',
            padding: '40px',
          }}>
          <Typography
            textAlign="center"
            mb="24px"
            sx={{fontSize: '20px', fontWeight: '500', LineHeight: '26px'}}>
            編集
          </Typography>
          <Grid container spacing={2.5} mb="40px">
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Stack direction="row" spacing={1.5}>
                  <StyledText>発生日</StyledText>
                  <StyledRequired>必須</StyledRequired>
                </Stack>
              </Grid>
              <Grid item>
                <DatePicker
                  value={issueDate}
                  error={isIssueDateError}
                  inputFormat={'yyyy/MM/dd'}
                  onChangeValue={setIssueDate}
                  onErrorChange={setIsIssueDateError}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Stack direction="row" spacing={1.5}>
                  <StyledText>金額</StyledText>
                  <StyledRequired>必須</StyledRequired>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <TextField
                    type="number"
                    size="small"
                    {...register('price', {required: true})}
                    helperText={
                      errors.price?.type === 'required' && '入力してください'
                    }
                    error={errors.price?.type === 'required'}
                  />
                  <Typography ml="4px">円</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Stack direction="row" spacing={1.5}>
                  <StyledText>利用先名称</StyledText>
                  <StyledRequired>必須</StyledRequired>
                </Stack>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  {...register('company', {required: true})}
                  helperText={
                    errors.company?.type === 'required' && '入力してください'
                  }
                  error={errors.company?.type === 'required'}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Stack direction="row" spacing={1.5}>
                  <StyledText>用途</StyledText>
                  <StyledRequired>必須</StyledRequired>
                </Stack>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Select
                    defaultValue={props.expense?.category}
                    displayEmpty
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 12px',
                      },
                    }}
                    {...register('category', {required: true})}
                    error={errors.category?.type === 'required'}>
                    <MenuItem value="">選択してください</MenuItem>
                    {categoryList.map((category, index) => (
                      <MenuItem value={category.value} key={index}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{color: 'warning.main'}}>
                    {errors.category?.type === 'required' && '選択してください'}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledText>メモ</StyledText>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  {...register('note', {required: false})}
                />
              </Grid>
            </Grid>
          </Grid>
          <Button variant="outlined" onClick={handleClose} sx={{mr: '20px'}}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={
              isLoading ? (
                <CircularProgress
                  sx={{
                    color: 'white',
                    width: '20px  !important',
                    height: '20px  !important',
                  }}
                />
              ) : null
            }
            onClick={handleSubmit(onUpdate, () => {
              setOpenSnackbar(true);
              checkIssueDateError();
            })}>
            {isLoading ? '保存中' : '保存する'}
          </Button>
        </Box>
      </Modal>
      <Snackbar
        severity="error"
        message="入力内容を確認してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Box,
  MenuItem,
  Typography,
  Menu,
  ListItemIcon,
  Divider,
  Stack,
} from '@mui/material';
import {styled} from '@mui/system';
import {Auth} from 'aws-amplify';
import React, {useCallback, useMemo, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {ConsultantMeetingModal} from './ConsultantMeetingModal';
import {PlanDescriptionModal} from './PlanDescriptionModal';
import {Snackbar} from './Snackbar';
import {Plan} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {AccountIcon} from '../images/AccountIcon';
import {ArrowRightIcon} from '../images/ArrowRightIcon';
import {HeaderLogo} from '../images/HeaderLogo';
import {PlusIcon} from '../images/PlusIcon';
import {useProperties} from '../queries/property';
import {useUser} from '../queries/user';
import {isNonNull} from '../utils/isNonNull';

const headers = [
  {
    label: '雑費管理',
    href: '/expenses',
  },
  {
    label: 'ファイル管理',
    href: '/files',
  },
  {
    label: '全体収支',
    href: '/balance',
  },
];

export const Header = () => {
  const userState = useOAuthUserState();
  const groups =
    userState.user?.signInUserSession.accessToken.payload['cognito:groups'];
  const isAdmin = groups ? groups.includes('Admins') : false;
  const sub = userState.user?.attributes.sub ?? '';
  const {data: user} = useUser(sub);
  const userName =
    user?.firstName && user?.lastName
      ? `${user?.lastName} ${user?.firstName}`
      : '';
  const {data: properties} = useProperties();
  const [isOpenMeetingSchedule, setIsOpenMeetingSchedule] = useState(false);
  const [isClickMeeting, setIsClickMeeting] = useState(false);
  const [isOpenPlanDescription, setIsOpenPlanDescription] = useState(false);
  const [anchorElPropertyMenu, setAnchorElPropertyMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElAccountMenu, setAnchorElAccountMenu] =
    useState<null | HTMLElement>(null);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const handleOpenPropertyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPropertyMenu(event.currentTarget);
  };
  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);
  };
  const handleClosePropertyMenu = () => setAnchorElPropertyMenu(null);
  const handleCloseAccountMenu = () => setAnchorElAccountMenu(null);
  const navigate = useNavigate();
  const handleClickMenu = useCallback(
    (pathname: string) => {
      handleCloseAccountMenu();
      navigate(pathname);
    },
    [navigate],
  );
  const isPro = useMemo(() => user?.plan === Plan.PRO, [user?.plan]);
  const handleOpenMeetingModal = useCallback(() => {
    setIsClickMeeting(true);
    isPro ? setIsOpenMeetingSchedule(true) : setIsOpenPlanDescription(true);
    handleCloseAccountMenu();
  }, [isPro]);
  const handleOpenConsultantSheetModal = useCallback(() => {
    !isPro && setIsOpenPlanDescription(true);
    navigate(`/consultingInfo`, {state: user});
  }, [isPro, navigate, user]);

  return (
    <>
      <AppBar
        sx={{
          background:
            'linear-gradient(90deg, #31CDD3 0%, #31CDD3 30.21%, #7FE1E1 72.4%, #86E3C2 100%)',
          position: 'fixed',
          boxShadow: 'unset',
        }}>
        <Toolbar sx={{padding: '0 0 0 36px !important'}}>
          <StyledHeaderLogo onClick={() => navigate('/')} />
          <StyledButton onClick={handleOpenPropertyMenu}>
            物件情報
            <ArrowRightIcon
              fontSize="small"
              sx={{
                color: 'transparent !important',
                stroke: 'white',
                marginLeft: '4px',
                transition: 'transform ease-in .2s !important',
                transform: anchorElPropertyMenu ? 'rotate(90deg)' : 'unset',
              }}
            />
          </StyledButton>
          <Menu
            open={Boolean(anchorElPropertyMenu)}
            onClose={handleClosePropertyMenu}
            anchorEl={anchorElPropertyMenu}
            PaperProps={{style: {width: '200px'}}}>
            {properties?.items.filter(isNonNull).map(item => (
              <MenuItem
                key={item.id}
                onClick={() => {
                  navigate(`/property/${item.id}`);
                  handleClosePropertyMenu();
                }}>
                <Typography noWrap>{item.name}</Typography>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              sx={{color: 'flikPrimary.main', justifyContent: 'center'}}
              onClick={() => {
                navigate('/addProperty');
                handleClosePropertyMenu();
              }}>
              <ListItemIcon>
                <PlusIcon fontSize="small" strokeColor="#26B9C0" />
              </ListItemIcon>
              物件を追加
            </MenuItem>
          </Menu>
          {headers.map(({label, href}) => {
            return (
              <StyledButton key={label} to={href} component={RouterLink}>
                {label}
              </StyledButton>
            );
          })}
          <StyledButton
            sx={{
              width: '100%',
              maxWidth: '250px',
            }}
            onClick={handleOpenConsultantSheetModal}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography
                color="#FFFFFF"
                sx={{fontWeight: 600, fontSize: '16px'}}>
                コンサルティングシート
              </Typography>
              <Box
                bgcolor={'flikPrimary.-50'}
                px="4px"
                py="2px"
                borderRadius="6px"
                fontSize="12px"
                fontWeight={600}
                color="flikPrimary.+10"
                lineHeight={1.3}>
                PRO
              </Box>
            </Stack>
          </StyledButton>

          <Box sx={{margin: '0 0 0 auto'}}>
            <StyledButton
              onClick={handleOpenAccountMenu}
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'unset',
              }}>
              <Avatar
                sx={{
                  width: '36px',
                  height: '36px',
                  marginRight: '8px',
                  backgroundColor: 'transparent',
                }}>
                <AccountIcon fontSize="large" />
              </Avatar>
              <Typography
                component="span"
                sx={{
                  textAlign: 'left',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '140px',
                  color: 'white',
                  fontWeight: 600,
                }}>
                {userName}
              </Typography>
            </StyledButton>
            <Menu
              open={Boolean(anchorElAccountMenu)}
              onClose={handleCloseAccountMenu}
              anchorEl={anchorElAccountMenu}
              PaperProps={{style: {width: 'fit-content'}}}>
              <MenuItem onClick={() => handleClickMenu('/profile')}>
                <Typography>設定</Typography>
              </MenuItem>
              <MenuItem onClick={handleOpenMeetingModal}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>コンサルティング面談</Typography>
                  <Box
                    bgcolor={'flikPrimary.-40'}
                    px="4px"
                    py="2px"
                    borderRadius="6px"
                    fontSize="12px"
                    fontWeight={600}
                    color="flikPrimary.+10"
                    lineHeight={1.3}>
                    PRO
                  </Box>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleClickMenu('/terms');
                }}>
                <Typography>利用規約</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleClickMenu('/privacy')}>
                <Typography>プライバシーポリシー</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleClickMenu('/law')}>
                <Typography>特定商取引法に基づく表記</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleClickMenu('/help')}>
                <Typography>ヘルプ</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleClickMenu('/contact')}>
                <Typography>お問い合わせ</Typography>
              </MenuItem>
              <Divider />
              {isAdmin ? (
                <MenuItem
                  onClick={() => {
                    handleClickMenu('/admin');
                  }}>
                  <Typography>管理者コンソール</Typography>
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={() => {
                  Auth.signOut();
                  navigate('/');
                }}>
                <Typography>ログアウト</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {isOpenPlanDescription ? (
        <PlanDescriptionModal
          open={isOpenPlanDescription}
          isForMeeting={isClickMeeting}
          onClose={() => {
            setIsOpenPlanDescription(false);
            setIsClickMeeting(false);
          }}
        />
      ) : null}
      {isOpenMeetingSchedule ? (
        <ConsultantMeetingModal
          open={isOpenMeetingSchedule}
          onClose={() => setIsOpenMeetingSchedule(false)}
          userName={userName}
          email={user?.email ?? ''}
          onSuccess={() => setOpenSuccessSnackbar(true)}
        />
      ) : null}
      <Snackbar
        message="コンサルティング面談を申し込みました"
        open={openSuccessSnackbar}
        onClose={() => setOpenSuccessSnackbar(false)}
      />
    </>
  );
};

const StyledHeaderLogo = styled(HeaderLogo)`
  margin-right: 36px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  width: 120px;
  min-width: 100px;
  height: 72px;
  color: ${props => props.theme.palette.common.white};
  font-weight: 600;
  font-size: 16px;
  border-radius: unset;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
` as typeof Button;

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {BoxForAuth} from '../components/BoxForAuth';
import {LabelTitle} from '../components/LabelTitle';
import {Logo} from '../components/Logo';
import {PasswordValidation} from '../components/PasswordValidation';
import {TextFieldForAuth} from '../components/TextFieldForAuth';
import {combineAlphanumericCharacters} from '../utils/regex';

type FormInput = {
  authcode: number;
  password: string;
};

export const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onSubmit: SubmitHandler<FormInput> = data => {
    const state = location.state as {email: string};
    Auth.forgotPasswordSubmit(
      state.email,
      data.authcode.toString(),
      data.password,
    )
      .then(_data => {
        navigate('/completeResetPassword');
      })
      .catch(err => {
        Sentry.captureException(err);
        setSubmitError(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BoxForAuth>
      <Logo />
      <Stack
        sx={{
          width: '756px',
          borderRadius: '12px',
          bgcolor: 'flikGray.-30',
          padding: '40px',
          mt: '24px',
        }}
        justifyContent="center"
        alignItems="center"
        spacing={3}>
        <Typography
          textAlign="center"
          sx={{
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: '700',
            LineHeight: '26px',
          }}>
          パスワードの変更
        </Typography>
        {submitError ? (
          <Typography
            color="warning.main"
            fontSize="16px"
            mb="24px"
            textAlign="center">
            入力された情報が誤っています。
            <br />
            もう一度、入力された情報をご確認ください。
          </Typography>
        ) : null}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{mb: '25.5px'}}>
          <LabelTitle isRequired>6桁の認証コード</LabelTitle>

          <TextField
            fullWidth
            size="small"
            variant="outlined"
            InputLabelProps={{shrink: false}}
            sx={{
              width: '408px',
              height: '45px',
              '& .MuiInputBase-root': {bgcolor: 'white'},
            }}
            error={!!errors.authcode}
            {...register('authcode', {
              required: true,
              pattern: /^(?=.*?\d)[\d]{6}$/,
            })}
            helperText={
              errors.authcode ? '6桁の認証コードを入力してください' : ''
            }></TextField>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: 'relative',
          }}>
          <LabelTitle isRequired>新しいパスワード</LabelTitle>
          <TextFieldForAuth
            error={!!errors.password}
            fullWidth
            aria-label="password"
            size="small"
            label="パスワード"
            variant="outlined"
            sx={{
              width: '408px',
              height: '45px',
              position: 'relative',
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', {
              required: true,
              minLength: 8,
              pattern: combineAlphanumericCharacters,
            })}
          />
          <Box sx={{position: 'absolute', top: '48px', left: '210px'}}>
            <PasswordValidation
              wordCountError={
                !!errors.password?.types?.required ||
                !!errors.password?.types?.minLength ||
                !dirtyFields.password
              }
              alphanumericError={
                !!errors.password?.types?.required ||
                !!errors.password?.types?.pattern ||
                !dirtyFields.password
              }
            />
          </Box>
        </Stack>

        <Button
          sx={{
            '&:hover': {
              bgcolor: 'flikPrimary.main',
            },
            width: '270px',
            height: '45px',
            borderRadius: 2,
            textAlign: 'center',
            p: 1,
            bgcolor: 'flikPrimary.main',
            color: 'white',
            fontWeight: '700',
            mt: '80px !important',
          }}
          onClick={handleSubmit(onSubmit)}>
          変更する
        </Button>
      </Stack>
    </BoxForAuth>
  );
};

import * as Sentry from '@sentry/react';
import {Storage} from 'aws-amplify';

const downloadBlob = (blob: Blob, fileName: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

export const downloadPdf = async (s3Key: string, encordFileName: string) => {
  try {
    const result = await Storage.get(s3Key, {
      level: 'private',
      download: true,
    });
    if (!result) return;
    downloadBlob(result.Body as Blob, decodeURIComponent(encordFileName));
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};

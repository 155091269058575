import {GraphQLResult} from '@aws-amplify/api-graphql';
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {styled} from '@mui/system';
import {API, graphqlOperation} from 'aws-amplify';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {ResidentFormInput} from './AddResident';
import {GetResidentQuery, MoneyInfoInput} from '../API';
import {AddItemButton} from '../components/AddItemButton';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {Cell} from '../components/Cell';
import {DatePicker} from '../components/DatePicker';
import {DeleteRuleButton} from '../components/DeleteRuleButton';
import {IconButton} from '../components/IconButton';
import {LabelTitle} from '../components/LabelTitle';
import {MoneyInfo} from '../components/MoneyInfo';
import {MoneyInfoTableHead} from '../components/MoneyInfoTableHead';
import {initialValue} from '../components/RoomCostInfo';
import {Select} from '../components/Select';
import {Snackbar} from '../components/Snackbar';
import {MAX_INPUT_LENGTH} from '../components/TextField';
import {getResident} from '../graphql/queries';
import {DeleteIcon} from '../images/DeleteIcon';
import {useProperty} from '../queries/property';
import {useUpdateResidentMutation} from '../queries/resident';
import {useRoom} from '../queries/room';
import {isNonNull} from '../utils/isNonNull';
import {frequencyList, monthList, yearsList} from '../utils/selectOptions';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export const EditResidentScreen = () => {
  const {propertyId, roomId} = useParams();
  const {data: room} = useRoom(roomId);
  const {data: property} = useProperty(propertyId);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: updateResident, isLoading} = useUpdateResidentMutation();
  const {
    register,
    getValues,
    setValue,
    formState: {errors},
    handleSubmit,
    control,
  } = useForm<ResidentFormInput>({
    mode: 'onBlur',
  });
  const {
    fields: keyMoneyFields,
    append: keyMoneyAppend,
    remove: keyMoneyRemove,
  } = useFieldArray({
    control,
    name: 'keyMoney',
  });
  const {
    fields: firstIncomeFields,
    append: firstIncomeAppend,
    remove: firstIncomeRemove,
  } = useFieldArray({
    control,
    name: 'firstIncome',
  });
  const {
    fields: rentFields,
    append: rentAppend,
    remove: rentRemove,
  } = useFieldArray({
    control,
    name: 'rent',
  });
  const {
    fields: customItemsFields,
    append: customItemsAppend,
    remove: customItemsRemove,
  } = useFieldArray({
    control,
    name: 'customItems',
  });
  const {
    fields: customMoveInCostItemsFields,
    append: customMoveInCostItemsAppend,
    remove: customMoveInCostItemsRemove,
    update: customMoveInCostItemsUpdate,
  } = useFieldArray({
    control,
    name: 'customMoveInCostItems',
  });
  const {
    fields: advertisingRatesFields,
    append: advertisingRatesAppend,
    remove: advertisingRatesRemove,
  } = useFieldArray({
    control,
    name: 'advertisingRates',
  });
  const {
    fields: customMoveOutCostItemsFields,
    append: customMoveOutCostItemsAppend,
    remove: customMoveOutCostItemsRemove,
    update: customMoveOutCostItemsUpdate,
  } = useFieldArray({
    control,
    name: 'customMoveOutCostItems',
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [isDateError, setIsDateError] = useState(false);
  const [initialResident, setInitialResident] = useState<
    GetResidentQuery['getResident'] | null
  >(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const residents = useMemo(() => {
    if (!room) return null;
    return room.residents?.items;
  }, [room]);
  const currentResidentId = useMemo(() => {
    if (!residents) return '';
    const id = residents.find(r => !r?.endDate)?.id;
    return id ?? '';
  }, [residents]);
  const formatCosts = useCallback((costs: Array<MoneyInfoInput | null>) => {
    return costs.map(c => {
      return {
        money: c?.money ?? 0,
        frequency: c?.frequency ?? 'everyMonth',
        year: c?.year ?? new Date().getFullYear().toString(),
        month: c?.month ?? '1',
      };
    });
  }, []);
  useEffect(() => {
    const fetchResidentData = async () => {
      const result = (await API.graphql(
        graphqlOperation(getResident, {id: currentResidentId}),
      )) as GraphQLResult<GetResidentQuery>;
      const currentResident = result.data?.getResident
        ? {...result.data?.getResident}
        : null;
      if (currentResident) {
        setInitialResident(currentResident);
        setValue('name', currentResident.name);
        const date = new Date(currentResident.startDate);
        setStartDate(date);
        setValue('keyMoney', currentResident.keyMoney);
        setValue('firstIncome', currentResident.firstIncome);
        setValue('rent', currentResident.rent);
        currentResident.customItems &&
          setValue(
            'customItems',
            currentResident.customItems.filter(isNonNull).map(item => {
              return {
                key: item.key,
                value: item.value,
              };
            }),
          );
        currentResident.customMoveInCostItems &&
          setValue(
            'customMoveInCostItems',
            currentResident.customMoveInCostItems
              .filter(isNonNull)
              .map(item => {
                return {
                  key: item.key,
                  value: item.value ? formatCosts(item.value) : [],
                };
              }),
          );
        currentResident.advertisingRates &&
          setValue('advertisingRates', currentResident.advertisingRates);
        currentResident.customMoveOutCostItems &&
          setValue(
            'customMoveOutCostItems',
            currentResident.customMoveOutCostItems
              .filter(isNonNull)
              .map(item => {
                return {
                  key: item.key,
                  value: item.value ? formatCosts(item.value) : [],
                };
              }),
          );
      }
    };
    if (currentResidentId) {
      fetchResidentData();
    }
  }, [currentResidentId, formatCosts, setValue]);
  const onSave: SubmitHandler<ResidentFormInput> = useCallback(
    async data => {
      if (!roomId || !currentResidentId) {
        navigate('/error');
        return;
      }
      if (!startDate || isDateError) {
        setOpenSnackbar(true);
        return;
      }
      updateResident(
        {
          input: {
            id: currentResidentId,
            name: getValues('name'),
            startDate: startDate ? toISOStringWithTimezone(startDate) : '',
            customItems: data.customItems,
            keyMoney: data.keyMoney,
            firstIncome: data.firstIncome,
            rent: data.rent,
            customMoveInCostItems: data.customMoveInCostItems,
            advertisingRates: data.advertisingRates,
            customMoveOutCostItems: data.customMoveOutCostItems,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            navigate(`/property/${propertyId}/${roomId}?tab=resident`, {
              state: {operationState: 'edit-success'},
            });
          },
          onError: () => {
            navigate('/error');
            return;
          },
        },
      );
    },
    [
      currentResidentId,
      getValues,
      isDateError,
      navigate,
      propertyId,
      queryClient,
      roomId,
      startDate,
      updateResident,
    ],
  );

  return initialResident ? (
    <>
      <Typography
        sx={{fontSize: '24px', margin: '0 0 24px 0', fontWeight: '700'}}>
        契約者情報を編集（{property?.name} - {room?.name}）
      </Typography>
      <BaseBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{fontWeight: 500, mb: '8px'}}>基本情報</Typography>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle isRequired>入居開始日</LabelTitle>
              </Cell>
              <StyledCell>
                <DatePicker
                  inputFormat={'yyyy/MM/dd'}
                  value={startDate}
                  error={!startDate || isDateError}
                  onChangeValue={setStartDate}
                  onErrorChange={setIsDateError}
                />
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>入居者氏名</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField {...register('name')} size="small" fullWidth />
              </StyledCell>
            </Stack>
            <Stack direction="column" sx={{width: '100%'}}>
              {customItemsFields.map((field, index) => (
                <Stack key={field.id} direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <TextField
                      {...register(`customItems.${index}.key`, {
                        required: true,
                        maxLength: MAX_INPUT_LENGTH,
                      })}
                      placeholder="項目名"
                      size="small"
                      helperText={
                        errors.customItems?.[index]?.key?.type === 'required' &&
                        '入力してください'
                      }
                      error={!!errors.customItems?.[index]?.key}
                    />
                  </Cell>
                  <Cell sx={{width: '100%'}}>
                    <TextField
                      {...register(`customItems.${index}.value`, {
                        required: true,
                      })}
                      placeholder="項目内容"
                      size="small"
                      multiline
                      fullWidth
                      helperText={
                        errors.customItems?.[index]?.value && '入力してください'
                      }
                      error={!!errors.customItems?.[index]?.value}
                    />
                    <IconButton
                      tooltip="削除"
                      onClick={() => customItemsRemove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Cell>
                </Stack>
              ))}
              <Box sx={{margin: '8px 0 24px'}}>
                <AddItemButton
                  onAdd={() => customItemsAppend({key: '', value: ''})}>
                  項目を追加
                </AddItemButton>
              </Box>
            </Stack>
            <Typography sx={{fontWeight: 500, mb: '8px'}}>収入</Typography>
            <MoneyInfoTableHead />
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>礼金</LabelTitle>
              </Cell>
              <StyledRepeatRuleCell>
                <Stack spacing={1} mb="8px">
                  {keyMoneyFields.map((field, index) => (
                    <MoneyInfo
                      key={field.id}
                      register={register}
                      name="keyMoney"
                      index={index}
                      defaultValue={field}
                      errors={
                        Array.isArray(errors.keyMoney)
                          ? errors.keyMoney
                          : undefined
                      }
                      onRemove={keyMoneyRemove}
                    />
                  ))}
                  <AddItemButton
                    onAdd={() =>
                      keyMoneyAppend({...initialValue, frequency: 'once'})
                    }>
                    繰り返しルールを追加
                  </AddItemButton>
                </Stack>
              </StyledRepeatRuleCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>初月賃料</LabelTitle>
              </Cell>
              <StyledRepeatRuleCell>
                <Stack spacing={1} mb="8px">
                  {firstIncomeFields.map((field, index) => (
                    <MoneyInfo
                      key={field.id}
                      register={register}
                      name="firstIncome"
                      index={index}
                      defaultValue={field}
                      errors={
                        Array.isArray(errors.firstIncome)
                          ? errors.firstIncome
                          : undefined
                      }
                      onRemove={firstIncomeRemove}
                    />
                  ))}
                  <AddItemButton
                    onAdd={() =>
                      firstIncomeAppend({...initialValue, frequency: 'once'})
                    }>
                    繰り返しルールを追加
                  </AddItemButton>
                </Stack>
              </StyledRepeatRuleCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>月額賃料</LabelTitle>
              </Cell>
              <StyledRepeatRuleCell>
                <Stack spacing={1} mb="8px">
                  {rentFields.map((field, index) => (
                    <MoneyInfo
                      key={field.id}
                      register={register}
                      name="rent"
                      index={index}
                      defaultValue={field}
                      errors={
                        Array.isArray(errors.rent) ? errors.rent : undefined
                      }
                      onRemove={rentRemove}
                    />
                  ))}
                  <AddItemButton onAdd={() => rentAppend({...initialValue})}>
                    繰り返しルールを追加
                  </AddItemButton>
                </Stack>
              </StyledRepeatRuleCell>
            </Stack>
            {customMoveInCostItemsFields.map((field, index) => (
              <Grid item xs={12} key={index}>
                <Stack key={field.id} direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <TextField
                      {...register(`customMoveInCostItems.${index}.key`, {
                        required: true,
                        maxLength: MAX_INPUT_LENGTH,
                      })}
                      size="small"
                      placeholder="項目名"
                      helperText={
                        Array.isArray(errors.customMoveInCostItems) &&
                        !!errors.customMoveInCostItems[index] &&
                        Object.keys(errors.customMoveInCostItems[index]).some(
                          key => key === 'key',
                        ) &&
                        errors.customMoveInCostItems[index].key?.type ===
                          'required' &&
                        '入力してください'
                      }
                      error={
                        Array.isArray(errors.customMoveInCostItems) &&
                        !!errors.customMoveInCostItems[index] &&
                        Object.keys(errors.customMoveInCostItems[index]).some(
                          key => key === 'key',
                        ) &&
                        errors.customMoveInCostItems[index].key?.type ===
                          'required'
                      }
                    />
                  </Cell>
                  <StyledRepeatRuleCell sx={{justifyContent: 'space-between'}}>
                    <Stack spacing={1} mb="8px">
                      {field.value &&
                        field.value.map((value, i) => (
                          <Stack
                            key={`${field.id}-${i}`}
                            direction="row"
                            spacing={3}
                            alignItems="center">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}>
                              <TextField
                                {...register(
                                  `customMoveInCostItems.${index}.value.${i}.money`,
                                  {
                                    required: true,
                                  },
                                )}
                                type="number"
                                sx={{width: '180px'}}
                                size="small"
                                // TODO: https://geekbox.atlassian.net/browse/FLIK-116
                                helperText={
                                  Array.isArray(errors.customMoveInCostItems) &&
                                  !!errors.customMoveInCostItems[index] &&
                                  Object.keys(
                                    errors.customMoveInCostItems[index],
                                  ).some(key => key === 'value') &&
                                  '入力してください'
                                }
                                error={
                                  Array.isArray(errors.customMoveInCostItems) &&
                                  !!errors.customMoveInCostItems[index] &&
                                  Object.keys(
                                    errors.customMoveInCostItems[index],
                                  ).some(key => key === 'value')
                                }
                              />
                              <Typography>円</Typography>
                            </Stack>
                            <Select
                              {...register(
                                `customMoveInCostItems.${index}.value.${i}.frequency`,
                              )}
                              defaultValue={value?.frequency}
                              sx={{width: '130px'}}>
                              {frequencyList.map((s, index) => (
                                <MenuItem value={s.value} key={index}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}>
                                <Select
                                  {...register(
                                    `customMoveInCostItems.${index}.value.${i}.year`,
                                  )}
                                  defaultValue={value?.year}
                                  sx={{width: '100px'}}>
                                  {yearsList.map((s, index) => (
                                    <MenuItem value={s.value} key={index}>
                                      {s.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography>年</Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}>
                                <Select
                                  {...register(
                                    `customMoveInCostItems.${index}.value.${i}.month`,
                                  )}
                                  defaultValue={value?.month}
                                  sx={{width: '100px'}}>
                                  {monthList.map((s, index) => (
                                    <MenuItem value={s.value} key={index}>
                                      {s.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography>月</Typography>
                              </Stack>
                            </Stack>
                            {i !== 0 && (
                              <DeleteRuleButton
                                onClick={() => {
                                  const items =
                                    getValues('customMoveInCostItems') ?? [];
                                  items[index]?.value?.splice(i, 1);
                                  customMoveInCostItemsUpdate(
                                    index,
                                    items[index],
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        ))}
                      <AddItemButton
                        onAdd={() => {
                          const items =
                            getValues('customMoveInCostItems') ?? [];
                          items[index]?.value?.push({
                            ...initialValue,
                          });
                          customMoveInCostItemsUpdate(index, items[index]);
                        }}>
                        繰り返しルールを追加
                      </AddItemButton>
                    </Stack>
                    <IconButton
                      tooltip="削除"
                      onClick={() => customMoveInCostItemsRemove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledRepeatRuleCell>
                </Stack>
              </Grid>
            ))}
            <Grid item>
              <Box sx={{margin: '8px 0 24px'}}>
                <AddItemButton
                  onAdd={() =>
                    customMoveInCostItemsAppend({
                      key: '',
                      value: [{...initialValue}],
                    })
                  }>
                  収入を追加
                </AddItemButton>
              </Box>
            </Grid>
            <Typography sx={{fontWeight: 500, mb: '8px'}}>費用</Typography>
            <MoneyInfoTableHead />
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>広告料</LabelTitle>
              </Cell>
              <StyledRepeatRuleCell>
                <Stack spacing={1} mb="8px">
                  {advertisingRatesFields.map((field, index) => (
                    <MoneyInfo
                      key={field.id}
                      register={register}
                      name="advertisingRates"
                      index={index}
                      defaultValue={field}
                      errors={
                        Array.isArray(errors.advertisingRates)
                          ? errors.advertisingRates
                          : undefined
                      }
                      onRemove={advertisingRatesRemove}
                    />
                  ))}
                  <AddItemButton
                    onAdd={() =>
                      advertisingRatesAppend({
                        ...initialValue,
                        frequency: 'once',
                      })
                    }>
                    繰り返しルールを追加
                  </AddItemButton>
                </Stack>
              </StyledRepeatRuleCell>
            </Stack>
            {customMoveOutCostItemsFields.map((field, index) => (
              <Grid item xs={12} key={index}>
                <Stack key={field.id} direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <TextField
                      {...register(`customMoveOutCostItems.${index}.key`, {
                        required: true,
                        maxLength: MAX_INPUT_LENGTH,
                      })}
                      size="small"
                      placeholder="項目名"
                      helperText={
                        Array.isArray(errors.customMoveOutCostItems) &&
                        !!errors.customMoveOutCostItems[index] &&
                        Object.keys(errors.customMoveOutCostItems[index]).some(
                          key => key === 'key',
                        ) &&
                        errors.customMoveOutCostItems[index].key?.type ===
                          'required' &&
                        '入力してください'
                      }
                      error={
                        Array.isArray(errors.customMoveOutCostItems) &&
                        !!errors.customMoveOutCostItems[index] &&
                        Object.keys(errors.customMoveOutCostItems[index]).some(
                          key => key === 'key',
                        ) &&
                        errors.customMoveOutCostItems[index].key?.type ===
                          'required'
                      }
                    />
                  </Cell>
                  <StyledRepeatRuleCell sx={{justifyContent: 'space-between'}}>
                    <Stack spacing={1} mb="8px">
                      {field.value &&
                        field.value.map((value, i) => (
                          <Stack
                            key={`${field.id}-${i}`}
                            direction="row"
                            spacing={3}
                            alignItems="center">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}>
                              <TextField
                                {...register(
                                  `customMoveOutCostItems.${index}.value.${i}.money`,
                                  {
                                    required: true,
                                  },
                                )}
                                type="number"
                                sx={{width: '180px'}}
                                size="small"
                                // TODO: https://geekbox.atlassian.net/browse/FLIK-116
                                helperText={
                                  Array.isArray(
                                    errors.customMoveOutCostItems,
                                  ) &&
                                  !!errors.customMoveOutCostItems[index] &&
                                  Object.keys(
                                    errors.customMoveOutCostItems[index],
                                  ).some(key => key === 'value') &&
                                  '入力してください'
                                }
                                error={
                                  Array.isArray(
                                    errors.customMoveOutCostItems,
                                  ) &&
                                  !!errors.customMoveOutCostItems[index] &&
                                  Object.keys(
                                    errors.customMoveOutCostItems[index],
                                  ).some(key => key === 'value')
                                }
                              />
                              <Typography>円</Typography>
                            </Stack>
                            <Select
                              {...register(
                                `customMoveOutCostItems.${index}.value.${i}.frequency`,
                              )}
                              defaultValue={value?.frequency}
                              sx={{width: '130px'}}>
                              {frequencyList.map((s, index) => (
                                <MenuItem value={s.value} key={index}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}>
                                <Select
                                  {...register(
                                    `customMoveOutCostItems.${index}.value.${i}.year`,
                                  )}
                                  defaultValue={value?.year}
                                  sx={{width: '100px'}}>
                                  {yearsList.map((s, index) => (
                                    <MenuItem value={s.value} key={index}>
                                      {s.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography>年</Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}>
                                <Select
                                  {...register(
                                    `customMoveOutCostItems.${index}.value.${i}.month`,
                                  )}
                                  defaultValue={value?.month}
                                  sx={{width: '100px'}}>
                                  {monthList.map((s, index) => (
                                    <MenuItem value={s.value} key={index}>
                                      {s.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography>月</Typography>
                              </Stack>
                            </Stack>
                            {i !== 0 && (
                              <DeleteRuleButton
                                onClick={() => {
                                  const items =
                                    getValues('customMoveOutCostItems') ?? [];
                                  items[index]?.value?.splice(i, 1);
                                  customMoveOutCostItemsUpdate(
                                    index,
                                    items[index],
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        ))}
                      <AddItemButton
                        onAdd={() => {
                          const items =
                            getValues('customMoveOutCostItems') ?? [];
                          items[index]?.value?.push({
                            ...initialValue,
                          });
                          customMoveOutCostItemsUpdate(index, items[index]);
                        }}>
                        繰り返しルールを追加
                      </AddItemButton>
                    </Stack>
                    <IconButton
                      tooltip="削除"
                      onClick={() => customMoveOutCostItemsRemove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledRepeatRuleCell>
                </Stack>
              </Grid>
            ))}
            <Grid item>
              <Box sx={{margin: '8px 0 24px'}}>
                <AddItemButton
                  onAdd={() =>
                    customMoveOutCostItemsAppend({
                      key: '',
                      value: [{...initialValue}],
                    })
                  }>
                  費用を追加
                </AddItemButton>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button variant="outlined" onClick={() => navigate(-1)}>
                キャンセル
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        width: '20px  !important',
                        height: '20px  !important',
                      }}
                    />
                  ) : null
                }
                onClick={handleSubmit(onSave, () => setOpenSnackbar(true))}>
                {isLoading ? '保存中' : '保存する'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </BaseBox>
      <Snackbar
        severity="error"
        message="入力内容を確認してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : null;
};

const StyledCell = styled(Cell)({
  width: '450px',
});

const StyledRepeatRuleCell = styled(Cell)({
  width: '100%',
});

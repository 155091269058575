import {Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {BoxForAuth} from '../components/BoxForAuth';
import {Button} from '../components/Button';
import {Logo} from '../components/Logo';

export const CompleteResetPasswordScreen = () => {
  const navigate = useNavigate();
  return (
    <BoxForAuth>
      <Stack spacing={5}>
        <Logo />
        <Stack spacing={3} alignItems="center">
          <Typography
            textAlign="center"
            sx={{
              fontStyle: 'normal',
              fontSize: '20px',
              fontWeight: '700',
              LineHeight: '26px',
            }}>
            パスワードの再設定が完了しました
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              return navigate('/signIn');
            }}>
            ログイン画面
          </Button>
        </Stack>
      </Stack>
    </BoxForAuth>
  );
};

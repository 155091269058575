import {Typography} from '@mui/material';
import React from 'react';

export const Footer = () => {
  return (
    <Typography
      sx={{
        textAlign: 'center',
        color: 'flikGray.main',
        fontSize: '10px',
        marginBottom: '8px',
      }}>
      Copyright 2023 FREE Inc. All Rights Reserved.
    </Typography>
  );
};

import {Stack, Typography, Grid, styled} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {useOAuthUserState} from '../AuthProvider';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {LabelTitle} from '../components/LabelTitle';
import {TextField} from '../components/TextField';
import {useUpdateUserMutation, useUser} from '../queries/user';

export const ChangeProfileScreen = () => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data} = useUser(sub);
  const queryClient = useQueryClient();
  const {mutate: updateUser} = useUpdateUserMutation();
  const [firstName, setFirstName] = useState(data?.firstName ?? '');
  const [lastName, setLastName] = useState(data?.lastName ?? '');

  const onSubmit = useCallback(async () => {
    updateUser(
      {
        input: {
          id: sub,
          firstName: firstName,
          lastName: lastName,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          navigate('/profile');
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [firstName, lastName, navigate, queryClient, sub, updateUser]);

  return (
    <Stack margin="auto" spacing={3} maxWidth={900}>
      <Title>アカウント編集</Title>
      <BaseBox>
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{marginBottom: '20px'}}>
          <Grid item xs={3}>
            <LabelTitle isRequired>名前</LabelTitle>
          </Grid>
          <Grid item container spacing={1} xs={9} direction="row">
            <Grid item xs={6}>
              <TextField
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Stack
          justifyContent="end"
          direction="row"
          spacing={3}
          sx={{mt: '24px'}}>
          <Button variant="outlined" onClick={() => navigate('/profile')}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            変更する
          </Button>
        </Stack>
      </BaseBox>
    </Stack>
  );
};

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
` as typeof Typography;

import {Modal, Stack, styled, Typography} from '@mui/material';
import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {BaseBox as Box} from './BaseBox';
import {Button} from './Button';
import {Cell} from './Cell';
import {DatePicker} from './DatePicker';
import {LabelTitle} from './LabelTitle';
import {useUpdateRoomMutation} from '../queries/room';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export type PropsForSaleRoom = {
  openSaleRoomModal: boolean;
  setOpenSaleRoomModal: Dispatch<SetStateAction<boolean>>;
  roomId: string | undefined;
  roomName: string;
  canSell: boolean;
  setOpenErrorSaleModal: Dispatch<SetStateAction<boolean>>;
};

export const SaleRoomModal: React.VFC<PropsForSaleRoom> = ({
  openSaleRoomModal,
  setOpenSaleRoomModal,
  roomId,
  roomName,
  canSell,
  setOpenErrorSaleModal,
}: PropsForSaleRoom) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: updateRoom} = useUpdateRoomMutation();
  const [soldDate, setSoldDate] = useState<Date | null>(null);
  const [isDateError, setIsDateError] = useState(false);
  const onSold = useCallback(async () => {
    if (!canSell) {
      setOpenErrorSaleModal(true);
      setOpenSaleRoomModal(false);
      return;
    }
    if (!roomId || !soldDate) {
      navigate('/error');
      return;
    }
    updateRoom(
      {
        input: {
          id: roomId,
          soldDate: toISOStringWithTimezone(soldDate),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          setOpenSaleRoomModal(false);
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [
    canSell,
    roomId,
    soldDate,
    updateRoom,
    setOpenErrorSaleModal,
    setOpenSaleRoomModal,
    navigate,
    queryClient,
  ]);
  const handleClose = useCallback(() => {
    setOpenSaleRoomModal(false);
  }, [setOpenSaleRoomModal]);
  return (
    <Modal
      open={openSaleRoomModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '580px',
          padding: '40px',
          boxShadow: 'none',
        }}>
        <Typography
          textAlign="center"
          mb="24px"
          sx={{fontSize: '20px', fontWeight: '500', LineHeight: '26px'}}>
          売却
        </Typography>
        <Typography
          sx={{fontSize: '16px', fontWeight: '300', LineHeight: '24px'}}>
          <b>部屋番号{roomName}</b>
          を売却済に変更します。変更すると、部屋情報と入居者情報が編集できなくなります。
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '300',
            LineHeight: '24px',
            color: '#DA0000',
          }}>
          ※本操作は実行すると取り消すことができません。売却済に変更する対象を確認の上、操作を行ってください。
        </Typography>
        <Stack direction="row" spacing={0} mt="24px" mb="40px">
          <Cell color="primary-40">
            <LabelTitle isRequired>売却日</LabelTitle>
          </Cell>
          <StyledCell>
            <DatePicker
              inputFormat={'yyyy/MM/dd'}
              value={soldDate}
              error={isDateError}
              onChangeValue={setSoldDate}
              onErrorChange={setIsDateError}
            />
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={3} justifyContent="center">
          <Button variant="outlined" onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={onSold}
            disabled={!soldDate || isDateError}>
            売却する
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const StyledCell = styled(Cell)({
  width: '320px',
});

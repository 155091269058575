import {Repayment, StructureType} from '../API';

export const monthList = [
  {value: '1', name: '1'},
  {value: '2', name: '2'},
  {value: '3', name: '3'},
  {value: '4', name: '4'},
  {value: '5', name: '5'},
  {value: '6', name: '6'},
  {value: '7', name: '7'},
  {value: '8', name: '8'},
  {value: '9', name: '9'},
  {value: '10', name: '10'},
  {value: '11', name: '11'},
  {value: '12', name: '12'},
];

const generateYearsList = () => {
  return [...Array(150)].map((_, index) => {
    return {
      value: 1950 + index,
      name: 1950 + index,
    };
  });
};

export const yearsList = generateYearsList();

export const structureList = [
  {value: StructureType.W, name: '木造（W造）'},
  {value: StructureType.LIGHTS, name: '軽量鉄骨造（S造）'},
  {value: StructureType.WEIGHTS, name: '重量鉄骨造（S造）'},
  {value: StructureType.RC, name: '鉄筋コンクリート造（RC造）'},
  {
    value: StructureType.SRC,
    name: '鉄骨鉄筋コンクリート造（SRC造）',
  },
  {value: StructureType.AL, name: 'アルミ造（AL造）'},
  {
    value: StructureType.CFT,
    name: 'コンクリート充填鋼管構造（CFT造） ',
  },
  {
    value: StructureType.CB,
    name: 'コンクリートブロック造（CB造）',
  },
];

export const conditionList = [
  {value: 'vacancy', name: '空室'},
  {value: 'debtCollection', name: '集金代行'},
  {value: 'sublease', name: 'サブリース'},
  {value: 'directly', name: '自主管理'},
  {value: 'others', name: 'その他'},
];

export const layoutList = [
  {value: '1R', name: '1R'},
  {value: '1K', name: '1K'},
  {value: '1DK', name: '1DK'},
  {value: '1LDK', name: '1LDK'},
  {value: '2K', name: '2K'},
  {value: '2DK', name: '2DK'},
  {value: '2LDK', name: '2LDK'},
  {value: '3K', name: '3K'},
  {value: '3DK', name: '3DK'},
  {value: '3LDK', name: '3LDK'},
  {value: '4K', name: '4K'},
  {value: '4DK', name: '4DK'},
  {value: '4LDK', name: '4LDK'},
];

export const frequencyList = [
  {value: 'once', name: '1回'},
  {value: 'everyMonth', name: '毎月'},
];

export const repaymentMethodList = [
  {value: Repayment.EQUAL_PRINCIPAL, name: '元金均等'},
  {value: Repayment.EQUAL_INSTALLMENT, name: '元利均等'},
];

export const earlyRepaymentMethodList = [
  {value: 'repaymentAmountReduction', name: '返済額軽減'},
  {value: 'termReduction', name: '期間短縮'},
];

const generateLoanYearsList = () => {
  return [...Array(600)].map((_, index) => {
    return {
      value: index + 1,
      name: index + 1,
    };
  });
};

export const loanYearsList = generateLoanYearsList();

export const consultationList = [
  {key: '1', value: '売却したい、または売却できなくて困っている'},
  {key: '2', value: '収支改善したい'},
  {key: '3', value: '収支の良いものを購入したい'},
  {key: '4', value: '情報交換したい'},
  {key: '5', value: '中長期のアドバイスが聞きたい'},
  {key: '6', value: 'その他'},
];

export const MeetingTimeTable = [
  {key: '1', value: '10:00'},
  {key: '2', value: '11:00'},
  {key: '3', value: '12:00'},
  {key: '4', value: '13:00'},
  {key: '5', value: '14:00'},
  {key: '6', value: '15:00'},
  {key: '7', value: '16:00'},
  {key: '8', value: '17:00'},
  {key: '9', value: '18:00'},
  {key: '10', value: '19:00'},
  {key: '11', value: '20:00'},
];

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import axios from 'axios';
import {addDays} from 'date-fns';
import {useCallback, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {Button} from './Button';
import {DatePicker} from './DatePicker';
import {Snackbar} from './Snackbar';
import {TextField} from './TextField';
import {BaseBox as Box} from '../components/BaseBox';
import {MeetingTimeTable, consultationList} from '../utils/selectOptions';

type Props = {
  open: true;
  onClose: () => void;
  userName: string;
  email: string;
  onSuccess: () => void;
};

type FormInput = {meetingTime: string; consultantDetail: string; memo: string};

export const ConsultantMeetingModal = ({
  open,
  onClose,
  userName,
  email,
  onSuccess,
}: Props) => {
  const navigate = useNavigate();
  const twoDaysLater = addDays(new Date(), 2);
  const [date, setDate] = useState<Date | null>(twoDaysLater);
  const [isDateError, setIsDateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormInput>({
    mode: 'onBlur',
  });
  const shouldDisableDate = (date: Date) => {
    return date.getDay() === 3;
  };
  const watchConsultantDetail = watch('consultantDetail', '');
  const checkIssueDateError = useCallback(() => {
    !date && setIsDateError(true);
  }, [date]);
  const stringProcess = (input: string) => {
    //入力内容の最初と最後に改行がある場合に改行（\n）を削除
    return input ? input.replace(/^\n+|\n+$/g, '') : '';
  };
  const onSubmit: SubmitHandler<FormInput> = useCallback(
    data => {
      setIsLoading(true);
      checkIssueDateError();
      if (!date || isDateError) {
        setOpenSnackbar(true);
        setIsLoading(false);
        return;
      }
      const processedString = stringProcess(data.memo);
      axios
        .post('https://api.freescorp.com/contact', {
          ToAddresses: 'contact@freescorp.com',
          Subject: 'コンサルティング面談のお問い合わせがありました',
          Message: `面談希望者：${userName}\nメールアドレス：${email}\n面談日時：${date.getFullYear()}年${
            date.getMonth() + 1
          }月${date.getDate()}日 ${data.meetingTime}〜\n面談内容：${
            data.consultantDetail
          }\n${processedString}`,
        })
        .then(() => {
          setIsLoading(false);
          onSuccess();
          onClose();
          navigate('/');
        })
        .catch(() => {
          navigate('/error');
          return;
        });
    },
    [
      checkIssueDateError,
      date,
      email,
      isDateError,
      navigate,
      onClose,
      onSuccess,
      userName,
    ],
  );
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '464px',
            boxShadow: 'none',
            padding: '40px',
          }}>
          <Stack direction="column" spacing={3}>
            <Typography textAlign="center" fontSize="20px" fontWeight={500}>
              コンサルティング面談
            </Typography>
            <Typography>
              ご希望の日時を設定してください。担当者が確認後、折り返しご連絡させていただきます。
            </Typography>
            <Stack direction="row" spacing={2.5}>
              <Stack direction="column" width="50%" spacing={1}>
                <Stack direction="row" spacing={1.5}>
                  <StyledText>日付</StyledText>
                  <StyledRequired>必須</StyledRequired>
                </Stack>
                <FormControl fullWidth>
                  <DatePicker
                    value={date}
                    error={isDateError}
                    inputFormat={'yyyy/MM/dd'}
                    onChangeValue={setDate}
                    onErrorChange={setIsDateError}
                    minDate={twoDaysLater}
                    disableDate={shouldDisableDate}
                  />
                </FormControl>
              </Stack>
              <Stack direction="column" width="50%" spacing={1}>
                <Stack direction="row" spacing={1.5}>
                  <StyledText>時間</StyledText>
                  <StyledRequired>必須</StyledRequired>
                </Stack>
                <FormControl fullWidth>
                  <Select
                    defaultValue=""
                    displayEmpty
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 12px',
                      },
                    }}
                    {...register('meetingTime', {required: true})}
                    error={errors.meetingTime?.type === 'required'}>
                    <MenuItem value="">選択する</MenuItem>
                    {MeetingTimeTable.map((time, index) => (
                      <MenuItem value={time.value} key={index}>
                        {time.value}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    sx={{color: 'warning.main', marginBottom: '-20px'}}>
                    {errors.meetingTime?.type === 'required' &&
                      '選択してください'}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1.5}>
                <StyledText>ご相談内容</StyledText>
                <StyledRequired>必須</StyledRequired>
              </Stack>
              <FormControl fullWidth>
                <Select
                  defaultValue=""
                  displayEmpty
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      padding: '8px 12px',
                    },
                  }}
                  {...register('consultantDetail', {required: true})}
                  error={errors.consultantDetail?.type === 'required'}>
                  <MenuItem value="">選択する</MenuItem>
                  {consultationList.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  sx={{color: 'warning.main', marginBottom: '-20px'}}>
                  {errors.consultantDetail?.type === 'required' &&
                    '選択してください'}
                </FormHelperText>
              </FormControl>
            </Stack>
            {watchConsultantDetail === 'その他' ? (
              <Stack direction="column" spacing={1}>
                <StyledText>その他の詳細</StyledText>
                <TextField
                  {...register('memo')}
                  size="small"
                  multiline
                  minRows={4}
                />
              </Stack>
            ) : null}
            <Stack direction="row" spacing={3} pt={2}>
              <Button variant="outlined" onClick={onClose}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        width: '20px  !important',
                        height: '20px  !important',
                      }}
                    />
                  ) : null
                }
                onClick={handleSubmit(onSubmit, () => setOpenSnackbar(true))}>
                申し込む
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Snackbar
        severity="error"
        message="入力内容を確認してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

const StyledText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: 1.3,
}));
const StyledRequired = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '18.2px',
  color: 'red',
}));

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Grid,
  Typography,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
  SelectChangeEvent,
} from '@mui/material';
import {styled} from '@mui/system';
import * as Sentry from '@sentry/react';
import {Storage} from 'aws-amplify';
import React, {useCallback, useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {PropertyFile, PropertyFileCategory} from '../API';
import {BaseBox as Box} from '../components/BaseBox';
import {Button} from '../components/Button';
import {DeleteFileModal} from '../components/DeleteFileModal';
import {IconButton} from '../components/IconButton';
import {UploadFileModal} from '../components/UploadFileModal';
import {ArrowBackIcon} from '../images/ArrowBackIcon';
import {ArrowForwardIcon} from '../images/ArrowForwardIcon';
import {DeleteIcon} from '../images/DeleteIcon';
import {DownloadIcon} from '../images/DownloadIcon';
import {UploadIcon} from '../images/UploadIcon';
import {
  useDeletePropertyFileMutation,
  usePropertyFiles,
} from '../queries/propertyFile';
import {convertDateToString} from '../utils/convertDateToString';
import {convertPropertyFileCategoryToString} from '../utils/convertPropertyFileCategoryToString';
import {isNonNull} from '../utils/isNonNull';

const ROWS_PER_PAGE = 10;

export const categoryList = [
  {value: PropertyFileCategory.SALES, name: '売買契約'},
  {value: PropertyFileCategory.LEASING, name: '賃貸管理'},
  {value: PropertyFileCategory.LOAN, name: '銀行・ローン'},
  {value: PropertyFileCategory.REPAIR, name: 'リフォーム'},
  {value: PropertyFileCategory.INSURANCE, name: '損害保険'},
  {value: PropertyFileCategory.TAX, name: '確定申告・税務'},
  {value: PropertyFileCategory.OTHER, name: 'その他'},
];

const styledPaper = styled(Paper)(() => ({
  boxShadow: 'unset',
}));

export const FilesScreen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: deletePropertyFile, isLoading} =
    useDeletePropertyFileMutation();
  const {data} = usePropertyFiles();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDeleteFileModal, setOpenDeleteFileModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState<string>();
  const [selectProperty, setSelectProperty] = useState<string>();
  const [deleteFile, setDeleteFile] = useState<PropertyFile>();
  const [filteredPropertyFiles, setFilteredPropertyFiles] = useState<
    PropertyFile[]
  >([]);
  const [displayPropertyFiles, setDisplayPropertyFiles] = useState<
    PropertyFile[]
  >([]);
  const [propertyNameList, setPropertyNameList] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number>();

  useEffect(() => {
    if (!data) return;
    const properties = data.items.filter(isNonNull).map(file => {
      return file.propertyName;
    });
    setPropertyNameList(
      Array.from(new Set(properties.filter(item => item !== ''))),
    );
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const propertyFiles = data.items.filter(isNonNull);
    if (selectCategory && selectProperty) {
      return setFilteredPropertyFiles(
        propertyFiles
          .filter(file => {
            return (
              convertPropertyFileCategoryToString(file.category) ===
              selectCategory
            );
          })
          .filter(file => {
            return file.propertyName === selectProperty;
          }),
      );
    }
    if (selectCategory) {
      return setFilteredPropertyFiles(
        propertyFiles.filter(file => {
          return (
            convertPropertyFileCategoryToString(file.category) ===
            selectCategory
          );
        }),
      );
    }
    if (selectProperty) {
      return setFilteredPropertyFiles(
        propertyFiles.filter(file => {
          return file.propertyName === selectProperty;
        }),
      );
    }
    return setFilteredPropertyFiles(propertyFiles);
  }, [selectCategory, selectProperty, data]);

  useEffect(() => {
    setPageCount(Math.ceil(filteredPropertyFiles.length / ROWS_PER_PAGE));
    setDisplayPropertyFiles(
      filteredPropertyFiles.slice(
        (page - 1) * ROWS_PER_PAGE,
        page * ROWS_PER_PAGE,
      ),
    );
  }, [filteredPropertyFiles, page]);

  const onFileDelete = useCallback(
    async (encodedFileName: string) => {
      await Storage.remove('documents/' + encodedFileName, {
        level: 'private',
      }).catch(err => {
        Sentry.captureException(err);
        navigate('/error');
        return;
      });
      deletePropertyFile(
        {
          input: {
            id: encodedFileName,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
          },
          onError: () => {
            navigate('/error');
            return;
          },
        },
      );
    },
    [deletePropertyFile, navigate, queryClient],
  );

  function downloadBlob(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  const onFileDownload = useCallback(async (encodedFileName: string) => {
    const result = (await Storage.get('documents/' + encodedFileName, {
      level: 'private',
      download: true,
    }).catch(err => {
      Sentry.captureException(err);
      return;
      // https://docs.amplify.aws/gen1/react/prev/build-a-backend/storage/download/
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as any;
    if (!result) return;
    downloadBlob(result.Body as Blob, decodeURIComponent(encodedFileName));
  }, []);

  const handleUploadFile = () => {
    setOpenUploadModal(true);
  };

  const handleDeleteFile = (deleteFile: PropertyFile) => {
    setDeleteFile(deleteFile);
    setOpenDeleteFileModal(true);
  };

  const handleSelectCategory = (event: SelectChangeEvent) => {
    const value = convertPropertyFileCategoryToString(
      event.target.value as PropertyFileCategory,
    );
    setSelectCategory(value);
  };

  const handleSelectProperty = (event: SelectChangeEvent) => {
    setSelectProperty(event.target.value as string);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return (
    <>
      <Box sx={{padding: '40px'}}>
        <Stack>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb="19.5px">
            <Grid item container xs="auto">
              <Grid item mr="20px">
                <Stack>
                  <Typography mb="4px">カテゴリー</Typography>
                  <Grid item container xs="auto" alignItems="center">
                    <Grid item>
                      <Select
                        defaultValue=""
                        displayEmpty
                        sx={{
                          '& .MuiOutlinedInput-input': {
                            paddingY: '12px',
                            paddingX: '10px',
                          },
                        }}
                        onChange={handleSelectCategory}>
                        <MenuItem value="">全てのカテゴリー</MenuItem>
                        {categoryList.map((category, index) => (
                          <MenuItem value={category.value} key={index}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item>
                <Stack>
                  <Typography mb="4px">物件</Typography>
                  <Grid item container xs="auto" alignItems="center">
                    <Grid item>
                      <Select
                        defaultValue=""
                        displayEmpty
                        sx={{
                          '& .MuiOutlinedInput-input': {
                            paddingY: '12px',
                            paddingX: '10px',
                          },
                        }}
                        onChange={handleSelectProperty}>
                        <MenuItem value="">全ての物件</MenuItem>
                        {propertyNameList.map((file, index) => (
                          <MenuItem value={file} key={index}>
                            {file}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container xs="auto">
              <Button
                variant="outlined"
                size="small"
                sx={{
                  mr: '16px',
                }}
                startIcon={
                  <UploadIcon fontSize="small" strokeColor="#26B9C0" />
                }
                onClick={() => handleUploadFile()}>
                ファイルのアップロード
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={styledPaper}>
            <Table aria-label="simple table">
              <TableHead sx={{backgroundColor: 'flikPrimary.-40'}}>
                <TableRow>
                  <TableCell>ファイル名</TableCell>
                  <TableCell>カテゴリー</TableCell>
                  <TableCell>物件名・部屋番号</TableCell>
                  <TableCell>更新日時</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayPropertyFiles.map(file => {
                  return (
                    <TableRow key={file.id}>
                      <TableCell component="th" scope="row">
                        {decodeURIComponent(file?.name)}
                      </TableCell>
                      <TableCell>
                        {convertPropertyFileCategoryToString(file.category)}
                      </TableCell>
                      <TableCell>{`${file.propertyName}${file.roomName}`}</TableCell>
                      <TableCell>
                        {convertDateToString(file.updatedAt, 'yyyy/M/d')}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          sx={{mr: '12px'}}
                          tooltip="ダウンロード"
                          onClick={() => onFileDownload(file.name)}>
                          <DownloadIcon />
                        </IconButton>
                        <IconButton
                          tooltip="削除"
                          onClick={() => handleDeleteFile(file)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              mt="19.5px"
              mb="40px">
              <Grid item container xs="auto">
                <Pagination
                  count={pageCount}
                  renderItem={propertyFile => (
                    <PaginationItem
                      sx={{
                        backgroundColor: '#E9EAEE',
                        '&:hover': {
                          backgroundColor: 'flickPrimary.main',
                        },
                        '&.Mui-selected': {color: 'white'},
                      }}
                      components={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...propertyFile}
                    />
                  )}
                  color="primary"
                  shape="rounded"
                  page={page}
                  onChange={handleChangePage}
                />
              </Grid>
            </Grid>
          </TableContainer>
        </Stack>
      </Box>
      {openUploadModal ? (
        <UploadFileModal
          openUploadModal={openUploadModal}
          setOpenUploadModal={setOpenUploadModal}
        />
      ) : null}
      {openDeleteFileModal ? (
        <DeleteFileModal
          openDeleteFileModal={openDeleteFileModal}
          setOpenDeleteFileModal={setOpenDeleteFileModal}
          onFileDelete={onFileDelete}
          deleteFileName={deleteFile?.name ?? ''}
          deleteFileId={deleteFile?.id ?? ''}
          isLoading={isLoading}
        />
      ) : null}
    </>
  );
};

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Stack,
  Typography,
  Grid,
  Checkbox,
  InputAdornment,
  IconButton,
  Link,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import React, {useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {OrderType} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {TextField} from '../components/TextField';
import usePayment from '../hooks/usePayment';
import {useCreateOrderMutation} from '../queries/orders';
import {useUpdateUserMutation, useUser} from '../queries/user';

type FormInput = {
  password: string;
  policy: boolean;
};

export const LeaveScreen = () => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: user} = useUser(sub);
  const queryClient = useQueryClient();
  const {mutateAsync: createOrder} = useCreateOrderMutation();
  const {cancelRecurringPayment} = usePayment();
  const {mutate: updateUser} = useUpdateUserMutation();
  const {
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'all',
    defaultValues: {
      password: '',
    },
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onCancel = () => {
    navigate('/profile');
  };
  const [passwordError, setPasswordError] = useState(false);
  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.changePassword(userState.user, data.password, 'password')
      .then(async () => {
        updateUser(
          {
            input: {
              id: sub,
              disabled: true,
            },
          },
          {
            onSuccess: async () => {
              if (user && user?.plan === 'PRO') {
                await createOrder(
                  {
                    input: {
                      orderType: OrderType.CANCEL_PRO_PLAN,
                      userOrdersId: sub,
                    },
                  },
                  {
                    onSuccess: async response => {
                      if (!response?.id) {
                        navigate('/error');
                        return;
                      }
                      cancelRecurringPayment(
                        response.id,
                        sub,
                        user.subscriptionId ?? '',
                      );
                    },
                    onError: () => {
                      navigate('/error');
                      return;
                    },
                  },
                );
              }
              queryClient.invalidateQueries();
              await Auth.deleteUser().then(() => {
                navigate('/signIn', {state: {leave: true}});
              });
            },
            onError: () => {
              navigate('/error');
              return;
            },
          },
        );
      })
      .catch(err => {
        Sentry.captureException(err);
        setPasswordError(true);
      });
  };
  return (
    <Stack alignItems="center">
      <Grid direction="column" spacing={3} container sx={{width: '900px'}}>
        <Grid item>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '1.3',
            }}>
            退会
          </Typography>
        </Grid>
        <Grid item>
          <BaseBox>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '700',
                mb: '12px',
                lineHeight: '23.4px',
              }}>
              退会時のデータ削除について
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                mb: '12px',
                lineHeight: '1.8',
              }}>
              保存されているすべてのデータが削除されます。退会後の情報の復元はできません。
              <br />
              ※プロプランをご契約中の場合、退会と同時にサブスクリプション登録も解約されます。プラン変更のみをご希望の場合は
              <Link href="/plan" underline="hover" color="flikPrimary.main">
                こちら
              </Link>
              から変更いただけます。
            </Typography>
            <Grid container direction="row" alignItems="center" my="20px">
              <Grid item>
                <Checkbox
                  {...register('policy', {
                    required: true,
                  })}
                />
              </Grid>
              <Grid item>
                <Typography>上記の内容に同意しました</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Typography>パスワードを入力してください</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={!!errors.password}
                  aria-label="password"
                  placeholder="パスワード"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password', {
                    required: true,
                  })}
                  helperText={
                    errors.password ? 'パスワードを入力してくだい' : ''
                  }
                />
              </Grid>
            </Grid>
            {errors.policy?.types?.required ? (
              <Typography color="warning.main" fontSize="14px" mt="16px">
                上記の内容に同意してください
              </Typography>
            ) : null}
            {passwordError ? (
              <Typography color="warning.main" fontSize="14px" mt="16px">
                パスワードが正しくありません
              </Typography>
            ) : null}
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{mt: '40px'}}>
              <Grid item>
                <Button variant="outlined" onClick={onCancel}>
                  キャンセル
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!dirtyFields.password}
                  variant="contained"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}>
                  退会する
                </Button>
              </Grid>
            </Grid>
          </BaseBox>
        </Grid>
      </Grid>
    </Stack>
  );
};

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import {Stack, Typography} from '@mui/material';
import React, {FC} from 'react';

type Props = {
  wordCountError: boolean;
  alphanumericError: boolean;
};

export const PasswordValidation: FC<Props> = ({
  wordCountError,
  alphanumericError,
}) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.5}>
        {wordCountError ? (
          <RemoveIcon fontSize="inherit" />
        ) : (
          <CheckCircleIcon
            fontSize="inherit"
            sx={{color: 'flikPrimary.main'}}
          />
        )}
        <Typography sx={{fontSize: '12px'}}>半角英数字８文字以上</Typography>
      </Stack>
      <Stack direction="row" spacing={0.5}>
        {alphanumericError ? (
          <RemoveIcon fontSize="inherit" />
        ) : (
          <CheckCircleIcon
            fontSize="inherit"
            sx={{color: 'flikPrimary.main'}}
          />
        )}
        <Typography sx={{fontSize: '12px'}}>英数字を組み合わせる</Typography>
      </Stack>
    </Stack>
  );
};

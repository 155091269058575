import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const PlusIcon = ({
  strokeColor,
  ...props
}: SvgIconProps & {
  strokeColor?: string;
}) => {
  const color = strokeColor ? strokeColor : '#181C32';
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16 6.66663V25.3333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66666 16H25.3333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import 'react-native-get-random-values';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {styled} from '@mui/system';
import * as Sentry from '@sentry/react';
import {Storage} from 'aws-amplify';
import React, {useCallback, useState} from 'react';
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {CreatePropertyMutationVariables, CustomItemInput} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {AddItemButton} from '../components/AddItemButton';
import {Button} from '../components/Button';
import {Cell} from '../components/Cell';
import {DatePicker} from '../components/DatePicker';
import {IconButton} from '../components/IconButton';
import {LabelTitle} from '../components/LabelTitle';
import {PropertyImage} from '../components/PropertyImage';
import {Select} from '../components/Select';
import {Snackbar} from '../components/Snackbar';
import {MAX_INPUT_LENGTH, TextField} from '../components/TextField';
import {DeleteIcon} from '../images/DeleteIcon';
import {ImageSquareIcon} from '../images/ImageSquareIcon';
import {useCreatePropertyMutation} from '../queries/property';
import {convertStringToStructureType} from '../utils/convertStringToStructureType';
import {isOversizedImage} from '../utils/isOversizedImage';
import {structureList} from '../utils/selectOptions';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export type PropertyFormInput = {
  name: string;
  address: string;
  station: string;
  minutes: number;
  structure: string;
  stories: number;
  units: number;
  years: Date | null;
  buildingManagement: string;
  rentalManagement: string;
  customItems: CustomItemInput[];
};

export const Input = styled('input')({
  display: 'none',
});

export const AddPropertyScreen = () => {
  const userState = useOAuthUserState();
  const userId = userState.user?.attributes.sub ?? '';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: createProperty, isLoading} = useCreatePropertyMutation();

  const [propertyImage, setPropertyImage] = useState<File>();
  const [propertyImageURL, setPropertyImageURL] = useState<string>();
  const [years, setYears] = useState<Date | null>(null);
  const [isYearsError, setIsYearsError] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isOverImageSize, setIsOverImageSize] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
  } = useForm<PropertyFormInput>({
    mode: 'onBlur',
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'customItems',
  });

  const onSubmit: SubmitHandler<PropertyFormInput> = async data => {
    if (isYearsError) {
      onError();
      return;
    }
    const propertyId = uuidv4();
    const variables: CreatePropertyMutationVariables = {
      input: {
        id: propertyId,
        name: data.name,
        thumbnail: '',
        address: data.address,
        station: data.station,
        minutes: data.minutes,
        structure: convertStringToStructureType(data.structure),
        stories: data.stories || null,
        units: data.units || null,
        years: years ? toISOStringWithTimezone(years) : undefined,
        buildingManagement: data.buildingManagement,
        rentalManagement: data.rentalManagement,
        customItems: data.customItems,
        userPropertiesId: userId,
      },
    };
    try {
      if (propertyImage) {
        await Storage.put(
          `properties/${propertyId}/${encodeURIComponent(propertyImage.name)}`,
          propertyImage,
          {
            level: 'private',
          },
        );
        variables.input.thumbnail = `properties/${propertyId}/${encodeURIComponent(
          propertyImage.name,
        )}`;
      }
      createProperty(variables, {
        onSuccess: () => {
          queryClient.invalidateQueries();
          navigate(`/property/${propertyId}`, {
            state: {operationState: 'add-success'},
          });
        },
        onError: () => {
          navigate('/error');
          return;
        },
      });
    } catch (err) {
      Sentry.captureException(err);
      navigate('/error');
      return;
    }
  };

  const onFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files == null) {
        return;
      }
      const file = e.currentTarget.files[0];
      isOversizedImage(file)
        ? setIsOverImageSize(true)
        : setIsOverImageSize(false);
      setPropertyImage(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPropertyImageURL(reader.result as string);
      };
      e.target.value = '';
    },
    [],
  );
  const onError = useCallback(() => setOpenSnackbar(true), []);

  return (
    <>
      <Typography
        sx={{fontSize: '24px', margin: '0 0 24px 0', fontWeight: '700'}}>
        物件情報の追加
      </Typography>
      <Box
        sx={{
          width: '100%',
          padding: '36px',
          bgcolor: 'white',
          boxShadow: '1',
          borderRadius: '12px',
        }}>
        <Grid container spacing={3}>
          <Grid item>
            <PropertyImage imageUrl={propertyImageURL ?? ''} />
            <Stack spacing={1} alignItems="flex-start" width="320px">
              {propertyImage ? (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item maxWidth="250px">
                    <Typography noWrap fontSize="12px">
                      {propertyImage.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      tooltip="削除"
                      sx={{bgcolor: 'flikGray.-25'}}
                      onClick={() => {
                        setPropertyImage(undefined);
                        setPropertyImageURL('');
                        setIsOverImageSize(false);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container>
                <Grid item>
                  <label htmlFor="contained-button-file">
                    <Input
                      id="contained-button-file"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={onFileChange}
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<ImageSquareIcon color="primary" />}>
                      ファイルを選択
                    </Button>
                  </label>
                </Grid>
              </Grid>
              <Typography
                sx={{fontSize: '12px', lineHeight: '1.3', width: '320px'}}>
                5MB以内のjpg、png画像をアップロードすることができます
              </Typography>
              {isOverImageSize ? (
                <StyledText>
                  アップロードされているファイルが 5MB を超えています。
                </StyledText>
              ) : null}
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle isRequired>物件名称</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  {...register('name', {required: true})}
                  placeholder="Prop Dock マンション"
                  helperText={
                    errors.name?.type === 'required' &&
                    '物件名を入力してください'
                  }
                  size="small"
                  error={errors.name?.type === 'required'}
                />
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle isRequired>所在地</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  {...register('address', {required: true})}
                  placeholder="大阪市西区京町堀1-8-31"
                  helperText={
                    errors.address?.type === 'required' &&
                    '所在地を入力してください'
                  }
                  size="small"
                  error={errors.address?.type === 'required'}
                />
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle isRequired>最寄駅</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  {...register('station', {required: true})}
                  placeholder="心斎橋"
                  helperText={
                    errors.station?.type === 'required' &&
                    '最寄り駅を入力してください'
                  }
                  size="small"
                  error={errors.station?.type === 'required'}
                />
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle isRequired>駅徒歩</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  type="number"
                  {...register('minutes', {required: true})}
                  placeholder="3"
                  helperText={
                    errors.minutes?.type === 'required' &&
                    '最寄り駅を入力してください'
                  }
                  size="small"
                  error={errors.minutes?.type === 'required'}
                />
                <Typography sx={{marginLeft: '4px'}}>分　</Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle isRequired>構造</LabelTitle>
              </Cell>
              <StyledCell>
                <FormControl fullWidth>
                  <Select
                    defaultValue=""
                    displayEmpty
                    {...register('structure', {required: true})}
                    error={errors.structure?.type === 'required'}>
                    <MenuItem value="">選択してください</MenuItem>
                    {structureList.map((s, index) => (
                      <MenuItem value={s.value} key={index}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{color: 'warning.main'}}>
                    {errors.structure?.type === 'required' &&
                      '構造を選択してください'}
                  </FormHelperText>
                </FormControl>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>階建</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  type="number"
                  {...register('stories', {required: false})}
                  placeholder="6"
                  size="small"
                />
                <Typography sx={{marginLeft: '4px', wordBreak: 'keep-all'}}>
                  階建
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>総戸数</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  type="number"
                  size="small"
                  {...register('units', {required: false})}
                  placeholder="30"
                />
                <Typography sx={{marginLeft: '4px'}}>戸　</Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>築年月</LabelTitle>
              </Cell>
              <StyledCell>
                <DatePicker
                  views={['year', 'month']}
                  value={years}
                  inputFormat={'yyyy/MM'}
                  error={isYearsError}
                  onChangeValue={setYears}
                  onErrorChange={setIsYearsError}
                />
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>建物管理会社名</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  size="small"
                  {...register('buildingManagement', {required: false})}
                  placeholder="株式会社 Prop Dock"
                />
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>賃貸管理会社名</LabelTitle>
              </Cell>
              <StyledCell>
                <TextField
                  size="small"
                  {...register('rentalManagement', {required: false})}
                  placeholder="株式会社 Prop Dock"
                />
              </StyledCell>
            </Stack>
            <Stack direction="column" sx={{width: '100%'}}>
              {fields.map((field, index) => (
                <Stack key={field.id} direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <TextField
                      {...register(`customItems.${index}.key`, {
                        required: true,
                        maxLength: MAX_INPUT_LENGTH,
                      })}
                      placeholder="項目名"
                      size="small"
                      helperText={
                        errors.customItems?.[index]?.key?.type === 'required' &&
                        '入力してください'
                      }
                      error={!!errors.customItems?.[index]?.key}
                    />
                  </Cell>
                  <Cell sx={{width: '100%'}}>
                    <TextField
                      {...register(`customItems.${index}.value`, {
                        required: true,
                      })}
                      placeholder="項目内容"
                      size="small"
                      multiline
                      fullWidth
                      helperText={
                        errors.customItems?.[index]?.value && '入力してください'
                      }
                      error={!!errors.customItems?.[index]?.value}
                    />
                    <IconButton tooltip="削除" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Cell>
                </Stack>
              ))}
              <Box sx={{margin: '16px 0 24px'}}>
                <AddItemButton onAdd={() => append({key: '', value: ''})}>
                  項目を追加
                </AddItemButton>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button variant="outlined" onClick={() => navigate('/')}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        width: '20px  !important',
                        height: '20px  !important',
                      }}
                    />
                  ) : null
                }
                onClick={handleSubmit(onSubmit, onError)}>
                {isLoading ? '保存中' : '保存する'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        severity="error"
        message="入力内容を確認してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

const StyledCell = styled(Cell)({
  width: '450px',
});

const StyledText = styled(Typography)({
  fontSize: '12px',
  lineHeight: 1.3,
  color: '#DA0000',
  width: '320px',
});

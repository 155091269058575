import CryptoJS from 'crypto-js';
import $ from 'jquery';
import {useCallback} from 'react';
import {OrderType} from '../API';

const getCurrentTimestamp = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = zeroPadding(now.getMonth() + 1);
  const day = zeroPadding(now.getDate());
  const hours = zeroPadding(now.getHours());
  const minutes = zeroPadding(now.getMinutes());
  const seconds = zeroPadding(now.getSeconds());
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const zeroPadding = (num: number) => {
  return num < 10 ? '0' + num : num.toString();
};

type UpdatePaymentMethodOrder = {
  pay_method: string;
  merchant_id: string;
  service_id: string;
  cust_code: string;
  sps_cust_no: string;
  sps_payment_no: string;
  terminal_type: string;
  success_url: string;
  cancel_url: string;
  error_url: string;
  pagecon_url: string;
  free1: string;
  free2: string;
  free3: string;
  free_csv: string;
  request_date: string;
  limit_second: string;
  hashkey: string;
};

type RecurringPaymentOrder = {
  pay_method: string;
  merchant_id: string;
  service_id: string;
  cust_code: string;
  sps_cust_no: string;
  sps_payment_no: string;
  order_id: string;
  item_id: string;
  pay_item_id: string;
  item_name: string;
  tax: string;
  amount: string;
  pay_type: string;
  auto_charge_type: string;
  service_type: string;
  div_settele: string;
  last_charge_month: string;
  camp_type: string;
  tracking_id: string;
  terminal_type: string;
  success_url: string;
  cancel_url: string;
  error_url: string;
  pagecon_url: string;
  free1: string;
  free2: string;
  free3: string;
  free_csv: string;
  dtl_rowno: string;
  dtl_item_id: string;
  dtl_item_name: string;
  dtl_item_count: string;
  dtl_tax: string;
  dtl_amount: string;
  dtl_free1: string;
  dtl_free2: string;
  dtl_free3: string;
  request_date: string;
  limit_second: string;
  hashkey: string;
};

function usePayment() {
  const sendOrder = (
    order: RecurringPaymentOrder | UpdatePaymentMethodOrder,
    connectUrl: string,
  ) => {
    const orderString = Object.values(order).join('');
    const utf8Text = unescape(encodeURIComponent(orderString));
    const spsHashcode = CryptoJS.SHA1(utf8Text).toString();
    const form = $('<form></form>', {
      action: connectUrl,
      target: 'receiver',
      method: 'POST',
    }).hide();
    Object.entries(order).forEach(([key, value]) => {
      form.append(
        $('<input>', {
          type: 'hidden',
          name: key,
          value,
        }),
      );
    });
    form.append(
      $('<input>', {
        type: 'hidden',
        name: 'sps_hashcode',
        value: spsHashcode,
      }),
    );
    $('body').append(form);
    form.trigger('submit');
  };

  const initiateRecurringPayment = useCallback(
    (orderId: string, userId: string) => {
      const order: RecurringPaymentOrder = {
        pay_method: 'credit',
        merchant_id: process.env.REACT_APP_SBPAYMENT_MERCHANT_ID ?? '',
        service_id: process.env.REACT_APP_SBPAYMENT_SERVICE_ID ?? '',
        cust_code: userId,
        sps_cust_no: '',
        sps_payment_no: '',
        order_id: orderId,
        item_id: OrderType.PRO_PLAN,
        pay_item_id: '',
        item_name: 'Prop Dock PRO',
        tax: '235',
        amount: '2580',
        pay_type: '1',
        auto_charge_type: '1',
        service_type: '0',
        div_settele: '0',
        last_charge_month: '',
        camp_type: '0',
        tracking_id: '',
        terminal_type: '0',
        success_url: process.env.REACT_APP_SBPAYMENT_SUCCESSURL ?? '',
        cancel_url: process.env.REACT_APP_SBPAYMENT_CANCELURL ?? '',
        error_url: process.env.REACT_APP_SBPAYMENT_ERRORURL ?? '',
        pagecon_url: process.env.REACT_APP_SBPAYMENT_PAGECONURL ?? '',
        free1: '',
        free2: '',
        free3: '',
        free_csv: '',
        dtl_rowno: '1',
        dtl_item_id: OrderType.PRO_PLAN,
        dtl_item_name: 'Prop Dock PRO',
        dtl_item_count: '1',
        dtl_tax: '235',
        dtl_amount: '2580',
        dtl_free1: '',
        dtl_free2: '',
        dtl_free3: '',
        request_date: getCurrentTimestamp(),
        limit_second: '',
        hashkey: process.env.REACT_APP_SBPAYMENT_HASHKEY ?? '',
      };
      const connectUrl = 'https://fep.sps-system.com/f01/FepBuyInfoReceive.do';
      sendOrder(order, connectUrl);
    },
    [],
  );

  const cancelRecurringPayment = useCallback(
    (orderId: string, userId: string, trackingId: string) => {
      const order: RecurringPaymentOrder = {
        pay_method: 'credit',
        merchant_id: process.env.REACT_APP_SBPAYMENT_MERCHANT_ID ?? '',
        service_id: process.env.REACT_APP_SBPAYMENT_SERVICE_ID ?? '',
        cust_code: userId,
        sps_cust_no: '',
        sps_payment_no: '',
        order_id: orderId,
        item_id: OrderType.PRO_PLAN,
        pay_item_id: '',
        item_name: 'Prop Dock PRO',
        tax: '235',
        amount: '2580',
        pay_type: '1',
        auto_charge_type: '1',
        service_type: '1',
        div_settele: '0',
        last_charge_month: '',
        camp_type: '0',
        tracking_id: trackingId,
        terminal_type: '0',
        success_url: process.env.REACT_APP_SBPAYMENT_SUCCESSURL ?? '',
        cancel_url: process.env.REACT_APP_SBPAYMENT_CANCELURL ?? '',
        error_url: process.env.REACT_APP_SBPAYMENT_ERRORURL ?? '',
        pagecon_url: process.env.REACT_APP_SBPAYMENT_PAGECONURL ?? '',
        free1: '',
        free2: '',
        free3: '',
        free_csv: '',
        dtl_rowno: '1',
        dtl_item_id: OrderType.PRO_PLAN,
        dtl_item_name: 'Prop Dock PRO',
        dtl_item_count: '1',
        dtl_tax: '235',
        dtl_amount: '2580',
        dtl_free1: '',
        dtl_free2: '',
        dtl_free3: '',
        request_date: getCurrentTimestamp(),
        limit_second: '',
        hashkey: process.env.REACT_APP_SBPAYMENT_HASHKEY ?? '',
      };
      const connectUrl = 'https://fep.sps-system.com/f01/FepBuyInfoReceive.do';
      sendOrder(order, connectUrl);
    },
    [],
  );

  const updatePaymentMethod = useCallback((userId: string) => {
    const order: UpdatePaymentMethodOrder = {
      pay_method: 'credit',
      merchant_id: process.env.REACT_APP_SBPAYMENT_MERCHANT_ID ?? '',
      service_id: process.env.REACT_APP_SBPAYMENT_SERVICE_ID ?? '',
      cust_code: userId,
      sps_cust_no: '',
      sps_payment_no: '',
      terminal_type: '0',
      success_url: process.env.REACT_APP_SBPAYMENT_SUCCESSURL ?? '',
      cancel_url: process.env.REACT_APP_SBPAYMENT_CANCELURL ?? '',
      error_url: process.env.REACT_APP_SBPAYMENT_ERRORURL ?? '',
      pagecon_url: process.env.REACT_APP_SBPAYMENT_PAGECONURL ?? '',
      free1: '',
      free2: '',
      free3: '',
      free_csv: '',
      request_date: getCurrentTimestamp(),
      limit_second: '',
      hashkey: process.env.REACT_APP_SBPAYMENT_HASHKEY ?? '',
    };
    const connectUrl = 'https://fep.sps-system.com/f04/FepPayInfoReceive.do';
    sendOrder(order, connectUrl);
  }, []);

  return {
    initiateRecurringPayment,
    cancelRecurringPayment,
    updatePaymentMethod,
  };
}

export default usePayment;

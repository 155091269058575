import styled from '@emotion/styled';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {BaseBox} from '../components/BaseBox';

type NavItem = {
  category: string;
  isSelect: boolean;
};

type NavigateListProps = {
  Navlist: NavItem[];
  setIsSelect: Dispatch<SetStateAction<NavItem[]>>;
};

function NavigateList({Navlist, setIsSelect}: NavigateListProps) {
  const style = (isSelect: boolean) => ({
    color: isSelect ? 'black' : 'rgba(156, 156, 156, 1)',
    fontWeight: isSelect ? '700' : '400',
  });
  return (
    <Nav>
      <ul>
        {Navlist.map((item, index) => {
          return (
            <li key={index}>
              <a href="#recruitmentinformation">
                <div>
                  <span
                    onClick={() => {
                      setIsSelect(prevState => {
                        return prevState.map(i =>
                          i.category === item.category
                            ? {category: i.category, isSelect: true}
                            : {category: i.category, isSelect: false},
                        );
                      });
                    }}
                    style={style(item.isSelect)}>
                    {item.category}
                  </span>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </Nav>
  );
}

export const HelpScreen = () => {
  const NavList: NavItem[] = [
    {category: 'すべて', isSelect: true},
    {category: '入力操作', isSelect: false},
    {category: '物件情報', isSelect: false},
    {category: '部屋情報', isSelect: false},
    {category: '契約者情報', isSelect: false},
    {category: 'ファイル管理', isSelect: false},
    {category: '雑費管理', isSelect: false},
  ];
  const [isSelect, setIsSelect] = useState<NavItem[]>(NavList);
  return (
    <>
      <FaqSection>
        <h1>FAQ</h1>
      </FaqSection>
      <NavigateList Navlist={isSelect} setIsSelect={setIsSelect} />
      <QuestionSection>
        {isSelect[0].isSelect || isSelect[1].isSelect ? (
          <>
            <H2>入力操作</H2>
            <Ul>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq1" />
                  <Label htmlFor="faq1">
                    <b>Q</b>
                    <b>
                      1年以上前にさかのぼって過去の物件情報やその他の情報を入力することは可能でしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      もちろん可能です。物件購入時の資料をお手元にご用意いただき、購入当時から現在までの状況をご入力いただければ過去の年次収支報告書も自動的に生成されます。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq2" />
                  <Label htmlFor="faq2">
                    <b>Q</b>
                    <b>
                      「頻度」の1回と毎月はどのように使い分ければ良いでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      家賃・修繕積立金・管理費、のように毎月発生するものは「毎月」をご選択いただくと収支報告書に自動的に毎月反映されます。
                      <br />
                      固定資産税や損害保険料などの数年に1度のものは、頻度を「1回」を選択して頂き、お支払いした月を「開始月」でご選択いただくと収支報告書に自動的に開始月で選択した月のみ反映されます。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq3" />
                  <Label htmlFor="faq3">
                    <b>Q</b>
                    <b>
                      部屋情報の費用欄や契約者情報の収入欄の「繰り返しルール追加」というボタンはどんな時に使用するのでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      頻度を「毎月」に設定していて途中で金額が変更になった場合に、変更後の金額を「繰り返しルール追加」に再度ご入力ください。
                      <br />
                      例えば所有中に修繕積立金が上昇した場合には、
                      <br />
                      2,000円 毎月 2015年1月
                      <br />
                      3,000円 毎月 2022年1月
                      <br />
                      所有中にサブリース家賃が更新により減少した場合、
                      <br />
                      70,000円 毎月 2015年1月
                      <br />
                      68,000円 毎月 2017年1月
                      <br />
                      のようなご入力になります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
            </Ul>
          </>
        ) : null}

        {isSelect[0].isSelect || isSelect[2].isSelect ? (
          <>
            <H2>物件情報</H2>
            <Ul>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq4" />
                  <Label htmlFor="faq4">
                    <b>Q</b>
                    <b>
                      物件情報追加の入力項目欄の一番下の「＋項目を追加」というボタンはどんな時に使用するのでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      デフォルトの項目以外にオーナーが把握しておきたい情報を一目で確認できるように任意でご用意をしておりますので、オーナーの必要に応じて追加でご入力して頂いて構いません。
                      <br />
                      なお、ご入力いただいた追加項目は収支報告書には反映されません。例えば「営業担当者」という項目を追加して「Prop
                      太郎」と入力しておくことで担当者を把握することができます。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq5" />
                  <Label htmlFor="faq5">
                    <b>Q</b>
                    <b>
                      物件情報追加の写真アップロードはどんな使い方をするのでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      物件イメージ写真を5MB以内のjpg、png画像でアップロードすることができますので、お好きな写真をアップロードしてください。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
            </Ul>
          </>
        ) : null}

        {isSelect[0].isSelect || isSelect[3].isSelect ? (
          <>
            <H2>部屋情報</H2>
            <Ul>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq6" />
                  <Label htmlFor="faq6">
                    <b>Q</b>
                    <b>
                      基本情報の「土地面積」は入力していなくても問題はないでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      特に問題はありません。ユーザーの情報把握の為に任意でご活用下さい。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq7" />
                  <Label htmlFor="faq7">
                    <b>Q</b>
                    <b>
                      費用欄の「購入諸費用」はどのように入力すればよいでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      購入時の諸費用明細をご確認いただき、購入時諸費用金額を入力後、「頻度」を1回、「開始月」には決済月（お引き渡し月）をご選択ください。
                      <br />
                      なお、諸費用には固定資産税・都市計画税の年割金額や管理費・修繕積立金の清算金額も含まれることがほとんどです。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq8" />
                  <Label htmlFor="faq8">
                    <b>Q</b>
                    <b>
                      費用欄の「固定資産税・都市計画税」はどのように入力すればよいでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      頻度を「1回」を選択して頂き、「開始月」にお支払いした月をご選択下さい。
                      <br />
                      例えば全期全納でお支払いの場合、
                      <br />
                      50,000円 1回 2020年5月
                      <br />
                      50,000円 1回 2021年5月
                      <br />
                      50,000円 1回 2022年6月
                      <br />
                      のようなご入力になります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq9" />
                  <Label htmlFor="faq9">
                    <b>Q</b>
                    <b>
                      費用欄の「固定資産税・都市計画税」は年額を記載したら良いのか、購入時の年割を記載したら良いのか分かりません。
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      年額をご入力下さい。購入時の年割の固定資産税・都市計画税に関しては諸費用の金額に含めてください。
                      <br />
                      ※年割の固定資産税・都市計画税を、諸費用とは別でお支払いした場合には、頻度を「1回」を選択して頂き、「開始月」にお支払いした月をご選択下さい。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq10" />
                  <Label htmlFor="faq10">
                    <b>Q</b>
                    <b>
                      費用欄の「地震火災保険料」はどのように入力すればよいでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      数年に一度のお支払いに合わせて頻度を「1回」を選択して頂き、「開始月」にお支払いした月をご選択下さい。例えば、
                      <br />
                      20,000円 1回 2016年10月 <br />
                      20,000円 1回 2021年10月 <br />
                      のようなご入力になります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq11" />
                  <Label htmlFor="faq11">
                    <b>Q</b>
                    <b>
                      基本情報の一番下にある「＋項目を追加」というボタンはどんな時に使用するのでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      デフォルトの項目以外にオーナーが把握しておきたい情報を一目で確認できるように任意でご用意をしておりますので、オーナーの必要に応じて追加でご入力して頂いて構いません。なお、ご入力いただいた追加項目は収支報告書には反映されません。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq12" />
                  <Label htmlFor="faq12">
                    <b>Q</b>
                    <b>
                      費用欄の一番下にある「＋費用を追加」というボタンはどんな時に使用するのでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      デフォルトの項目以外の支出を管理できるように任意でご用意をしておりますので、オーナーの必要に応じて追加でご入力して頂いて構いません。なお、ご入力いただいた追加項目は収支報告書にも自動的に反映されます。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
            </Ul>
          </>
        ) : null}

        {isSelect[0].isSelect || isSelect[4].isSelect ? (
          <>
            <H2>契約者情報</H2>
            <Ul>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq13" />
                  <Label htmlFor="faq13">
                    <b>Q</b>
                    <b>
                      基本情報の「礼金」はどのように入力すれば良いでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      購入決済時または賃借人の入居時に発生する礼金をご入力ください。ご入力いただいた追加項目は収支報告書にも自動的に反映されます。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq14" />
                  <Label htmlFor="faq14">
                    <b>Q</b>
                    <b>
                      「初月賃料」と「月額賃料」はどのように入力すれば良いでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      物件決済（お引き渡し）の際に、決済月（お引き渡し月）の日割り家賃に加えて清算した月の賃料の合計金額をご入力ください。
                      <br />
                      例えば物件決済が2022年6月で家賃清算が6月7月の2ヵ月の場合、「初月賃料」には6月日割り家賃+7月分家賃の合計金額をご入力いただき、「頻度」を「1回」、「開始月」を6月「月額賃料」には月額賃料を入力し、「頻度」を毎月、「開始月」を8月とご入力ください。
                      <br />
                      例えば上記の場合であれば、
                      <br />
                      75,000円 1回 2022年6月
                      <br />
                      50,000円 毎月 2022年8月
                      <br />
                      のようなご入力になります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq15" />
                  <Label htmlFor="faq15">
                    <b>Q</b>
                    <b>
                      賃借人が退去した時はどこを入力すれば良いのでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      契約者情報画面の「現在の契約者情報」右横の「退去」をクリックしていただき、退去日・内装費用・広告料など全て分かり次第ご入力いただき「退去する」をクリックしてください。
                      <br />
                      なお、賃借人への日割り家賃等の返金がある場合には「＋項目を追加」にご入力ください。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq16" />
                  <Label htmlFor="faq16">
                    <b>Q</b>
                    <b>
                      新たに賃借人が入居する時はどこを入力すれば良いのでしょうか？
                    </b>
                    <br />
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      契約者情報画面の「現在の契約者情報」右横の「入居」をクリックしていただき、新たな入居者情報と入居に対する費用をご入力いただき「保存する」をクリックしてください。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq17" />
                  <Label htmlFor="faq17">
                    <b>Q</b>
                    <b>物件を売却した際にはどうしたら良いでしょうか？</b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      契約者情報画面の「現在の契約者情報」右横の「退去」をクリックしていただき、退去日の月日を売却月日と同じに指定していただき「退去する」をクリックしてください。その後、契約者情報画面右上の「売却」をクリックしていただき、「売却日」に退去日同様の月日をご選択ください。これで売却完了になります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
            </Ul>
          </>
        ) : null}

        {isSelect[0].isSelect || isSelect[5].isSelect ? (
          <>
            <H2>ファイル管理</H2>
            <Ul>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq18" />
                  <Label htmlFor="faq18">
                    <b>Q</b>
                    <b>
                      PDFをアップロードする際に物件名・部屋番号を入力しなくても問題ないでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      問題ありません。確定申告書など、物件や部屋に紐付かない書類は未入力でご利用いただいても構いません。なお、物件名をご入力いただくと物件名でフィルターをかけることが可能となり、PDFファイル検索が便利になります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
            </Ul>
          </>
        ) : null}

        {isSelect[0].isSelect || isSelect[6].isSelect ? (
          <>
            <H2>雑費管理</H2>
            <Ul>
              <li>
                <QuestionBox>
                  <CheckBox type="checkbox" id="faq20" />
                  <Label htmlFor="faq20">
                    <b>Q</b>
                    <b>
                      雑費追加時に金額が大きいと
                      「Error」が表示されるのですが、雑費の1項目の上限金額はいくらでしょうか？
                    </b>
                  </Label>
                  <ContentAnswer className="content">
                    <p>
                      雑費の1項目で扱える金額は 100,000,000 円までになります。
                    </p>
                  </ContentAnswer>
                </QuestionBox>
              </li>
            </Ul>
          </>
        ) : null}
      </QuestionSection>
    </>
  );
};

const FaqSection = styled.div`
  background: #d9f6f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media (min-width: 768px) {
    padding: 80px 0;
    margin: -40px -36px;
  }
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    @media (min-width: 768px) {
      font-size: 80px;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  width: 100%;
  font-size: 16px;
  align-items: center;
  margin: 32px auto 0;
  max-width: 900px;
  padding: 0 24px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    margin: 88px auto 0;
  }
  ul {
    display: flex;
    padding-left: 0;
    gap: 16px 20px;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      gap: 32px;
    }
  }
  li {
    list-style: none;
  }
  a {
    font-size: 14px;
    text-decoration: none;
    color: #a1a5b6;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`;

const QuestionSection = styled.section`
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding: 0 24px 32px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
  }
`;

const QuestionBox = styled(BaseBox)`
  width: 100%;
  padding: 24px;
  @media (min-width: 768px) {
    padding: 32px;
  }
  b {
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    line-height: 1.8;
    :first-of-type {
      color: #31cdd3;
      font-size: 20px;
      margin-right: 20px;
    }
    :nth-of-type(2) {
      max-width: 770px;
      margin-right: 28px;
      font-size: 16px;
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
  p {
    margin-left: 35px;
    max-width: 757px;
    font-size: 14px;
    line-height: 2;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`;

const H2 = styled.h2`
  justify-self: left;
  margin: 32px auto 12px;
  font-size: 20px;
  @media (min-width: 768px) {
    margin: 48px auto 24px;
    font-size: 24px;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  li:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    li:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

const CheckBox = styled.input`
  display: none;
  :checked + label + .content {
    transition: all 1.5s;
    max-height: 500px;
  }
  :checked + label::before {
    transform: rotate(90deg) !important;
  }
`;

const Label = styled.label`
  width: 100%;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.3s;
  display: flex;
  cursor: pointer;
  ::after,
  ::before {
    content: '';
    position: absolute;
    right: 0.8rem;
    width: 3px;
    height: 19.2px;
    background-color: #d4af37;
    transition: all 0.3s;
  }
  ::after {
    transform: rotate(90deg);
  }
  ::after,
  ::before {
    right: 0;
    top: 5px;
  }
`;

const ContentAnswer = styled.div`
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
`;

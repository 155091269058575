import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const ExclamationCircleIcon = ({...props}: SvgIconProps & {}) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#000000"
      fill="none !important"
      strokeLinecap="round"
      strokeLinejoin="round"
      sx={{fill: 'none', ...props.sx}}
      {...props}>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
      <path d="M12 9v4" />
      <path d="M12 16v.01" />
    </SvgIcon>
  );
};

import {
  Alert,
  AlertProps,
  Slide,
  SlideProps,
  Snackbar as MuiSnackbar,
  SnackbarProps,
} from '@mui/material';
import React, {VFC} from 'react';
import {CheckCircleIcon} from '../images/CheckCircleIcon';
import {InfoCircleIcon} from '../images/InfoCircleIcon';

type CustomProps = {
  message: string;
  open: SnackbarProps['open'];
  onClose: () => void;
  severity?: AlertProps['severity'];
};

const Transition = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

export const Snackbar: VFC<CustomProps> = ({
  message,
  open,
  onClose,
  severity = 'success',
}) => {
  return (
    <MuiSnackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      TransitionComponent={Transition}>
      <Alert
        iconMapping={{
          success: (
            <CheckCircleIcon style={{fill: 'white'}} fontSize="inherit" />
          ),
          warning: (
            <InfoCircleIcon style={{fill: 'white'}} fontSize="inherit" />
          ),
          error: <InfoCircleIcon style={{fill: 'white'}} fontSize="inherit" />,
        }}
        onClose={onClose}
        severity={severity}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

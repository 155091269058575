// equalPrincipal: 元金均等
// equalRepayment: 元利均等
export type RepaymentMethod = 'EQUAL_PRINCIPAL' | 'EQUAL_INSTALLMENT';

// termReduction: 期間短縮
// repaymentAmountReduction: 返済額軽減
export type ReductionType = 'termReduction' | 'repaymentAmountReduction';

export type CalculationMethod = 'bankLoan';

export type SimulationResult = {
  month: string;
  repaymentAmount: number;
  interest: number;
  principal: number;
  remainingPrincipal: number;
  repaymentAmountIncludeEarlyRepayment: number;
  interestIncludeEarlyRepayment: number;
  principalIncludeEarlyRepayment: number;
  remainingPrincipalIncludeEarlyRepayment: number;
};

export interface ExtraRepayment {
  date: Date;
  amount: number;
}

export function mortgageSimulation(
  loanAmount: number,
  repaymentCount: number,
  interestRate: number,
  repaymentMethod: RepaymentMethod,
  loanExecutionDate: Date,
  firstRepaymentDate: Date,
  calculationMethod: CalculationMethod,
  extraRepayments: ExtraRepayment[],
  reductionType: ReductionType,
): SimulationResult[] {
  // 繰り上げ返済の情報を日付順にソート
  const sortedExtraRepayments = extraRepayments.sort(
    (a, b) => a.date.getTime() - b.date.getTime(),
  );

  // 返済シミュレーションの結果を格納する配列
  const results: SimulationResult[] = [];

  // 残高を初期化
  let remainingPrincipal = loanAmount;
  let remainingPrincipalIncludeEarlyRepayment = loanAmount;

  // 元利均等の場合の返済額を事前に計算
  const equalRepaymentAmount =
    repaymentMethod === 'EQUAL_INSTALLMENT'
      ? (loanAmount * (interestRate / 100 / 12)) /
        (1 - Math.pow(1 + interestRate / 100 / 12, -repaymentCount))
      : 0;
  let equalRepaymentAmountIncludeEarlyRepayment =
    repaymentMethod === 'EQUAL_INSTALLMENT'
      ? (loanAmount * (interestRate / 100 / 12)) /
        (1 - Math.pow(1 + interestRate / 100 / 12, -repaymentCount))
      : 0;

  // 返済回数分繰り返す
  for (let i = 1; i <= repaymentCount; i++) {
    const currentDate = new Date(firstRepaymentDate);
    currentDate.setMonth(currentDate.getMonth() + (i - 1));

    // 現在の返済月
    const month = `${currentDate.getFullYear()}年${
      currentDate.getMonth() + 1
    }月`;

    // 今月の利息を計算
    const interest = (remainingPrincipal * interestRate) / 100 / 12;
    const interestIncludeEarlyRepayment =
      (remainingPrincipalIncludeEarlyRepayment * interestRate) / 100 / 12;

    // 今月の元金を計算
    let principal: number;
    let principalIncludeEarlyRepayment: number;
    if (repaymentMethod === 'EQUAL_INSTALLMENT') {
      // 元利均等の場合
      principal =
        i == repaymentCount
          ? remainingPrincipal
          : remainingPrincipal - equalRepaymentAmount <= 0
          ? remainingPrincipal
          : equalRepaymentAmount - interest;
      principalIncludeEarlyRepayment =
        i == repaymentCount
          ? remainingPrincipalIncludeEarlyRepayment
          : remainingPrincipalIncludeEarlyRepayment -
              equalRepaymentAmountIncludeEarlyRepayment <=
            0
          ? remainingPrincipalIncludeEarlyRepayment
          : equalRepaymentAmountIncludeEarlyRepayment -
            interestIncludeEarlyRepayment;
    } else {
      // 元金均等の場合
      principal =
        i == repaymentCount ? remainingPrincipal : loanAmount / repaymentCount;
      if (remainingPrincipal - principal <= 0) {
        principal = remainingPrincipal;
      }
      principalIncludeEarlyRepayment =
        i == repaymentCount
          ? remainingPrincipalIncludeEarlyRepayment
          : loanAmount / repaymentCount;
      if (
        remainingPrincipalIncludeEarlyRepayment -
          principalIncludeEarlyRepayment <=
        0
      ) {
        principalIncludeEarlyRepayment =
          remainingPrincipalIncludeEarlyRepayment;
      }
    }

    // 繰り上げ返済を適用する
    const currentRepayment = sortedExtraRepayments[0];
    if (
      currentRepayment &&
      currentRepayment.date.getFullYear() === currentDate.getFullYear() &&
      currentRepayment.date.getMonth() === currentDate.getMonth()
    ) {
      const extraRepaymentAmount = currentRepayment.amount;
      sortedExtraRepayments.shift(); // 次の繰り上げ返済に進む

      // 期間短縮の場合
      if (reductionType === 'termReduction') {
        remainingPrincipalIncludeEarlyRepayment -= extraRepaymentAmount;
      }
      // 返済額減少の場合
      else if (reductionType === 'repaymentAmountReduction') {
        remainingPrincipalIncludeEarlyRepayment -= extraRepaymentAmount;
        if (repaymentMethod === 'EQUAL_INSTALLMENT') {
          principalIncludeEarlyRepayment =
            equalRepaymentAmountIncludeEarlyRepayment -
            interestIncludeEarlyRepayment;
        }
        const remainingRepaymentCount = repaymentCount - (i - 1);
        equalRepaymentAmountIncludeEarlyRepayment =
          (remainingPrincipalIncludeEarlyRepayment *
            (interestRate / 100 / 12)) /
          (1 - Math.pow(1 + interestRate / 100 / 12, -remainingRepaymentCount));
      }
    }

    // 今月の返済額を計算
    const repaymentAmount = interest + principal;
    const repaymentAmountIncludeEarlyRepayment =
      interestIncludeEarlyRepayment + principalIncludeEarlyRepayment;

    // 残高を減らす
    remainingPrincipal -= principal;
    remainingPrincipalIncludeEarlyRepayment -= principalIncludeEarlyRepayment;

    // 残高がマイナスになった場合、調整を行う
    if (remainingPrincipal < 0) {
      principal += remainingPrincipal;
      remainingPrincipal = 0;
    }

    // 結果を配列に格納する
    results.push({
      month,
      repaymentAmount,
      interest,
      principal,
      remainingPrincipal,
      repaymentAmountIncludeEarlyRepayment,
      interestIncludeEarlyRepayment,
      principalIncludeEarlyRepayment,
      remainingPrincipalIncludeEarlyRepayment,
    });

    // 残高が0になったら終了
    if (remainingPrincipal <= 0) {
      break;
    }
  }

  return results;
}

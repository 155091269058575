import React from 'react';

export const MinusIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.75C5 9.55109 5.10536 9.36032 5.29289 9.21967C5.48043 9.07902 5.73478 9 6 9H14C14.2652 9 14.5196 9.07902 14.7071 9.21967C14.8946 9.36032 15 9.55109 15 9.75C15 9.94891 14.8946 10.1397 14.7071 10.2803C14.5196 10.421 14.2652 10.5 14 10.5H6C5.73478 10.5 5.48043 10.421 5.29289 10.2803C5.10536 10.1397 5 9.94891 5 9.75Z"
        fill="#767C95"
      />
    </svg>
  );
};

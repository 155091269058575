import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Stack,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Box,
  Link,
  CircularProgress,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import React, {useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {BoxForAuth} from '../components/BoxForAuth';
import {Button} from '../components/Button';
import {LabelTitle} from '../components/LabelTitle';
import {Logo} from '../components/Logo';
import {PasswordValidation} from '../components/PasswordValidation';
import {TextField} from '../components/TextField';
import {combineAlphanumericCharacters} from '../utils/regex';

type FormInput = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  policy: boolean;
};

export const SignUpScreen = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [existsUser, setExistsUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit: SubmitHandler<FormInput> = data => {
    setIsLoading(true);
    Auth.signUp({
      username: data.email,
      password: data.password,
    })
      .then(() => {
        setIsLoading(false);
        navigate('/signUpVerification', {
          state: {
            email: data.email,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
          },
        });
      })
      .catch(err => {
        Sentry.captureException(err);
        if (err.code === 'UsernameExistsException') {
          setExistsUser(true);
        }
        setIsLoading(false);
      });
  };
  return (
    <BoxForAuth>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mb: '24.5px'}}>
        <Logo />
      </Grid>
      <Box
        sx={{
          width: '756px',
          borderRadius: '12px',
          bgcolor: 'flikGray.-30',
          padding: '40px',
        }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}>
          <Grid item>
            <Typography
              textAlign="center"
              sx={{
                fontStyle: 'normal',
                fontSize: '20px',
                fontWeight: '700',
                LineHeight: '1.3',
              }}>
              アカウント登録
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            spacing={1}>
            <Grid item xs={3} alignSelf="center">
              <LabelTitle isRequired>名前</LabelTitle>
            </Grid>
            <Grid item xs={4}>
              <TextField
                placeholder="姓"
                error={!!errors.lastName}
                {...register('lastName', {
                  required: true,
                })}
                helperText={errors.lastName ? '姓を入力してください' : ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                placeholder="名"
                error={!!errors.firstName}
                {...register('firstName', {
                  required: true,
                })}
                helperText={errors.firstName ? '名を入力してください' : ''}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <Grid item xs={3}>
              <LabelTitle isRequired>メールアドレス</LabelTitle>
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder="メールアドレス"
                error={!!errors.email}
                {...register('email', {
                  required: true,
                  pattern:
                    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,
                })}
                helperText={
                  errors.email ? 'メールアドレスを正しく入力してください' : ''
                }></TextField>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            spacing={1}>
            <Grid item xs={3} mt="15px">
              <LabelTitle isRequired>パスワード</LabelTitle>
            </Grid>
            <Grid item xs={8}>
              <Stack spacing={1}>
                <TextField
                  error={!!errors.password}
                  fullWidth
                  aria-label="password"
                  placeholder="パスワード"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password', {
                    required: true,
                    minLength: 8,
                    pattern: combineAlphanumericCharacters,
                  })}
                />
                <PasswordValidation
                  wordCountError={
                    !!errors.password?.types?.required ||
                    !!errors.password?.types?.minLength ||
                    !dirtyFields.password
                  }
                  alphanumericError={
                    !!errors.password?.types?.required ||
                    !!errors.password?.types?.pattern ||
                    !dirtyFields.password
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{mt: '24px'}}>
            <Grid item>
              <Checkbox
                {...register('policy', {
                  required: true,
                })}
                size="small"
              />
            </Grid>
            <Grid item>
              <Typography>
                <Link href="/terms" underline="hover" color="flikPrimary.main">
                  利用規約
                </Link>
                および
                <Link
                  href="/privacy"
                  underline="hover"
                  color="flikPrimary.main">
                  プライバシーポリシー
                </Link>
                に同意する
              </Typography>
            </Grid>
          </Grid>
          {errors.policy?.types?.required ? (
            <Typography color="warning.main" fontSize="14px">
              利用規約およびプライバシーポリシーに同意してください
            </Typography>
          ) : null}
          {existsUser ? (
            <Typography color="warning.main" fontSize="14px">
              メールアドレスは既に使用されています
            </Typography>
          ) : null}
          <Grid item>
            <Button
              variant="contained"
              disabled={isLoading}
              startIcon={
                isLoading ? (
                  <CircularProgress
                    sx={{
                      color: 'white',
                      width: '20px  !important',
                      height: '20px  !important',
                    }}
                  />
                ) : null
              }
              onClick={handleSubmit(onSubmit)}
              sx={{width: '270px !important'}}>
              {isLoading ? '登録中' : '登録する'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BoxForAuth>
  );
};

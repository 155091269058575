import {Box, BoxProps} from '@mui/material';
import React from 'react';

//Modal直下で自作コンポーネント（今回はBaseBox）を使用時のコンソールエラーの解消
//参考にしたURL↓
//https://www.gaji.jp/blog/2021/01/08/6247/
type CustomProps = BoxProps & {
  forwardref?: React.Ref<HTMLDivElement>;
};

export const BaseBox = React.forwardRef<HTMLDivElement, CustomProps>(
  function BaseBox({sx, ...props}, ref) {
    return (
      <Box
        ref={ref}
        tabIndex={0}
        sx={{
          width: '100%',
          padding: '36px',
          bgcolor: 'white',
          boxShadow: '1',
          borderRadius: '12px',
          ...sx,
        }}
        {...props}>
        {props.children}
      </Box>
    );
  },
);

import {Modal, ModalProps, Typography} from '@mui/material';
import React, {VFC} from 'react';
import {useNavigate} from 'react-router-dom';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {OutlinedCheckCircleIcon} from '../images/OutlinedCheckCircleIcon';

type Props = Omit<ModalProps, 'onClose' | 'children'> & {onClose: () => void};

export const AddRoomSuccessfulModal: VFC<Props> = ({onClose, ...props}) => {
  const navigate = useNavigate();
  return (
    <Modal onClose={onClose} {...props}>
      <BaseBox
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '464px',
          boxShadow: 'none',
        }}>
        <OutlinedCheckCircleIcon />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: 20,
            lineHeight: 1.5,
            m: '24px 0 40px',
          }}
          align="center">
          部屋情報を追加しました。
          <br />
          続けて契約者情報を登録しますか？
        </Typography>
        <Button variant="outlined" sx={{mr: '16px'}} onClick={onClose}>
          今はしない
        </Button>
        <Button variant="contained" onClick={() => navigate('addResident')}>
          登録する
        </Button>
      </BaseBox>
    </Modal>
  );
};

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Paper,
  Stack,
  Grid,
  Typography,
  Pagination,
  PaginationItem,
} from '@mui/material';
import {styled} from '@mui/system';
import React, {useEffect, useState} from 'react';
import {Expenses, ExpensesCategory} from '../API';
import {BaseBox as Box} from '../components/BaseBox';
import {Button} from '../components/Button';
import {CreateExpenseModal} from '../components/CreateExpenseModal';
import {DatePicker} from '../components/DatePicker';
import {DeleteExpenseModal} from '../components/DeleteExpenseModal';
import {DownloadExpensesModal} from '../components/DownloadExpensesPdf';
import {EditExpenseModal} from '../components/EditExpenseModal';
import {IconButton} from '../components/IconButton';
import {Snackbar} from '../components/Snackbar';
import {ArrowBackIcon} from '../images/ArrowBackIcon';
import {ArrowForwardIcon} from '../images/ArrowForwardIcon';
import {DeleteIcon} from '../images/DeleteIcon';
import {DownloadIcon} from '../images/DownloadIcon';
import {EditIcon} from '../images/EditIcon';
import {PlusIcon} from '../images/PlusIcon';
import {useExpenses} from '../queries/expenses';
import {chunkItems} from '../utils/chunkItems';
import {convertDateToString} from '../utils/convertDateToString';
import {convertExpensesCategoryToString} from '../utils/convertExpensesCategoryToString';
import {isNonNull} from '../utils/isNonNull';

const styledPaper = styled(Paper)(() => ({
  boxShadow: 'unset',
}));

export const categoryList = [
  {value: ExpensesCategory.TRAVELING, name: '旅費交通費'},
  {value: ExpensesCategory.COMMUNICATION, name: '通信費'},
  {value: ExpensesCategory.LIBRARY, name: '図書費'},
  {value: ExpensesCategory.PARTICIPATION, name: '参加費'},
  {value: ExpensesCategory.ENTERTAINMENT, name: '交際接待費'},
  {value: ExpensesCategory.OTHER, name: 'その他'},
];

enum SortOrder {
  ASCENDING,
  DESCENDING,
}

export const ExpensesScreen = () => {
  const {data} = useExpenses();
  const [expenses, setExpenses] = useState<Expenses[]>([]);
  const [expensesChunk, setExpensesChunk] = useState<Expenses[][]>([[]]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDownloadPDF, setOpenDownloadPDF] = useState(false);
  const [editForExpense, setEditForExpense] = useState<Expenses | null>(null);
  const [deleteForExpense, setDeleteForExpense] = useState<Expenses | null>(
    null,
  );
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);
  const rowsPerPage = 10;
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCreateExpense = () => {
    setOpenCreateModal(true);
  };
  const handleEditExpense = (expense: Expenses) => {
    setOpenEditModal(true);
    setEditForExpense(expense);
  };
  const handleDeleteExpenses = (expense: Expenses) => {
    setOpenDeleteModal(true);
    setDeleteForExpense(expense);
  };
  const handleDownloadExpensesPDF = () => {
    setOpenDownloadPDF(true);
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeStartDate = (date: Date | null) => {
    setStartDate(date);
    if (!date || !endDate) {
      return;
    }
    if (date > endDate) {
      setOpenSnackbar(true);
    }
  };
  const handleChangeEndDate = (date: Date | null) => {
    setEndDate(date);
    if (!date || !startDate) {
      return;
    }
    if (date < startDate) {
      setOpenSnackbar(true);
    }
  };
  const handleChangeSortOrder = () => {
    setSortOrder(
      sortOrder === SortOrder.ASCENDING
        ? SortOrder.DESCENDING
        : SortOrder.ASCENDING,
    );
  };

  useEffect(() => {
    if (!data?.items) {
      return;
    }
    const firstArray = data.items.filter(isNonNull);
    if (sortOrder === SortOrder.ASCENDING) {
      firstArray.sort((a, b) => {
        if (a.issueDate > b.issueDate) return 1;
        if (a.issueDate < b.issueDate) return -1;
        if (a.createdAt > b.createdAt) return 1;
        if (a.createdAt < b.createdAt) return -1;
        return 0;
      });
    } else {
      firstArray.sort((a, b) => {
        if (a.issueDate > b.issueDate) return -1;
        if (a.issueDate < b.issueDate) return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;
        return 0;
      });
    }
    const secondArray = firstArray.filter(expense => {
      if (!startDate) {
        return true;
      }
      const issueDate = new Date(expense.issueDate);
      return startDate <= issueDate;
    });
    const thirdArray = secondArray.filter(expense => {
      if (!endDate) {
        return true;
      }
      const issueDate = new Date(expense.issueDate);
      return issueDate <= endDate;
    });
    setExpenses(thirdArray);
    const chunks = chunkItems(thirdArray, rowsPerPage);
    setExpensesChunk(chunks);
  }, [
    data,
    openCreateModal,
    openDeleteModal,
    openEditModal,
    startDate,
    endDate,
    sortOrder,
  ]);

  return (
    <>
      <Box sx={{padding: '40px'}}>
        <Stack>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb="19.5px">
            <Grid item container xs="auto">
              <Grid item>
                <Stack>
                  <Typography mb="4px">期間指定</Typography>
                  <Grid item container xs="auto" alignItems="center">
                    <Grid item>
                      <DatePicker
                        value={startDate}
                        inputFormat={'yyyy/MM/dd'}
                        onChangeValue={handleChangeStartDate}
                      />
                    </Grid>
                    <Grid item>
                      <Typography mx="8px">〜</Typography>
                    </Grid>
                    <Grid item>
                      <DatePicker
                        value={endDate}
                        inputFormat={'yyyy/MM/dd'}
                        onChangeValue={handleChangeEndDate}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container xs="auto">
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    mr: '16px',
                  }}
                  startIcon={
                    <PlusIcon fontSize="small" strokeColor="#26B9C0" />
                  }
                  onClick={handleCreateExpense}>
                  新規登録
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={
                    <DownloadIcon fontSize="small" strokeColor="#26B9C0" />
                  }
                  onClick={handleDownloadExpensesPDF}>
                  ダウンロード
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={styledPaper}>
            <Table aria-label="simple table">
              <TableHead sx={{backgroundColor: 'flikPrimary.-40'}}>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={true}
                      direction={
                        sortOrder === SortOrder.ASCENDING ? 'asc' : 'desc'
                      }
                      onClick={handleChangeSortOrder}>
                      発生日
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell>利用先名称</TableCell>
                  <TableCell>用途</TableCell>
                  <TableCell>メモ</TableCell>
                  <TableCell>編集 / 削除</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expensesChunk[page - 1] &&
                  expensesChunk[page - 1].map(expense => (
                    <TableRow
                      key={expense?.id}
                      sx={{
                        backgroundColor: 'white',
                        '&:nth-of-type(even)': {
                          backgroundColor: 'flikGray.-30',
                        },
                      }}>
                      <TableCell>
                        {expense?.issueDate &&
                          convertDateToString(expense?.issueDate, 'yyyy/M/d')}
                      </TableCell>
                      <TableCell>{expense?.price.toLocaleString()}円</TableCell>
                      <TableCell>{expense?.company}</TableCell>
                      <TableCell>
                        {convertExpensesCategoryToString(expense?.category)}
                      </TableCell>
                      <TableCell>{expense?.note}</TableCell>
                      <TableCell>
                        <IconButton
                          tooltip="編集"
                          onClick={() => handleEditExpense(expense)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          tooltip="削除"
                          onClick={() => handleDeleteExpenses(expense)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              mt="19.5px"
              mb="40px">
              <Grid item container xs="auto">
                <Pagination
                  count={Math.ceil(expenses.length / rowsPerPage)}
                  renderItem={item => (
                    <PaginationItem
                      sx={{
                        backgroundColor: '#E9EAEE',
                        '&:hover': {
                          backgroundColor: 'flickPrimary.main',
                        },
                        '&.Mui-selected': {color: 'white'},
                      }}
                      components={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                  color="primary"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Grid>
            </Grid>
          </TableContainer>
        </Stack>
      </Box>
      {openCreateModal ? (
        <CreateExpenseModal
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
        />
      ) : null}
      {openEditModal ? (
        <EditExpenseModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          expense={editForExpense}
        />
      ) : null}
      {openDeleteModal ? (
        <DeleteExpenseModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          expense={deleteForExpense}
        />
      ) : null}
      {openDownloadPDF ? (
        <DownloadExpensesModal
          open={openDownloadPDF}
          setOpenModal={setOpenDownloadPDF}
        />
      ) : null}
      <Snackbar
        severity="error"
        message="期間指定を正しく入力してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

import {Select as MuiSelect, SelectProps, SvgIconProps} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';
import {ArrowDownIcon} from '../images/ArrowDownIcon';

const ArrowIcon = (props: SvgIconProps) => {
  return (
    <ArrowDownIcon
      fontSize="small"
      sx={{
        stroke: '#333333',
        width: '20px',
        fill: 'transparent !important',
        transition: 'transform ease-in .2s !important',
      }}
      {...props}
    />
  );
};

export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  function Select({size = 'small', ...props}, ref) {
    return (
      <StyledMuiSelect
        ref={ref}
        size={size}
        IconComponent={ArrowIcon}
        {...props}
      />
    );
  },
);

const StyledMuiSelect = styled(MuiSelect)<SelectProps>(props => ({
  '& .MuiSelect-select': {
    padding: props.size === 'small' ? '8px 12px' : '12px',
  },
  position: 'relative',
  padding: '0',
}));

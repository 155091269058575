import {Stack, Typography, Button as MuiButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {VFC} from 'react';
import {ArrowRightIcon} from '../images/ArrowRightIcon';

type Props = {
  selectedDate: string;
  centerButtonName: string;
  moveToCurrent: () => void;
  moveToNext: () => void;
  moveToPrev: () => void;
  disabled: boolean;
};

export const PeriodSelector: VFC<Props> = ({
  selectedDate,
  centerButtonName,
  moveToCurrent,
  moveToNext,
  moveToPrev,
  disabled,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{m: '24px 0 20px'}}>
      <Typography sx={{fontSize: '18px', fontWeight: 500, width: '120px'}}>
        {selectedDate}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <StyledMuiButton sx={{padding: '6px'}} onClick={moveToPrev}>
          <ArrowRightIcon
            sx={{
              fill: 'transparent !important',
              stroke: 'black',
              fontSize: '16px !important',
              transform: 'rotate(180deg)',
            }}
          />
        </StyledMuiButton>
        <StyledMuiButton onClick={moveToCurrent}>
          {centerButtonName}
        </StyledMuiButton>
        <StyledMuiButton
          sx={{padding: '6px'}}
          onClick={moveToNext}
          disabled={disabled}>
          <ArrowRightIcon
            sx={{
              fill: 'transparent !important',
              stroke: disabled ? '#BEC0CC' : 'black',
              fontSize: '16px !important',
            }}
          />
        </StyledMuiButton>
      </Stack>
    </Stack>
  );
};

const StyledMuiButton = styled(MuiButton)(({theme}) => ({
  backgroundColor: theme.palette.flikGray[-25],
  color: theme.palette.common.black,
  borderRadius: '6px',
  padding: '5px 8px',
  minWidth: 'unset',
  '&:hover': {
    backgroundColor: theme.palette.flikPrimary.main,
    color: 'white',
  },
  '&:hover svg': {
    stroke: 'white',
    transition: 'stroke .25s',
  },
  '&.Mui-disabled': {
    backgroundColor: '#F5F8FA',
  },
}));

import {SvgIcon} from '@mui/material';
import React from 'react';

export const ArrowBackIcon = () => {
  return (
    <SvgIcon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 12L6.5 8L10.5 4"
        stroke="#181C32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

import {Grid, Link, Stack, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {OrderType, Plan} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {Button} from '../components/Button';
import usePayment from '../hooks/usePayment';
import {MinusIcon} from '../images/MinusIcon';
import {PlanCheckIcon} from '../images/PlanCheckIcon';
import {useCreateOrderMutation} from '../queries/orders';
import {useUser} from '../queries/user';

export const PlanScreen = () => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const {mutateAsync: createOrder} = useCreateOrderMutation();
  const {initiateRecurringPayment, cancelRecurringPayment} = usePayment();
  const sub = userState.user?.attributes.sub ?? '';
  const {data, isLoading} = useUser(sub);
  const isPro = useMemo(() => data?.plan === Plan.PRO, [data?.plan]);

  if (isLoading) return <></>;
  return (
    <Stack alignItems="center" spacing={5} mb={5}>
      <Typography fontSize="24px" fontWeight={700} lineHeight={1.3}>
        プランの詳細
      </Typography>
      <Grid direction="row" container justifyContent="center">
        <Grid direction="column" container item xs={3} mr={1}>
          <Grid item height="156px" />
          <Grid item height="52px">
            <StyledTypography>物件管理</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>部屋管理</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>入居者管理</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>雑費管理</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>雑費簡易帳簿エクスポート</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>PDFデータ管理</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>年次 / 月次収支報告書自動生成</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>
              年次 / 月次収支報告書エクスポート
            </StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>繰り上げ返済シミュレーション</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>AI物件査定</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography>月次コンサルティングレポート</StyledTypography>
          </Grid>
          <Grid item height="52px">
            <StyledTypography style={{whiteSpace: 'pre-line'}}>
              コンサルティングサポート {'\n'}（オンライン面談）
            </StyledTypography>
          </Grid>
        </Grid>
        <Grid
          direction="column"
          container
          item
          xs={3.8}
          mr={1}
          sx={{
            bgcolor: '#F5F8FA',
            borderRadius: '12px',
            maxWidth: '240px !important',
          }}>
          <Grid
            item
            height="156px"
            padding="20px"
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Stack mb={2.5}>
              <Typography textAlign="center" fontSize="16px" fontWeight={700}>
                ライトプラン
              </Typography>
              <Typography textAlign="center" fontSize="20px" fontWeight={500}>
                無料
              </Typography>
            </Stack>
            {isPro ? (
              <DowngradeButton
                variant="outlined"
                onClick={async () => {
                  await createOrder(
                    {
                      input: {
                        orderType: OrderType.CANCEL_PRO_PLAN,
                        userOrdersId: sub,
                      },
                    },
                    {
                      onSuccess: async response => {
                        if (!response?.id) {
                          navigate('/error');
                          return;
                        }
                        cancelRecurringPayment(
                          response.id,
                          sub,
                          data?.subscriptionId ?? '',
                        );
                      },
                      onError: () => {
                        navigate('/error');
                        return;
                      },
                    },
                  );
                }}>
                ダウングレードする
              </DowngradeButton>
            ) : (
              <StyledUnderContractTypography>
                ご契約中のプランです
              </StyledUnderContractTypography>
            )}
          </Grid>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <MinusIcon />
          </PlanItem>
          <PlanItem>
            <MinusIcon />
          </PlanItem>
          <PlanItem>
            <MinusIcon />
          </PlanItem>
        </Grid>
        <Grid
          direction="column"
          container
          item
          xs={3.8}
          sx={{
            background:
              'linear-gradient(349.79deg, #FBFFE4 11.55%, #D9F6F7 90.64%)',
            borderRadius: '12px',
            maxWidth: '240px !important',
          }}>
          <Grid
            item
            height="156px"
            padding="20px"
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Stack mb={2.5}>
              <Typography textAlign="center" fontSize="16px" fontWeight={700}>
                プロプラン
              </Typography>
              <Typography textAlign="center" fontSize="20px" fontWeight={700}>
                2,580
                <span style={{fontSize: '14px', fontWeight: 700}}>
                  円/月(税込)
                </span>
              </Typography>
            </Stack>
            {isPro ? (
              <StyledUnderContractTypography>
                ご契約中のプランです
              </StyledUnderContractTypography>
            ) : (
              <Button
                variant="contained"
                sx={{width: '100% !important'}}
                onClick={async () => {
                  await createOrder(
                    {
                      input: {
                        orderType: OrderType.PRO_PLAN,
                        userOrdersId: sub,
                      },
                    },
                    {
                      onSuccess: async response => {
                        if (!response?.id) {
                          navigate('/error');
                          return;
                        }
                        initiateRecurringPayment(response.id, sub);
                      },
                      onError: () => {
                        navigate('/error');
                        return;
                      },
                    },
                  );
                }}>
                プロプランをはじめる
              </Button>
            )}
          </Grid>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
          <PlanItem>
            <PlanCheckIcon />
          </PlanItem>
        </Grid>
      </Grid>
      <Stack spacing={3} alignItems="center">
        {!isPro && (
          <Button
            variant="contained"
            sx={{width: '200px !important'}}
            onClick={async () => {
              await createOrder(
                {
                  input: {
                    orderType: OrderType.PRO_PLAN,
                    userOrdersId: sub,
                  },
                },
                {
                  onSuccess: async response => {
                    if (!response?.id || !sub) {
                      navigate('/error');
                      return;
                    }
                    initiateRecurringPayment(response.id, sub);
                  },
                  onError: () => {
                    navigate('/error');
                    return;
                  },
                },
              );
            }}>
            プロプランをはじめる
          </Button>
        )}
        <Link
          onClick={() => navigate(-1)}
          underline="hover"
          color="flikPrimary.main"
          fontWeight={700}
          sx={{
            cursor: 'pointer',
          }}>
          もどる
        </Link>
      </Stack>
    </Stack>
  );
};

const StyledTypography = styled(Typography)`
  font-size: 14px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
`;
const StyledUnderContractTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.flikGray['+15'],
  height: '45px',
  display: 'flex',
  alignItems: 'center',
}));
const PlanItem = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
`;
const DowngradeButton = styled(Button)(({theme}) => ({
  backgroundColor: 'white',
  borderColor: theme.palette.flikGray['+15'],
  color: theme.palette.flikGray['+15'],
  width: '100% !important',
  '&:hover': {
    borderColor: theme.palette.flikGray['+15'],
  },
}));

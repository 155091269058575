import {Box, BoxProps} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {ReactNode, VFC} from 'react';

type Props = {
  color?: 'primary-40' | 'gray-30';
  children: ReactNode;
} & BoxProps;

export const Cell: VFC<Props> = ({color = 'gray-30', children, ...props}) => {
  return (
    <StyledBox color={color} {...props}>
      {children}
    </StyledBox>
  );
};

const StyledBox = styled(Box)(props => ({
  backgroundColor:
    props.color === 'primary-40'
      ? props.theme.palette.flikPrimary['-40']
      : props.theme.palette.flikGray['-30'],
  padding: '8px 12px',
  minWidth: '250px',
  minHeight: '53px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  border: '1px solid white',
  wordBreak: 'break-all',
}));

import {CircularProgress, Modal, Stack, Typography} from '@mui/material';
import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {BaseBox as Box} from './BaseBox';
import {Button} from './Button';

export type PropsForDeleteProperty = {
  openDeletePropertyModal: boolean;
  setOpenDeletePropertyModal: Dispatch<SetStateAction<boolean>>;
  onPropertyDelete: () => Promise<void>;
  propertyName: string;
  isLoading: boolean;
};

export const DeletePropertyModal: React.VFC<PropsForDeleteProperty> = ({
  openDeletePropertyModal,
  setOpenDeletePropertyModal,
  onPropertyDelete,
  propertyName,
  isLoading,
}: PropsForDeleteProperty) => {
  const handleClose = useCallback(() => {
    setOpenDeletePropertyModal(false);
  }, [setOpenDeletePropertyModal]);

  const handleDelete = useCallback(() => {
    onPropertyDelete();
  }, [onPropertyDelete]);

  return (
    <Modal
      open={openDeletePropertyModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '464px',
          padding: '40px',
          boxShadow: 'none',
        }}>
        <Typography
          textAlign="center"
          sx={{fontSize: '20px', fontWeight: '500', LineHeight: '26px'}}>
          削除
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '300',
            LineHeight: '24px',
            marginTop: '24px',
            marginBottom: '40px',
          }}>
          <span
            style={{
              fontWeight: 600,
              wordWrap: 'break-word',
            }}>
            {`${propertyName}`}
          </span>
          を削除します。 <br />
          削除後のデータは復元できませんのでご注意ください。
        </Typography>
        <Stack direction="row" spacing={3} justifyContent="center">
          <Button variant="outlined" onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="warning"
            disabled={isLoading}
            startIcon={
              isLoading ? (
                <CircularProgress
                  sx={{
                    color: 'white',
                    width: '20px  !important',
                    height: '20px  !important',
                  }}
                />
              ) : null
            }
            onClick={handleDelete}>
            {isLoading ? '削除中' : ' 削除する'}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

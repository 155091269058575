import {StructureType} from '../API';

export const convertStringToStructureType = (value: string) => {
  switch (value) {
    case 'W':
      return StructureType.W;
    case 'LIGHTS':
      return StructureType.LIGHTS;
    case 'WEIGHTS':
      return StructureType.WEIGHTS;
    case 'RC':
      return StructureType.RC;
    case 'SRC':
      return StructureType.SRC;
    case 'AL':
      return StructureType.AL;
    case 'CFT':
      return StructureType.CFT;
    case 'CB':
      return StructureType.CB;
    default:
      return StructureType.OTHER;
  }
};

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useEffect, useState, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {IconButton} from './IconButton';
import {UploadFileInfo} from './UploadFileModal';
import {DeleteIcon} from '../images/DeleteIcon';
import {useProperties, useProperty} from '../queries/property';
import {usePropertyFiles} from '../queries/propertyFile';
import {categoryList} from '../screens/Files';
import {convertBytesToMegabytes} from '../utils/convertBytesToMegabytes';
import {isNonNull} from '../utils/isNonNull';

type Props = {
  uploadFile: UploadFileInfo;
  fileIndex: number;
  handleUpdate: (info: UploadFileInfo) => void;
  handleDelete: (info: UploadFileInfo) => void;
};

export const CustomUploadFile = ({
  uploadFile,
  fileIndex,
  handleUpdate,
  handleDelete,
}: Props) => {
  const [selectPropertyId, setSelectPropertyId] = useState<string | undefined>(
    '',
  );
  const {data: properties} = useProperties();
  const {data: property} = useProperty(selectPropertyId);
  const {data: files} = usePropertyFiles();
  const [isDuplicateFile, setIsDuplicateFile] = useState(false);
  const {
    register,
    formState: {errors},
  } = useFormContext<UploadFileInfo[]>();

  useEffect(() => {
    const propertyId = properties?.items.filter(isNonNull).find(property => {
      return property.name === uploadFile.property;
    });
    setSelectPropertyId(propertyId?.id);
  }, [uploadFile.property, properties]);

  useEffect(() => {
    if (!files) return;
    const registedFile = files.items.filter(isNonNull).map(item => {
      return item.id;
    });
    if (uploadFile && !!uploadFile.name) {
      const encodedFileName = encodeURIComponent(uploadFile.name);
      registedFile.includes(encodedFileName)
        ? setIsDuplicateFile(true)
        : setIsDuplicateFile(false);
    }
  }, [uploadFile, files, setIsDuplicateFile]);

  useEffect(() => {
    uploadFile.error = isDuplicateFile;
    handleUpdate(uploadFile);
  }, [handleUpdate, isDuplicateFile, uploadFile]);

  const rooms = useMemo(() => {
    return property?.rooms?.items ?? null;
  }, [property]);

  const onSelectCategory = (event: SelectChangeEvent) => {
    uploadFile.category = event.target.value;
    handleUpdate(uploadFile);
  };

  const onSelectProperty = (event: SelectChangeEvent) => {
    uploadFile.property = event.target.value;
    uploadFile.room = null;
    handleUpdate(uploadFile);
  };

  const onSelectRoom = (event: SelectChangeEvent) => {
    uploadFile.room = event.target.value;
    handleUpdate(uploadFile);
  };

  return (
    <TableBody>
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography
            noWrap
            width="200px"
            sx={{
              fontSize: '14px',
              lineHeight: '18.2px',
            }}>
            {uploadFile.name}
          </Typography>
          {isDuplicateFile ? (
            <Typography
              sx={{
                fontSize: '14px',
                color: '#DA0000',
                lineHeight: '18.2px',
              }}>
              すでに同名のファイルが存在します
            </Typography>
          ) : null}
        </TableCell>
        <TableCell>
          {convertBytesToMegabytes(uploadFile.size).toFixed(2) + 'MB'}
        </TableCell>
        <TableCell>
          <FormControl>
            <Select
              defaultValue={uploadFile.category ?? ''}
              value={uploadFile.category ?? ''}
              displayEmpty
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingY: '12px',
                  paddingX: '10px',
                },
              }}
              {...register(`${fileIndex}.category`, {
                required: true,
              })}
              error={errors?.[fileIndex]?.category?.type === 'required'}
              onChange={onSelectCategory}>
              <MenuItem value="">選択してください</MenuItem>
              {categoryList.map(category => (
                <MenuItem value={category?.name} key={category.name}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{color: 'warning.main'}}>
              {errors?.[fileIndex]?.category?.type === 'required' &&
                '選択してください'}
            </FormHelperText>
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl>
            <Select
              defaultValue={uploadFile.property ?? ''}
              value={uploadFile.property ?? ''}
              displayEmpty
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingY: '12px',
                  paddingX: '10px',
                },
              }}
              {...register(`${fileIndex}.property`)}
              onChange={onSelectProperty}>
              <MenuItem value="">選択してください</MenuItem>
              {properties?.items.filter(isNonNull).map(property => (
                <MenuItem value={property?.name} key={property.id}>
                  {property?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <Select
            defaultValue={uploadFile.room ?? ''}
            value={uploadFile.room ?? ''}
            displayEmpty
            sx={{
              '& .MuiOutlinedInput-input': {
                paddingY: '12px',
                paddingX: '10px',
              },
            }}
            onChange={onSelectRoom}>
            <MenuItem value="">選択してください</MenuItem>
            {rooms?.filter(isNonNull).map(item => (
              <MenuItem value={item.name} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell>
          <IconButton tooltip="削除" onClick={() => handleDelete(uploadFile)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TreeItem} from '@mui/lab';
import TreeView from '@mui/lab/TreeView';
import {Box, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CurrentResidentInfo} from './CurrentResidentInfo';
import {DeleteResidentModal} from './DeleteResidentModal';
import {MoveOutResidentModal} from './MoveOutResidentModal';
import {PastResidentInfo} from './PastResidentInfo';
import {Button} from '../components/Button';
import {DeleteIcon} from '../images/DeleteIcon';
import {EditIcon} from '../images/EditIcon';
import {EnterIcon} from '../images/EnterIcon';
import {ExitIcon} from '../images/ExitIcon';
import {useRoom} from '../queries/room';
import {convertDateToString} from '../utils/convertDateToString';

export const ResidentInfo = () => {
  const {roomId} = useParams();
  const {data: room} = useRoom(roomId);
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openMoveOut, setOpenMoveOut] = useState(false);

  const residents = useMemo(() => {
    if (!room) return null;
    return room.residents?.items;
  }, [room]);
  const currentResidentId = useMemo(() => {
    if (!residents) return '';
    const id = residents.find(r => !r?.endDate)?.id;
    return id ?? '';
  }, [residents]);
  const pastResident = useMemo(() => {
    if (!residents) return null;
    return residents.filter(r => !!r?.endDate);
  }, [residents]);

  const onCloseDeleteModal = useCallback(() => {
    setOpenDelete(false);
  }, []);

  const onCloseMoveOutModal = useCallback(() => {
    setOpenMoveOut(false);
  }, []);

  return (
    <>
      <Stack spacing={6}>
        <div>
          <StyledBox alignItems="center">
            <Typography sx={{fontWeight: 500, marginRight: '40px'}}>
              現在の契約者情報
            </Typography>
            {room?.soldDate ? null : currentResidentId ? (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<EditIcon color="primary" />}
                  onClick={() => navigate('editResident')}>
                  編集
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<ExitIcon color="primary" />}
                  onClick={() => setOpenMoveOut(true)}>
                  退去
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  startIcon={<DeleteIcon color="error" />}
                  onClick={() => setOpenDelete(true)}>
                  削除
                </Button>
              </Stack>
            ) : (
              <Button
                variant="outlined"
                size="small"
                startIcon={<EnterIcon color="primary" />}
                onClick={() => navigate('addResident')}>
                入居
              </Button>
            )}
          </StyledBox>
          <CurrentResidentInfo id={currentResidentId} />
        </div>
        <div>
          <StyledBox>
            <Typography sx={{fontWeight: 500}}>過去の契約者情報</Typography>
          </StyledBox>
          {pastResident && pastResident.length > 0 ? (
            <>
              <Typography sx={{fontWeight: 500, m: '8px 0 8px 12px'}}>
                契約期間
              </Typography>
              <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect>
                {pastResident.map(
                  (r, index) =>
                    r && (
                      <TreeItem
                        key={index}
                        nodeId={String(index)}
                        label={`${convertDateToString(
                          r.startDate,
                          'yyyy/M/d',
                        )} 〜 ${
                          r.endDate &&
                          convertDateToString(r.endDate, 'yyyy/M/d')
                        }`}>
                        <PastResidentInfo residentId={r.id} />
                      </TreeItem>
                    ),
                )}
              </TreeView>
            </>
          ) : (
            <Typography sx={{m: '8px 0 8px 12px'}}>
              過去の契約者情報はありません。
            </Typography>
          )}
        </div>
      </Stack>
      <DeleteResidentModal
        open={openDelete}
        onClose={onCloseDeleteModal}
        residentId={currentResidentId}
      />
      <MoveOutResidentModal
        open={openMoveOut}
        onClose={onCloseMoveOutModal}
        residentId={currentResidentId}
      />
    </>
  );
};

const StyledBox = styled(Box)(props => ({
  padding: '8px 12px',
  backgroundColor: props.theme.palette.flikGray['-30'],
  width: 'max-content',
  display: 'flex',
  borderRadius: '6px',
}));

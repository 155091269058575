import {Grid, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React, {VFC} from 'react';
import {Cell} from './Cell';
import {LabelTitle} from './LabelTitle';
import {MoneyInfoTableHead} from './MoneyInfoTableHead';
import {MoneyList} from './MoneyList';
import {GetResidentQueryVariables} from '../API';
import {useResident} from '../queries/resident';
import {convertDateToString} from '../utils/convertDateToString';
import {isNonNull} from '../utils/isNonNull';

type Props = {
  id: GetResidentQueryVariables['id'];
};

export const CurrentResidentInfo: VFC<Props> = ({id}) => {
  const {data: resident} = useResident(id);

  return id && resident ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography sx={{fontWeight: 500, m: '16px 0 8px'}}>
          基本情報
        </Typography>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>入居開始日</LabelTitle>
          </Cell>
          <StyledCell>
            {convertDateToString(resident.startDate, 'yyyy/M/d')}
          </StyledCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>入居者氏名</LabelTitle>
          </Cell>
          <StyledCell>{resident.name}</StyledCell>
        </Stack>
        {resident.customItems?.filter(isNonNull).map((item, index) => (
          <Grid item xs={12} key={index}>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>{item.key}</LabelTitle>
              </Cell>
              <StyledCell>
                <Typography>{item.value}</Typography>
              </StyledCell>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{fontWeight: 500, mb: '8px'}}>収入</Typography>
        <MoneyInfoTableHead />
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>礼金</LabelTitle>
          </Cell>
          <StyledCostCell>
            <Stack direction="column" spacing={1}>
              {resident?.keyMoney.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCostCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>初月賃料</LabelTitle>
          </Cell>
          <StyledCostCell>
            <Stack direction="column" spacing={1}>
              {resident.firstIncome?.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCostCell>
        </Stack>
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>月額賃料</LabelTitle>
          </Cell>
          <StyledCostCell>
            <Stack direction="column" spacing={1}>
              {resident.rent?.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCostCell>
        </Stack>
        {resident.customMoveInCostItems
          ?.filter(isNonNull)
          .map((item, index) => (
            <Grid item xs={12} key={index}>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle>{item.key}</LabelTitle>
                </Cell>
                <StyledCostCell>
                  <Stack direction="column" spacing={1}>
                    {item.value?.map((c, index) => (
                      <MoneyList key={index} cost={c} />
                    ))}
                  </Stack>
                </StyledCostCell>
              </Stack>
            </Grid>
          ))}
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{fontWeight: 500, mb: '8px'}}>費用</Typography>
        <MoneyInfoTableHead />
        <Stack direction="row" spacing={0}>
          <Cell color="primary-40">
            <LabelTitle>広告料</LabelTitle>
          </Cell>
          <StyledCostCell>
            <Stack direction="column" spacing={1}>
              {resident?.advertisingRates?.map((c, index) => (
                <MoneyList key={index} cost={c} />
              ))}
            </Stack>
          </StyledCostCell>
        </Stack>
        {resident.customMoveOutCostItems
          ?.filter(isNonNull)
          .map((item, index) => (
            <Grid item xs={12} key={index}>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle>{item.key}</LabelTitle>
                </Cell>
                <StyledCostCell>
                  <Stack direction="column" spacing={1}>
                    {item.value?.map((c, index) => (
                      <MoneyList key={index} cost={c} />
                    ))}
                  </Stack>
                </StyledCostCell>
              </Stack>
            </Grid>
          ))}
      </Grid>
    </Grid>
  ) : (
    <Typography sx={{m: '8px 0 8px 12px'}}>入居者はいません。</Typography>
  );
};

const StyledCell = styled(Cell)({
  width: '450px',
});
const StyledCostCell = styled(Cell)({
  width: '100%',
});

import {Button, Grid, TextField, Stack, Typography} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import {API, graphqlOperation} from 'aws-amplify';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {CreateUserMutationVariables} from '../API';
import {BoxForAuth} from '../components/BoxForAuth';
import {Logo} from '../components/Logo';
import {createUser} from '../graphql/mutations';

type FormInput = {
  code: string;
};

export const SignUpVerificationScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [codeMismatchException, setCodeMismatchException] = useState(false);
  const state = location.state as {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  };
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
  });
  return (
    <BoxForAuth>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mb: '24.5px'}}>
        <Logo />
      </Grid>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '756px',
          borderRadius: '12px',
          bgcolor: 'flikGray.-30',
          padding: '40px',
        }}>
        <Typography
          textAlign="center"
          sx={{
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: '700',
            LineHeight: '26px',
            mb: '24px',
          }}>
          認証コードを送信しました
        </Typography>
        <Typography textAlign="center" sx={{mb: '33px'}}>
          ご登録いただいたメールアドレスへ認証コードをお送りしました。
          <br />
          届いたメールに記載の認証コードを入力し、登録を進めてください。
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item>
            <Typography sx={{fontSize: '16px', mr: '24px'}}>
              認証コード
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              sx={{
                width: '452px',
                '& .MuiInputBase-root': {bgcolor: 'white'},
              }}
              error={!!(errors.code || codeMismatchException)}
              {...register('code', {
                required: true,
                pattern: /^(?=.*?\d)[\d]{1,}$/,
              })}
              helperText={
                errors.code || codeMismatchException
                  ? '認証に失敗しました。認証コードをもう一度入力してください。'
                  : ''
              }></TextField>
          </Grid>
        </Grid>
        <Button
          onClick={() => {
            Auth.resendSignUp(state.email);
          }}
          sx={{color: 'flikPrimary.main', fontSize: '12px', ml: '420px'}}>
          認証コードを再送信する
        </Button>
        <Button
          sx={{
            '&:hover': {
              bgcolor: 'flikPrimary.main',
            },
            width: '270px',
            height: '45px',
            borderRadius: 2,
            textAlign: 'center',
            p: 1,
            mt: '30px',
            bgcolor: 'flikPrimary.main',
            color: 'white',
            fontWeight: '700',
          }}
          onClick={handleSubmit(data => {
            Auth.confirmSignUp(state.email, data.code)
              .then(() => {
                Auth.signIn(state.email, state.password).then(
                  async (data: {
                    attributes: {
                      sub: string;
                      email: string;
                    };
                  }) => {
                    const variables: CreateUserMutationVariables = {
                      input: {
                        id: data.attributes.sub,
                        email: data.attributes.email,
                        firstName: state.firstName,
                        lastName: state.lastName,
                      },
                    };
                    try {
                      await API.graphql(
                        graphqlOperation(createUser, variables),
                      );
                    } catch (err) {
                      Sentry.captureException(err);
                      await Auth.deleteUser();
                      navigate('/error');
                      return;
                    }
                    navigate('/', {state: {firstSignIn: true}});
                  },
                );
              })
              .catch(err => {
                Sentry.captureException(err);
                if (err.code === 'CodeMismatchException') {
                  setCodeMismatchException(true);
                } else {
                  navigate('/error');
                }
                return;
              });
          })}>
          次へ
        </Button>
      </Stack>
    </BoxForAuth>
  );
};

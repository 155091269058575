import {Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '../components/Button';

export const NotFoundErrorScreen = () => {
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" mt="100px">
      <Stack spacing={2} alignItems="center">
        <Typography
          color="flikGray.-20"
          sx={{fontSize: '160px', fontWeight: 'bold'}}>
          404
        </Typography>
        <Typography sx={{fontSize: '20px', fontWeight: 'bold'}}>
          ページが見つかりませんでした
        </Typography>
        <Typography>
          一時的にアクセスできない状態か、ページが移動または削除された可能性があります。
        </Typography>
      </Stack>
      <Button
        variant="contained"
        sx={{mt: '46px'}}
        onClick={() => navigate('/')}>
        トップページ
      </Button>
    </Stack>
  );
};

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {EarlyRepaymentInfoInput} from '../API';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {Cell} from '../components/Cell';
import {DatePicker} from '../components/DatePicker';
import {EarlyRepaymentInfo} from '../components/EarlyRepaymentInfo';
import {EarlyRepaymentInfoHead} from '../components/EarlyRepaymentInfoHead';
import {LabelTitle} from '../components/LabelTitle';
import {Navigation} from '../components/Navigation';
import {Select} from '../components/Select';
import {Snackbar} from '../components/Snackbar';
import {MAX_INPUT_LENGTH, TextField} from '../components/TextField';
import {PlusIcon} from '../images/PlusIcon';
import {useProperty} from '../queries/property';
import {useRoom, useUpdateRoomMutation} from '../queries/room';
import {RoomBasicInfoType} from '../screens/AddRoom';
import {convertStringToRepaymentOrNull} from '../utils/convertStringToRepaymentOrNull';
import {isNonNull} from '../utils/isNonNull';
import {ReductionType} from '../utils/repaymentSimulation';
import {
  earlyRepaymentMethodList,
  loanYearsList,
  repaymentMethodList,
} from '../utils/selectOptions';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export type RepaymentInfo = Pick<
  RoomBasicInfoType,
  'loanAmount' | `interestRate` | 'loanYears' | 'repaymentMethod'
> & {
  earlyRepaymentMethod: ReductionType;
  earlyRepaymentInfo: EarlyRepaymentInfoInput[];
  deliveryDate: Date;
  repaymentDate: Date;
};

export const initialValue: EarlyRepaymentInfoInput = {
  year: new Date().getFullYear().toString(),
  month: '1',
  repaymentAmount: 0,
};

export const RepaymentInfoScreen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {mutate: updateRoom} = useUpdateRoomMutation();
  const {propertyId, roomId} = useParams();
  const {data: room} = useRoom(roomId);
  const {data: property} = useProperty(propertyId);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(
    room?.deliveryDate ? new Date(room.deliveryDate) : null,
  );
  const [repaymentDate, setRepaymentDate] = useState<Date | null>(
    room?.repaymentDate ? new Date(room?.repaymentDate) : null,
  );
  const [isChecked, setIsChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const {
    register,
    setValue,
    formState: {errors},
    handleSubmit,
    getValues,
    control,
  } = useForm<RepaymentInfo>({
    mode: 'onBlur',
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'earlyRepaymentInfo',
  });

  const isNotValidDeliveryDate = useMemo(
    () => !deliveryDate || isNaN(deliveryDate.getDate()),
    [deliveryDate],
  );

  const isNotValidRepaymentDate = useMemo(
    () => !repaymentDate || isNaN(repaymentDate.getDate()),
    [repaymentDate],
  );

  useEffect(() => {
    setValue(
      'earlyRepaymentInfo',
      room && room.earlyRepaymentInfo && room.earlyRepaymentInfo.length > 0
        ? room.earlyRepaymentInfo.filter(isNonNull)
        : [{...initialValue}],
    );
  }, [room, setValue]);

  const onChangeDeliveryDate = useCallback((years: Date | null) => {
    setDeliveryDate(years);
  }, []);
  const onChangeRepaymentDate = useCallback((years: Date | null) => {
    setRepaymentDate(years);
  }, []);
  const onSave = useCallback(async () => {
    if (!isChecked) {
      return;
    }
    if (!roomId || !room || !repaymentDate) {
      navigate('/error');
      return;
    }
    updateRoom(
      {
        input: {
          id: roomId,
          propertyRoomsId: propertyId,
          interestRate: getValues('interestRate'),
          repaymentMethod: convertStringToRepaymentOrNull(
            getValues('repaymentMethod') ?? '',
          ),
          repaymentDate: toISOStringWithTimezone(repaymentDate),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          setOpenSnackbar(true);
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [
    getValues,
    isChecked,
    navigate,
    propertyId,
    queryClient,
    repaymentDate,
    room,
    roomId,
    updateRoom,
  ]);
  const onNavigate: SubmitHandler<RepaymentInfo> = useCallback(
    data => {
      if (
        !deliveryDate ||
        !repaymentDate ||
        isNotValidDeliveryDate ||
        isNotValidRepaymentDate
      ) {
        return;
      }
      const repaymentInfo = {
        loanAmount: data.loanAmount,
        interestRate: data.interestRate,
        repaymentCount: data.loanYears,
        repaymentMethod: data.repaymentMethod,
        earlyRepaymentMethod: data.earlyRepaymentMethod,
        repaymentDate: repaymentDate,
        deliveryDate: deliveryDate,
        earlyRepaymentInfo: data.earlyRepaymentInfo,
      };
      navigate(`/repaymentResult/${roomId}/${propertyId}`, {
        state: {
          loanAmount: Number(repaymentInfo.loanAmount),
          interestRate: repaymentInfo.interestRate,
          repaymentCount: repaymentInfo.repaymentCount,
          repaymentMethod: repaymentInfo.repaymentMethod,
          earlyRepaymentMethod: repaymentInfo.earlyRepaymentMethod,
          repaymentDate: repaymentInfo.repaymentDate,
          deliveryDate: repaymentInfo.deliveryDate,
          earlyRepaymentInfo: repaymentInfo.earlyRepaymentInfo,
        },
      });
    },
    [
      deliveryDate,
      isNotValidDeliveryDate,
      isNotValidRepaymentDate,
      navigate,
      propertyId,
      repaymentDate,
      roomId,
    ],
  );

  return room && property ? (
    <>
      <Box sx={{display: 'flex'}}>
        <Navigation />
        <Box sx={{flexGrow: 1}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginBottom: '24px',
              //  maxWidth:　画面幅 - サイドメニュー幅 - 左右padding
              maxWidth: 'calc(100vw - 240px - 64px)',
            }}
            spacing={3}>
            <Typography
              noWrap
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: 1.3,
              }}>
              繰り上げ返済シミュレーション - 情報入力
            </Typography>
          </Stack>
          <BaseBox>
            <Stack direction="column" sx={{mb: '24px'}}>
              <Typography sx={{fontWeight: 500, mb: '8px'}}>
                基本情報
              </Typography>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>借入金額</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <TextField
                      type="number"
                      defaultValue={room.loanAmount ?? 0}
                      {...register('loanAmount', {
                        required: true,
                        maxLength: MAX_INPUT_LENGTH,
                      })}
                      helperText={
                        errors.loanAmount?.type === 'required' &&
                        '借入金額を入力してください'
                      }
                      error={errors.loanAmount?.type === 'required'}
                      size="small"
                    />
                    <Typography sx={{marginLeft: '4px'}}>円</Typography>
                  </StyledForm>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>金利</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <TextField
                      type="number"
                      defaultValue={room.interestRate ?? 0}
                      {...register('interestRate', {
                        required: true,
                        maxLength: MAX_INPUT_LENGTH,
                      })}
                      helperText={
                        errors.interestRate?.type === 'required' &&
                        '金利を入力してください'
                      }
                      error={errors.interestRate?.type === 'required'}
                      size="small"
                    />
                    <Typography sx={{marginLeft: '4px'}}>％</Typography>
                  </StyledForm>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>期間</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <FormControl fullWidth>
                      <Select
                        {...register('loanYears', {
                          required: true,
                          maxLength: MAX_INPUT_LENGTH,
                        })}
                        error={errors.loanYears?.type === 'required'}
                        defaultValue={room.loanYears ? room.loanYears * 12 : 12}
                        sx={{width: '450px'}}>
                        {loanYearsList.map((s, index) => (
                          <MenuItem value={s.value} key={index}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{color: 'warning.main'}}>
                        {errors.loanYears?.type === 'required' &&
                          '融資期間を選択してください'}
                      </FormHelperText>
                    </FormControl>
                  </StyledForm>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>返済方法</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <FormControl fullWidth>
                      <Select
                        defaultValue={room.repaymentMethod ?? ''}
                        displayEmpty
                        {...register('repaymentMethod', {
                          required: true,
                        })}
                        error={errors.repaymentMethod?.type === 'required'}>
                        <MenuItem value="">選択する</MenuItem>
                        {repaymentMethodList.map((s, index) => (
                          <MenuItem value={s.value} key={index}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{color: 'warning.main'}}>
                        {errors.repaymentMethod?.type === 'required' &&
                          '返済方法を選択してください'}
                      </FormHelperText>
                    </FormControl>
                  </StyledForm>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>繰上げ返済方式</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <FormControl fullWidth>
                      <Select
                        {...register('earlyRepaymentMethod')}
                        defaultValue={'repaymentAmountReduction'}>
                        {earlyRepaymentMethodList.map((s, index) => (
                          <MenuItem value={s.value} key={index}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{color: 'warning.main'}}>
                        {errors.earlyRepaymentMethod?.type === 'required' &&
                          '繰上げ返済方式を選択してください'}
                      </FormHelperText>
                    </FormControl>
                  </StyledForm>
                </StyledCell>
              </Stack>
            </Stack>
            <Stack direction="column" sx={{mb: '24px', width: '100%'}}>
              <Typography sx={{fontWeight: 500, mb: '8px'}}>
                繰り上げ返済設定
              </Typography>
              <Stack direction="row" spacing={0}>
                <Stack direction="column" width="100%">
                  <EarlyRepaymentInfoHead />
                  {fields.map((field, index) => (
                    <Stack key={field.id} direction="row" spacing={0}>
                      <Cell color="primary-40">{`繰り上げ返済${
                        index + 1
                      }`}</Cell>
                      <StyledCell>
                        <EarlyRepaymentInfo
                          key={field.id}
                          register={register}
                          name="earlyRepaymentInfo"
                          index={index}
                          defaultValue={field}
                          errors={
                            Array.isArray(errors.earlyRepaymentInfo)
                              ? errors.earlyRepaymentInfo
                              : undefined
                          }
                          onRemove={remove}
                        />
                      </StyledCell>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Box sx={{margin: '16px 0 0'}}>
                <Button
                  size="small"
                  startIcon={<PlusIcon strokeColor="#31CDD3" />}
                  onClick={() => append({...initialValue})}>
                  繰り上げ返済情報を追加
                </Button>
              </Box>
            </Stack>
            <Stack direction="column" sx={{mb: '24px'}}>
              <Typography sx={{fontWeight: 500, mb: '8px'}}>
                返済に関する情報
              </Typography>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>返済開始日</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      inputFormat={'yyyy/MM/dd'}
                      value={repaymentDate}
                      error={isNotValidRepaymentDate}
                      onChangeValue={onChangeRepaymentDate}
                    />
                  </StyledForm>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <Cell color="primary-40">
                  <LabelTitle isRequired>融資実行日</LabelTitle>
                </Cell>
                <StyledCell>
                  <StyledForm>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      inputFormat={'yyyy/MM/dd'}
                      value={deliveryDate}
                      error={isNotValidDeliveryDate}
                      onChangeValue={onChangeDeliveryDate}
                    />
                  </StyledForm>
                </StyledCell>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={() => setIsChecked(isChecked => !isChecked)}
                  />
                }
                label="入力した「金利」「返済方法」「返済開始日」を部屋情報に反映する"
              />
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
              <Button
                variant="outlined"
                onClick={() => navigate(`/property/${propertyId}/${room.id}`)}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                onMouseDown={handleSubmit(onSave)}
                onClick={handleSubmit(onNavigate)}>
                計算する
              </Button>
            </Stack>
          </BaseBox>
        </Box>
      </Box>
      <Snackbar
        severity="success"
        message="返済情報の更新が完了しました"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : null;
};

const StyledCell = styled(Cell)(() => ({
  width: '100%',
}));

const StyledForm = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  maxWidth: '450px',
  width: '100%',
}));

import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateRoomMutation,
  CreateRoomMutationVariables,
  DeleteRoomMutation,
  DeleteRoomMutationVariables,
  GetRoomQuery,
  ListRoomsByOwnerIdQuery,
  ListRoomsByOwnerIdQueryVariables,
  ModelRoomFilterInput,
  UpdateRoomMutation,
  UpdateRoomMutationVariables,
} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {createRoom, deleteRoom, updateRoom} from '../graphql/mutations';
import {getRoom, listRoomsByOwnerId} from '../graphql/queries';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export const useRoom = (roomId?: string) => {
  return useQuery(
    ['room', roomId],
    async () => {
      if (!roomId) return;
      const result = (await API.graphql(
        graphqlOperation(getRoom, {id: roomId}),
      )) as GraphQLResult<GetRoomQuery>;
      return result.data?.getRoom;
    },
    {
      enabled: !!roomId,
    },
  );
};

export const useRooms = (filter?: ModelRoomFilterInput | null) => {
  const user = useOAuthUser();
  return useQuery(['rooms', user?.attributes.sub], async () => {
    if (!user) {
      return undefined;
    }
    const variables: ListRoomsByOwnerIdQueryVariables = {
      ownerId: user.attributes.sub,
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listRoomsByOwnerId, variables),
    )) as GraphQLResult<ListRoomsByOwnerIdQuery>;
    return result?.data?.listRoomsByOwnerId;
  });
};

export const useUnsoldRooms = () => {
  return useRooms({
    soldDate: {
      attributeExists: false,
    },
  });
};

export const useRoomsByYear = (year: number) => {
  const date = new Date(year.toString());
  const nextYearDate = new Date(date);
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
  return useRooms({
    or: [
      {
        soldDate: {
          ge: toISOStringWithTimezone(date),
        },
        deliveryDate: {
          lt: toISOStringWithTimezone(nextYearDate),
        },
      },
      {
        soldDate: {
          attributeExists: false,
        },
        deliveryDate: {
          lt: toISOStringWithTimezone(nextYearDate),
        },
      },
    ],
  });
};

export const useRoomsByMonth = (year: number, month: number) => {
  const date = new Date(year.toString() + '-' + ('00' + month).slice(-2));
  const nextMonthDate = new Date(date);
  nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
  return useRooms({
    or: [
      {
        soldDate: {
          ge: toISOStringWithTimezone(date),
        },
        deliveryDate: {
          lt: toISOStringWithTimezone(nextMonthDate),
        },
      },
      {
        soldDate: {
          attributeExists: false,
        },
        deliveryDate: {
          lt: toISOStringWithTimezone(nextMonthDate),
        },
      },
    ],
  });
};

export const useCreateRoomMutation = () => {
  return useMutation(async (variables: CreateRoomMutationVariables) => {
    const room = (await API.graphql(
      graphqlOperation(createRoom, variables),
    )) as GraphQLResult<CreateRoomMutation>;
    return room.data?.createRoom;
  });
};

export const useUpdateRoomMutation = () => {
  return useMutation(async (variables: UpdateRoomMutationVariables) => {
    const room = (await API.graphql(
      graphqlOperation(updateRoom, variables),
    )) as GraphQLResult<UpdateRoomMutation>;
    return room.data?.updateRoom;
  });
};

export const useDeleteRoomMutation = () => {
  return useMutation(async (variables: DeleteRoomMutationVariables) => {
    const room = (await API.graphql(
      graphqlOperation(deleteRoom, variables),
    )) as GraphQLResult<DeleteRoomMutation>;
    return room.data?.deleteRoom;
  });
};

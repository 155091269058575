import {Stack, Typography, styled} from '@mui/material';
import {useMemo, useState} from 'react';
import {Cell} from './Cell';
import {ConsultingSheetList} from './ConsultingSheetList';
import {LabelTitle} from './LabelTitle';
import {ConsultingSheetInput, User} from '../API';
import {convertDateToString} from '../utils/convertDateToString';

type Props = {consultingData: ConsultingSheetInput[]; userInfo: User};

export const ConsultingSheet = ({consultingData, userInfo}: Props) => {
  const [selectSheetIndex, setSelectSheetIndex] = useState(0);

  const remainingPrincipalAdvice = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const lapsedYears =
      currentYear -
      new Date(consultingData[selectSheetIndex].deliveryDate).getFullYear();
    if (lapsedYears < 5)
      return '売却による投資金額の回収にはまだ早い可能性が高いです';
    if (lapsedYears < 10)
      return '売却による投資金額の回収が出来る可能性があります';
    return '売却による投資金額の回収が出来る可能性があります';
  }, [consultingData, selectSheetIndex]);

  const totalPrincipalPerYearAdvice = useMemo(() => {
    const totalPrincipalPerYear =
      consultingData[selectSheetIndex].totalPrincipalPerYear;
    if (totalPrincipalPerYear)
      return `年間で約${totalPrincipalPerYear.toLocaleString()}円ローン残高が減少します。`;
    return null;
  }, [consultingData, selectSheetIndex]);

  const interestRateAdvice = useMemo(() => {
    const currentInterestRate =
      consultingData[selectSheetIndex].interestRate ?? 0;
    if (currentInterestRate <= 1.8)
      return '平均か平均よりやや低く、良い状態の金利です';
    if (currentInterestRate <= 2.0)
      return '平均か平均よりやや高いですが、良い状態の金利です';
    return '平均より高く改善の余地がある金利です';
  }, [consultingData, selectSheetIndex]);

  const yearOfTermReductionAdvice = useMemo(() => {
    const yearOfTermReduction =
      consultingData[selectSheetIndex].yearOfTermReduction;
    if (yearOfTermReduction)
      return `100万円あたり約${consultingData[selectSheetIndex].yearOfTermReduction}年返済期間が短縮します。`;
    return null;
  }, [consultingData, selectSheetIndex]);

  const roomConditionAdvice = useMemo(() => {
    switch (consultingData[selectSheetIndex].condition) {
      case '空室':
        return '募集家賃・礼金・広告料を調整して早期の入居を促しましょう';
      case '集金代行':
        return '管理委託手数料を定期的に見直しましょう';
      case '自主管理':
        return '口座管理を月に一度行い、滞納に注意してください';
      case 'サブリース':
        return 'サブリースを解除することで家賃収入が増加する可能性があります';
      default:
        return null;
    }
  }, [consultingData, selectSheetIndex]);

  const reducedRepaymentAdvice = useMemo(() => {
    const reducedRepayment = consultingData[selectSheetIndex].reducedRepayment;
    if (reducedRepayment)
      return `100万円あたり月額約${reducedRepayment.toLocaleString()}円返済金額が短縮します。`;
    return null;
  }, [consultingData, selectSheetIndex]);

  const roomRepairAdvice = useMemo(() => {
    const editYears = consultingData[selectSheetIndex].years;
    if (!editYears) return null;
    const currentYear = new Date().getFullYear();
    const lapsedYears = currentYear - new Date(editYears).getFullYear();
    if (lapsedYears <= 5)
      return 'まだ修繕積立金が上昇する可能性は低いと思われます';
    if (lapsedYears < 10) return '修繕積立金が上昇する可能性が高い思われます';
    return '修繕積立金が上昇する可能性が非常に高い、または上昇済みと思われます';
  }, [consultingData, selectSheetIndex]);

  const managementCommissionAdvice = useMemo(() => {
    if (consultingData[selectSheetIndex].managementCommission <= 2000)
      return '平均か平均よりやや低く、良い状態です';
    if (consultingData[selectSheetIndex].managementCommission <= 3000)
      return '平均か平均よりやや高く改善の余地があります';
    return '平均より高く改善の余地が充分にあります';
  }, [consultingData, selectSheetIndex]);

  const totalIncomeAndExpensesAdvice = useMemo(() => {
    if (consultingData[selectSheetIndex].totalIncomeAndExpenses <= 0)
      return '平均より低く収支は改善の余地が充分にあります';
    if (consultingData[selectSheetIndex].totalIncomeAndExpenses < 5000)
      return '平均か平均よりやや低く、収支は改善の余地があります';
    return '平均より高く、収支は良い状態です';
  }, [consultingData, selectSheetIndex]);

  return (
    <Stack direction="row">
      <ConsultingSheetList
        selectSheetIndex={selectSheetIndex}
        consultingData={consultingData}
        userInfo={userInfo}
        setSelectSheetIndex={setSelectSheetIndex}
      />
      <Stack
        display="flex"
        flexDirection="column"
        paddingLeft="460px"
        boxSizing="border-box"
        width="100%">
        <Typography fontSize="18px" fontWeight={600} mb={2}>
          {`${consultingData[selectSheetIndex].propertyName}_${consultingData[selectSheetIndex].roomName}`}
        </Typography>
        <Stack spacing={3} pb="150px">
          <Stack>
            <Typography fontWeight={600} mb={1}>
              物件概要
            </Typography>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>物件名</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].propertyName}
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>号室</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].roomName}
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>住所</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].address}
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>駅</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].station}
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>平米</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].occupiedArea}㎡
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>戸数</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].units}戸
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>階数</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].stories}階
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>築年月</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {!isNaN(
                    new Date(
                      consultingData[selectSheetIndex].years ?? '',
                    ).getFullYear(),
                  ) ||
                  !isNaN(
                    new Date(
                      consultingData[selectSheetIndex].years ?? '',
                    ).getMonth(),
                  )
                    ? convertDateToString(
                        consultingData[selectSheetIndex].years ?? '',
                        'yyyy/M',
                      )
                    : '-'}
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>賃貸会社</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].rentalManagement ?? '-'}
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>建物管理会社</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].buildingManagement ?? '-'}
                </Typography>
              </StyledCell>
            </Stack>
          </Stack>

          <Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography fontWeight={600} mb={1}>
                借入概要
              </Typography>
            </Stack>
            <Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle>金融機関</LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography>
                    {consultingData[selectSheetIndex].bankName ?? '-'}
                  </Typography>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle>融資額</LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography>
                    {consultingData[
                      selectSheetIndex
                    ].loanAmount.toLocaleString()}
                    円
                  </Typography>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle style={{whiteSpace: 'pre-line'}}>
                    残債額{'\n'}時点
                  </LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography style={{whiteSpace: 'pre-line'}}>
                    {consultingData[selectSheetIndex].remainingPrincipal
                      ? `${(
                          consultingData[selectSheetIndex].remainingPrincipal ??
                          0
                        ).toLocaleString()}円`
                      : '-'}
                    {'\n'}
                    {consultingData[selectSheetIndex].remainingPrincipal
                      ? `${consultingData[selectSheetIndex]?.currentDate} 時点`
                      : '-'}
                  </Typography>
                </StyledCell>
                {consultingData[selectSheetIndex].remainingPrincipal ? (
                  <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                    <Typography style={{whiteSpace: 'pre-line'}}>
                      {remainingPrincipalAdvice}
                    </Typography>
                  </StyledCell>
                ) : null}
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle>金利</LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography>
                    {consultingData[selectSheetIndex].interestRate
                      ? `${consultingData[selectSheetIndex].interestRate}%`
                      : '-'}
                  </Typography>
                </StyledCell>
                {consultingData[selectSheetIndex].interestRate ? (
                  <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                    <Typography style={{whiteSpace: 'pre-line'}}>
                      {interestRateAdvice}
                    </Typography>
                  </StyledCell>
                ) : null}
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle style={{whiteSpace: 'pre-line'}}>
                    元本/月{'\n'}利息/月
                  </LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography style={{whiteSpace: 'pre-line'}}>
                    {consultingData[selectSheetIndex].principal
                      ? `${(
                          consultingData[selectSheetIndex].principal ?? 0
                        ).toLocaleString()}円`
                      : '-'}
                    {'\n'}
                    {consultingData[selectSheetIndex].interest
                      ? `${(
                          consultingData[selectSheetIndex].interest ?? 0
                        ).toLocaleString()}円`
                      : '-'}
                  </Typography>
                </StyledCell>
                {totalPrincipalPerYearAdvice ? (
                  <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                    <Typography style={{whiteSpace: 'pre-line'}}>
                      {totalPrincipalPerYearAdvice}
                    </Typography>
                  </StyledCell>
                ) : null}
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle>融資実行日</LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography>
                    {convertDateToString(
                      consultingData[selectSheetIndex].deliveryDate ?? '',
                      'yyyy/M',
                    )}
                  </Typography>
                </StyledCell>
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle>最終返済期限</LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography>
                    {consultingData[selectSheetIndex].lastRepaymentYear
                      ? `${convertDateToString(
                          consultingData[selectSheetIndex].lastRepaymentYear ??
                            '',
                          'yyyy/M',
                        )}`
                      : null}
                  </Typography>
                </StyledCell>
                {consultingData[selectSheetIndex].yearOfTermReduction ? (
                  <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                    <Typography style={{whiteSpace: 'pre-line'}}>
                      {yearOfTermReductionAdvice}
                    </Typography>
                  </StyledCell>
                ) : null}
              </Stack>
              <Stack direction="row" spacing={0}>
                <StyledHeaderCell color="primary-40">
                  <LabelTitle>残年数</LabelTitle>
                </StyledHeaderCell>
                <StyledCell>
                  <Typography>
                    {consultingData[selectSheetIndex].remainingRepaymentYear
                      ? `${consultingData[selectSheetIndex].remainingRepaymentYear}年`
                      : '-'}
                  </Typography>
                </StyledCell>
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <Typography fontWeight={600} mb={1}>
              収入
            </Typography>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>管理状況</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography>
                  {consultingData[selectSheetIndex].condition}
                </Typography>
              </StyledCell>
              {roomConditionAdvice ? (
                <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                  <Typography style={{whiteSpace: 'pre-line'}}>
                    {roomConditionAdvice}
                  </Typography>
                </StyledCell>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>賃料・共益費</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#00ABFB">
                  {consultingData[selectSheetIndex].roomIncome.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
            </Stack>
          </Stack>

          <Stack>
            <Typography fontWeight={600} mb={1}>
              支出
            </Typography>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>返済金額</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#DA0000">
                  {consultingData[selectSheetIndex].repayment
                    ? `-${Math.round(
                        consultingData[selectSheetIndex].repayment,
                      ).toLocaleString()}円`
                    : null}
                </Typography>
              </StyledCell>
              {reducedRepaymentAdvice ? (
                <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                  <Typography style={{whiteSpace: 'pre-line'}}>
                    {reducedRepaymentAdvice}
                  </Typography>
                </StyledCell>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>管理費</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#DA0000">
                  -
                  {consultingData[
                    selectSheetIndex
                  ].managementFee.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>修繕費</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#DA0000">
                  -
                  {consultingData[
                    selectSheetIndex
                  ].repairReserveFund.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
              {roomRepairAdvice ? (
                <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                  <Typography style={{whiteSpace: 'pre-line'}}>
                    {roomRepairAdvice}
                  </Typography>
                </StyledCell>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>その他費用</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#DA0000">
                  -
                  {consultingData[
                    selectSheetIndex
                  ].otherRoomCost.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>管理委託手数料</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#DA0000">
                  -
                  {consultingData[
                    selectSheetIndex
                  ].managementCommission.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
              <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                <Typography style={{whiteSpace: 'pre-line'}}>
                  {managementCommissionAdvice}
                </Typography>
              </StyledCell>
            </Stack>
          </Stack>

          <Stack>
            <Typography fontWeight={600} mb={1}>
              収支合計
            </Typography>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>収入合計</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#00ABFB">
                  {consultingData[
                    selectSheetIndex
                  ].totalIncome.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>支出合計</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography color="#DA0000">
                  -{consultingData[selectSheetIndex].totalCost.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
            </Stack>
            <Stack direction="row" spacing={0}>
              <StyledHeaderCell color="primary-40">
                <LabelTitle>収支合計</LabelTitle>
              </StyledHeaderCell>
              <StyledCell>
                <Typography
                  color={
                    consultingData[selectSheetIndex].totalIncomeAndExpenses > 0
                      ? '#00ABFB'
                      : '#DA0000'
                  }>
                  {consultingData[
                    selectSheetIndex
                  ].totalIncomeAndExpenses.toLocaleString()}
                  円
                </Typography>
              </StyledCell>
              <StyledCell sx={{backgroundColor: '#FDF6B9'}}>
                <Typography style={{whiteSpace: 'pre-line'}}>
                  {totalIncomeAndExpensesAdvice}
                </Typography>
              </StyledCell>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const StyledHeaderCell = styled(Cell)(() => ({
  width: '100%',
  maxWidth: '120px',
  padding: '8px',
  minHeight: 'inherit',
}));
const StyledCell = styled(Cell)(() => ({
  width: '100%',
  maxWidth: '380px',
  padding: '8px',
  minHeight: 'inherit',
}));

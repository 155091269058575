import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MoneyInfo, PdfCategory, Resident, Room} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {BalanceTableBody} from '../components/BalanceTableBody';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {PeriodSelector} from '../components/PeriodSelector';
import {DownloadIcon} from '../images/DownloadIcon';
import {useDownloadPdf} from '../queries/download';
import {useResidentsByMonth, useResidentsByYear} from '../queries/resident';
import {useRoomsByMonth, useRoomsByYear} from '../queries/room';
import {useUser} from '../queries/user';
import {convertDateToString} from '../utils/convertDateToString';
import {
  getCostFromMoneyInfoByMonth,
  getCostFromMoneyInfoByYear,
} from '../utils/getCostFromMoneyInfo';
import {isNonNull} from '../utils/isNonNull';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export type TermType = 'yearly' | 'monthly';

const calcSubtotal = (value: Object) => {
  return Object.values(value).reduce((sum, v) => {
    if (typeof sum !== 'number') return 0;
    if (Array.isArray(v)) {
      const totalCustomCostMoney = v.reduce((s, m) => s + m.money, 0);
      return sum + totalCustomCostMoney;
    } else {
      return sum + v;
    }
  }, 0);
};

export const BalanceScreen = () => {
  const navigate = useNavigate();
  const date = useMemo(() => new Date(), []);
  const userState = useOAuthUserState();
  const userId = userState.user?.attributes.sub ?? '';
  const {data} = useUser(userId);
  const [term, setTerm] = useState<TermType>('yearly');
  const [selectedYear, setSelectedYear] = useState<Date>(date);
  const [selectedMonth, setSelectedMonth] = useState<Date>(date);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const {data: roomsByYear, isLoading: isLoadingRoomsByYear} = useRoomsByYear(
    selectedYear.getFullYear(),
  );
  const {data: residentsByYear, isLoading: isLoadingResidentsByYear} =
    useResidentsByYear(selectedYear.getFullYear());
  const {data: roomsByMonth, isLoading: isLoadingRoomsByMonth} =
    useRoomsByMonth(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1);
  const {data: residentsByMonth, isLoading: isLoadingResidentsByMonth} =
    useResidentsByMonth(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth() + 1,
    );
  const {mutate: downloadBalancePdf, isLoading: isLoadingDownloadPdf} =
    useDownloadPdf();
  const isLoading = useMemo(() => {
    return term === 'yearly'
      ? isLoadingRoomsByYear || isLoadingResidentsByYear
      : isLoadingRoomsByMonth || isLoadingResidentsByMonth;
  }, [
    term,
    isLoadingRoomsByYear,
    isLoadingRoomsByMonth,
    isLoadingResidentsByYear,
    isLoadingResidentsByMonth,
  ]);
  const isEmptyData = useMemo(() => {
    return term === 'yearly'
      ? !isLoading && !!roomsByYear && roomsByYear.items.length === 0
      : !isLoading && !!roomsByMonth && roomsByMonth.items.length === 0;
  }, [term, isLoading, roomsByYear, roomsByMonth]);
  const disabled = useMemo(() => {
    return term === 'yearly'
      ? selectedYear.getFullYear() === date.getFullYear()
      : selectedMonth.getFullYear() === date.getFullYear() &&
          selectedMonth.getMonth() === date.getMonth();
  }, [date, selectedMonth, selectedYear, term]);
  const resetTotal = useCallback(() => {
    setTotalCost(0);
    setTotalIncome(0);
  }, []);
  const moveToCurrent = useCallback(() => {
    term === 'yearly' ? setSelectedYear(date) : setSelectedMonth(date);
  }, [date, term]);
  const moveToPrev = useCallback(() => {
    term === 'yearly'
      ? setSelectedYear(prev => {
          const prevDate = new Date(prev);
          prevDate.setFullYear(prevDate.getFullYear() - 1);
          return prevDate;
        })
      : setSelectedMonth(prev => {
          const prevDate = new Date(prev);
          prevDate.setMonth(prevDate.getMonth() - 1);
          return prevDate;
        });
  }, [term]);
  const moveToNext = useCallback(() => {
    term === 'yearly'
      ? setSelectedYear(prev => {
          const prevDate = new Date(prev);
          prevDate.setFullYear(prevDate.getFullYear() + 1);
          return prevDate;
        })
      : setSelectedMonth(prev => {
          const prevDate = new Date(prev);
          prevDate.setMonth(prevDate.getMonth() + 1);
          return prevDate;
        });
  }, [term]);
  const handleChangeTerm = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: TermType | null,
  ) => {
    if (newAlignment) {
      setTerm(newAlignment);
    }
  };
  const targetResidents = useCallback(
    (room: Room) => {
      const residents = term === 'yearly' ? residentsByYear : residentsByMonth;
      if (!residents) return null;
      const filtered = residents.items
        .filter(isNonNull)
        .filter(item => item.room?.id === room.id);
      return filtered.length > 0 ? (filtered as Resident[]) : null;
    },
    [residentsByMonth, residentsByYear, term],
  );
  const calcMoney = useCallback(
    (info: MoneyInfo[], endDate: string) => {
      return info
        .map((element, index) => {
          const nextDate =
            info.length > index + 1
              ? new Date(
                  Number(info[index + 1].year),
                  Number(info[index + 1].month) - 1,
                )
              : null;
          if (nextDate) {
            nextDate.setMonth(nextDate.getMonth() - 1);
          }
          const lastDate = nextDate ? toISOStringWithTimezone(nextDate) : '';
          return term === 'yearly'
            ? getCostFromMoneyInfoByYear(
                element,
                selectedYear.getFullYear(),
                !endDate || (!!lastDate && !!endDate && endDate > lastDate)
                  ? lastDate
                  : endDate,
              )
            : getCostFromMoneyInfoByMonth(
                element,
                selectedMonth.getFullYear(),
                selectedMonth.getMonth() + 1,
                !endDate || (!!lastDate && !!endDate && endDate > lastDate)
                  ? lastDate
                  : endDate,
              ) ?? 0;
        })
        .reduce((sum, money) => sum + money, 0);
    },
    [selectedMonth, selectedYear, term],
  );
  const displayData = useMemo(() => {
    const rooms = term === 'yearly' ? roomsByYear : roomsByMonth;
    if (!rooms) {
      return null;
    }
    const data = rooms.items.filter(isNonNull).map(room => {
      const soldDate = room.soldDate ?? '';
      const roomCost = {
        propertyTax: calcMoney(room.propertyTax as MoneyInfo[], soldDate),
        purchaseCosts: calcMoney(room.purchaseCosts as MoneyInfo[], soldDate),
        repairReserveFund: calcMoney(
          room.repairReserveFund as MoneyInfo[],
          soldDate,
        ),
        realEstateAcquisitionTax: calcMoney(
          room.realEstateAcquisitionTax as MoneyInfo[],
          soldDate,
        ),
        insuranceFee: calcMoney(room.insuranceFee as MoneyInfo[], soldDate),
        managementFee: calcMoney(room.managementFee as MoneyInfo[], soldDate),
        customCostItems:
          room.customCostItems?.filter(isNonNull).map(item => {
            return {
              key: item.key ?? '',
              money: calcMoney(item.value as MoneyInfo[], soldDate),
            };
          }) ?? null,
      };
      const residents = targetResidents(room);
      return {
        id: room.id ?? '',
        name: room.name ?? '',
        property: {
          name: room.property?.name ?? '',
          address: room.property?.address ?? '',
        },
        occupiedArea: room.occupiedArea ?? '',
        sellingPrice: room.sellingPrice ?? 0,
        deliveryDate: room.deliveryDate ?? '',
        ...roomCost,
        subTotalRoomCost: calcSubtotal(roomCost),
        residents: residents
          ? residents.map(resident => {
              const endDate = resident.endDate ?? '';
              const income = {
                keyMoney: calcMoney(resident.keyMoney as MoneyInfo[], endDate),
                firstIncome: calcMoney(
                  resident.firstIncome as MoneyInfo[],
                  endDate,
                ),
                rent: calcMoney(resident.rent as MoneyInfo[], endDate),
                customMoveInCostItems:
                  resident.customMoveInCostItems
                    ?.filter(isNonNull)
                    .map(item => {
                      return {
                        key: item.key ?? '',
                        money: calcMoney(item.value as MoneyInfo[], endDate),
                      };
                    }) ?? null,
              };
              const cost = {
                renovationCost: resident.renovationCost
                  ? calcMoney(resident.renovationCost as MoneyInfo[], endDate)
                  : 0,
                keyExchangeCost: resident.keyExchangeCost
                  ? calcMoney(resident.keyExchangeCost as MoneyInfo[], endDate)
                  : 0,
                advertisingRates: resident.advertisingRates
                  ? calcMoney(resident.advertisingRates as MoneyInfo[], endDate)
                  : 0,
                customMoveOutCostItems:
                  resident.customMoveOutCostItems
                    ?.filter(isNonNull)
                    .map(item => {
                      return {
                        key: item.key ?? '',
                        money: calcMoney(item.value as MoneyInfo[], endDate),
                      };
                    }) ?? null,
              };
              return {
                id: resident.id,
                name: resident.name,
                monthlyRent: resident.rent[0]?.money ?? 0,
                startDate: resident.startDate,
                endDate: endDate,
                ...income,
                ...cost,
                subTotalCost: calcSubtotal(cost) as number,
                subTotalIncome: calcSubtotal(income) as number,
              };
            })
          : [],
      };
    });
    return data;
  }, [calcMoney, roomsByMonth, roomsByYear, targetResidents, term]);
  useEffect(() => {
    resetTotal();
  }, [resetTotal, selectedYear]);
  useEffect(() => {
    resetTotal();
  }, [resetTotal, selectedMonth]);
  useEffect(() => {
    if (!displayData) return;
    const totalRoomCost = displayData
      .map(d => {
        return d.subTotalRoomCost;
      })
      .reduce((s, m) => s + m, 0);
    const totalResidentsCost = displayData
      .map(d => {
        return d.residents
          .map(r => {
            return r.subTotalCost;
          })
          .reduce((s, m) => s + m, 0);
      })
      .reduce((s, m) => s + m, 0);
    const totalResidentsIncome = displayData
      .map(d => {
        return d.residents
          .map(r => {
            return r.subTotalIncome;
          })
          .reduce((s, m) => s + m, 0);
      })
      .reduce((s, m) => s + m, 0);
    setTotalIncome(totalResidentsIncome);
    setTotalCost(totalRoomCost + totalResidentsCost);
  }, [calcMoney, displayData, targetResidents]);

  return (
    <BaseBox sx={{overflow: 'scroll'}}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          color="primary"
          value={term}
          exclusive
          onChange={handleChangeTerm}>
          <StyledToggleButton value="yearly">年次</StyledToggleButton>
          <StyledToggleButton value="monthly">月次</StyledToggleButton>
        </ToggleButtonGroup>
        <Button
          disabled={isLoading}
          variant="outlined"
          size="small"
          startIcon={
            isLoadingDownloadPdf ? (
              <CircularProgress
                sx={{
                  width: '20px  !important',
                  height: '20px  !important',
                }}
              />
            ) : (
              <DownloadIcon
                fontSize="small"
                strokeColor={isLoading ? '#A1A5B6' : '#31CDD3'}
              />
            )
          }
          onClick={async () => {
            downloadBalancePdf(
              {
                category: PdfCategory.BALANCE,
                fileName:
                  term === 'yearly'
                    ? `${selectedYear.getFullYear()}年　収支報告書`
                    : `${convertDateToString(
                        toISOStringWithTimezone(selectedMonth),
                        'yyyy/M',
                      )}　収支報告書`,
                userName: `${data?.lastName} ${data?.firstName}`,
                downloadDate:
                  term === 'yearly'
                    ? selectedYear.getFullYear().toString()
                    : convertDateToString(
                        toISOStringWithTimezone(selectedMonth),
                        'yyyy/M',
                      ),
                balanceData: {
                  data: displayData,
                  totalIncome: totalIncome,
                  totalCost: totalCost,
                  term: term,
                },
              },
              {
                onError: () => {
                  navigate('/error');
                },
              },
            );
          }}>
          {isLoadingDownloadPdf ? 'ダウンロード中' : 'ダウンロード'}
        </Button>
      </Stack>
      <PeriodSelector
        selectedDate={
          term === 'yearly'
            ? `${selectedYear.getFullYear()}年`
            : convertDateToString(
                toISOStringWithTimezone(selectedMonth),
                'yyyy/M',
              ) ?? ''
        }
        centerButtonName={term === 'yearly' ? '今年' : '今月'}
        moveToCurrent={moveToCurrent}
        moveToNext={moveToNext}
        moveToPrev={moveToPrev}
        disabled={disabled}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: '#FDF6B9',
          width: '300px',
          fontWeight: 500,
          fontSize: '14px',
          p: '12px',
          mb: '8px',
        }}>
        <span>収支合計（収入ー支出）</span>
        <span>{(totalIncome - totalCost).toLocaleString()}円</span>
      </Stack>
      <Typography
        sx={{
          fontSize: '12px',
          lineHeight: 1.3,
          textAlign: 'right',
          mb: '4px',
        }}>
        単位：円
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead
            sx={{
              backgroundColor: 'flikPrimary.-40',
              border: '1px solid #DADCE3',
            }}>
            <TableRow>
              <TableCell align="center">物件情報</TableCell>
              <TableCell align="center">部屋情報</TableCell>
              <TableCell align="center">収入</TableCell>
              <TableCell align="center">支出</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!displayData && displayData.length > 0 ? (
              <>
                {displayData.map(
                  data =>
                    data && <BalanceTableBody key={data.id} data={data} />,
                )}
                <TableRow>
                  <TableCell sx={{borderBottom: 'none'}} colSpan={2} />
                  <TableCell sx={{fontWeight: 500, borderBottom: 'none'}}>
                    <Stack direction="row" justifyContent="space-between">
                      <span>収入合計</span>
                      <span>{totalIncome.toLocaleString()}</span>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{fontWeight: 500, borderBottom: 'none'}}>
                    <Stack direction="row" justifyContent="space-between">
                      <span>支出合計</span>
                      <span>{totalCost.toLocaleString()}</span>
                    </Stack>
                  </TableCell>
                </TableRow>
              </>
            ) : isEmptyData ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography sx={{textAlign: 'center', padding: '16px'}}>
                    データがありません
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    padding={5}>
                    <CircularProgress sx={{color: '#31CDD3'}} />
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </BaseBox>
  );
};

const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
  padding: '6px 0',
  width: '114px',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 1.3,
  color: theme.palette.flikPrimary.main,
  borderRadius: '6px',
  transition: 'background-color .25s',
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: theme.palette.flikPrimary.main,
    border: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.flikPrimary.main,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.flikPrimary[-50],
  },
}));

import {SvgIconProps, SvgIcon} from '@mui/material';
import React from 'react';

export const ArrowUpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      sx={{fill: 'none', ...props.sx}}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_396_3410)">
        <path
          d="M16 6.66663V25.3333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 14.6666L16 6.66663"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14.6666L16 6.66663"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_396_3410">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material';
import React from 'react';

export const ThemeProvider: React.FC = ({children}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#31CDD3',
      },
      common: {
        black: '#181C32',
      },
      flikPrimary: {
        main: '#31CDD3',
        '+10': '#25A7AC',
        '-40': '#D9F6F7',
        '-50': '#EFF8F8',
      },
      error: {
        main: '#DA0000',
      },
      warning: {
        main: '#DA0000',
      },
      flikGray: {
        main: '#A1A5B6',
        '+15': '#767C95',
        '-10': '#BEC0CC',
        '-20': '#DADCE3',
        '-25': '#E9EAEE',
        '-30': '#F5F8FA',
      },
      text: {primary: '#181C32'},
    },
    shadows: [
      'none',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.6)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
      '0px 2px 18px 6px rgba(204, 204, 204, 0.3)',
    ],
    typography: {
      fontFamily: [
        'Hiragino Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          colorAction: {
            color: 'inherit',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '6px !important',
            border: '1px solid #E9EAEE',
            marginTop: '4px',
            boxShadow: '0px 2px 18px 6px rgba(204, 204, 204, 0.3) !important',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '12px 20px',
            '&:hover': {
              backgroundColor: '#EFF8F8',
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'unset !important',
            marginRight: '4px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: '4px 0 !important',
            borderColor: '#E9EAEE',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: 'unset !important',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#181C32',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: 'white',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#DADCE3',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#31CDD3',
              borderWidth: '1px',
            },
          },
          notchedOutline: {
            borderColor: '#DADCE3',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          iconSizeSmall: {
            marginRight: '4px',
            marginLeft: '0',
            '& > *:nth-of-type(1)': {
              fontSize: 20,
              fill: 'transparent',
            },
          },
          root: {
            lineHeight: '130%',
            '&.Mui-disabled': {
              backgroundColor: '#DADCE3',
            },
          },
          outlined: {
            backgroundColor: '#ffffff',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 4,
            marginLeft: 0,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#181C32',
            fontSize: '14px',
            borderRadius: '6px',
          },
          tooltipPlacementBottom: {
            marginTop: '8px !important',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: '12px 16px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: '#EFF8F8',
            },
            '&.Mui-selected': {
              color: '#31CDD3',
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          icon: {
            marginRight: '4px',
          },
          root: {
            fontSize: '16px',
            fontWeight: 600,
            alignItems: 'center',
            borderRadius: '12px',
            padding: '8px 20px',
            color: 'white',
            lineHeight: 1.3,
            '&.MuiAlert-standardSuccess': {
              backgroundColor: '#47D615',
            },
            '&.MuiAlert-standardWarning': {
              backgroundColor: '#DA0000',
            },
            '&.MuiAlert-standardError': {
              backgroundColor: '#DA0000',
            },
          },
          action: {
            padding: '0 0 0 16px',
          },
          message: {
            padding: 0,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            svg: {
              color: 'transparent',
              width: '20px',
              height: '20px',
            },
            borderRadius: '6px',
            width: '28px',
            minWidth: '28px',
            height: '28px',
          },
        },
      },
    },
  });
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

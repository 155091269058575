import {Stack, Typography} from '@mui/material';
import React, {useCallback, VFC} from 'react';
import {MoneyInfo} from '../API';
import {frequencyList} from '../utils/selectOptions';

export const MoneyList: VFC<{cost: MoneyInfo | null}> = ({cost}) => {
  const convertFrequencyToDisplayName = useCallback((frequency: string) => {
    return frequencyList.find(list => list.value === frequency)?.name;
  }, []);
  return (
    <Stack direction="row" spacing={3}>
      <Typography width={200}>{cost?.money.toLocaleString()}円</Typography>
      <Typography width={130}>
        {cost?.frequency ? convertFrequencyToDisplayName(cost?.frequency) : ''}
      </Typography>
      <Typography width={160}>
        {cost?.year}年{cost?.month}月
      </Typography>
    </Stack>
  );
};

import {CircularProgress, Grid, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {
  RoomBasicInfoType,
  RoomCostInfoType,
  RoomEarlyRepaymentInfoType,
} from './AddRoom';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {RoomBasicInfo} from '../components/RoomBasicInfo';
import {RoomCostInfo} from '../components/RoomCostInfo';
import {RoomEarlyRepaymentInfo} from '../components/RoomEarlyRepaymentInfo';
import {Snackbar} from '../components/Snackbar';
import {useRoom, useUpdateRoomMutation} from '../queries/room';

export const Input = styled('input')({
  display: 'none',
});

export const EditRoomInfoScreen = () => {
  const navigate = useNavigate();
  const {propertyId, roomId} = useParams();
  const queryClient = useQueryClient();
  const {mutate: updateRoom, isLoading} = useUpdateRoomMutation();
  const {data: room} = useRoom(roomId);
  const [isBasicInfoError, setIsBasicInfoError] = useState(false);
  const [isCostInfoError, setIsCostInfoError] = useState(false);
  const [basicInfo, setBasicInfo] = useState<RoomBasicInfoType | null>(null);
  const [costInfo, setCostInfo] = useState<RoomCostInfoType | null>(null);
  const [isEarlyRepaymentInfoError, setIsEarlyRepaymentInfoError] =
    useState(false);
  const [earlyRepaymentInfo, setEarlyRepaymentInfo] =
    useState<RoomEarlyRepaymentInfoType | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSaveButtonDown, setIsSaveButtonDown] = useState(false);
  const {handleSubmit} = useForm();
  useEffect(() => {
    if (room) {
      setBasicInfo({
        name: room.name,
        layout: room.layout,
        condition: room.condition,
        deliveryDate: room.deliveryDate,
        salesCompany: room.salesCompany,
        sellingPrice: room.sellingPrice,
        occupiedArea: room.occupiedArea,
        totalArea: room.totalArea,
        bankName: room.bankName,
        loanAmount: room.loanAmount,
        loanYears: room.loanYears,
        interestRate: room.interestRate,
        repaymentMethod: room.repaymentMethod,
        repaymentDate: room.repaymentDate,
        customItems: room.customItems,
      });
      setCostInfo({
        purchaseCosts: room.purchaseCosts,
        propertyTax: room.propertyTax,
        realEstateAcquisitionTax: room.realEstateAcquisitionTax,
        insuranceFee: room.insuranceFee,
        repairReserveFund: room.repairReserveFund,
        managementFee: room.managementFee,
        managementCommission: room.managementCommission,
        customCostItems: room.customCostItems,
      });
      setEarlyRepaymentInfo({
        earlyRepaymentInfo: room.earlyRepaymentInfo,
      });
    }
  }, [room]);
  const onSave = useCallback(async () => {
    setIsSaveButtonDown(false);
    if (!roomId) {
      navigate('/error');
      return;
    }
    if (isBasicInfoError || isCostInfoError || isEarlyRepaymentInfoError) {
      setOpenSnackbar(true);
      return;
    }
    updateRoom(
      {
        input: {
          id: roomId,
          propertyRoomsId: propertyId,
          ...basicInfo,
          ...costInfo,
          ...earlyRepaymentInfo,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          navigate(`/property/${propertyId}/${roomId}`, {
            state: {operationState: 'edit-success'},
          });
        },
        onError: () => {
          navigate('/error');
          return;
        },
      },
    );
  }, [
    basicInfo,
    costInfo,
    earlyRepaymentInfo,
    isBasicInfoError,
    isCostInfoError,
    isEarlyRepaymentInfoError,
    navigate,
    propertyId,
    queryClient,
    roomId,
    updateRoom,
  ]);

  return room ? (
    <>
      <Typography
        sx={{fontSize: '24px', margin: '0 0 24px 0', fontWeight: '700'}}>
        部屋情報の編集
      </Typography>
      <BaseBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {basicInfo && (
              <RoomBasicInfo
                basicInfo={basicInfo}
                onBasicInfoChange={setBasicInfo}
                onErrorChange={setIsBasicInfoError}
                isSubmitted={isSaveButtonDown}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {costInfo && (
              <RoomCostInfo
                costInfo={costInfo}
                onCostInfoChange={setCostInfo}
                onErrorChange={setIsCostInfoError}
                isSubmitted={isSaveButtonDown}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {earlyRepaymentInfo && (
              <RoomEarlyRepaymentInfo
                earlyRepaymentInfo={earlyRepaymentInfo}
                onEarlyRepaymentInfoChange={setEarlyRepaymentInfo}
                onErrorChange={setIsEarlyRepaymentInfoError}
                isSubmitted={isSaveButtonDown}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button variant="outlined" onClick={() => navigate(-1)}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        width: '20px  !important',
                        height: '20px  !important',
                      }}
                    />
                  ) : null
                }
                onMouseDown={() => setIsSaveButtonDown(true)}
                onClick={handleSubmit(onSave)}>
                {isLoading ? '保存中' : '保存する'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </BaseBox>
      <Snackbar
        severity="error"
        message="入力内容を確認してください"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : null;
};

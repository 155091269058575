import {Box, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React from 'react';

export const MoneyInfoTableHead = () => {
  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{backgroundColor: 'flikPrimary.-40', border: '1px solid white'}}>
      <Box sx={{width: '250px'}} />
      <Box sx={{width: '225px'}}>
        <StyledTypography>金額</StyledTypography>
      </Box>
      <Box sx={{width: '152px'}}>
        <StyledTypography>頻度</StyledTypography>
      </Box>
      <Box>
        <StyledTypography>開始月</StyledTypography>
      </Box>
    </Stack>
  );
};

const StyledTypography = styled(Typography)({
  fontWeight: 500,
  padding: '4px 12px',
  boxSizing: 'border-box',
});

import {
  Stack,
  Grid,
  Box,
  Typography,
  Modal,
  CircularProgress,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ExpenseDataInput, PdfCategory} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {Button} from '../components/Button';
import {DatePicker} from '../components/DatePicker';
import {useDownloadPdf} from '../queries/download';
import {useExpenses} from '../queries/expenses';
import {useUser} from '../queries/user';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

type Props = {
  open: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

const StyledText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '20.8px',
}));

const StyledRequired = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '300',
  lineHeight: '18.2px',
  color: 'red',
}));

export const DownloadExpensesModal: React.VFC<Props> = ({
  open,
  setOpenModal,
}: Props) => {
  const queryClient = useQueryClient();
  const [downloadYear, setDownloadYear] = useState<Date>(new Date());
  const [isYearError, setIsYearError] = useState(false);
  const userState = useOAuthUserState();
  const userId = userState.user?.attributes.sub ?? '';
  const {data: user} = useUser(userId);
  const startDate = useMemo(() => {
    const startOfMonth = new Date(downloadYear.toString());
    startOfMonth.setMonth(0);
    startOfMonth.setDate(1);
    return toISOStringWithTimezone(startOfMonth);
  }, [downloadYear]);
  const endDate = useMemo(() => {
    const endOfMonth = new Date(downloadYear.toString());
    endOfMonth.setMonth(11);
    endOfMonth.setDate(31);
    return toISOStringWithTimezone(endOfMonth);
  }, [downloadYear]);
  const {data: expenses, refetch, isLoading} = useExpenses(startDate, endDate);
  const {mutate: downloadExpensesPdf, isLoading: isLoadingDownloadPdf} =
    useDownloadPdf();
  const navigate = useNavigate();

  const handleClose = () => {
    setDownloadYear(new Date());
    setOpenModal(false);
  };

  useEffect(() => {
    queryClient.invalidateQueries();
    refetch();
  }, [queryClient, downloadYear, refetch]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          color: 'white',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '464px',
          bgcolor: 'background.paper',
          borderRadius: '12px',
          boxShadow: 'none',
          p: '40px',
        }}>
        <Stack height="100%">
          <Typography
            textAlign="center"
            mb="24px"
            sx={{fontSize: '20px', fontWeight: '500', LineHeight: '26px'}}>
            雑費のダウンロード
          </Typography>
          <StyledText sx={{fontWeight: '300', mb: '24px'}}>
            ダウンロードする雑費の期間の選択をしてください
          </StyledText>
          <Stack mb="40px">
            <Grid item container alignItems="center" mb="8px">
              <Grid item mr="12px">
                <StyledText>期間(年)</StyledText>
              </Grid>
              <Grid item>
                <StyledRequired>必須</StyledRequired>
              </Grid>
            </Grid>
            <DatePicker
              views={['year']}
              value={downloadYear}
              error={isYearError}
              inputFormat={'yyyy'}
              onChangeValue={v => {
                if (!v) {
                  setIsYearError(true);
                  return;
                }
                setDownloadYear(v);
              }}
              onErrorChange={setIsYearError}
            />
          </Stack>
        </Stack>
        <Button variant="outlined" onClick={handleClose} sx={{mr: '20px'}}>
          キャンセル
        </Button>
        <StyledDiv disabled={isYearError || !downloadYear}>
          <Button
            variant="contained"
            disabled={
              isYearError || !downloadYear || isLoading || isLoadingDownloadPdf
            }
            startIcon={
              isLoadingDownloadPdf ? (
                <CircularProgress
                  sx={{
                    color: 'white',
                    width: '20px  !important',
                    height: '20px  !important',
                  }}
                />
              ) : null
            }
            onClick={async () => {
              downloadExpensesPdf(
                {
                  category: PdfCategory.EXPENSE,
                  fileName: `${downloadYear
                    .getFullYear()
                    .toString()}年_年間雑費詳細`,
                  userName: `${user?.lastName} ${user?.firstName}`,
                  downloadDate: downloadYear.getFullYear().toString(),
                  expenseData: expenses?.items as ExpenseDataInput[],
                },
                {
                  onSuccess: () => {
                    setDownloadYear(new Date());
                    setOpenModal(false);
                  },
                  onError: () => {
                    navigate('/error');
                  },
                },
              );
            }}>
            {isLoadingDownloadPdf ? 'ダウンロード中' : 'ダウンロード'}
          </Button>
        </StyledDiv>
      </Box>
    </Modal>
  );
};

const StyledDiv = styled('div')(({disabled}: {disabled: boolean}) => ({
  pointerEvents: disabled ? 'none' : 'unset',
  display: 'contents',
}));

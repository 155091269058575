import {Stack, Typography, TableRow, TableCell} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {VFC} from 'react';
import {Room, Resident} from '../API';
import {convertDateToString} from '../utils/convertDateToString';
import {isNonNull} from '../utils/isNonNull';

export type BalanceDataType = {
  id: string;
  name: string;
  property: {
    name: string;
    address: string;
  };
  occupiedArea: Room['occupiedArea'];
  sellingPrice: Room['sellingPrice'];
  deliveryDate: Room['deliveryDate'];
  propertyTax: number;
  purchaseCosts: number;
  realEstateAcquisitionTax: number;
  repairReserveFund: number;
  insuranceFee: number;
  managementFee: number;
  customCostItems: {key: string; money: number}[] | null;
  subTotalRoomCost: number;
  residents: {
    id: Resident['id'];
    name: Resident['name'];
    monthlyRent: number;
    startDate: Resident['startDate'];
    endDate: Resident['endDate'];
    keyMoney: number;
    firstIncome: number;
    rent: number;
    customMoveInCostItems: {key: string; money: number}[] | null;
    renovationCost: number;
    keyExchangeCost: number;
    advertisingRates: number;
    customMoveOutCostItems: {key: string; money: number}[] | null;
    subTotalCost: number;
    subTotalIncome: number;
  }[];
};

type Props = {
  data: BalanceDataType;
};

export const BalanceTableBody: VFC<Props> = ({data}) => {
  return (
    <>
      <TableRow
        key={data.id}
        sx={{
          '&:last-child td, &:last-child th': {border: 0},
        }}>
        <StyledTableCell
          rowSpan={data.residents.length + 1}
          variant="body"
          scope="row"
          sx={{verticalAlign: 'top', width: '23vw'}}>
          <StyledCellTitle>
            {`${data.property?.name}${data.name}`}
          </StyledCellTitle>
          <Typography sx={{fontSize: '14px', color: 'grey.800'}}>
            住所：{data.property?.address}
          </Typography>
        </StyledTableCell>
        <StyledTableCell sx={{verticalAlign: 'top', width: '20vw'}}>
          <StyledCellTitle>部屋</StyledCellTitle>
          専有面積：{data.occupiedArea}㎡
          <br />
          売買価格：{data.sellingPrice.toLocaleString()}円 <br />
          引渡日：
          {convertDateToString(data.deliveryDate, 'yyyy/M/d')}
          <br />
        </StyledTableCell>
        <StyledTableCell align="center" sx={{width: '20vw'}}>
          ー
        </StyledTableCell>
        <StyledTableCell align="center" sx={{width: '37vw'}}>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '16px',
            }}>
            <StyledStackForItems direction="row">
              <span>固定資産税・都市計画税</span>
              <span>{data.propertyTax.toLocaleString()}</span>
            </StyledStackForItems>
            <StyledStackForItems direction="row">
              <span>購入諸費用</span>
              <span>{data.purchaseCosts.toLocaleString()}</span>
            </StyledStackForItems>
            <StyledStackForItems direction="row">
              <span>不動産取得税</span>
              <span>{data.realEstateAcquisitionTax.toLocaleString()}</span>
            </StyledStackForItems>
            <StyledStackForItems direction="row">
              <span>修繕積立金</span>
              <span>{data.repairReserveFund.toLocaleString()}</span>
            </StyledStackForItems>
            <StyledStackForItems direction="row">
              <span>地震火災保険料</span>
              <span>{data.insuranceFee.toLocaleString()}</span>
            </StyledStackForItems>
            <StyledStackForItems direction="row">
              <span>管理費</span>
              <span>{data.managementFee.toLocaleString()}</span>
            </StyledStackForItems>
            {data.customCostItems?.filter(isNonNull).map((item, index) => (
              <StyledStackForItems key={index} direction="row">
                <span>{item.key}</span>
                <span>{item.money.toLocaleString()}</span>
              </StyledStackForItems>
            ))}
          </Stack>
          <StyledStack direction="row" justifyContent="space-between">
            <span>小計</span>
            <span>{data.subTotalRoomCost.toLocaleString()}</span>
          </StyledStack>
        </StyledTableCell>
      </TableRow>
      {data.residents &&
        data.residents?.map(resident => (
          <TableRow key={resident.id}>
            <StyledTableCell sx={{verticalAlign: 'top'}}>
              <StyledCellTitle>賃借人</StyledCellTitle>
              賃借人名：{resident.name}
              <br />
              月額賃料：{resident.monthlyRent.toLocaleString()}円
              <br />
              入居期間：
              {convertDateToString(resident.startDate, 'yyyy/M/d')}～<br />
              {resident.endDate &&
                convertDateToString(resident.endDate, 'yyyy/M/d')}
            </StyledTableCell>
            <StyledTableCell align="center" sx={{verticalAlign: 'top'}}>
              <StyledStackForItems direction="row">
                <span>礼金</span>
                <span>{resident.keyMoney.toLocaleString()}</span>
              </StyledStackForItems>
              <StyledStackForItems direction="row">
                <span>賃料</span>
                <span>
                  {(resident.rent + resident.firstIncome).toLocaleString()}
                </span>
              </StyledStackForItems>
              {resident.customMoveInCostItems
                ?.filter(isNonNull)
                .map((item, index) => (
                  <StyledStackForItems key={index} direction="row">
                    <span>{item.key}</span>
                    <span>{item.money.toLocaleString()}</span>
                  </StyledStackForItems>
                ))}
              <StyledStack direction="row" justifyContent="space-between">
                <span>小計</span>
                <span>{resident.subTotalIncome.toLocaleString()}</span>
              </StyledStack>
            </StyledTableCell>
            <StyledTableCell sx={{verticalAlign: 'top'}} align="center">
              <Stack
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  columnGap: '16px',
                }}>
                <StyledStackForItems direction="row">
                  <span>内装・リフォーム費用</span>
                  <span>{resident.renovationCost}</span>
                </StyledStackForItems>
                <StyledStackForItems direction="row">
                  <span>鍵交換費用</span>
                  <span>{resident.keyExchangeCost}</span>
                </StyledStackForItems>
                <StyledStackForItems direction="row">
                  <span>広告料</span>
                  <span>{resident.advertisingRates}</span>
                </StyledStackForItems>
                {resident.customMoveOutCostItems
                  ?.filter(isNonNull)
                  .map((item, index) => (
                    <StyledStackForItems key={index} direction="row">
                      <span>{item.key}</span>
                      <span>{item.money.toLocaleString()}</span>
                    </StyledStackForItems>
                  ))}
              </Stack>
              <StyledStack direction="row" justifyContent="space-between">
                <span>小計</span>
                <span>{resident.subTotalCost.toLocaleString()}</span>
              </StyledStack>
            </StyledTableCell>
          </TableRow>
        ))}
    </>
  );
};

const StyledCellTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 600,
  mb: '4px',
}));
const StyledStack = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.flikGray[-25],
  padding: '0 4px',
  marginTop: '12px',
}));
const StyledTableCell = styled(TableCell)(() => ({
  padding: '6PX 12PX',
  fontWeight: 300,
  border: '1px solid #DADCE3',
  wordBreak: 'break-all',
  minWidth: '150px',
}));
const StyledStackForItems = styled(Stack)(() => ({
  padding: '0 4px',
  fontSize: '14px',
  justifyContent: 'space-between',
  textAlign: 'left',
}));

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import React, {useCallback, useMemo, VFC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {SoldLabel} from './SoldLabel';
import {BuildingIcon} from '../images/BuildingIcon';
import {PlusIcon} from '../images/PlusIcon';
import {useProperty} from '../queries/property';

const drawerWidth = 240;

export const Navigation: VFC = () => {
  const {propertyId, roomId} = useParams();
  const {data: property} = useProperty(propertyId);
  const navigate = useNavigate();
  const sortRooms = useMemo(() => {
    if (property?.rooms?.items) {
      return property?.rooms?.items?.sort((a, b) => {
        if (!!a && !!b) {
          return a.name < b.name ? -1 : 1;
        }
        return 0;
      });
    }
    return null;
  }, [property?.rooms?.items]);
  const onSubmit = useCallback(() => {
    navigate(`/property/${propertyId}/addRoom`);
  }, [navigate, propertyId]);
  return (
    <>
      <Box component="nav">
        <Drawer
          variant="permanent"
          open
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              height: 'calc(100vh - 72px)',
              bottom: 0,
              top: 'unset',
              boxSizing: 'border-box',
              borderColor: 'flikGray.-25',
            },
          }}>
          <List>
            <ListItem
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                padding: '24px 16px',
              }}>
              <ListItemIcon sx={{marginRight: '8px'}}>
                <BuildingIcon sx={{stroke: 'black'}} />
              </ListItemIcon>
              <Typography noWrap>{property?.name}</Typography>
            </ListItem>
            <ListItemButton
              selected={!roomId}
              onClick={() => navigate(`/property/${propertyId}`)}>
              物件情報
            </ListItemButton>
            <ListItem
              sx={{
                padding: '12px 16px 4px',
                fontSize: '12px',
                color: 'flikGray.+15',
              }}>
              部屋一覧
            </ListItem>
            {sortRooms &&
              sortRooms.map(
                room =>
                  room && (
                    <ListItemButton
                      key={room.id}
                      selected={roomId === room.id}
                      onClick={() =>
                        navigate(`/property/${propertyId}/${room.id}`)
                      }
                      sx={{display: 'flex', justifyContent: 'space-between'}}>
                      <Typography
                        color={roomId === room.id ? 'primary' : 'black'}
                        noWrap>
                        {room.name}
                      </Typography>
                      {room.soldDate ? (
                        <SoldLabel sx={{fontSize: '12px'}}>売却済</SoldLabel>
                      ) : null}
                    </ListItemButton>
                  ),
              )}
            <ListItemButton onClick={onSubmit}>
              <ListItemIcon sx={{marginRight: '4px'}}>
                <PlusIcon strokeColor="#31CDD3" />
              </ListItemIcon>
              <Typography sx={{color: 'flikPrimary.main'}}>
                部屋を追加
              </Typography>
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
    </>
  );
};

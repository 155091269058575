import {Box, Link, Stack} from '@mui/material';
import React from 'react';
import {TypographyForTermsAndConditions as Typography} from '../components/TypographyForTermsAndConditions';
import {UlForTermsAndConditions as Ul} from '../components/UlForTermsAndConditions';
import {useIsMobile} from '../hooks/useIsMobile';

export const TermsScreen = () => {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      padding="40px 0">
      <Box
        width={isMobile ? '100%' : '900px'}
        padding={isMobile ? '0 20px' : '0'}>
        <Stack>
          <Typography variant="h1">利用規約</Typography>
          <Typography variant="body1">
            この利用規約（以下、「本規約」といいます。）は、フリー株式会社（住所：大阪市西区京町堀1-8-31
            安田会館ビル
            205、以下、「当社」といいます。）が本サービスにおける利用に関する条件を、本サービスを利用する利用者と当社との間で定めるものです。利用者は、本規約の内容に同意した場合に限り、本アプリを使用することができるものとします。
          </Typography>
          <Typography variant="h2">第１条（適用）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                本規約は、本サービスの提供条件及び本サービスの利用に関する、当社と利用者との間の権利義務関係を定めることを目
                的とし、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社が当社ウェブサイト
                （https://prop-dock.com）上で掲載する本サービス利用に関するお知らせや説明等は、本規約の一部を構成するものとし、当該お知らせや説明等が本規約の内容と異なる場合は、本規約の規定が優先して適用されるものとします。
              </Typography>
            </li>
          </Ul>

          <Typography variant="h2">第２条（定義）</Typography>
          <Typography variant="body1">
            本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
            <br />
            （１）「本アプリ」とは、当社が提供する WEB
            アプリケーション「PropDock」を意味します。
            <br />
            （２）「本サービス」とは、本アプリにより提供されるサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）の全てを意味します。
            <br />
            （３）「基本サービス」とは、本サービスのうち、無料により利用者が使用することができるサービス（利用者の資産情報の管理ツールの提供）を意味します。
            <br />
            （３）「利用契約」とは、本規約及び当社と利用者との間で締結する、本サービスの利用契約を意味します。
            <br />
            （４）「有料サブスクリプションサービス」とは、https://www.freescorp.com/prop-dock
            に掲載された、本サービスのうち、利用者の希望により提供される有料定期購入サービスを意味します。
            <br />
            （５）「利用契約」とは、本規約及び当社と利用者との間で締結する、本サービスの利用契約を意味します。
            <br />
            （６）「利用者」とは、本規約に基づいて本アプリ及び本サービスを利用する個人または法人などの利用者を意味します。
            <br />
            （７）「利用者情報」とは、当社が、(a)当社が直接的に、又は、(b)当社システム利用者を通じて当社が間接的に、利用者から取得する情報その他利用者に関する情報を意味します。
            <br />
            （８）「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権、その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
            <br />
            （９）「本プライバシーポリシー」とは、
            <Link href="/privacy" underline="hover">
              https://prop-dock.com/privacy
            </Link>
            に掲載された、本アプリに係るプライバシーポリシーを意味します。
          </Typography>
          <Typography variant="h2">第３条（本アプリの利用目的）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                本アプリは、個人・法人を問わず、利用者が利用者自身の事業のために使用する目的でのみ使用することができ、販売、配布又は開発等の私的使用以外の目的で使用してはなりません。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者は、本アプリを、当社が提供する状態でのみ利用するものとし、本アプリの複製、修正、変更、改変、翻案その他一切の行為を行ってはなりません。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第４条（権利帰属）</Typography>
          <Typography variant="body1">
            本サービスおよび本サービスに関連する一切の情報についての著作権およびその他知的財産権はすべて当社または当社にその利用を許諾した権利者に帰属し、利用者は無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます。）、伝送、配布、出版、営業使用等をしてはならないものとします。
          </Typography>
          <Typography variant="h2">第５条 （利用者の責任）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                利用者は、利用者ご自身の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為及びその結果について一切の責任を負うものとします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、利用者が本規約に違反して本サービスを利用していると認めた場合、アカウントの停止、提供コンテンツの削除その他当社が必要かつ適切と判断する措置を講じます。ただし、当社は、かかる違反を防止又は是正する義務を負いません。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者は、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを賠償するものとします。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第６条（秘密保持）</Typography>
          <Typography variant="body1">
            利用者は、本サービスに関連して当社が利用者に対して秘密に取り扱うことを求めた開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
          </Typography>
          <Typography variant="h2">第７条（権利義務の譲渡の禁止）</Typography>
          <Typography variant="body1">
            利用者は、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者はかかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          </Typography>
          <Typography variant="h2">第８条 （保証の否認及び免責）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                当社は、本サービス（本コンテンツを含みます。）に事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。当社は、利用者に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。何らかの理由（諸法規の適用がある場合を含みます。）により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任の範囲は、当社の責に帰すべき事由により現実に発生した直接かつ通常の損害に限られるものとし、かつ、損害の事由が生じた時点から遡って過去1年間に当該利用者から現実に受領した本サービスの利用料金の総額を上限とします（但し、消費者契約法が適用され、かつ、当社の債務不履行又は不法行為について、当社、当社の代表者又はその使用する者に故意又は重大な過失がある場合を除きます。）。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第９条（第三者の権利）</Typography>
          <Typography variant="body1">
            利用者が、Apple Inc.(以下「Apple」) および Google
            inc.（以下「Google」）が提供する、App Store および Google Play
            から、本アプリをダウンロードしている場合、または iOS
            デバイスで本アプリを使用している場合、Apple および
            Googleが定める規約を読み、理解し、これに同意しているものとします。
          </Typography>
          <Typography variant="h2">第１０条（プライバシー）</Typography>
          <Typography variant="body1">
            当社は、利用者情報を、本プライバシーポリシーに従って適切に取り扱います。利用者は、本サービスの個人情報の取り扱いに関して、本プライバシーポリシーを確認するものとします。プライバシーポリシーは、本規約の一部として本規約に組み込まれるものとします。本規約を承諾することで、利用者は、本プライバシーポリシーに同意するものとします。
          </Typography>
          <Typography variant="h2">第１１条（登録・利用方法）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                本サービスの利用を希望する者（以下、「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本アプリの利用の登録を申請することができます。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                前項に定める登録の完了時に、本サービスの利用のための利用契約が登録利用者と当社の間に成立し、登録利用者は本アプリを本規約に従い利用することができるようになります。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
                <br />
                （１）当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
                <br />
                （２）未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
                <br />
                （３）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
                <br />
                （４）過去当社との契約に違反した者またはその関係者であると当社が判断した場合
                <br />
                （５）第１１条に定める措置を受けたことがある場合
                <br />
                （６）その他、登録を適当でないと当社が判断した場合
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者は、本サービスの利用に際して利用者情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正するものとします。(a)利用者自ら又は(b)当社若しくは当社システム利用者が利用者問い合わせの際に代理入力した利用者情報の内容に虚偽又は誤りがあったことにより利用者に損害が生じたとしても、当社又は当社システム利用者は、当社に重大な過失がある場合を除き、一切責任を負いません。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第１２条（アカウントの管理）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                利用者は、本サービスの利用に際して当社が発行するパスワードを不正に利用されないよう自らの責任で厳重に管理するものとします。当社又は当社システム利用者は、登録されたパスワードを利用して行なわれた一切の行為を、利用者本人の行為とみなすことができるものとし、パスワードまたは利用者
                ID
                の管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は利用者が負うものとし、当社は一切の責任を負いません。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者は、本サービスのアカウントを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">
            第１３条（第三者のアプリケーション）
          </Typography>
          <Typography variant="body1">
            当社は、本サービスが、第三者が提供するアプリケーションおよびデバイスと互換性があることを保証しません。
          </Typography>
          <Typography variant="h2">第１４条（サービスの提供）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                利用者は、本サービスを利用するにあたり、必要な機器及び通信手段を、利用者の費用と責任で用意するものとします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、当社が必要と判断する場合、あらかじめ利用者に通知することなく、いつでも、本サービスの全部又は一部の内容を変更し、また、その提供を中止することができるものとします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                以下のいずれかの事由があると判断した場合、利用者に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                <br />
                （１）本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                <br />
                （２）地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                <br />
                （３）コンピュータまたは通信回線等が事故により停止した場合
                <br />
                （４）その他、当社が本サービスの提供が困難と判断した場合
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、本サービスの提供の停止または中断により、利用者または第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、相当な注意を払い、合理的な技術を用いて、本サービスの運用を維持し、提供します。ただし、当社のサービス内容は、利用者に何らの責任も負わずに随時適用法に従って変更される場合があります。
                <br />
                <br />
                以下に例を示します。
                <br />
                ・本サービスは、技術的な問題、メンテナンスもしくはテスト、または更新
                (関連する法律や規制要件の変更を反映するために必要なものを含む)
                が原因で、一時的な中断が発生する場合があります。
                <br />
                ・当社は、サービスを常に進化させ、改善することを目指しています。また本サービスのすべての部分
                (特定の機能、特徴、サブスクリプションプランなど)
                を変更、中断、または (永続的もしくは一時的に)
                停止することがあります。
                <br />
                <br />
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                本サービスは、政府機関、その他の第三者、または当社の支配を超える事象に起因するインターネット、その他のサービスの停止または障害に関しては、利用者に対する責任を一切負わず、また、利用者に払い戻しを行う義務も負いません。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第１５条（退会）</Typography>
          <Typography variant="body1">
            本サービスに登録した利用者は、ユーザーが退会処理を行うかもしくは当社へ退会の依頼をすることによりアカウントを削除して退会することができるものとします。なお、退会者の利用者情報の取り扱いについては、プライバシーポリシーにてこれを定めるものとします。
          </Typography>
          <Typography variant="h2">
            第１６条（有料サブスクリプションサービス）
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                利用者は、有料サブスクリプションサービスの利用により、当社スタッフとのオンライン面談及び資産管理についての相談を行うことができます。オンライン面談は、当社スタッフと利用者との間の日程調整により行われますが、当社スタッフの都合により、利用者の希望できない日程にて行うことができない場合があります。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者は、有料サブスクリプションサービスの利用により、毎月末日に、利用者が本アプリに入力した資産情報について、当社が作成する資料データの送付を受けることができます。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者は、希望により有料サブスクリプションサービスを利用することができますが、当社の判断により有料サブスクリプションサービスの提供ができない場合があります。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                利用者が、有料サブスクリプションサービスのキャンセル又は当社の判断により有料サブスクリプションサービスを中断した場合（たとえば、利用者が禁止事項記載の行為を行った場合や、利用者の支払に関する詳細情報が故意に変更された場合など）、再度の加入ができないことがあります。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                有料サブスクリプションサービスは、今後終了する可能性があります。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">
            第１７条（有料サブスクリプションサービスの価格の変更）
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                当社は、有料サブスクリプションの料金及び条件を変更することがあります。変更があった場合には、当社は利用者に対して、妥当な通知を行い、事前にその旨をお知らせします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                価格の変更は、変更の通知日が属する次の月の初日に効力が生じます。利用者は、価格変更の効力が生じた後も有料サブスクリプションサービスの利用を継続することによって、新たな価格を受け入れたものとみなします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                価格変更に同意しない場合は、価格変更が有効になる前に、該当する有料サブスクリプションの解約をすることで、変更を拒否できます。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第１８条 （禁止事項）</Typography>
          <Typography variant="body1">
            利用者は、本サービスの利用に際して、以下の事項を行ってはなりません。
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                犯罪行為に関連する裁判所の判決、決定もしくは命令、又は法令上拘束力のある行政措置に違反する行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                公の秩序又は善良の風俗を害するおそれのある行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社又は第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上又は契約上の権利を侵害する行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引又は助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿又は送信する行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社又は第三者になりすます行為又は意図的に虚偽の情報を流布させる行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）、他の利用者に対する嫌がらせや誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                反社会的勢力（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。）に対する利益供与その他の協力行為
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                宗教活動又は宗教団体への勧誘行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示又は提供する行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                本サービスのサーバやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、その他当社による本サービスの運営又は他の利用者による本サービスの利用を妨害し、これらに支障を与える行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                本アプリを逆アセンブル、逆コンパイル又はリバース・エンジニアリングする行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                上記１から１１のいずれかに該当する行為を援助又は助長する行為。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                ロボット、スパイダー、クローラー、スクレイパー、又はその他の自動化された手段若しくは処理方法を使用し、本サービスからのデータ若しくはコンテンツの収集を行う行為
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                他の利用者に関する個人情報等を収集または蓄積する行為
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                その他、当社が不適当と判断した行為。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">
            第１９条（利用制限および登録抹消）
          </Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                当社は、利用者が以下の各号のいずれかの事由に該当する場合は、事前の通知なく、利用者に対して本サービスの全部もしくは一部の利用を制限しまたは利用者としての登録を抹消することができるものとします。
                <br />
                （１）本規約のいずれかの条項に違反した場合
                <br />
                （２）登録事項に虚偽の事実があることが判明した場合
                <br />
                （３）破産、民事再生、会社更生または特別清算の手続開始決定等の申立があった場合
                <br />
                （４）最終アクセスから２年以上本サービスの利用がない場合
                <br />
                （５）当社からの問い合わせその他の回答を求める連絡に対して３０日間以上応答がない場合
                <br />
                （６）その他、当社が本サービスの利用を適当でないと判断した場合
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社が利用者のアカウントを削除した時点で、利用者の本サービスにおけるすべての利用権は、理由を問わず、消滅するものとします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                当社は、本条に基づき当社が行った行為により利用者に生じた損害について、一切の責任を負いません。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第２０条 （連絡方法）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                本サービスに関する当社から利用者への連絡は、当社が運営するウェブサイト又は本アプリ内の適宜の場所への掲示、登録メールアドレスへの送信、その他、当社が適当と判断する方法により行うものとします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                本サービスに関する利用者から当社への連絡は、当社が運営するウェブサイト又は本アプリ内の適宜の場所に設置するお問い合わせフォームの送信又は当社が指定する方法により行うものとします。
              </Typography>
            </li>
          </Ul>
          <Typography variant="h2">第２１条（利用規約の変更）</Typography>
          <Typography variant="body1">
            当社は、当社が必要とする場合、民法第５４８条の４の規定により、相当の事由があると認められる場合においていつでも、本規約を変更できるものとします。その場合、当社は、変更後の本規約の内容及び効力発生日を、本アプリ若しくは当社ウェブサイトに表示するか、又は当社が定める方法により利用者に通知することで利用者に周知します。変更後の本規約は、効力発生日から効力を生じるものとします。
          </Typography>
          <Typography variant="h2">第２２条（分離可能性）</Typography>
          <Typography variant="body1">
            本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残り部分は、継続して完全に効力を有するものとします。
          </Typography>
          <Typography variant="h2">第２３条 （言語）</Typography>
          <Typography variant="body1">
            本規約は、日本語より作成されます。
          </Typography>
          <Typography variant="h2">第２４条（準拠法・裁判管轄）</Typography>
          <Ul>
            <li>
              <Typography variant="body1">
                本規約の解釈にあたっては、日本法を準拠法とします。
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
              </Typography>
            </li>
          </Ul>
          <Typography variant="body1" alignSelf="end">
            以上
          </Typography>
          <Typography variant="body1">
            【２０２３年２月１１日制定】
            <br />
            【２０２４年９月１２日改訂】
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

import {Auth, Hub} from 'aws-amplify';
import constate from 'constate';
import {useCallback, useEffect, useState} from 'react';

export type OAuthUser = {
  attributes: {
    sub: string;
    email: string;
  };
  signInUserSession: {
    accessToken: {
      payload: {
        'cognito:groups': string[];
      };
    };
  };
};

export const [AuthProvider, useOAuthUserState] = constate(() => {
  const [oAuthUser, setOAuthUser] = useState<{
    state: 'loading' | 'ready';
    user: OAuthUser | null;
  }>({
    state: 'loading',
    user: null,
  });
  const updateUser = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then(currentUser => setOAuthUser({state: 'ready', user: currentUser}))
      .catch(() => {
        setOAuthUser({state: 'ready', user: null});
      });
  }, []);
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({payload: {event}}) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          updateUser();
          break;
        case 'signOut':
          setOAuthUser({state: 'ready', user: null});
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
    updateUser();
    return unsubscribe;
  }, [updateUser]);
  return oAuthUser;
});

export const useOAuthUser = () => {
  const userState = useOAuthUserState();
  return userState.user;
};

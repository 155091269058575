import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation} from 'react-query';
import {
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
  CreateOrderMutationVariables,
  CreateOrderMutation,
} from '../API';
import {createOrder, updateOrder} from '../graphql/mutations';

export const useCreateOrderMutation = () => {
  return useMutation(async (variables: CreateOrderMutationVariables) => {
    const order = (await API.graphql(
      graphqlOperation(createOrder, variables),
    )) as GraphQLResult<CreateOrderMutation>;
    return order.data?.createOrder;
  });
};

export const useUpdateOrderMutation = () => {
  return useMutation(async (variables: UpdateOrderMutationVariables) => {
    const order = (await API.graphql(
      graphqlOperation(updateOrder, variables),
    )) as GraphQLResult<UpdateOrderMutation>;
    return order.data?.updateOrder;
  });
};

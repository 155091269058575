import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  Button,
} from '@mui/material';
import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {EarlyRepaymentInfoInput, Repayment} from '../API';
import {BaseBox} from '../components/BaseBox';
import {Cell} from '../components/Cell';
import {LabelTitle} from '../components/LabelTitle';
import {Navigation} from '../components/Navigation';
import {RepaymentResultTableBody} from '../components/RepaymentResultTableBody';
import {ArrowRightIcon} from '../images/ArrowRightIcon';
import {useProperty} from '../queries/property';
import {useRoom} from '../queries/room';
import {RepaymentBarChart} from '../utils/RepaymentBarChart';
import {
  ExtraRepayment,
  ReductionType,
  mortgageSimulation,
} from '../utils/repaymentSimulation';

export const RepaymentResultScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const repaymentInfo = location.state as {
    loanAmount: number;
    interestRate: number;
    repaymentCount: number;
    repaymentMethod: Repayment;
    earlyRepaymentMethod: ReductionType;
    repaymentDate: Date;
    deliveryDate: Date;
    earlyRepaymentInfo: EarlyRepaymentInfoInput[];
  };
  const {propertyId, roomId} = useParams();
  const {data: room} = useRoom(roomId);
  const {data: property} = useProperty(propertyId);
  const calculationMethod = 'bankLoan';
  const loanAmount = repaymentInfo.loanAmount;
  const repaymentCount = repaymentInfo.repaymentCount;
  const annualInterestRate = repaymentInfo.interestRate;
  const repaymentType = repaymentInfo.repaymentMethod;
  const startDate = repaymentInfo.deliveryDate;
  const firstRepaymentDate = repaymentInfo.repaymentDate;
  const reductionType = repaymentInfo.earlyRepaymentMethod;
  const extraRepayments: ExtraRepayment[] =
    repaymentInfo.earlyRepaymentInfo.map(info => ({
      date: new Date(
        Number(info.year),
        Number(info.month) - 1,
        firstRepaymentDate.getDate(),
      ),
      amount: info.repaymentAmount,
    }));
  const results = mortgageSimulation(
    loanAmount,
    repaymentCount,
    annualInterestRate,
    repaymentType,
    startDate,
    firstRepaymentDate,
    calculationMethod,
    extraRepayments,
    reductionType,
  );
  const totalEarlyRepaymentAmount = repaymentInfo.earlyRepaymentInfo.reduce(
    (sum, info) => sum + Number(info.repaymentAmount),
    0,
  );
  const currentMonthRepayment = Math.round(
    results
      .filter(result => {
        return (
          result.month ===
          `${new Date().getFullYear()}年${new Date().getMonth() + 1}月`
        );
      })
      .reduce(
        (sum, result) =>
          totalEarlyRepaymentAmount > 0
            ? sum + result.repaymentAmountIncludeEarlyRepayment
            : sum + result.repaymentAmount,
        0,
      ),
  );
  const currentYearRepayment = Math.round(
    results
      .filter(result => {
        const year = result.month.split('年').shift() ?? '';
        const date = new Date(year);
        return date.getFullYear() === new Date().getFullYear();
      })
      .reduce(
        (sum, result) =>
          totalEarlyRepaymentAmount > 0
            ? sum + result.repaymentAmountIncludeEarlyRepayment
            : sum + result.repaymentAmount,
        0,
      ),
  );
  const totalRepaymentAmount = Math.round(
    results.reduce((sum, result) => sum + result.repaymentAmount, 0),
  );
  const totalRepaymentAmountIncludeEarlyRepayment = Math.round(
    results
      .filter(result => {
        return result.repaymentAmountIncludeEarlyRepayment !== 0;
      })
      .reduce(
        (sum, result) => sum + result.repaymentAmountIncludeEarlyRepayment,
        0,
      ),
  );
  const totalInterest = Math.round(
    results.reduce((sum, result) => sum + result.interest, 0),
  );
  const totalInterestIncludeEarlyRepayment = Math.round(
    results
      .filter(result => {
        return result.repaymentAmountIncludeEarlyRepayment !== 0;
      })
      .reduce((sum, result) => sum + result.interestIncludeEarlyRepayment, 0),
  );
  const digits = 1000;
  const percentageInterest =
    Math.round((totalInterest / totalRepaymentAmount) * 100 * digits) / digits;
  const percentageInterestIncludeEarlyRepayment =
    Math.round(
      (totalInterestIncludeEarlyRepayment /
        totalRepaymentAmountIncludeEarlyRepayment) *
        100 *
        digits,
    ) / digits;
  const termReduction =
    repaymentCount -
    results.filter(result => {
      return result.repaymentAmountIncludeEarlyRepayment !== 0;
    }).length;
  const yearOfTermReduction = Math.floor(termReduction / 12);
  const monthOfTermReduction = termReduction % 12;
  const averageRepaymentAmount = Math.round(
    results.reduce((sum, result) => sum + result.repaymentAmount, 0) /
      results.length,
  );
  const averageRepaymentAmountIncludeEarlyRepayment = Math.round(
    results
      .filter(result => {
        return result.repaymentAmountIncludeEarlyRepayment !== 0;
      })
      .reduce(
        (sum, result) => sum + result.repaymentAmountIncludeEarlyRepayment,
        0,
      ) /
      results.filter(result => {
        return result.repaymentAmountIncludeEarlyRepayment !== 0;
      }).length,
  );
  const fullPaymentDate = results.filter(result => {
    return result.repaymentAmountIncludeEarlyRepayment !== 0;
  });
  return room && property ? (
    <>
      <Box sx={{display: 'flex'}}>
        <Navigation />
        <Box sx={{flexGrow: 1}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginBottom: '24px',
              //  maxWidth:　画面幅 - サイドメニュー幅 - 左右padding
              maxWidth: 'calc(100vw - 240px - 64px)',
            }}
            spacing={3}>
            <Typography
              noWrap
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: 1.3,
              }}>
              繰り上げ返済シミュレーション結果
            </Typography>
          </Stack>
          <BaseBox>
            <Grid
              spacing={4.5}
              direction="row"
              container
              sx={{mb: '41.5px'}}
              justifyContent="space-around">
              <Grid item xs={6}>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>借入額合計</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {repaymentInfo.loanAmount.toLocaleString()}円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>月々返済分</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {repaymentInfo.loanAmount.toLocaleString()}円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>当月返済額</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {currentMonthRepayment.toLocaleString()}円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>当年返済額</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {currentYearRepayment.toLocaleString()}円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>総支払額</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {totalEarlyRepaymentAmount > 0
                        ? totalRepaymentAmountIncludeEarlyRepayment.toLocaleString()
                        : totalRepaymentAmount.toLocaleString()}
                      円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>返済額合計</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {totalEarlyRepaymentAmount > 0
                        ? totalRepaymentAmountIncludeEarlyRepayment.toLocaleString()
                        : totalRepaymentAmount.toLocaleString()}
                      円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>利息合計</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {totalEarlyRepaymentAmount > 0
                        ? totalInterestIncludeEarlyRepayment.toLocaleString()
                        : totalInterest.toLocaleString()}
                      円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>利息割合</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {totalEarlyRepaymentAmount > 0
                        ? percentageInterestIncludeEarlyRepayment
                        : percentageInterest}
                      ％
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>繰り上げ返済金額</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {totalEarlyRepaymentAmount.toLocaleString()}円
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>短縮期間</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {termReduction === 0
                        ? '0年'
                        : `${yearOfTermReduction}年${monthOfTermReduction}ヶ月`}
                    </Typography>
                  </StyledCell>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <Cell color="primary-40">
                    <LabelTitle>完済日</LabelTitle>
                  </Cell>
                  <StyledCell>
                    <Typography>
                      {fullPaymentDate[fullPaymentDate.length - 1].month}
                    </Typography>
                  </StyledCell>
                </Stack>
              </Grid>
              <Grid item xs="auto">
                <RepaymentBarChart
                  loanAmount={loanAmount}
                  totalRepaymentAmount={totalRepaymentAmount}
                  totalRepaymentAmountIncludeEarlyRepayment={
                    totalRepaymentAmountIncludeEarlyRepayment
                  }
                  totalInterest={totalInterest}
                  totalInterestIncludeEarlyRepayment={
                    totalInterestIncludeEarlyRepayment
                  }
                  percentageInterest={percentageInterest}
                  percentageInterestIncludeEarlyRepayment={
                    percentageInterestIncludeEarlyRepayment
                  }
                  averageRepaymentAmount={averageRepaymentAmount}
                  averageRepaymentAmountIncludeEarlyRepayment={
                    averageRepaymentAmountIncludeEarlyRepayment
                  }
                  totalEarlyRepaymentAmount={totalEarlyRepaymentAmount}
                />
              </Grid>
            </Grid>
            <Button
              size="small"
              startIcon={
                <ArrowRightIcon
                  sx={{
                    fill: 'transparent !important',
                    stroke: '#31CDD3',
                    fontSize: '20px !important',
                    transform: 'rotate(180deg)',
                  }}
                />
              }
              onClick={() =>
                navigate(`/repaymentInfo/${roomId}/${propertyId}`)
              }>
              <Typography color="flikPrimary.main">入力画面に戻る</Typography>
            </Button>
            <Typography sx={{fontWeight: 600, mt: '47.5px', mb: '8px'}}>
              返済スケジュール
            </Typography>
            <Table>
              <TableHead sx={{backgroundColor: 'flikPrimary.-40'}}>
                <TableRow>
                  <StyledTableCell align="center">月数</StyledTableCell>
                  <StyledTableCell align="right">返済額</StyledTableCell>
                  <StyledTableCell align="right">利息</StyledTableCell>
                  <StyledTableCell align="right">元金</StyledTableCell>
                  <StyledTableCell align="right">元金残高</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalEarlyRepaymentAmount > 0
                  ? results
                      .filter(result => {
                        return (
                          result.repaymentAmountIncludeEarlyRepayment !== 0
                        );
                      })
                      .map((result, index) => {
                        return (
                          <RepaymentResultTableBody
                            key={index}
                            month={result.month}
                            repaymentAmount={
                              result.repaymentAmountIncludeEarlyRepayment
                            }
                            interest={result.interestIncludeEarlyRepayment}
                            principal={result.principalIncludeEarlyRepayment}
                            remainingPrincipal={
                              result.remainingPrincipalIncludeEarlyRepayment
                            }
                          />
                        );
                      })
                  : results.map((result, index) => {
                      return (
                        <RepaymentResultTableBody
                          key={index}
                          month={result.month}
                          repaymentAmount={result.repaymentAmount}
                          interest={result.interest}
                          principal={result.principal}
                          remainingPrincipal={result.remainingPrincipal}
                        />
                      );
                    })}
              </TableBody>
            </Table>
          </BaseBox>
        </Box>
      </Box>
    </>
  ) : null;
};

const StyledCell = styled(Cell)(() => ({
  width: '100%',
}));

const StyledTableCell = styled(TableCell)`
  padding: 12px 16px;
  font-weight: 600;
`;

import {ExpensesCategory} from '../API';

export const convertStringToExpensesCategory = (category: string) => {
  switch (category) {
    case 'TRAVELING':
      return ExpensesCategory.TRAVELING;
    case 'COMMUNICATION':
      return ExpensesCategory.COMMUNICATION;
    case 'LIBRARY':
      return ExpensesCategory.LIBRARY;
    case 'PARTICIPATION':
      return ExpensesCategory.PARTICIPATION;
    case 'ENTERTAINMENT':
      return ExpensesCategory.ENTERTAINMENT;
    case 'OTHER':
    default:
      return ExpensesCategory.OTHER;
  }
};

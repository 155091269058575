import {Grid, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import React, {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {EarlyRepaymentInfoHead} from './EarlyRepaymentInfoHead';
import {EarlyRepaymentList} from './EarlyRepaymentList';
import {MoneyInfoTableHead} from './MoneyInfoTableHead';
import {MoneyList} from './MoneyList';
import {Room} from '../API';
import {Button} from '../components/Button';
import {Cell} from '../components/Cell';
import {LabelTitle} from '../components/LabelTitle';
import {EditIcon} from '../images/EditIcon';
import {useRoom} from '../queries/room';
import {convertDateToString} from '../utils/convertDateToString';
import {convertRepaymentOrNullToString} from '../utils/convertRepaymentOrNullToString';
import {isNonNull} from '../utils/isNonNull';
import {conditionList} from '../utils/selectOptions';

export const RoomInfo = () => {
  const {roomId} = useParams();
  const {data: room} = useRoom(roomId);
  const navigate = useNavigate();
  const convertConditionToDisplayName = useCallback(
    (value: Room['condition']) => {
      return conditionList.find(list => list.value === value)?.name;
    },
    [],
  );

  return room ? (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{mb: '8px'}}>
        <Typography sx={{fontWeight: 500}}>基本情報</Typography>
        {!room.soldDate && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<EditIcon color="primary" />}
            onClick={() => navigate('edit')}>
            編集
          </Button>
        )}
      </Stack>
      <Grid spacing={0} direction="row" container>
        <Grid item xs={6}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>号室</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.name}</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>間取り</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.layout}</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>部屋の状況</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>
                {convertConditionToDisplayName(room.condition)}
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>引渡し日・決算日</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>
                {convertDateToString(room.deliveryDate, 'yyyy/M/d')}
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>購入会社名</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.salesCompany}</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>専有面積</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.occupiedArea}m²</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>土地面積合計</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>
                {room.totalArea ? `${room.totalArea}m²` : ''}
              </Typography>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle sx={{whiteSpace: 'noWrap'}}>
                銀行名（ローン会社名）
              </LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.bankName}</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>融資年数</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.loanYears}年</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>融資金額</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.loanAmount.toLocaleString()}円</Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>金利</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>
                {room.interestRate ? `${room.interestRate}%` : ''}
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>返済方法</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>
                {convertRepaymentOrNullToString(room.repaymentMethod)}
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>返済開始日</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>
                {room.repaymentDate
                  ? convertDateToString(room.repaymentDate, 'yyyy/M/d')
                  : null}
              </Typography>
            </StyledCell>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>売買価格</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{room.sellingPrice.toLocaleString()}円</Typography>
            </StyledCell>
          </Stack>
        </Grid>
      </Grid>
      {room.customItems?.filter(isNonNull).map((item, index) => (
        <Grid item xs={12} key={index}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>{item.key}</LabelTitle>
            </Cell>
            <StyledCell>
              <Typography>{item.value}</Typography>
            </StyledCell>
          </Stack>
        </Grid>
      ))}

      <Typography sx={{fontWeight: 500, mt: '24px', mb: '8px'}}>
        費用
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <MoneyInfoTableHead />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>購入諸費用</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.purchaseCosts?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>固定資産税・都市計画税</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.propertyTax?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>不動産取得税</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.realEstateAcquisitionTax?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>地震火災保険料</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.insuranceFee?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>修繕積立金</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.repairReserveFund?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>管理費</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.managementFee?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0}>
            <Cell color="primary-40">
              <LabelTitle>管理委託手数料</LabelTitle>
            </Cell>
            <StyledCell>
              <Stack direction="column" spacing={1}>
                {room.managementCommission?.map((c, index) => (
                  <MoneyList key={index} cost={c} />
                ))}
              </Stack>
            </StyledCell>
          </Stack>
        </Grid>
        {room.customCostItems?.filter(isNonNull).map((item, index) => (
          <Grid key={index} item xs={12}>
            <Stack direction="row" spacing={0}>
              <Cell color="primary-40">
                <LabelTitle>{item.key}</LabelTitle>
              </Cell>
              <StyledCell>
                <Stack direction="column" spacing={1}>
                  {item.value?.map((c, index) => (
                    <MoneyList key={index} cost={c} />
                  ))}
                </Stack>
              </StyledCell>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Typography sx={{fontWeight: 500, mt: '24px', mb: '8px'}}>
        繰上げ返済設定
      </Typography>
      {room.earlyRepaymentInfo &&
      room.earlyRepaymentInfo?.filter(isNonNull).length > 0 ? (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <EarlyRepaymentInfoHead width="225px" />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column">
                {room.earlyRepaymentInfo?.map((info, index) => (
                  <EarlyRepaymentList key={index} info={info} index={index} />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography>未設定</Typography>
      )}
    </>
  ) : null;
};

const StyledCell = styled(Cell)(() => ({
  width: '100%',
}));

import {
  IconButton as MuiIconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {VFC} from 'react';

type CustomProps = {
  tooltip?: string;
} & IconButtonProps;

export const IconButton: VFC<CustomProps> = ({tooltip = '', ...props}) => {
  return (
    <Tooltip title={tooltip}>
      <StyledIconButton {...props} />
    </Tooltip>
  );
};

const StyledIconButton = styled(MuiIconButton)(() => ({
  '&.MuiIconButton-root': {
    padding: '6px',
    '&.MuiIconButton-root > svg': {
      fill: 'none',
    },
  },
})) as typeof MuiIconButton;

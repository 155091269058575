import {Repayment} from '../API';

export const convertStringToRepaymentOrNull = (value: string) => {
  switch (value) {
    case 'EQUAL_PRINCIPAL':
      return Repayment.EQUAL_PRINCIPAL;
    case 'EQUAL_INSTALLMENT':
      return Repayment.EQUAL_INSTALLMENT;
    default:
      return null;
  }
};

import '@aws-amplify/ui-react/styles.css';
import './App.css';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Amplify} from 'aws-amplify';
import React, {useMemo} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {AuthProvider} from './AuthProvider';
import {ThemeProvider} from './ThemeProvider';
import awsExports from './aws-exports';
import {Screens} from './screens';

Sentry.init({
  dsn: 'https://fdbdeef9053643b895086f15eac2d1a0@o1280658.ingest.sentry.io/4504767917457408',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: awsExports.aws_user_files_s3_bucket.split('-')[1],
});

Amplify.configure({
  ...awsExports,
  Auth: {
    identityPoolId: awsExports.aws_cognito_identity_pool_id,
    region: awsExports.aws_cognito_region,
    userPoolId: awsExports.aws_user_pools_id,
    userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
  },
  Storage: {
    AWSS3: {
      bucket: awsExports.aws_user_files_s3_bucket,
      region: awsExports.aws_user_files_s3_bucket_region,
    },
  },
});

function App() {
  const queryClient = useMemo(() => new QueryClient(), []);
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <Screens />
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;

import {PropertyFileCategory} from '../API';

export const convertPropertyFileCategoryToString = (
  category: PropertyFileCategory | undefined,
) => {
  switch (category) {
    case PropertyFileCategory.INSURANCE:
      return '損害保険';
    case PropertyFileCategory.LEASING:
      return '賃貸管理';
    case PropertyFileCategory.LOAN:
      return '銀行・ローン';
    case PropertyFileCategory.REPAIR:
      return 'リフォーム';
    case PropertyFileCategory.SALES:
      return '売買契約';
    case PropertyFileCategory.TAX:
      return '確定申告・税務';
    case PropertyFileCategory.OTHER:
      return 'その他';
    default:
      return '';
  }
};

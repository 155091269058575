import {Grid, Link, Modal, Stack, Typography, styled} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Button} from './Button';
import {OrderType} from '../API';
import {useOAuthUserState} from '../AuthProvider';
import {BaseBox as Box} from '../components/BaseBox';
import usePayment from '../hooks/usePayment';
import {MinusIcon} from '../images/MinusIcon';
import {PlanCheckIcon} from '../images/PlanCheckIcon';
import {useCreateOrderMutation} from '../queries/orders';

type Props = {
  open: true;
  isForMeeting: boolean;
  onClose: () => void;
};

export const PlanDescriptionModal = ({open, isForMeeting, onClose}: Props) => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const {mutateAsync: createOrder} = useCreateOrderMutation();
  const {initiateRecurringPayment} = usePayment();

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '800px',
            maxHeight: '90vh',
            overflowY: 'auto',
            boxShadow: 'none',
            padding: '40px',
          }}>
          <Stack direction="column" alignItems="center" spacing={4}>
            {isForMeeting ? (
              <Typography textAlign="center" fontSize="20px" fontWeight={600}>
                プロプランを契約すると、
                <br />
                業界経験豊富なプロのコンサルタントによる
                <br />
                オンライン面談を受けることができます
              </Typography>
            ) : (
              <Typography textAlign="center" fontSize="20px" fontWeight={600}>
                プロプランを契約すると、
                <br />
                毎月の運用状況やプロによる運用アドバイスが記載された
                <br />
                コンサルティングシートをお送りします
              </Typography>
            )}
            <Grid direction="row" container>
              <Grid direction="column" container item xs={3.8} mr={2}>
                <Grid item height="75px" />
                <Grid item height="52px">
                  <StyledTypography>物件 / 部屋 / 入居者管理</StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>雑費管理</StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>雑費簡易帳簿エクスポート</StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>PDFデータ管理</StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>
                    年次 / 月次収支報告書エクスポート
                  </StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>
                    繰り上げ返済シミュレーション
                  </StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>AI物件査定</StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography>
                    月次コンサルティングレポート
                  </StyledTypography>
                </Grid>
                <Grid item height="52px">
                  <StyledTypography style={{whiteSpace: 'pre-line'}}>
                    コンサルティングサポート {'\n'}（オンライン面談）
                  </StyledTypography>
                </Grid>
              </Grid>
              <Grid
                direction="column"
                container
                item
                xs={3.8}
                mr={2}
                sx={{
                  bgcolor: '#F5F8FA',
                  borderRadius: '12px',
                }}>
                <Grid
                  item
                  height="75px"
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  alignItems="center">
                  <Typography
                    textAlign="center"
                    fontSize="16px"
                    fontWeight={700}>
                    ライトプラン
                  </Typography>
                  <Typography
                    textAlign="center"
                    fontSize="20px"
                    fontWeight={500}>
                    無料
                  </Typography>
                </Grid>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <MinusIcon />
                </PlanItem>
                <PlanItem>
                  <MinusIcon />
                </PlanItem>
                <PlanItem>
                  <MinusIcon />
                </PlanItem>
              </Grid>
              <Grid
                direction="column"
                container
                item
                xs={3.8}
                sx={{
                  background:
                    'linear-gradient(349.79deg, #FBFFE4 11.55%, #D9F6F7 90.64%)',
                  borderRadius: '12px',
                }}>
                <Grid
                  item
                  height="75px"
                  display="flex"
                  direction="column"
                  justifyContent="center"
                  alignItems="center">
                  <Typography
                    textAlign="center"
                    fontSize="16px"
                    fontWeight={700}>
                    プロプラン
                  </Typography>
                  <Typography
                    textAlign="center"
                    fontSize="20px"
                    fontWeight={700}>
                    2,580
                    <span style={{fontSize: '14px', fontWeight: 700}}>
                      円/月(税込)
                    </span>
                  </Typography>
                </Grid>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
                <PlanItem>
                  <PlanCheckIcon />
                </PlanItem>
              </Grid>
            </Grid>
            <Stack spacing={3} justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                onClick={async () => {
                  await createOrder(
                    {
                      input: {
                        orderType: OrderType.PRO_PLAN,
                        userOrdersId: userState.user?.attributes.sub,
                      },
                    },
                    {
                      onSuccess: async response => {
                        if (!response?.id || !userState.user?.attributes.sub) {
                          navigate('/error');
                          return;
                        }
                        initiateRecurringPayment(
                          response.id,
                          userState.user.attributes.sub,
                        );
                      },
                      onError: () => {
                        navigate('/error');
                        return;
                      },
                    },
                  );
                }}
                sx={{width: '100%', minWidth: '200px'}}>
                プロプランをはじめる
              </Button>
              <Link
                onClick={onClose}
                underline="hover"
                color="flikPrimary.main"
                fontWeight={600}
                fontSize="14px"
                sx={{cursor: 'pointer'}}>
                閉じる
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

const StyledTypography = styled(Typography)`
  font-size: 14px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
`;
const PlanItem = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
`;

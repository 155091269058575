import {SvgIconProps, SvgIcon} from '@material-ui/core';
import React from 'react';

export const PlanCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.1004C14.5461 22.1004 16.9879 21.089 18.7882 19.2886C20.5886 17.4883 21.6 15.0465 21.6 12.5004C21.6 9.95431 20.5886 7.51252 18.7882 5.71217C16.9879 3.91182 14.5461 2.90039 12 2.90039C9.45392 2.90039 7.01212 3.91182 5.21177 5.71217C3.41143 7.51252 2.4 9.95431 2.4 12.5004C2.4 15.0465 3.41143 17.4883 5.21177 19.2886C7.01212 21.089 9.45392 22.1004 12 22.1004ZM16.4484 10.9488C16.667 10.7225 16.7879 10.4193 16.7852 10.1047C16.7825 9.79007 16.6563 9.4891 16.4338 9.26661C16.2113 9.04412 15.9103 8.91792 15.5957 8.91518C15.281 8.91245 14.9779 9.0334 14.7516 9.25199L10.8 13.2036L9.2484 11.652C9.02208 11.4334 8.71895 11.3124 8.40432 11.3152C8.08968 11.3179 7.78871 11.4441 7.56622 11.6666C7.34373 11.8891 7.21753 12.1901 7.21479 12.5047C7.21206 12.8193 7.33301 13.1225 7.5516 13.3488L9.9516 15.7488C10.1766 15.9738 10.4818 16.1001 10.8 16.1001C11.1182 16.1001 11.4234 15.9738 11.6484 15.7488L16.4484 10.9488Z"
        fill="#31CDD3"
      />
    </SvgIcon>
  );
};

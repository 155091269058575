import {Box, Stack, Link} from '@mui/material';
import React from 'react';
import {TypographyForTermsAndConditions as Typography} from '../components/TypographyForTermsAndConditions';
import {useIsMobile} from '../hooks/useIsMobile';

export const LawScreen = () => {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      padding="40px 0">
      <Box
        width={isMobile ? '100%' : '900px'}
        padding={isMobile ? '0 20px' : '0'}>
        <Stack>
          <Typography variant="h1">特定商取引法に基づく表示</Typography>
          <Typography variant="body1">
            当社が提供するアプリケーション「PropDock」について、特定商取引法に基づく表示は以下のとおりです。
          </Typography>
          <Typography variant="h2">（販売事業者の名称）</Typography>
          <Typography variant="body1">
            名称: フリー株式会社 <br />
            住所: 大阪市西区京町堀1-8-31 安田会館ビル 205
            <br />
            メールアドレス：contact@freescorp.com（フリー株式会社お客様お問い合わせ窓口）
            <br />
            代表者又は通信販売に関する業務の責任者の氏名
            <br />
            代表取締役 北川純也
          </Typography>
          <Typography variant="h2">（問い合わせ先）</Typography>
          <Typography variant="body1">
            お問い合わせの前に
            <Link href="/help" underline="hover">
              ヘルプ
            </Link>
            をご参照ください。なお、サービスに関する質問・サポートはご契約内容により対応します。連絡先電話番号についてもご請求いただければ、遅滞なく電話番号を開示いたします。
          </Typography>
          <Typography variant="h2">（販売価格）</Typography>
          <Typography variant="body1">
            基本使用料金は無料。有料のサブスクリプションサービスについては、購入月は無料で、翌月より、月額料金
            2,580円（消費税込み定価）
          </Typography>
          <Typography variant="h2">（販売価格以外の費用）</Typography>
          <Typography variant="body1">
            インターネット接続費用その他インターネット利用に必要な費用。モバイルデバイスをご利用の場合にはパケット通信料が発生する場合があります。
          </Typography>
          <Typography variant="h2">（支払方法）</Typography>
          <Typography variant="body1">
            ・クレジットカード
            <br />
            ・Apple App Store
            <br />
            ・Google Play
          </Typography>
          <Typography variant="h2">
            （サブスクリプションサービスの提供にかかる条件）
          </Typography>
          <Typography variant="body1">
            <span style={{fontWeight: '700'}}>購入</span>
            <br />
            1. クレジットカードでのお支払い
            <br />
            本サービスのサブスクリプションは、サブスクリプション購入当月は無料、購入月の翌月の第１営業日に最初の月額料金の請求が発生し、その後毎月第１営業日に請求が行われます。利用者は当社が利用者指定のお支払い方法を保存し、利用者が解約しない限り、毎月自動的に、利用者指定のお支払い方法で請求する権限を当社に付与します。
            <br />
            サブスクリプションは、毎月1日の前日までキャンセルした場合は翌月第1営業日の請求はかかりません。お支払い済みの月額料金については、返金されません。利用者のお支払いが完了しなかった場合、当社は利用者のサブスクリプションを一時停止することができます。
            <br />
            <br />
            2. Apple App Storeでのお支払い
            <br />
            ご購入手続き完了時に、Apple
            Inc.もしくはそのグループ会社（以下、Apple等と総称します）が提供する決済手段への請求が発生します。決済代金の引き落とし時期や解約などその他の詳細につきましては、Apple等が定める規約及び決済手段の規約をご確認ください。
            <br />
            <br />
            3. Google Playでのお支払い
            <br />
            ご購入手続き完了時に、Google
            Inc.もしくはそのグループ会社（以下、Google等と総称します）が提供する決済手段への請求が発生します。決済代金の引き落とし時期や解約などその他の詳細につきましては、Google等が定める規約及び決済手段の規約をご確認ください。
          </Typography>
          <Typography variant="h2">（サービス提供時期）</Typography>
          <Typography variant="body1">
            購入決済完了次第、すぐに利用可能です。
          </Typography>
          <Typography variant="h2">（返品の取扱方法）</Typography>
          <Typography variant="body1">
            本サービスの特性上、返品、交換はお受けいたしかねます。
            <br />
            なお、解約いただくと、退会当月で契約が終了しますが、当社サイト内で表示するキャンセルポリシーが適用される場合を除いて中途解約による返金は承っておりません。
          </Typography>
          <Typography variant="h2">（動作環境）</Typography>
          <Typography variant="body1">
            ・Microsoft Edge 最新版
            <br />
            ・Google Chrome 最新版
            <br />
            ・Safari 最新版
            <br />
            ・Android OS 12 以上
            <br />
            ・iOS 17 以上
          </Typography>
          <Typography variant="h2">（改定履歴）</Typography>
          <Typography variant="body1">
            【２０２３年２月１１日制定】
            <br />
            【２０２４年９月１２日改訂】
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

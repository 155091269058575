import {Box, Stack, Tab, Tabs, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {ReactNode, useCallback, useEffect, useState, VFC} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {AddRoomSuccessfulModal} from '../components/AddRoomSuccessfulModal';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {ErrorSaleRoomModal} from '../components/ErrorSaleRoomModal';
import {Navigation} from '../components/Navigation';
import {ResidentInfo} from '../components/ResidentInfo';
import {RoomInfo} from '../components/RoomInfo';
import {SaleRoomModal} from '../components/SaleRoomModal';
import {Snackbar} from '../components/Snackbar';
import {SoldLabel} from '../components/SoldLabel';
import {ChartBarIcon} from '../images/ChartBarIcon';
import {MoneyCircleIcon} from '../images/MoneyCircleIcon';
import {useProperty} from '../queries/property';
import {useRoom} from '../queries/room';
import {OperationState, replaceState} from '../utils/OperationState';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

const TabPanel: VFC<TabPanelProps> = ({children, value, index, ...props}) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...props}>
      {value === index && (
        <BaseBox sx={{borderRadius: '0 12px 12px 12px'}}>{children}</BaseBox>
      )}
    </div>
  );
};

export const RoomDetailScreen = () => {
  const navigate = useNavigate();
  const {propertyId, roomId} = useParams();
  const {data: room} = useRoom(roomId);
  const {data: property} = useProperty(propertyId);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSaleRoomModal, setOpenSaleRoomModal] = useState(false);
  const [openSaleErrorModal, setOpenSaleErrorModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [canSell, setCanSell] = useState(false);
  const location = useLocation();
  const state = location.state as OperationState;
  const onTabChange = useCallback(
    (_e, newValue: number) => {
      setTabValue(newValue);
      const param = newValue === 1 ? 'tab=resident' : '';
      setSearchParams(param);
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (
      !!room?.residents?.items &&
      (room?.residents?.items.length === 0 ||
        !room?.residents?.items.find(item => !item?.endDate))
    ) {
      setCanSell(true);
      return;
    }
    setCanSell(false);
  }, [room, setCanSell]);
  useEffect(() => {
    const tabParam = searchParams.get('tab');
    if (tabParam === 'resident') {
      setTabValue(1);
    }
    if (state) {
      switch (state.operationState) {
        case 'add-success':
          !tabParam && setOpen(true);
          replaceState();
          return;
        case 'edit-success':
          setOpenSnackbar(true);
          replaceState();
          return;
        default:
          return;
      }
    }
  }, [searchParams, state]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleSaleRoom = () => {
    setOpenSaleRoomModal(true);
  };
  return room && property ? (
    <>
      <Box sx={{display: 'flex'}}>
        <Navigation />
        <Box sx={{flexGrow: 1}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginBottom: '24px',
              //  maxWidth:　画面幅 - サイドメニュー幅 - 左右padding
              maxWidth: 'calc(100vw - 240px - 64px)',
            }}
            spacing={3}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              overflow="hidden">
              <Typography
                noWrap
                sx={{
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: 1.3,
                }}>
                {property.name} - {room.name}
              </Typography>
              {room.soldDate ? (
                <SoldLabel sx={{flexShrink: 0}}>売却済</SoldLabel>
              ) : null}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              flexShrink={0}
              spacing={2}>
              <Button
                variant="outlined"
                size="small"
                startIcon={<ChartBarIcon color="primary" />}
                onClick={() =>
                  navigate(`/repaymentInfo/${roomId}/${propertyId}`)
                }>
                繰り上げ返済シミュレーション
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="warning"
                startIcon={
                  <MoneyCircleIcon
                    sx={{
                      fill: 'transparent !important',
                      stroke: room.soldDate === null ? '#DA0000' : '#A1A5B6',
                    }}
                  />
                }
                disabled={room.soldDate != null}
                onClick={handleSaleRoom}
                sx={{
                  flexShrink: 0,
                  '&.Mui-disabled': {
                    bgcolor: 'transparent',
                    color: 'flikGray.main',
                    border: '1px solid #A1A5B6',
                  },
                }}>
                {room.soldDate ? '売却済' : '売却'}
              </Button>
            </Stack>
          </Stack>
          <Box>
            <Tabs
              value={tabValue}
              onChange={onTabChange}
              sx={{
                '.MuiTabs-indicator': {
                  display: 'none',
                },
              }}>
              <StyledTab label="部屋情報" />
              <StyledTab label="契約者情報" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <RoomInfo />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ResidentInfo />
          </TabPanel>
        </Box>
      </Box>
      <AddRoomSuccessfulModal open={open} onClose={handleClose} />
      <Snackbar
        message="更新しました"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
      {openSaleRoomModal ? (
        <SaleRoomModal
          openSaleRoomModal={openSaleRoomModal}
          setOpenSaleRoomModal={setOpenSaleRoomModal}
          roomId={roomId}
          roomName={room.name}
          canSell={canSell}
          setOpenErrorSaleModal={setOpenSaleErrorModal}
        />
      ) : null}
      {openSaleErrorModal ? (
        <ErrorSaleRoomModal
          openErrorSaleModal={openSaleErrorModal}
          setOpenErrorSaleModal={setOpenSaleErrorModal}
        />
      ) : null}
    </>
  ) : null;
};

const StyledTab = styled(Tab)(({theme}) => ({
  borderRadius: '12px 12px 0 0',
  fontSize: '18px',
  fontWeight: 500,
  width: '136px',
  backgroundColor: theme.palette.flikGray['-25'],
  marginRight: 4,
  color: theme.palette.flikGray['+15'],
  '&.Mui-selected': {
    backgroundColor: theme.palette.flikPrimary.main,
    color: 'white',
  },
}));

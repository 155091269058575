import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  GetUserQuery,
  GetUserQueryVariables,
  ListUsersQuery,
  ListUsersQueryVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../API';
import {updateUser} from '../graphql/mutations';
import {getUser, listUsers} from '../graphql/queries';

export const useUsers = () => {
  return useQuery(['users'], async () => {
    const variables: ListUsersQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listUsers, variables),
    )) as GraphQLResult<ListUsersQuery>;
    return result?.data?.listUsers;
  });
};

export const useUser = (userId?: string) => {
  return useQuery(
    ['user', userId],
    async () => {
      if (!userId) return;
      const variable: GetUserQueryVariables = {
        id: userId,
      };
      const result = (await API.graphql(
        graphqlOperation(getUser, variable),
      )) as GraphQLResult<GetUserQuery>;
      return result.data?.getUser;
    },
    {
      enabled: !!userId,
    },
  );
};

export const useUpdateUserMutation = () => {
  return useMutation(async (variables: UpdateUserMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(updateUser, variables),
    )) as GraphQLResult<UpdateUserMutation>;
    return user.data?.updateUser;
  });
};

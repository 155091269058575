/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  disabled?: boolean | null,
  plan?: Plan | null,
  subscriptionId?: string | null,
  subscriptionStartDate?: string | null,
  subscriptionEndDate?: string | null,
  subscriptionType?: PlatformType | null,
};

export enum Plan {
  LIGHT = "LIGHT",
  PRO = "PRO",
}


export enum PlatformType {
  WEB = "WEB",
  IOS = "IOS",
  ANDROID = "ANDROID",
}


export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  plan?: ModelPlanInput | null,
  subscriptionId?: ModelStringInput | null,
  subscriptionStartDate?: ModelStringInput | null,
  subscriptionEndDate?: ModelStringInput | null,
  subscriptionType?: ModelPlatformTypeInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPlanInput = {
  eq?: Plan | null,
  ne?: Plan | null,
};

export type ModelPlatformTypeInput = {
  eq?: PlatformType | null,
  ne?: PlatformType | null,
};

export type User = {
  __typename: "User",
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  disabled?: boolean | null,
  plan?: Plan | null,
  subscriptionId?: string | null,
  subscriptionStartDate?: string | null,
  subscriptionEndDate?: string | null,
  subscriptionType?: PlatformType | null,
  files?: ModelPropertyFileConnection | null,
  expenses?: ModelExpensesConnection | null,
  properties?: ModelPropertyConnection | null,
  orders?: ModelOrderConnection | null,
  fcmTokens?: ModelFcmTokenConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelPropertyFileConnection = {
  __typename: "ModelPropertyFileConnection",
  items:  Array<PropertyFile | null >,
  nextToken?: string | null,
};

export type PropertyFile = {
  __typename: "PropertyFile",
  id: string,
  name: string,
  category: PropertyFileCategory,
  propertyName: string,
  roomName: string,
  userFilesId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum PropertyFileCategory {
  SALES = "SALES",
  LEASING = "LEASING",
  REPAIR = "REPAIR",
  LOAN = "LOAN",
  INSURANCE = "INSURANCE",
  TAX = "TAX",
  OTHER = "OTHER",
}


export type ModelExpensesConnection = {
  __typename: "ModelExpensesConnection",
  items:  Array<Expenses | null >,
  nextToken?: string | null,
};

export type Expenses = {
  __typename: "Expenses",
  id: string,
  issueDate: string,
  price: number,
  category: ExpensesCategory,
  note?: string | null,
  company?: string | null,
  userExpensesId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum ExpensesCategory {
  TRAVELING = "TRAVELING",
  COMMUNICATION = "COMMUNICATION",
  LIBRARY = "LIBRARY",
  PARTICIPATION = "PARTICIPATION",
  ENTERTAINMENT = "ENTERTAINMENT",
  OTHER = "OTHER",
}


export type ModelPropertyConnection = {
  __typename: "ModelPropertyConnection",
  items:  Array<Property | null >,
  nextToken?: string | null,
};

export type Property = {
  __typename: "Property",
  id: string,
  name: string,
  thumbnail?: string | null,
  address: string,
  station: string,
  minutes: number,
  structure: StructureType,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  customItems?:  Array<CustomItem | null > | null,
  rooms?: ModelRoomConnection | null,
  userPropertiesId?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum StructureType {
  W = "W",
  LIGHTS = "LIGHTS",
  WEIGHTS = "WEIGHTS",
  RC = "RC",
  SRC = "SRC",
  AL = "AL",
  CFT = "CFT",
  CB = "CB",
  OTHER = "OTHER",
}


export type CustomItem = {
  __typename: "CustomItem",
  key?: string | null,
  value?: string | null,
};

export type ModelRoomConnection = {
  __typename: "ModelRoomConnection",
  items:  Array<Room | null >,
  nextToken?: string | null,
};

export type Room = {
  __typename: "Room",
  id: string,
  ownerId: string,
  name: string,
  layout?: string | null,
  condition: string,
  deliveryDate: string,
  soldDate?: string | null,
  salesCompany?: string | null,
  sellingPrice: number,
  occupiedArea: string,
  totalArea: string,
  bankName?: string | null,
  loanYears: number,
  loanAmount: number,
  interestRate?: number | null,
  repaymentMethod?: Repayment | null,
  repaymentDate?: string | null,
  customItems?:  Array<CustomItem | null > | null,
  purchaseCosts?:  Array<MoneyInfo | null > | null,
  propertyTax?:  Array<MoneyInfo | null > | null,
  realEstateAcquisitionTax?:  Array<MoneyInfo | null > | null,
  insuranceFee?:  Array<MoneyInfo | null > | null,
  repairReserveFund?:  Array<MoneyInfo | null > | null,
  managementFee?:  Array<MoneyInfo | null > | null,
  managementCommission?:  Array<MoneyInfo | null > | null,
  customCostItems?:  Array<CustomCostItem | null > | null,
  residents?: ModelResidentConnection | null,
  property?: Property | null,
  earlyRepaymentInfo?:  Array<EarlyRepaymentInfo | null > | null,
  createdAt: string,
  updatedAt: string,
  propertyRoomsId?: string | null,
  owner?: string | null,
};

export enum Repayment {
  EQUAL_PRINCIPAL = "EQUAL_PRINCIPAL",
  EQUAL_INSTALLMENT = "EQUAL_INSTALLMENT",
}


export type MoneyInfo = {
  __typename: "MoneyInfo",
  money: number,
  frequency: string,
  year: string,
  month: string,
};

export type CustomCostItem = {
  __typename: "CustomCostItem",
  key?: string | null,
  value?:  Array<MoneyInfo | null > | null,
};

export type ModelResidentConnection = {
  __typename: "ModelResidentConnection",
  items:  Array<Resident | null >,
  nextToken?: string | null,
};

export type Resident = {
  __typename: "Resident",
  id: string,
  ownerId: string,
  name: string,
  startDate: string,
  endDate?: string | null,
  customItems?:  Array<CustomItem | null > | null,
  keyMoney:  Array<MoneyInfo | null >,
  firstIncome:  Array<MoneyInfo | null >,
  rent:  Array<MoneyInfo | null >,
  customMoveInCostItems?:  Array<CustomCostItem | null > | null,
  renovationCost?:  Array<MoneyInfo | null > | null,
  keyExchangeCost?:  Array<MoneyInfo | null > | null,
  customMoveOutCostItems?:  Array<CustomCostItem | null > | null,
  advertisingRates?:  Array<MoneyInfo | null > | null,
  room?: Room | null,
  createdAt: string,
  updatedAt: string,
  roomResidentsId?: string | null,
  owner?: string | null,
};

export type EarlyRepaymentInfo = {
  __typename: "EarlyRepaymentInfo",
  year: string,
  month: string,
  repaymentAmount: number,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  orderType?: OrderType | null,
  trackingId?: string | null,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
  userOrdersId?: string | null,
  owner?: string | null,
};

export enum OrderType {
  PRO_PLAN = "PRO_PLAN",
  CANCEL_PRO_PLAN = "CANCEL_PRO_PLAN",
}


export type ModelFcmTokenConnection = {
  __typename: "ModelFcmTokenConnection",
  items:  Array<FcmToken | null >,
  nextToken?: string | null,
};

export type FcmToken = {
  __typename: "FcmToken",
  id: string,
  owner: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  disabled?: boolean | null,
  plan?: Plan | null,
  subscriptionId?: string | null,
  subscriptionStartDate?: string | null,
  subscriptionEndDate?: string | null,
  subscriptionType?: PlatformType | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreatePropertyFileInput = {
  id?: string | null,
  name: string,
  category: PropertyFileCategory,
  propertyName: string,
  roomName: string,
  userFilesId?: string | null,
};

export type ModelPropertyFileConditionInput = {
  name?: ModelStringInput | null,
  category?: ModelPropertyFileCategoryInput | null,
  propertyName?: ModelStringInput | null,
  roomName?: ModelStringInput | null,
  userFilesId?: ModelIDInput | null,
  and?: Array< ModelPropertyFileConditionInput | null > | null,
  or?: Array< ModelPropertyFileConditionInput | null > | null,
  not?: ModelPropertyFileConditionInput | null,
};

export type ModelPropertyFileCategoryInput = {
  eq?: PropertyFileCategory | null,
  ne?: PropertyFileCategory | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdatePropertyFileInput = {
  id: string,
  name?: string | null,
  category?: PropertyFileCategory | null,
  propertyName?: string | null,
  roomName?: string | null,
  userFilesId?: string | null,
};

export type DeletePropertyFileInput = {
  id: string,
};

export type CreateExpensesInput = {
  id?: string | null,
  issueDate: string,
  price: number,
  category: ExpensesCategory,
  note?: string | null,
  company?: string | null,
  userExpensesId?: string | null,
};

export type ModelExpensesConditionInput = {
  issueDate?: ModelStringInput | null,
  price?: ModelIntInput | null,
  category?: ModelExpensesCategoryInput | null,
  note?: ModelStringInput | null,
  company?: ModelStringInput | null,
  userExpensesId?: ModelIDInput | null,
  and?: Array< ModelExpensesConditionInput | null > | null,
  or?: Array< ModelExpensesConditionInput | null > | null,
  not?: ModelExpensesConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelExpensesCategoryInput = {
  eq?: ExpensesCategory | null,
  ne?: ExpensesCategory | null,
};

export type UpdateExpensesInput = {
  id: string,
  issueDate?: string | null,
  price?: number | null,
  category?: ExpensesCategory | null,
  note?: string | null,
  company?: string | null,
  userExpensesId?: string | null,
};

export type DeleteExpensesInput = {
  id: string,
};

export type CreatePropertyInput = {
  id?: string | null,
  name: string,
  thumbnail?: string | null,
  address: string,
  station: string,
  minutes: number,
  structure: StructureType,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  customItems?: Array< CustomItemInput | null > | null,
  userPropertiesId?: string | null,
};

export type CustomItemInput = {
  key?: string | null,
  value?: string | null,
};

export type ModelPropertyConditionInput = {
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  address?: ModelStringInput | null,
  station?: ModelStringInput | null,
  minutes?: ModelIntInput | null,
  structure?: ModelStructureTypeInput | null,
  stories?: ModelIntInput | null,
  units?: ModelIntInput | null,
  years?: ModelStringInput | null,
  buildingManagement?: ModelStringInput | null,
  rentalManagement?: ModelStringInput | null,
  userPropertiesId?: ModelIDInput | null,
  and?: Array< ModelPropertyConditionInput | null > | null,
  or?: Array< ModelPropertyConditionInput | null > | null,
  not?: ModelPropertyConditionInput | null,
};

export type ModelStructureTypeInput = {
  eq?: StructureType | null,
  ne?: StructureType | null,
};

export type UpdatePropertyInput = {
  id: string,
  name?: string | null,
  thumbnail?: string | null,
  address?: string | null,
  station?: string | null,
  minutes?: number | null,
  structure?: StructureType | null,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  customItems?: Array< CustomItemInput | null > | null,
  userPropertiesId?: string | null,
};

export type DeletePropertyInput = {
  id: string,
};

export type CreateRoomInput = {
  id?: string | null,
  ownerId: string,
  name: string,
  layout?: string | null,
  condition: string,
  deliveryDate: string,
  soldDate?: string | null,
  salesCompany?: string | null,
  sellingPrice: number,
  occupiedArea: string,
  totalArea: string,
  bankName?: string | null,
  loanYears: number,
  loanAmount: number,
  interestRate?: number | null,
  repaymentMethod?: Repayment | null,
  repaymentDate?: string | null,
  customItems?: Array< CustomItemInput | null > | null,
  purchaseCosts?: Array< MoneyInfoInput | null > | null,
  propertyTax?: Array< MoneyInfoInput | null > | null,
  realEstateAcquisitionTax?: Array< MoneyInfoInput | null > | null,
  insuranceFee?: Array< MoneyInfoInput | null > | null,
  repairReserveFund?: Array< MoneyInfoInput | null > | null,
  managementFee?: Array< MoneyInfoInput | null > | null,
  managementCommission?: Array< MoneyInfoInput | null > | null,
  customCostItems?: Array< CustomCostItemInput | null > | null,
  earlyRepaymentInfo?: Array< EarlyRepaymentInfoInput | null > | null,
  propertyRoomsId?: string | null,
};

export type MoneyInfoInput = {
  money: number,
  frequency: string,
  year: string,
  month: string,
};

export type CustomCostItemInput = {
  key?: string | null,
  value?: Array< MoneyInfoInput | null > | null,
};

export type EarlyRepaymentInfoInput = {
  year: string,
  month: string,
  repaymentAmount: number,
};

export type ModelRoomConditionInput = {
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  condition?: ModelStringInput | null,
  deliveryDate?: ModelStringInput | null,
  soldDate?: ModelStringInput | null,
  salesCompany?: ModelStringInput | null,
  sellingPrice?: ModelIntInput | null,
  occupiedArea?: ModelStringInput | null,
  totalArea?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  loanYears?: ModelIntInput | null,
  loanAmount?: ModelIntInput | null,
  interestRate?: ModelFloatInput | null,
  repaymentMethod?: ModelRepaymentInput | null,
  repaymentDate?: ModelStringInput | null,
  and?: Array< ModelRoomConditionInput | null > | null,
  or?: Array< ModelRoomConditionInput | null > | null,
  not?: ModelRoomConditionInput | null,
  propertyRoomsId?: ModelIDInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelRepaymentInput = {
  eq?: Repayment | null,
  ne?: Repayment | null,
};

export type UpdateRoomInput = {
  id: string,
  ownerId?: string | null,
  name?: string | null,
  layout?: string | null,
  condition?: string | null,
  deliveryDate?: string | null,
  soldDate?: string | null,
  salesCompany?: string | null,
  sellingPrice?: number | null,
  occupiedArea?: string | null,
  totalArea?: string | null,
  bankName?: string | null,
  loanYears?: number | null,
  loanAmount?: number | null,
  interestRate?: number | null,
  repaymentMethod?: Repayment | null,
  repaymentDate?: string | null,
  customItems?: Array< CustomItemInput | null > | null,
  purchaseCosts?: Array< MoneyInfoInput | null > | null,
  propertyTax?: Array< MoneyInfoInput | null > | null,
  realEstateAcquisitionTax?: Array< MoneyInfoInput | null > | null,
  insuranceFee?: Array< MoneyInfoInput | null > | null,
  repairReserveFund?: Array< MoneyInfoInput | null > | null,
  managementFee?: Array< MoneyInfoInput | null > | null,
  managementCommission?: Array< MoneyInfoInput | null > | null,
  customCostItems?: Array< CustomCostItemInput | null > | null,
  earlyRepaymentInfo?: Array< EarlyRepaymentInfoInput | null > | null,
  propertyRoomsId?: string | null,
};

export type DeleteRoomInput = {
  id: string,
};

export type CreateResidentInput = {
  id?: string | null,
  ownerId: string,
  name: string,
  startDate: string,
  endDate?: string | null,
  customItems?: Array< CustomItemInput | null > | null,
  keyMoney: Array< MoneyInfoInput | null >,
  firstIncome: Array< MoneyInfoInput | null >,
  rent: Array< MoneyInfoInput | null >,
  customMoveInCostItems?: Array< CustomCostItemInput | null > | null,
  renovationCost?: Array< MoneyInfoInput | null > | null,
  keyExchangeCost?: Array< MoneyInfoInput | null > | null,
  customMoveOutCostItems?: Array< CustomCostItemInput | null > | null,
  advertisingRates?: Array< MoneyInfoInput | null > | null,
  roomResidentsId?: string | null,
};

export type ModelResidentConditionInput = {
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelResidentConditionInput | null > | null,
  or?: Array< ModelResidentConditionInput | null > | null,
  not?: ModelResidentConditionInput | null,
  roomResidentsId?: ModelIDInput | null,
};

export type UpdateResidentInput = {
  id: string,
  ownerId?: string | null,
  name?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  customItems?: Array< CustomItemInput | null > | null,
  keyMoney?: Array< MoneyInfoInput | null > | null,
  firstIncome?: Array< MoneyInfoInput | null > | null,
  rent?: Array< MoneyInfoInput | null > | null,
  customMoveInCostItems?: Array< CustomCostItemInput | null > | null,
  renovationCost?: Array< MoneyInfoInput | null > | null,
  keyExchangeCost?: Array< MoneyInfoInput | null > | null,
  customMoveOutCostItems?: Array< CustomCostItemInput | null > | null,
  advertisingRates?: Array< MoneyInfoInput | null > | null,
  roomResidentsId?: string | null,
};

export type DeleteResidentInput = {
  id: string,
};

export type CreateConsultingSheetInput = {
  id?: string | null,
  ownerId: string,
  propertyName: string,
  roomName: string,
  address: string,
  station: string,
  occupiedArea: string,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  bankName?: string | null,
  loanAmount: number,
  remainingPrincipal?: number | null,
  currentDate: string,
  interestRate?: number | null,
  principal?: number | null,
  interest?: number | null,
  deliveryDate: string,
  lastRepaymentYear: string,
  remainingRepaymentYear?: number | null,
  totalPrincipalPerYear?: number | null,
  reducedRepayment?: number | null,
  yearOfTermReduction?: number | null,
  condition: string,
  roomIncome: number,
  repayment: number,
  managementFee: number,
  repairReserveFund: number,
  otherRoomCost: number,
  managementCommission: number,
  totalIncome: number,
  totalCost: number,
  totalIncomeAndExpenses: number,
};

export type ModelConsultingSheetConditionInput = {
  ownerId?: ModelIDInput | null,
  propertyName?: ModelStringInput | null,
  roomName?: ModelStringInput | null,
  address?: ModelStringInput | null,
  station?: ModelStringInput | null,
  occupiedArea?: ModelStringInput | null,
  stories?: ModelIntInput | null,
  units?: ModelIntInput | null,
  years?: ModelStringInput | null,
  buildingManagement?: ModelStringInput | null,
  rentalManagement?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  loanAmount?: ModelIntInput | null,
  remainingPrincipal?: ModelIntInput | null,
  currentDate?: ModelStringInput | null,
  interestRate?: ModelFloatInput | null,
  principal?: ModelIntInput | null,
  interest?: ModelIntInput | null,
  deliveryDate?: ModelStringInput | null,
  lastRepaymentYear?: ModelStringInput | null,
  remainingRepaymentYear?: ModelIntInput | null,
  totalPrincipalPerYear?: ModelIntInput | null,
  reducedRepayment?: ModelIntInput | null,
  yearOfTermReduction?: ModelIntInput | null,
  condition?: ModelStringInput | null,
  roomIncome?: ModelIntInput | null,
  repayment?: ModelIntInput | null,
  managementFee?: ModelIntInput | null,
  repairReserveFund?: ModelIntInput | null,
  otherRoomCost?: ModelIntInput | null,
  managementCommission?: ModelIntInput | null,
  totalIncome?: ModelIntInput | null,
  totalCost?: ModelIntInput | null,
  totalIncomeAndExpenses?: ModelIntInput | null,
  and?: Array< ModelConsultingSheetConditionInput | null > | null,
  or?: Array< ModelConsultingSheetConditionInput | null > | null,
  not?: ModelConsultingSheetConditionInput | null,
};

export type ConsultingSheet = {
  __typename: "ConsultingSheet",
  id: string,
  ownerId: string,
  propertyName: string,
  roomName: string,
  address: string,
  station: string,
  occupiedArea: string,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  bankName?: string | null,
  loanAmount: number,
  remainingPrincipal?: number | null,
  currentDate: string,
  interestRate?: number | null,
  principal?: number | null,
  interest?: number | null,
  deliveryDate: string,
  lastRepaymentYear: string,
  remainingRepaymentYear?: number | null,
  totalPrincipalPerYear?: number | null,
  reducedRepayment?: number | null,
  yearOfTermReduction?: number | null,
  condition: string,
  roomIncome: number,
  repayment: number,
  managementFee: number,
  repairReserveFund: number,
  otherRoomCost: number,
  managementCommission: number,
  totalIncome: number,
  totalCost: number,
  totalIncomeAndExpenses: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateConsultingSheetInput = {
  id: string,
  ownerId?: string | null,
  propertyName?: string | null,
  roomName?: string | null,
  address?: string | null,
  station?: string | null,
  occupiedArea?: string | null,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  bankName?: string | null,
  loanAmount?: number | null,
  remainingPrincipal?: number | null,
  currentDate?: string | null,
  interestRate?: number | null,
  principal?: number | null,
  interest?: number | null,
  deliveryDate?: string | null,
  lastRepaymentYear?: string | null,
  remainingRepaymentYear?: number | null,
  totalPrincipalPerYear?: number | null,
  reducedRepayment?: number | null,
  yearOfTermReduction?: number | null,
  condition?: string | null,
  roomIncome?: number | null,
  repayment?: number | null,
  managementFee?: number | null,
  repairReserveFund?: number | null,
  otherRoomCost?: number | null,
  managementCommission?: number | null,
  totalIncome?: number | null,
  totalCost?: number | null,
  totalIncomeAndExpenses?: number | null,
};

export type DeleteConsultingSheetInput = {
  id: string,
};

export type CreateOrderInput = {
  id?: string | null,
  orderType?: OrderType | null,
  trackingId?: string | null,
  userOrdersId?: string | null,
};

export type ModelOrderConditionInput = {
  orderType?: ModelOrderTypeInput | null,
  trackingId?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  userOrdersId?: ModelIDInput | null,
};

export type ModelOrderTypeInput = {
  eq?: OrderType | null,
  ne?: OrderType | null,
};

export type UpdateOrderInput = {
  id: string,
  orderType?: OrderType | null,
  trackingId?: string | null,
  userOrdersId?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type CreateFcmTokenInput = {
  id?: string | null,
  owner: string,
};

export type ModelFcmTokenConditionInput = {
  owner?: ModelIDInput | null,
  and?: Array< ModelFcmTokenConditionInput | null > | null,
  or?: Array< ModelFcmTokenConditionInput | null > | null,
  not?: ModelFcmTokenConditionInput | null,
};

export type UpdateFcmTokenInput = {
  id: string,
  owner?: string | null,
};

export type DeleteFcmTokenInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  plan?: ModelPlanInput | null,
  subscriptionId?: ModelStringInput | null,
  subscriptionStartDate?: ModelStringInput | null,
  subscriptionEndDate?: ModelStringInput | null,
  subscriptionType?: ModelPlatformTypeInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelPropertyFileFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelPropertyFileCategoryInput | null,
  propertyName?: ModelStringInput | null,
  roomName?: ModelStringInput | null,
  userFilesId?: ModelIDInput | null,
  and?: Array< ModelPropertyFileFilterInput | null > | null,
  or?: Array< ModelPropertyFileFilterInput | null > | null,
  not?: ModelPropertyFileFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelExpensesFilterInput = {
  id?: ModelIDInput | null,
  issueDate?: ModelStringInput | null,
  price?: ModelIntInput | null,
  category?: ModelExpensesCategoryInput | null,
  note?: ModelStringInput | null,
  company?: ModelStringInput | null,
  userExpensesId?: ModelIDInput | null,
  and?: Array< ModelExpensesFilterInput | null > | null,
  or?: Array< ModelExpensesFilterInput | null > | null,
  not?: ModelExpensesFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPropertyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  address?: ModelStringInput | null,
  station?: ModelStringInput | null,
  minutes?: ModelIntInput | null,
  structure?: ModelStructureTypeInput | null,
  stories?: ModelIntInput | null,
  units?: ModelIntInput | null,
  years?: ModelStringInput | null,
  buildingManagement?: ModelStringInput | null,
  rentalManagement?: ModelStringInput | null,
  userPropertiesId?: ModelIDInput | null,
  and?: Array< ModelPropertyFilterInput | null > | null,
  or?: Array< ModelPropertyFilterInput | null > | null,
  not?: ModelPropertyFilterInput | null,
};

export type ModelRoomFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  condition?: ModelStringInput | null,
  deliveryDate?: ModelStringInput | null,
  soldDate?: ModelStringInput | null,
  salesCompany?: ModelStringInput | null,
  sellingPrice?: ModelIntInput | null,
  occupiedArea?: ModelStringInput | null,
  totalArea?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  loanYears?: ModelIntInput | null,
  loanAmount?: ModelIntInput | null,
  interestRate?: ModelFloatInput | null,
  repaymentMethod?: ModelRepaymentInput | null,
  repaymentDate?: ModelStringInput | null,
  and?: Array< ModelRoomFilterInput | null > | null,
  or?: Array< ModelRoomFilterInput | null > | null,
  not?: ModelRoomFilterInput | null,
  propertyRoomsId?: ModelIDInput | null,
};

export type ModelResidentFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelResidentFilterInput | null > | null,
  or?: Array< ModelResidentFilterInput | null > | null,
  not?: ModelResidentFilterInput | null,
  roomResidentsId?: ModelIDInput | null,
};

export type ModelConsultingSheetFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  propertyName?: ModelStringInput | null,
  roomName?: ModelStringInput | null,
  address?: ModelStringInput | null,
  station?: ModelStringInput | null,
  occupiedArea?: ModelStringInput | null,
  stories?: ModelIntInput | null,
  units?: ModelIntInput | null,
  years?: ModelStringInput | null,
  buildingManagement?: ModelStringInput | null,
  rentalManagement?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  loanAmount?: ModelIntInput | null,
  remainingPrincipal?: ModelIntInput | null,
  currentDate?: ModelStringInput | null,
  interestRate?: ModelFloatInput | null,
  principal?: ModelIntInput | null,
  interest?: ModelIntInput | null,
  deliveryDate?: ModelStringInput | null,
  lastRepaymentYear?: ModelStringInput | null,
  remainingRepaymentYear?: ModelIntInput | null,
  totalPrincipalPerYear?: ModelIntInput | null,
  reducedRepayment?: ModelIntInput | null,
  yearOfTermReduction?: ModelIntInput | null,
  condition?: ModelStringInput | null,
  roomIncome?: ModelIntInput | null,
  repayment?: ModelIntInput | null,
  managementFee?: ModelIntInput | null,
  repairReserveFund?: ModelIntInput | null,
  otherRoomCost?: ModelIntInput | null,
  managementCommission?: ModelIntInput | null,
  totalIncome?: ModelIntInput | null,
  totalCost?: ModelIntInput | null,
  totalIncomeAndExpenses?: ModelIntInput | null,
  and?: Array< ModelConsultingSheetFilterInput | null > | null,
  or?: Array< ModelConsultingSheetFilterInput | null > | null,
  not?: ModelConsultingSheetFilterInput | null,
};

export type ModelConsultingSheetConnection = {
  __typename: "ModelConsultingSheetConnection",
  items:  Array<ConsultingSheet | null >,
  nextToken?: string | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  orderType?: ModelOrderTypeInput | null,
  trackingId?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
  userOrdersId?: ModelIDInput | null,
};

export type ModelFcmTokenFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelFcmTokenFilterInput | null > | null,
  or?: Array< ModelFcmTokenFilterInput | null > | null,
  not?: ModelFcmTokenFilterInput | null,
};

export type CreatePdfInput = {
  identityId: string,
  category: PdfCategory,
  fileName: string,
  userName: string,
  downloadDate?: string | null,
  expenseData?: Array< ExpenseDataInput > | null,
  balanceData?: BalanceDataInput | null,
  consultingData?: Array< ConsultingSheetInput > | null,
};

export enum PdfCategory {
  EXPENSE = "EXPENSE",
  BALANCE = "BALANCE",
  CONSULTINGSHEET = "CONSULTINGSHEET",
}


export type ExpenseDataInput = {
  id: string,
  issueDate: string,
  price: number,
  category: ExpensesCategory,
  note?: string | null,
  company?: string | null,
  userExpensesId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type BalanceDataInput = {
  data?: Array< RealEstateDataInput > | null,
  term: string,
  totalIncome: number,
  totalCost: number,
};

export type RealEstateDataInput = {
  id: string,
  name: string,
  property: PropertyInput,
  occupiedArea: string,
  sellingPrice: number,
  deliveryDate: string,
  propertyTax: number,
  purchaseCosts: number,
  realEstateAcquisitionTax: number,
  repairReserveFund: number,
  insuranceFee: number,
  managementFee: number,
  customCostItems?: Array< CostItemInput > | null,
  subTotalRoomCost: number,
  residents: Array< ResidentInput >,
};

export type PropertyInput = {
  name: string,
  address: string,
};

export type CostItemInput = {
  key: string,
  money: number,
};

export type ResidentInput = {
  id: string,
  name: string,
  monthlyRent: number,
  startDate: string,
  endDate?: string | null,
  keyMoney: number,
  firstIncome: number,
  rent: number,
  customMoveInCostItems?: Array< CostItemInput > | null,
  renovationCost: number,
  keyExchangeCost: number,
  advertisingRates: number,
  customMoveOutCostItems?: Array< CostItemInput > | null,
  subTotalCost: number,
  subTotalIncome: number,
};

export type ConsultingSheetInput = {
  id: string,
  ownerId: string,
  propertyName: string,
  roomName: string,
  address: string,
  station: string,
  occupiedArea: string,
  stories?: number | null,
  units?: number | null,
  years?: string | null,
  buildingManagement?: string | null,
  rentalManagement?: string | null,
  bankName?: string | null,
  loanAmount: number,
  remainingPrincipal?: number | null,
  currentDate: string,
  interestRate?: number | null,
  principal?: number | null,
  interest?: number | null,
  deliveryDate: string,
  lastRepaymentYear: string,
  remainingRepaymentYear?: number | null,
  totalPrincipalPerYear?: number | null,
  reducedRepayment?: number | null,
  yearOfTermReduction?: number | null,
  condition: string,
  roomIncome: number,
  repayment: number,
  managementFee: number,
  repairReserveFund: number,
  otherRoomCost: number,
  managementCommission: number,
  totalIncome: number,
  totalCost: number,
  totalIncomeAndExpenses: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type StatusResult = {
  __typename: "StatusResult",
  statusCode?: number | null,
  body?: string | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  plan?: ModelSubscriptionStringInput | null,
  subscriptionId?: ModelSubscriptionStringInput | null,
  subscriptionStartDate?: ModelSubscriptionStringInput | null,
  subscriptionEndDate?: ModelSubscriptionStringInput | null,
  subscriptionType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionPropertyFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  propertyName?: ModelSubscriptionStringInput | null,
  roomName?: ModelSubscriptionStringInput | null,
  userFilesId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPropertyFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyFileFilterInput | null > | null,
};

export type ModelSubscriptionExpensesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  issueDate?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionIntInput | null,
  category?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  company?: ModelSubscriptionStringInput | null,
  userExpensesId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionExpensesFilterInput | null > | null,
  or?: Array< ModelSubscriptionExpensesFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPropertyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  station?: ModelSubscriptionStringInput | null,
  minutes?: ModelSubscriptionIntInput | null,
  structure?: ModelSubscriptionStringInput | null,
  stories?: ModelSubscriptionIntInput | null,
  units?: ModelSubscriptionIntInput | null,
  years?: ModelSubscriptionStringInput | null,
  buildingManagement?: ModelSubscriptionStringInput | null,
  rentalManagement?: ModelSubscriptionStringInput | null,
  userPropertiesId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
};

export type ModelSubscriptionRoomFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  layout?: ModelSubscriptionStringInput | null,
  condition?: ModelSubscriptionStringInput | null,
  deliveryDate?: ModelSubscriptionStringInput | null,
  soldDate?: ModelSubscriptionStringInput | null,
  salesCompany?: ModelSubscriptionStringInput | null,
  sellingPrice?: ModelSubscriptionIntInput | null,
  occupiedArea?: ModelSubscriptionStringInput | null,
  totalArea?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  loanYears?: ModelSubscriptionIntInput | null,
  loanAmount?: ModelSubscriptionIntInput | null,
  interestRate?: ModelSubscriptionFloatInput | null,
  repaymentMethod?: ModelSubscriptionStringInput | null,
  repaymentDate?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRoomFilterInput | null > | null,
  or?: Array< ModelSubscriptionRoomFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionResidentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionResidentFilterInput | null > | null,
  or?: Array< ModelSubscriptionResidentFilterInput | null > | null,
};

export type ModelSubscriptionConsultingSheetFilterInput = {
  ownerId?: ModelSubscriptionIDInput | null,
  propertyName?: ModelSubscriptionStringInput | null,
  roomName?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  station?: ModelSubscriptionStringInput | null,
  occupiedArea?: ModelSubscriptionStringInput | null,
  stories?: ModelSubscriptionIntInput | null,
  units?: ModelSubscriptionIntInput | null,
  years?: ModelSubscriptionStringInput | null,
  buildingManagement?: ModelSubscriptionStringInput | null,
  rentalManagement?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  loanAmount?: ModelSubscriptionIntInput | null,
  remainingPrincipal?: ModelSubscriptionIntInput | null,
  currentDate?: ModelSubscriptionStringInput | null,
  interestRate?: ModelSubscriptionFloatInput | null,
  principal?: ModelSubscriptionIntInput | null,
  interest?: ModelSubscriptionIntInput | null,
  deliveryDate?: ModelSubscriptionStringInput | null,
  lastRepaymentYear?: ModelSubscriptionStringInput | null,
  remainingRepaymentYear?: ModelSubscriptionIntInput | null,
  totalPrincipalPerYear?: ModelSubscriptionIntInput | null,
  reducedRepayment?: ModelSubscriptionIntInput | null,
  yearOfTermReduction?: ModelSubscriptionIntInput | null,
  condition?: ModelSubscriptionStringInput | null,
  roomIncome?: ModelSubscriptionIntInput | null,
  repayment?: ModelSubscriptionIntInput | null,
  managementFee?: ModelSubscriptionIntInput | null,
  repairReserveFund?: ModelSubscriptionIntInput | null,
  otherRoomCost?: ModelSubscriptionIntInput | null,
  managementCommission?: ModelSubscriptionIntInput | null,
  totalIncome?: ModelSubscriptionIntInput | null,
  totalCost?: ModelSubscriptionIntInput | null,
  totalIncomeAndExpenses?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionConsultingSheetFilterInput | null > | null,
  or?: Array< ModelSubscriptionConsultingSheetFilterInput | null > | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderType?: ModelSubscriptionStringInput | null,
  trackingId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
};

export type ModelSubscriptionFcmTokenFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  owner?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionFcmTokenFilterInput | null > | null,
  or?: Array< ModelSubscriptionFcmTokenFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePropertyFileMutationVariables = {
  input: CreatePropertyFileInput,
  condition?: ModelPropertyFileConditionInput | null,
};

export type CreatePropertyFileMutation = {
  createPropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePropertyFileMutationVariables = {
  input: UpdatePropertyFileInput,
  condition?: ModelPropertyFileConditionInput | null,
};

export type UpdatePropertyFileMutation = {
  updatePropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePropertyFileMutationVariables = {
  input: DeletePropertyFileInput,
  condition?: ModelPropertyFileConditionInput | null,
};

export type DeletePropertyFileMutation = {
  deletePropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateExpensesMutationVariables = {
  input: CreateExpensesInput,
  condition?: ModelExpensesConditionInput | null,
};

export type CreateExpensesMutation = {
  createExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateExpensesMutationVariables = {
  input: UpdateExpensesInput,
  condition?: ModelExpensesConditionInput | null,
};

export type UpdateExpensesMutation = {
  updateExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteExpensesMutationVariables = {
  input: DeleteExpensesInput,
  condition?: ModelExpensesConditionInput | null,
};

export type DeleteExpensesMutation = {
  deleteExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePropertyMutationVariables = {
  input: CreatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type CreatePropertyMutation = {
  createProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePropertyMutationVariables = {
  input: UpdatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type UpdatePropertyMutation = {
  updateProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePropertyMutationVariables = {
  input: DeletePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type DeletePropertyMutation = {
  deleteProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateRoomMutationVariables = {
  input: CreateRoomInput,
  condition?: ModelRoomConditionInput | null,
};

export type CreateRoomMutation = {
  createRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateRoomMutationVariables = {
  input: UpdateRoomInput,
  condition?: ModelRoomConditionInput | null,
};

export type UpdateRoomMutation = {
  updateRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteRoomMutationVariables = {
  input: DeleteRoomInput,
  condition?: ModelRoomConditionInput | null,
};

export type DeleteRoomMutation = {
  deleteRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateResidentMutationVariables = {
  input: CreateResidentInput,
  condition?: ModelResidentConditionInput | null,
};

export type CreateResidentMutation = {
  createResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateResidentMutationVariables = {
  input: UpdateResidentInput,
  condition?: ModelResidentConditionInput | null,
};

export type UpdateResidentMutation = {
  updateResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteResidentMutationVariables = {
  input: DeleteResidentInput,
  condition?: ModelResidentConditionInput | null,
};

export type DeleteResidentMutation = {
  deleteResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateConsultingSheetMutationVariables = {
  input: CreateConsultingSheetInput,
  condition?: ModelConsultingSheetConditionInput | null,
};

export type CreateConsultingSheetMutation = {
  createConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConsultingSheetMutationVariables = {
  input: UpdateConsultingSheetInput,
  condition?: ModelConsultingSheetConditionInput | null,
};

export type UpdateConsultingSheetMutation = {
  updateConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConsultingSheetMutationVariables = {
  input: DeleteConsultingSheetInput,
  condition?: ModelConsultingSheetConditionInput | null,
};

export type DeleteConsultingSheetMutation = {
  deleteConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateFcmTokenMutationVariables = {
  input: CreateFcmTokenInput,
  condition?: ModelFcmTokenConditionInput | null,
};

export type CreateFcmTokenMutation = {
  createFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFcmTokenMutationVariables = {
  input: UpdateFcmTokenInput,
  condition?: ModelFcmTokenConditionInput | null,
};

export type UpdateFcmTokenMutation = {
  updateFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFcmTokenMutationVariables = {
  input: DeleteFcmTokenInput,
  condition?: ModelFcmTokenConditionInput | null,
};

export type DeleteFcmTokenMutation = {
  deleteFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyFileQueryVariables = {
  id: string,
};

export type GetPropertyFileQuery = {
  getPropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPropertyFilesQueryVariables = {
  filter?: ModelPropertyFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertyFilesQuery = {
  listPropertyFiles?:  {
    __typename: "ModelPropertyFileConnection",
    items:  Array< {
      __typename: "PropertyFile",
      id: string,
      name: string,
      category: PropertyFileCategory,
      propertyName: string,
      roomName: string,
      userFilesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFilesByUserFilesIdQueryVariables = {
  userFilesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFilesByUserFilesIdQuery = {
  listFilesByUserFilesId?:  {
    __typename: "ModelPropertyFileConnection",
    items:  Array< {
      __typename: "PropertyFile",
      id: string,
      name: string,
      category: PropertyFileCategory,
      propertyName: string,
      roomName: string,
      userFilesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExpensesQueryVariables = {
  id: string,
};

export type GetExpensesQuery = {
  getExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListExpensesQueryVariables = {
  filter?: ModelExpensesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExpensesQuery = {
  listExpenses?:  {
    __typename: "ModelExpensesConnection",
    items:  Array< {
      __typename: "Expenses",
      id: string,
      issueDate: string,
      price: number,
      category: ExpensesCategory,
      note?: string | null,
      company?: string | null,
      userExpensesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListExpensesByUserExpensesIdQueryVariables = {
  userExpensesId: string,
  issueDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExpensesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExpensesByUserExpensesIdQuery = {
  listExpensesByUserExpensesId?:  {
    __typename: "ModelExpensesConnection",
    items:  Array< {
      __typename: "Expenses",
      id: string,
      issueDate: string,
      price: number,
      category: ExpensesCategory,
      note?: string | null,
      company?: string | null,
      userExpensesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyQueryVariables = {
  id: string,
};

export type GetPropertyQuery = {
  getProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPropertiesQueryVariables = {
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertiesQuery = {
  listProperties?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPropertiesByUserPropertiesIdQueryVariables = {
  userPropertiesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertiesByUserPropertiesIdQuery = {
  listPropertiesByUserPropertiesId?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRoomQueryVariables = {
  id: string,
};

export type GetRoomQuery = {
  getRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListRoomsQueryVariables = {
  filter?: ModelRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRoomsQuery = {
  listRooms?:  {
    __typename: "ModelRoomConnection",
    items:  Array< {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRoomsByOwnerIdQueryVariables = {
  ownerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRoomsByOwnerIdQuery = {
  listRoomsByOwnerId?:  {
    __typename: "ModelRoomConnection",
    items:  Array< {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetResidentQueryVariables = {
  id: string,
};

export type GetResidentQuery = {
  getResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListResidentsQueryVariables = {
  filter?: ModelResidentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResidentsQuery = {
  listResidents?:  {
    __typename: "ModelResidentConnection",
    items:  Array< {
      __typename: "Resident",
      id: string,
      ownerId: string,
      name: string,
      startDate: string,
      endDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      keyMoney:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null >,
      firstIncome:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null >,
      rent:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null >,
      customMoveInCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      renovationCost?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      keyExchangeCost?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customMoveOutCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      advertisingRates?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      room?:  {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      roomResidentsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListResidentsByOwnerIdQueryVariables = {
  ownerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResidentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResidentsByOwnerIdQuery = {
  listResidentsByOwnerId?:  {
    __typename: "ModelResidentConnection",
    items:  Array< {
      __typename: "Resident",
      id: string,
      ownerId: string,
      name: string,
      startDate: string,
      endDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      keyMoney:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null >,
      firstIncome:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null >,
      rent:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null >,
      customMoveInCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      renovationCost?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      keyExchangeCost?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customMoveOutCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      advertisingRates?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      room?:  {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      roomResidentsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConsultingSheetQueryVariables = {
  id: string,
};

export type GetConsultingSheetQuery = {
  getConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConsultingSheetsQueryVariables = {
  filter?: ModelConsultingSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultingSheetsQuery = {
  listConsultingSheets?:  {
    __typename: "ModelConsultingSheetConnection",
    items:  Array< {
      __typename: "ConsultingSheet",
      id: string,
      ownerId: string,
      propertyName: string,
      roomName: string,
      address: string,
      station: string,
      occupiedArea: string,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      bankName?: string | null,
      loanAmount: number,
      remainingPrincipal?: number | null,
      currentDate: string,
      interestRate?: number | null,
      principal?: number | null,
      interest?: number | null,
      deliveryDate: string,
      lastRepaymentYear: string,
      remainingRepaymentYear?: number | null,
      totalPrincipalPerYear?: number | null,
      reducedRepayment?: number | null,
      yearOfTermReduction?: number | null,
      condition: string,
      roomIncome: number,
      repayment: number,
      managementFee: number,
      repairReserveFund: number,
      otherRoomCost: number,
      managementCommission: number,
      totalIncome: number,
      totalCost: number,
      totalIncomeAndExpenses: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListConsultingSheetsByOwnerIdQueryVariables = {
  ownerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsultingSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultingSheetsByOwnerIdQuery = {
  listConsultingSheetsByOwnerId?:  {
    __typename: "ModelConsultingSheetConnection",
    items:  Array< {
      __typename: "ConsultingSheet",
      id: string,
      ownerId: string,
      propertyName: string,
      roomName: string,
      address: string,
      station: string,
      occupiedArea: string,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      bankName?: string | null,
      loanAmount: number,
      remainingPrincipal?: number | null,
      currentDate: string,
      interestRate?: number | null,
      principal?: number | null,
      interest?: number | null,
      deliveryDate: string,
      lastRepaymentYear: string,
      remainingRepaymentYear?: number | null,
      totalPrincipalPerYear?: number | null,
      reducedRepayment?: number | null,
      yearOfTermReduction?: number | null,
      condition: string,
      roomIncome: number,
      repayment: number,
      managementFee: number,
      repairReserveFund: number,
      otherRoomCost: number,
      managementCommission: number,
      totalIncome: number,
      totalCost: number,
      totalIncomeAndExpenses: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      orderType?: OrderType | null,
      trackingId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        email: string,
        disabled?: boolean | null,
        plan?: Plan | null,
        subscriptionId?: string | null,
        subscriptionStartDate?: string | null,
        subscriptionEndDate?: string | null,
        subscriptionType?: PlatformType | null,
        files?:  {
          __typename: "ModelPropertyFileConnection",
          items:  Array< {
            __typename: "PropertyFile",
            id: string,
            name: string,
            category: PropertyFileCategory,
            propertyName: string,
            roomName: string,
            userFilesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpensesConnection",
          items:  Array< {
            __typename: "Expenses",
            id: string,
            issueDate: string,
            price: number,
            category: ExpensesCategory,
            note?: string | null,
            company?: string | null,
            userExpensesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        properties?:  {
          __typename: "ModelPropertyConnection",
          items:  Array< {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        orders?:  {
          __typename: "ModelOrderConnection",
          items:  Array< {
            __typename: "Order",
            id: string,
            orderType?: OrderType | null,
            trackingId?: string | null,
            createdAt: string,
            updatedAt: string,
            userOrdersId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fcmTokens?:  {
          __typename: "ModelFcmTokenConnection",
          items:  Array< {
            __typename: "FcmToken",
            id: string,
            owner: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      userOrdersId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFcmTokenQueryVariables = {
  id: string,
};

export type GetFcmTokenQuery = {
  getFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFcmTokensQueryVariables = {
  filter?: ModelFcmTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFcmTokensQuery = {
  listFcmTokens?:  {
    __typename: "ModelFcmTokenConnection",
    items:  Array< {
      __typename: "FcmToken",
      id: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreatePdfQueryVariables = {
  input: CreatePdfInput,
};

export type CreatePdfQuery = {
  createPdf?:  {
    __typename: "StatusResult",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    email: string,
    disabled?: boolean | null,
    plan?: Plan | null,
    subscriptionId?: string | null,
    subscriptionStartDate?: string | null,
    subscriptionEndDate?: string | null,
    subscriptionType?: PlatformType | null,
    files?:  {
      __typename: "ModelPropertyFileConnection",
      items:  Array< {
        __typename: "PropertyFile",
        id: string,
        name: string,
        category: PropertyFileCategory,
        propertyName: string,
        roomName: string,
        userFilesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpensesConnection",
      items:  Array< {
        __typename: "Expenses",
        id: string,
        issueDate: string,
        price: number,
        category: ExpensesCategory,
        note?: string | null,
        company?: string | null,
        userExpensesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    properties?:  {
      __typename: "ModelPropertyConnection",
      items:  Array< {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        orderType?: OrderType | null,
        trackingId?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          email: string,
          disabled?: boolean | null,
          plan?: Plan | null,
          subscriptionId?: string | null,
          subscriptionStartDate?: string | null,
          subscriptionEndDate?: string | null,
          subscriptionType?: PlatformType | null,
          files?:  {
            __typename: "ModelPropertyFileConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpensesConnection",
            nextToken?: string | null,
          } | null,
          properties?:  {
            __typename: "ModelPropertyConnection",
            nextToken?: string | null,
          } | null,
          orders?:  {
            __typename: "ModelOrderConnection",
            nextToken?: string | null,
          } | null,
          fcmTokens?:  {
            __typename: "ModelFcmTokenConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        userOrdersId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fcmTokens?:  {
      __typename: "ModelFcmTokenConnection",
      items:  Array< {
        __typename: "FcmToken",
        id: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePropertyFileSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFileFilterInput | null,
  owner?: string | null,
};

export type OnCreatePropertyFileSubscription = {
  onCreatePropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePropertyFileSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFileFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePropertyFileSubscription = {
  onUpdatePropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePropertyFileSubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFileFilterInput | null,
  owner?: string | null,
};

export type OnDeletePropertyFileSubscription = {
  onDeletePropertyFile?:  {
    __typename: "PropertyFile",
    id: string,
    name: string,
    category: PropertyFileCategory,
    propertyName: string,
    roomName: string,
    userFilesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateExpensesSubscriptionVariables = {
  filter?: ModelSubscriptionExpensesFilterInput | null,
  owner?: string | null,
};

export type OnCreateExpensesSubscription = {
  onCreateExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateExpensesSubscriptionVariables = {
  filter?: ModelSubscriptionExpensesFilterInput | null,
  owner?: string | null,
};

export type OnUpdateExpensesSubscription = {
  onUpdateExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteExpensesSubscriptionVariables = {
  filter?: ModelSubscriptionExpensesFilterInput | null,
  owner?: string | null,
};

export type OnDeleteExpensesSubscription = {
  onDeleteExpenses?:  {
    __typename: "Expenses",
    id: string,
    issueDate: string,
    price: number,
    category: ExpensesCategory,
    note?: string | null,
    company?: string | null,
    userExpensesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
  owner?: string | null,
};

export type OnCreatePropertySubscription = {
  onCreateProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePropertySubscription = {
  onUpdateProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
  owner?: string | null,
};

export type OnDeletePropertySubscription = {
  onDeleteProperty?:  {
    __typename: "Property",
    id: string,
    name: string,
    thumbnail?: string | null,
    address: string,
    station: string,
    minutes: number,
    structure: StructureType,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    rooms?:  {
      __typename: "ModelRoomConnection",
      items:  Array< {
        __typename: "Room",
        id: string,
        ownerId: string,
        name: string,
        layout?: string | null,
        condition: string,
        deliveryDate: string,
        soldDate?: string | null,
        salesCompany?: string | null,
        sellingPrice: number,
        occupiedArea: string,
        totalArea: string,
        bankName?: string | null,
        loanYears: number,
        loanAmount: number,
        interestRate?: number | null,
        repaymentMethod?: Repayment | null,
        repaymentDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        purchaseCosts?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        propertyTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        realEstateAcquisitionTax?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        insuranceFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        repairReserveFund?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementFee?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        managementCommission?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        residents?:  {
          __typename: "ModelResidentConnection",
          items:  Array< {
            __typename: "Resident",
            id: string,
            ownerId: string,
            name: string,
            startDate: string,
            endDate?: string | null,
            createdAt: string,
            updatedAt: string,
            roomResidentsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        property?:  {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        earlyRepaymentInfo?:  Array< {
          __typename: "EarlyRepaymentInfo",
          year: string,
          month: string,
          repaymentAmount: number,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        propertyRoomsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    userPropertiesId?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateRoomSubscriptionVariables = {
  filter?: ModelSubscriptionRoomFilterInput | null,
  owner?: string | null,
};

export type OnCreateRoomSubscription = {
  onCreateRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateRoomSubscriptionVariables = {
  filter?: ModelSubscriptionRoomFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRoomSubscription = {
  onUpdateRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteRoomSubscriptionVariables = {
  filter?: ModelSubscriptionRoomFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRoomSubscription = {
  onDeleteRoom?:  {
    __typename: "Room",
    id: string,
    ownerId: string,
    name: string,
    layout?: string | null,
    condition: string,
    deliveryDate: string,
    soldDate?: string | null,
    salesCompany?: string | null,
    sellingPrice: number,
    occupiedArea: string,
    totalArea: string,
    bankName?: string | null,
    loanYears: number,
    loanAmount: number,
    interestRate?: number | null,
    repaymentMethod?: Repayment | null,
    repaymentDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    purchaseCosts?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    propertyTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    realEstateAcquisitionTax?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    insuranceFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    repairReserveFund?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementFee?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    managementCommission?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    residents?:  {
      __typename: "ModelResidentConnection",
      items:  Array< {
        __typename: "Resident",
        id: string,
        ownerId: string,
        name: string,
        startDate: string,
        endDate?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        keyMoney:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        firstIncome:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        rent:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null >,
        customMoveInCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        renovationCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        keyExchangeCost?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        customMoveOutCostItems?:  Array< {
          __typename: "CustomCostItem",
          key?: string | null,
          value?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
        } | null > | null,
        advertisingRates?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
        room?:  {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        roomResidentsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    property?:  {
      __typename: "Property",
      id: string,
      name: string,
      thumbnail?: string | null,
      address: string,
      station: string,
      minutes: number,
      structure: StructureType,
      stories?: number | null,
      units?: number | null,
      years?: string | null,
      buildingManagement?: string | null,
      rentalManagement?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      rooms?:  {
        __typename: "ModelRoomConnection",
        items:  Array< {
          __typename: "Room",
          id: string,
          ownerId: string,
          name: string,
          layout?: string | null,
          condition: string,
          deliveryDate: string,
          soldDate?: string | null,
          salesCompany?: string | null,
          sellingPrice: number,
          occupiedArea: string,
          totalArea: string,
          bankName?: string | null,
          loanYears: number,
          loanAmount: number,
          interestRate?: number | null,
          repaymentMethod?: Repayment | null,
          repaymentDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          purchaseCosts?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          propertyTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          realEstateAcquisitionTax?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          insuranceFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          repairReserveFund?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementFee?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          managementCommission?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          residents?:  {
            __typename: "ModelResidentConnection",
            nextToken?: string | null,
          } | null,
          property?:  {
            __typename: "Property",
            id: string,
            name: string,
            thumbnail?: string | null,
            address: string,
            station: string,
            minutes: number,
            structure: StructureType,
            stories?: number | null,
            units?: number | null,
            years?: string | null,
            buildingManagement?: string | null,
            rentalManagement?: string | null,
            userPropertiesId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          earlyRepaymentInfo?:  Array< {
            __typename: "EarlyRepaymentInfo",
            year: string,
            month: string,
            repaymentAmount: number,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          propertyRoomsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      userPropertiesId?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    earlyRepaymentInfo?:  Array< {
      __typename: "EarlyRepaymentInfo",
      year: string,
      month: string,
      repaymentAmount: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    propertyRoomsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateResidentSubscriptionVariables = {
  filter?: ModelSubscriptionResidentFilterInput | null,
  owner?: string | null,
};

export type OnCreateResidentSubscription = {
  onCreateResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateResidentSubscriptionVariables = {
  filter?: ModelSubscriptionResidentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateResidentSubscription = {
  onUpdateResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteResidentSubscriptionVariables = {
  filter?: ModelSubscriptionResidentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteResidentSubscription = {
  onDeleteResident?:  {
    __typename: "Resident",
    id: string,
    ownerId: string,
    name: string,
    startDate: string,
    endDate?: string | null,
    customItems?:  Array< {
      __typename: "CustomItem",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    keyMoney:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    firstIncome:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    rent:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null >,
    customMoveInCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    renovationCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    keyExchangeCost?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    customMoveOutCostItems?:  Array< {
      __typename: "CustomCostItem",
      key?: string | null,
      value?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
    } | null > | null,
    advertisingRates?:  Array< {
      __typename: "MoneyInfo",
      money: number,
      frequency: string,
      year: string,
      month: string,
    } | null > | null,
    room?:  {
      __typename: "Room",
      id: string,
      ownerId: string,
      name: string,
      layout?: string | null,
      condition: string,
      deliveryDate: string,
      soldDate?: string | null,
      salesCompany?: string | null,
      sellingPrice: number,
      occupiedArea: string,
      totalArea: string,
      bankName?: string | null,
      loanYears: number,
      loanAmount: number,
      interestRate?: number | null,
      repaymentMethod?: Repayment | null,
      repaymentDate?: string | null,
      customItems?:  Array< {
        __typename: "CustomItem",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      purchaseCosts?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      propertyTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      realEstateAcquisitionTax?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      insuranceFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      repairReserveFund?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementFee?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      managementCommission?:  Array< {
        __typename: "MoneyInfo",
        money: number,
        frequency: string,
        year: string,
        month: string,
      } | null > | null,
      customCostItems?:  Array< {
        __typename: "CustomCostItem",
        key?: string | null,
        value?:  Array< {
          __typename: "MoneyInfo",
          money: number,
          frequency: string,
          year: string,
          month: string,
        } | null > | null,
      } | null > | null,
      residents?:  {
        __typename: "ModelResidentConnection",
        items:  Array< {
          __typename: "Resident",
          id: string,
          ownerId: string,
          name: string,
          startDate: string,
          endDate?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          keyMoney:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          firstIncome:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          rent:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null >,
          customMoveInCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          renovationCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          keyExchangeCost?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          customMoveOutCostItems?:  Array< {
            __typename: "CustomCostItem",
            key?: string | null,
          } | null > | null,
          advertisingRates?:  Array< {
            __typename: "MoneyInfo",
            money: number,
            frequency: string,
            year: string,
            month: string,
          } | null > | null,
          room?:  {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          roomResidentsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      property?:  {
        __typename: "Property",
        id: string,
        name: string,
        thumbnail?: string | null,
        address: string,
        station: string,
        minutes: number,
        structure: StructureType,
        stories?: number | null,
        units?: number | null,
        years?: string | null,
        buildingManagement?: string | null,
        rentalManagement?: string | null,
        customItems?:  Array< {
          __typename: "CustomItem",
          key?: string | null,
          value?: string | null,
        } | null > | null,
        rooms?:  {
          __typename: "ModelRoomConnection",
          items:  Array< {
            __typename: "Room",
            id: string,
            ownerId: string,
            name: string,
            layout?: string | null,
            condition: string,
            deliveryDate: string,
            soldDate?: string | null,
            salesCompany?: string | null,
            sellingPrice: number,
            occupiedArea: string,
            totalArea: string,
            bankName?: string | null,
            loanYears: number,
            loanAmount: number,
            interestRate?: number | null,
            repaymentMethod?: Repayment | null,
            repaymentDate?: string | null,
            createdAt: string,
            updatedAt: string,
            propertyRoomsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        userPropertiesId?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      earlyRepaymentInfo?:  Array< {
        __typename: "EarlyRepaymentInfo",
        year: string,
        month: string,
        repaymentAmount: number,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      propertyRoomsId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    roomResidentsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateConsultingSheetSubscriptionVariables = {
  filter?: ModelSubscriptionConsultingSheetFilterInput | null,
  id?: string | null,
};

export type OnCreateConsultingSheetSubscription = {
  onCreateConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConsultingSheetSubscriptionVariables = {
  filter?: ModelSubscriptionConsultingSheetFilterInput | null,
  id?: string | null,
};

export type OnUpdateConsultingSheetSubscription = {
  onUpdateConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConsultingSheetSubscriptionVariables = {
  filter?: ModelSubscriptionConsultingSheetFilterInput | null,
  id?: string | null,
};

export type OnDeleteConsultingSheetSubscription = {
  onDeleteConsultingSheet?:  {
    __typename: "ConsultingSheet",
    id: string,
    ownerId: string,
    propertyName: string,
    roomName: string,
    address: string,
    station: string,
    occupiedArea: string,
    stories?: number | null,
    units?: number | null,
    years?: string | null,
    buildingManagement?: string | null,
    rentalManagement?: string | null,
    bankName?: string | null,
    loanAmount: number,
    remainingPrincipal?: number | null,
    currentDate: string,
    interestRate?: number | null,
    principal?: number | null,
    interest?: number | null,
    deliveryDate: string,
    lastRepaymentYear: string,
    remainingRepaymentYear?: number | null,
    totalPrincipalPerYear?: number | null,
    reducedRepayment?: number | null,
    yearOfTermReduction?: number | null,
    condition: string,
    roomIncome: number,
    repayment: number,
    managementFee: number,
    repairReserveFund: number,
    otherRoomCost: number,
    managementCommission: number,
    totalIncome: number,
    totalCost: number,
    totalIncomeAndExpenses: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    orderType?: OrderType | null,
    trackingId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      disabled?: boolean | null,
      plan?: Plan | null,
      subscriptionId?: string | null,
      subscriptionStartDate?: string | null,
      subscriptionEndDate?: string | null,
      subscriptionType?: PlatformType | null,
      files?:  {
        __typename: "ModelPropertyFileConnection",
        items:  Array< {
          __typename: "PropertyFile",
          id: string,
          name: string,
          category: PropertyFileCategory,
          propertyName: string,
          roomName: string,
          userFilesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpensesConnection",
        items:  Array< {
          __typename: "Expenses",
          id: string,
          issueDate: string,
          price: number,
          category: ExpensesCategory,
          note?: string | null,
          company?: string | null,
          userExpensesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      properties?:  {
        __typename: "ModelPropertyConnection",
        items:  Array< {
          __typename: "Property",
          id: string,
          name: string,
          thumbnail?: string | null,
          address: string,
          station: string,
          minutes: number,
          structure: StructureType,
          stories?: number | null,
          units?: number | null,
          years?: string | null,
          buildingManagement?: string | null,
          rentalManagement?: string | null,
          customItems?:  Array< {
            __typename: "CustomItem",
            key?: string | null,
            value?: string | null,
          } | null > | null,
          rooms?:  {
            __typename: "ModelRoomConnection",
            nextToken?: string | null,
          } | null,
          userPropertiesId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        items:  Array< {
          __typename: "Order",
          id: string,
          orderType?: OrderType | null,
          trackingId?: string | null,
          user?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email: string,
            disabled?: boolean | null,
            plan?: Plan | null,
            subscriptionId?: string | null,
            subscriptionStartDate?: string | null,
            subscriptionEndDate?: string | null,
            subscriptionType?: PlatformType | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          userOrdersId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fcmTokens?:  {
        __typename: "ModelFcmTokenConnection",
        items:  Array< {
          __typename: "FcmToken",
          id: string,
          owner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrdersId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateFcmTokenSubscriptionVariables = {
  filter?: ModelSubscriptionFcmTokenFilterInput | null,
};

export type OnCreateFcmTokenSubscription = {
  onCreateFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFcmTokenSubscriptionVariables = {
  filter?: ModelSubscriptionFcmTokenFilterInput | null,
};

export type OnUpdateFcmTokenSubscription = {
  onUpdateFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFcmTokenSubscriptionVariables = {
  filter?: ModelSubscriptionFcmTokenFilterInput | null,
};

export type OnDeleteFcmTokenSubscription = {
  onDeleteFcmToken?:  {
    __typename: "FcmToken",
    id: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

import {Stack, Typography, Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useEffect, useMemo, useState, VFC} from 'react';
import {BaseBox} from '../components/BaseBox';
import {ArrowUpIcon} from '../images/ArrowUpIcon';
import {BuildingIcon} from '../images/BuildingIcon';
import {MoneyIcon} from '../images/MoneyIcon';
import {useExpenses} from '../queries/expenses';
import {useResidentsByMonth} from '../queries/resident';
import {useRoomsByMonth, useUnsoldRooms} from '../queries/room';
import {getCostFromMoneyInfoByMonth} from '../utils/getCostFromMoneyInfo';
import {isNonNull} from '../utils/isNonNull';
import {toISOStringWithTimezone} from '../utils/toISOStringWithTimezone';

export const SimpleStats: VFC = () => {
  const startDate = useMemo(() => {
    const startOfMonth = new Date();
    startOfMonth.setMonth(0);
    startOfMonth.setDate(1);
    return toISOStringWithTimezone(startOfMonth);
  }, []);
  const endDate = useMemo(() => {
    const endOfMonth = new Date();
    endOfMonth.setMonth(11);
    endOfMonth.setDate(31);
    return toISOStringWithTimezone(endOfMonth);
  }, []);
  const {data: expenses} = useExpenses(startDate, endDate);
  const {data: unsoldRooms} = useUnsoldRooms();
  const date = new Date();
  const targetYear = date.getFullYear();
  const targetMonth = date.getMonth() + 1;
  const {data: rooms} = useRoomsByMonth(targetYear, targetMonth);
  const {data: residents} = useResidentsByMonth(targetYear, targetMonth);
  const [incoming, setIncoming] = useState(0);
  const [outgoing, setOutgoing] = useState(0);
  const [expense, setExpense] = useState(0);

  useEffect(() => {
    if (!expenses) {
      return;
    }
    const expense = expenses.items
      .filter(isNonNull)
      .map(item => {
        return item.price;
      })
      .reduce((sum, element) => sum + element, 0);
    setExpense(expense);
  }, [expenses]);

  useEffect(() => {
    const incomings: (number | undefined)[] = [];
    const outgoings: (number | undefined)[] = [];
    rooms?.items.filter(isNonNull).map(item => {
      const soldDate = item.soldDate;
      item.purchaseCosts?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, soldDate),
        );
      });
      item.propertyTax?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, soldDate),
        );
      });
      item.realEstateAcquisitionTax?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, soldDate),
        );
      });
      item.insuranceFee?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, soldDate),
        );
      });
      item.repairReserveFund?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, soldDate),
        );
      });
      item.managementFee?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, soldDate),
        );
      });
      item.customCostItems?.filter(isNonNull).map(item => {
        return item.value?.map(cost => {
          return outgoings.push(
            getCostFromMoneyInfoByMonth(
              cost,
              targetYear,
              targetMonth,
              soldDate,
            ),
          );
        });
      });
      return item;
    });
    residents?.items.filter(isNonNull).map(item => {
      const endDate = item.endDate;
      item.keyMoney.map(cost => {
        return incomings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
        );
      });
      item.firstIncome.map(cost => {
        return incomings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
        );
      });
      item.rent.map(cost => {
        return incomings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
        );
      });
      item.customMoveInCostItems?.filter(isNonNull).map(item => {
        return item.value?.map(cost => {
          return incomings.push(
            getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
          );
        });
      });
      item.renovationCost?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
        );
      });
      item.keyExchangeCost?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
        );
      });
      item.advertisingRates?.map(cost => {
        return outgoings.push(
          getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
        );
      });
      item.customMoveOutCostItems?.filter(isNonNull).map(item => {
        return item.value?.map(cost => {
          return outgoings.push(
            getCostFromMoneyInfoByMonth(cost, targetYear, targetMonth, endDate),
          );
        });
      });
      return item;
    });
    setOutgoing(
      outgoings.filter(isNonNull).reduce((sum, element) => sum + element, 0),
    );
    setIncoming(
      incomings.filter(isNonNull).reduce((sum, element) => sum + element, 0),
    );
  }, [rooms, residents, targetYear, targetMonth]);

  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid container item xs="auto" alignItems="center" spacing={3}>
        <Grid item>
          <StyledBaseBox>
            <Grid container direction="row" alignItems="center" spacing={2.5}>
              <Grid item>
                <Circle color="#E1F6FF">
                  <ArrowUpIcon
                    sx={{
                      fontSize: '28px !important',
                      margin: '2px',
                      stroke: '#25A7AC',
                    }}
                  />
                </Circle>
              </Grid>
              <Grid item>
                <StyledTextHeading>当月の予定総収入</StyledTextHeading>
                <Stack direction="row" alignItems="flex-end">
                  <StyledTextResult>
                    {incoming.toLocaleString()}
                  </StyledTextResult>
                  <StyledTextUnit>円</StyledTextUnit>
                </Stack>
              </Grid>
            </Grid>
          </StyledBaseBox>
        </Grid>
        <Grid item>
          <StyledBaseBox>
            <Grid container direction="row" alignItems="center" spacing={2.5}>
              <Grid item>
                <Circle color="#FFF3F3">
                  <ArrowUpIcon
                    sx={{
                      fontSize: '28px !important',
                      margin: '2px',
                      stroke: 'red',
                      transform: 'rotate(180deg)',
                    }}
                  />
                </Circle>
              </Grid>
              <Grid item>
                <StyledTextHeading>当月の予定総支出</StyledTextHeading>
                <Stack direction="row" alignItems="flex-end">
                  <StyledTextResult>
                    {outgoing.toLocaleString()}
                  </StyledTextResult>
                  <StyledTextUnit>円</StyledTextUnit>
                </Stack>
              </Grid>
            </Grid>
          </StyledBaseBox>
        </Grid>
      </Grid>
      <Grid container item xs="auto" alignItems="center" spacing={2.5}>
        <Grid item>
          <StyledBaseBox>
            <Grid container direction="row" alignItems="center" spacing={2.5}>
              <Grid item>
                <Circle color="#E1F6FF">
                  <BuildingIcon
                    sx={{
                      fontSize: '28px !important',
                      margin: '2px',
                      stroke: '#00ABFB',
                    }}
                  />
                </Circle>
              </Grid>
              <Grid item>
                <Stack>
                  <StyledTextHeading>所有部屋数</StyledTextHeading>
                  <Stack direction="row" alignItems="flex-end">
                    <StyledTextResult>
                      {unsoldRooms?.items.length.toLocaleString()}
                    </StyledTextResult>
                    <StyledTextUnit>件</StyledTextUnit>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </StyledBaseBox>
        </Grid>
        <Grid item>
          <StyledBaseBox>
            <Grid container direction="row" alignItems="center" spacing={2.5}>
              <Grid item>
                <Circle color="#FFF1D4">
                  <MoneyIcon
                    sx={{
                      fontSize: '32px !important',
                      stroke: '#FF9300',
                    }}
                  />
                </Circle>
              </Grid>
              <Grid item>
                <Stack>
                  <StyledTextHeading>今年の雑費合計</StyledTextHeading>
                  <Stack direction="row" alignItems="flex-end">
                    <StyledTextResult>
                      {expense.toLocaleString()}
                    </StyledTextResult>
                    <StyledTextUnit>円</StyledTextUnit>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </StyledBaseBox>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledBaseBox = styled(BaseBox)(() => ({
  width: '320px',
  padding: '24px',
}));

const StyledTextHeading = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '20.8px',
  marginBottom: '8px',
}));

const StyledTextResult = styled(Typography)(() => ({
  fontFamily: 'Arial,メイリオ',
  fontSize: '40px',
  fontWeight: '700',
  lineHeight: 1,
  marginRight: '4px',
}));

const StyledTextUnit = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '30px',
}));

const Circle = styled('div')(({color}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: color,
  padding: '4px',
}));

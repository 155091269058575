import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Grid, InputAdornment, Stack, styled, Typography} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import React, {useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {IconButton} from '../components/IconButton';
import {LabelTitle} from '../components/LabelTitle';
import {PasswordValidation} from '../components/PasswordValidation';
import {TextField} from '../components/TextField';
import {combineAlphanumericCharacters} from '../utils/regex';

type FormInput = {
  currentPassword: string;
  newPassword: string;
};

export const ChangePasswordScreen = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(
          user,
          data.currentPassword,
          data.newPassword,
        );
      })
      .then(() => {
        navigate('/profile');
      })
      .catch(err => {
        Sentry.captureException(err);
        setSubmitError(true);
      });
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const disabled = useMemo(() => {
    return (
      !dirtyFields.currentPassword ||
      !dirtyFields.newPassword ||
      !!errors.currentPassword?.type ||
      !!errors.newPassword?.type
    );
  }, [
    dirtyFields.currentPassword,
    dirtyFields.newPassword,
    errors.currentPassword?.type,
    errors.newPassword?.type,
  ]);

  return (
    <Stack alignItems="center">
      <Grid direction="column" spacing={3} container sx={{width: '900px'}}>
        <Grid item>
          <Title>パスワード変更</Title>
        </Grid>
        <Grid item>
          <BaseBox sx={{width: '747px'}}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{mb: '25.5px'}}>
              <Grid item xs={4}>
                <LabelTitle isRequired>現在のパスワード</LabelTitle>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  error={!!errors.currentPassword}
                  fullWidth
                  aria-label="password"
                  type={showCurrentPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}>
                          {showCurrentPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('currentPassword', {
                    required: true,
                  })}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" sx={{mb: '24px'}}>
              <Grid item xs={4} mt="15px">
                <LabelTitle isRequired>新しいパスワード</LabelTitle>
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={1}>
                  <TextField
                    error={!!errors.newPassword}
                    fullWidth
                    aria-label="password"
                    type={showNewPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}>
                            {showNewPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('newPassword', {
                      required: true,
                      minLength: 8,
                      pattern: combineAlphanumericCharacters,
                    })}
                  />
                  <PasswordValidation
                    wordCountError={
                      !!errors.newPassword?.types?.required ||
                      !!errors.newPassword?.types?.minLength ||
                      !dirtyFields.newPassword
                    }
                    alphanumericError={
                      !!errors.newPassword?.types?.required ||
                      !!errors.newPassword?.types?.pattern ||
                      !dirtyFields.newPassword
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            {submitError ? (
              <Typography
                color="warning.main"
                fontSize="16px"
                mb="24px"
                textAlign="center">
                入力された情報が誤っています。
                <br />
                もう一度、入力された情報をご確認ください。
              </Typography>
            ) : null}
            <Stack justifyContent="end" direction="row" spacing={3}>
              <Button variant="outlined" onClick={() => navigate('/profile')}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={disabled}>
                変更する
              </Button>
            </Stack>
          </BaseBox>
        </Grid>
      </Grid>
    </Stack>
  );
};

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
` as typeof Typography;

import {Repayment} from '../API';

export const convertRepaymentOrNullToString = (
  value: Repayment | null | undefined,
) => {
  switch (value) {
    case Repayment.EQUAL_PRINCIPAL:
      return '元金均等';
    case Repayment.EQUAL_INSTALLMENT:
      return '元利均等';
    default:
      return '';
  }
};
